import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.scss";

// import { Row, Col, Nav, Button } from 'react-bootstrap';
import give from "../../../Resources/Images/give.svg";
import MenuImg from "../../../Resources/Images/menu.svg";
import close from "../../../Resources/Images/close.svg";

const HeaderReply = ({ backToMainComponent = () => {}, headerTitle = "" }) => {
  return (
    <div className="header-wrapper reply">
      <div className="nav-rep">
        <img className="invisible" src={give}></img>
        <h3>{headerTitle}</h3>
        <span className="invisible" onClick={backToMainComponent}>
          Back
        </span>
      </div>
    </div>
  );
};

export default HeaderReply;
