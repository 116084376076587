import axios from 'axios';
import { eventChannel } from 'redux-saga';

const BASE_URL = process.env.REACT_APP_API_URL;
let socket;
// export const fetchMessagesService = async (request) => {
//     const REGISTER_API_ENDPOINT = `${BASE_URL}/message/fetchAllMessages`;

//     var res = await axios.post(
//         REGISTER_API_ENDPOINT,
//         { ...request.payload },
//         {
//             // headers: {
//             //     Accept: "application/json",
//             //     xsrfCookieName: "XSRF-TOKEN",
//             //     xsrfHeaderName: "X-XSRF-TOKEN",
//             // },
//         }
//     )
//         .then(function (response) {

//             return response;
//         })
//         .catch(function (error) {

//             return error;
//         });

//     return res;
// };

// export const makePromoteService = async (request) => {
//     const REGISTER_API_ENDPOINT = `${BASE_URL}/response/toggleSupport`;

//     var res = await axios.post(
//         REGISTER_API_ENDPOINT,
//         { ...request.payload },
//         {
//             // headers: {
//             //     Accept: "application/json",
//             //     xsrfCookieName: "XSRF-TOKEN",
//             //     xsrfHeaderName: "X-XSRF-TOKEN",
//             // },
//         }
//     )
//         .then(function (response) {

//             return response;
//         })
//         .catch(function (error) {

//             return error;
//         });

//     return res;
// };

// export const reportMsgService = async (request) => {
//     const REGISTER_API_ENDPOINT = `${BASE_URL}/response/toggleReport`;

//     var res = await axios.post(
//         REGISTER_API_ENDPOINT,
//         { ...request.payload },
//         {
//             // headers: {
//             //     Accept: "application/json",
//             //     xsrfCookieName: "XSRF-TOKEN",
//             //     xsrfHeaderName: "X-XSRF-TOKEN",
//             // },
//         }
//     )
//         .then(function (response) {
//           
//             return response;
//         })
//         .catch(function (error) {
//        
//             return error;
//         });

//     return res;
// }

export const fetchSingleMsgService = async (request) => {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/message/fetchSingleMessage?id=${request.payload.id}`;

    var res = await axios.get(
        REGISTER_API_ENDPOINT,
        {
            // headers: {
            //     Accept: "application/json",
            //     xsrfCookieName: "XSRF-TOKEN",
            //     xsrfHeaderName: "X-XSRF-TOKEN",
            // },
        }
    )
        .then(function (response) {
            return response;
        })
        .catch(function (error) {

            return error;
        });

    return res;
}

// Socket //

export const fetchMessagesService = async (request) => {
    socket = request.connect;

    return new Promise((resolve) => {
        socket.emit("join", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {
                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const makePromoteService = async (request) => {
    socket = request.connect;
    request.payload.payload.browserHash = localStorage.getItem("browserHash").toString();
    return new Promise((resolve) => {
        socket.emit("toggle-support-message", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {
                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const reportMsgService = async (request) => {
    socket = request.connect;
    request.payload.payload.browserHash = localStorage.getItem("browserHash").toString();
    request.payload.payload.type = 'message';
    return new Promise((resolve) => {
        socket.emit("toggle-report-message", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {
                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const listenUpdatedSupportCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("updated-support-message", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const listenUpdatedReportCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("updated-report-message", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const listenNewMsgService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("send-new-message", (response) => {

            emit(response);
        });
        return () => { };
    });
}

/**
 * API request to translate the selected message with the target message
 * @param {*} request 
 */
export const translateSingleMsgService = async (request) => {

    const TRANSLATE_MESSAGE_API_ENDPOINT = `${BASE_URL}/message/translateSingleMessage?id=${request.payload.id}&lang=${request.payload.lang}`;

    var res = await axios.get(
        TRANSLATE_MESSAGE_API_ENDPOINT
    )
        .then(function (response) {

            return response;
        })
        .catch(function (error) {

            return error;
        });

    return res;

}

export const submitReplyMsgService = async (request) => {
    socket = request.connect;
    request.payload.payload.browserHash = localStorage.getItem("browserHash").toString();

    return new Promise((resolve) => {
        socket.emit("send-reply", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                socket.disconnect();
                socket.off();
                res = error;
            } else {

                socket.disconnect();
                socket.off();
                res = response;
            }

            resolve(res);
        });
    });
}

export const fetchReplyListService = async (request) => {
    socket = request.connect;
    return new Promise((resolve) => {
        socket.emit("join-reply-room", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {

                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const listenReplyCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("updated-replyCount-message", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const listenNewReplyService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("get-new-reply", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const supprtReplyService = async (request) => {
    socket = request.connect;
    request.payload.payload.browserHash = localStorage.getItem("browserHash").toString();
    return new Promise((resolve) => {
        socket.emit("toggle-support-reply", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {

                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const reportReplyService = async (request) => {
    socket = request.connect;
    request.payload.payload.browserHash = localStorage.getItem("browserHash").toString();
    request.payload.payload.type = 'reply';
    return new Promise((resolve) => {
        socket.emit("toggle-report-reply", request.payload.payload, function (error, response) {
            let res = null;
            if (error) {
                // socket.disconnect();
                // socket.off();
                res = error;
            } else {

                // socket.disconnect();
                // socket.off();
                res = response;
            }
            resolve(res);
        });
    });
}

export const listenReplySupportCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("updated-support-reply", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const listenReplyReportCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("updated-report-reply", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const trackMyMessageService = async (request) => {
    const id = request.payload.msgID;
    delete request.payload.msgID;
    const REGISTER_API_ENDPOINT = `${BASE_URL}/reply/impactInfo?id=${id}`;

    var res = await axios.post(
        REGISTER_API_ENDPOINT,
        { ...request.payload },
        {
            // headers: {
            //     Accept: "application/json",
            //     xsrfCookieName: "XSRF-TOKEN",
            //     xsrfHeaderName: "X-XSRF-TOKEN",
            // },
        }
    )
        .then(function (response) {

            return response;
        })
        .catch(function (error) {

            return error;
        });

    return res;
}

/**
 * API request to translate a reply message
 * @param {*} request 
 */
export const translateReplyMsgService = async (request) => {

    const TRANSLATE_MESSAGE_API_ENDPOINT = `${BASE_URL}/reply/translateReplyMessage?id=${request.payload.id}&lang=${request.payload.lang}`;

    var res = await axios.get(
        TRANSLATE_MESSAGE_API_ENDPOINT
    )
        .then(function (response) {

            return response;
        })
        .catch(function (error) {

            return error;
        });

    return res;

}

export const fetchMessageSectorsService = async (request) => {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/recipient/getRecipientsTypes`;

    let res = await axios.get(
        REGISTER_API_ENDPOINT,
    )
        .then(function (response) {

            return response;
        })
        .catch(function (error) {

            return error;
        });

    return res;
}

export const fetchSingleReplyMsgService = async (request) => {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/reply/fetchReplyMessage?id=${request.payload.id}`;

    var res = await axios.get(
        REGISTER_API_ENDPOINT,
        {
            // headers: {
            //     Accept: "application/json",
            //     xsrfCookieName: "XSRF-TOKEN",
            //     xsrfHeaderName: "X-XSRF-TOKEN",
            // },
        }
    )
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });

    return res;
}

export const shareEmailMessageService = async (request) => {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/message/socialShareEmail`;

    var res = await axios.post(
        REGISTER_API_ENDPOINT,
        { ...request.payload },
        {
            // headers: {
            //     Accept: "application/json",
            //     xsrfCookieName: "XSRF-TOKEN",
            //     xsrfHeaderName: "X-XSRF-TOKEN",
            // },
        }
    )
        .then(function (response) {

            //console.log("response",response);
            return response;
        })
        .catch(function (error) {

            return error;
        });
    //console.log("res",res)
    return res;
}

export const listenUpdatedDittoCountService = async (request) => {
    // socket = request.connect;
    return eventChannel(emit => {
        socket.on("get-updated-MsgDitto-count", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const listenUpdatedDittoReplyCountService = async (request) => {
    return eventChannel(emit => {
        socket.on("get-updated-ReplyDitto-count", (response) => {
            emit(response);
        });
        return () => { };
    });
}

export const fetchMessagesForSearchService = async (request) => {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/message/fetchMessagesForSearch`;

    var res = await axios.post(
        REGISTER_API_ENDPOINT,
        { ...request.payload }
    )
        .then(response => {
            //console.log("response",response);
            return response;
        })
        .catch(error => {
            return error;
        });
    //console.log("res",res)
    return res;
}

export const donationRegistrationService = async (request) => {
    
     const DONATION_API_ENDPOINT = `${BASE_URL}/browser/registerDonation`;
    //  const DONATION_API_ENDPOINT = 'http://localhost:5000/browser/registerDonation';

    var res = await axios
      .post(
        DONATION_API_ENDPOINT,
        { ...request.payload },
        {
          // headers: {
          //     Accept: "application/json",
          //     xsrfCookieName: "XSRF-TOKEN",
          //     xsrfHeaderName: "X-XSRF-TOKEN",
          // },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  
    return res;
  };