import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import StoryList from './Pages/StoryList';

const StoryRoutes = () => {
    return (
        <Fragment>
            <Route path="/stories" exact 
                render={(props) => (
                    <StoryList {...props} type={"story"} />
                )}
            />
            <Route path="/charities" exact 
                render={(props) => (
                    <StoryList {...props} type={"video"} />
                )}
            />
        </Fragment>
    )
};

export default StoryRoutes;
