import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import HomePage from './Pages/HomePage';

const HomePageRoutes = () => {
    return (
        <Fragment>
            <Route path="/" exact component={HomePage} />
        </Fragment>
    )
};

export default HomePageRoutes;
