import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core"
import { connect } from "react-redux";
import "./gratitude-list.scss";
import { useTranslation } from "react-i18next";

import Marker from "../../../../Resources/Images/marker.svg";

import PromoteGreenImg from "../../../../Resources/Images/promote-green.svg";
import PromoteImg from "../../../../Resources/Images/promote.svg";
import SendImg from "../../../../Resources/Images/send.svg";
import TranslateImg from "../../../../Resources/Images/translate.svg";
import FalgImg from "../../../../Resources/Images/flag.svg";
import DittoImg from "../../../../Resources/Images/ditto.svg";
import FalgRedImg from "../../../../Resources/Images/flag-red.svg";
import WriteReply from "./WriteReply";

import GiveImg from "../../../../Resources/Images/give.svg";
import DefaultImg from "../../../../Resources/Images/Default.svg";
import FrontLinersImg from "../../../../Resources/Images/NursesDoctors.svg";
import DisasterReliefImg from "../../../../Resources/Images/DiasterRelief.svg";
import MentalHealthImg from "../../../../Resources/Images/MentalHealth.svg";
import DomesticViolenceImg from "../../../../Resources/Images/DomesticViolence.svg";
import SuperMarketImg from "../../../../Resources/Images/SuperMarket.svg";
import PublicServiceImg from "../../../../Resources/Images/PublicService.svg";
import FirstResponseImg from "../../../../Resources/Images/FirstResponse.svg";
import FamilyFriendsImg from "../../../../Resources/Images/FamilyFriends.svg";
import DeliveryImg from "../../../../Resources/Images/Delivery.svg";
import ChemistsImg from "../../../../Resources/Images/Chemists.svg";
import fbImage from "../../../../Resources/Images/sg.jpg";
import { getLanguage } from '../../../../Utils/Storage';
import { Helmet } from "react-helmet";
import { checkUserPrecenseAction } from "../../../../Actions/generalAction";

const ReplyList = ({
  replyData = {},
  msgData = {},
  makePromo = () => { },
  reportMsg = () => { },
  shareMsg = () => { },
  viewTranslateIcon = true,
  translateMsg = () => { },
  openDittoComponent = () => { },
  dittoMsg = () => { },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [writeMsg, setWriteMsg] = useState(false);
  const [receipientIcon, setReceipientIcon] = useState("");

  const language = getLanguage();

  let enableRepostMsg = false;

  if (replyData.ditto.dittoedUsers) {
    enableRepostMsg = localStorage.getItem("browserHash") !== replyData.browserHash &&
      replyData.ditto.dittoedUsers.findIndex((dittoUser) => dittoUser.browserHash === localStorage.getItem("browserHash")) < 0 ?
      true : false;
  }

  useEffect(() => {
    props.checkUserPrecenseAction();
  }, []);

  useEffect(() => {
    switch (replyData.messageIcon) {
      case "Nurses_Doctors":
        setReceipientIcon(FrontLinersImg);
        break;
      case "Disaster_Relief":
        setReceipientIcon(DisasterReliefImg);
        break;
      case "Mental_Health":
        setReceipientIcon(MentalHealthImg);
        break;
      case "Domestic_Violence":
        setReceipientIcon(DomesticViolenceImg);
        break;
      case "Supermarkets":
        setReceipientIcon(SuperMarketImg);
        break;
      case "Public_Service":
        setReceipientIcon(PublicServiceImg);
        break;
      case "First_Response":
        setReceipientIcon(FirstResponseImg);
        break;
      case "Family_Friends":
        setReceipientIcon(FamilyFriendsImg);
        break;
      case "Delivery":
        setReceipientIcon(DeliveryImg);
        break;
      case "Chemists":
        setReceipientIcon(ChemistsImg);
        break;
      default:
        setReceipientIcon(DefaultImg);
    }
  }, [replyData]);

  let replyLang = (replyData.language) ? replyData.language.split("-") : ["en"];
  if (replyLang[0] == language) {
    viewTranslateIcon = false;
  } else {
    viewTranslateIcon = true;
  }

  return (
    <div>
      <div>
        <div className="massege-item d-flex">
          <div className="icon">
            <img src={receipientIcon} alt="" />
          </div>

          <Helmet>
            <title>{t("Message of Gratitude")}</title>
            <meta name="description" content={replyData.replyMessage} />
            <meta property="og:title" content={replyData.author} />
            <meta property="og:description" content={replyData.replyMessage} />
            <meta property="og:url" content={`${process.env.REACT_APP_LIVE_URL}/msg/${replyData._id}`} />
            <meta property="og:image" content={fbImage} />
          </Helmet>
          <div className="w-100">
            <div className="descripton pl-0">
              <p className="name">{replyData.author}</p>
              <p className="location">
                <img src={Marker} alt="" />
                {replyData.authorLocation}
              </p>
              <p className="descripton">{replyData.replyMessage}</p>
            </div>
            <ul className="clearfix mb-2 w-100">
              <li>
                {" "}
                <a onClick={() => shareMsg(replyData)}>
                  <Tooltip title={t("Share")} enterDelay={500} arrow>
                    <img src={SendImg} alt="" />
                  </Tooltip>
                </a>
              </li>
              {viewTranslateIcon && (
                <li className="translate">
                  {" "}
                  <a onClick={() => translateMsg(replyData)}>
                    <Tooltip title={t("Translate")} enterDelay={500} arrow>
                      <img src={TranslateImg} alt="" />
                    </Tooltip>
                  </a>
                </li>
              )}
              <li>
                {" "}
                <a
                  onClick={() =>
                    reportMsg(
                      replyData,
                      replyData.indecentReport.reportedHashes.includes(
                        props.userPrecense.data.browserHash
                      )
                        ? true
                        : false
                    )
                  }
                >
                  <Tooltip title={t("Report")} enterDelay={500} arrow>
                    <img
                      src={
                        props.userPrecense.data ?
                          replyData.indecentReport.reportedHashes.includes(
                            props.userPrecense.data.browserHash
                          )
                            ? FalgRedImg
                            : FalgImg
                          : FalgImg
                      }
                      alt=""
                      className="flag"
                    />
                  </Tooltip>
                </a>
              </li>
              <li>
                <a onClick={() => makePromo(replyData)}>
                  <Tooltip title={t("Like")} enterDelay={500} arrow>
                    <img
                      src={
                        props.userPrecense.data ?
                          replyData.support.supportedHashes.includes(
                            props.userPrecense.data.browserHash
                          )
                            ? PromoteGreenImg
                            : PromoteImg
                          : PromoteImg
                      }
                      alt=""
                    />
                  </Tooltip>
                  {replyData.support.count > 0 ? (
                    <span className=" ml-1">{replyData.support.count}</span>
                  ) : (
                      ""
                    )}{" "}
                </a>
              </li>


              <li>
                <a onClick={() =>
                  enableRepostMsg ?
                    dittoMsg(replyData) : ""}>
                  {enableRepostMsg ?
                    <Tooltip title={t("Repost")} enterDelay={500} arrow>
                      <img
                        src={DittoImg}
                        alt=""
                      />
                    </Tooltip> :
                    replyData.ditto.dittoCount ?
                      <img
                        src={DittoImg}
                        alt=""
                      /> : ""
                  }
                </a>
                {replyData.ditto.dittoCount ?
                  <a onClick={() => openDittoComponent(replyData.ditto.dittoedUsers)}>
                    <Tooltip title={t("Reposted by")} enterDelay={500} arrow>
                      <span className="rep-count ml-1" >{replyData.ditto.dittoCount}</span>
                    </Tooltip>
                  </a>
                  : ""
                }
              </li>


            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction())
  };
};

const mapStateToProps = (response) => ({
  userPrecense: response.general.userPrecense,
});
export default connect(mapStateToProps, mapDispatchToProps)(ReplyList);
