import { put, call, take, takeLatest } from "redux-saga/effects";
import {
    fetchMessagesService,
    makePromoteService,
    reportMsgService,
    fetchSingleMsgService,
    listenUpdatedSupportCountService,
    listenUpdatedReportCountService,
    listenNewMsgService,
    submitReplyMsgService,
    fetchReplyListService,
    listenReplyCountService,
    listenNewReplyService,
    supprtReplyService,
    reportReplyService,
    listenReplySupportCountService,
    listenReplyReportCountService,
    translateSingleMsgService,
    trackMyMessageService,
    translateReplyMsgService,
    fetchMessageSectorsService,
    fetchSingleReplyMsgService,
    shareEmailMessageService,
    listenUpdatedDittoCountService,
    listenUpdatedDittoReplyCountService,
    fetchMessagesForSearchService,
    donationRegistrationService
} from "../Services/communityService";
import { connectSocket, connectSocketCommunity } from "../Services/socketService";

import * as types from "../Actions";

let communitySocket;
connectSocketCommunity().then((socket) => {
    communitySocket = socket;
})

export function* fetchMessagesSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(fetchMessagesService, { payload, connect: communitySocket });
        if (response.success) {
            yield put({ type: types.FETCH_MESSAGES_SUCCESS, response, connect: communitySocket });
        } else {
            yield put({ type: types.FETCH_MESSAGES_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_MESSAGES_FAIL, error });
    }
}

export function* makePromoteMsgSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(makePromoteService, { payload, connect: communitySocket });
        if (response.support) {
            yield put({ type: types.MAKE_PROMOTE_MSG_SUCCESS, response });
        } else {
            yield put({ type: types.MAKE_PROMOTE_MSG_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.MAKE_PROMOTE_MSG_FAIL, error });
    }
}

export function* reportMsgSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(reportMsgService, { payload, connect: communitySocket });
        if (response.success) {
            yield put({ type: types.REPORT_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.REPORT_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.REPORT_MESSAGE_FAIL, error });
    }
}

export function* fetchSingleMsgSaga(payload) {
    try {
        var response = yield call(fetchSingleMsgService, payload);
        if (response.success) {
            yield put({ type: types.FETCH_SINGLE_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_SINGLE_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_SINGLE_MESSAGE_FAIL, error });
    }
}

export function* listenUpdatedSupportCountSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);
        var channel = yield call(listenUpdatedSupportCountService, { connect: communitySocket });
        while (true) {
            var response = yield take(channel);
            if (response.success) {
                yield put({ type: types.LISTEN_UPDATED_SUPPORT_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_UPDATED_SUPPORT_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_UPDATED_SUPPORT_COUNT_FAIL, error });
    }
}

export function* listenUpdatedReportCountSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);
        var channel = yield call(listenUpdatedReportCountService, { connect: communitySocket });
        while (true) {
            var response = yield take(channel);
            if (response.success) {
                yield put({ type: types.LISTEN_UPDATED_REPORT_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_UPDATED_REPORT_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_UPDATED_SUPPORT_COUNT_FAIL, error });
    }
}

export function* listenNewMsgSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);
        var channel = yield call(listenNewMsgService, { connect: communitySocket });

        while (true) {
            var response = yield take(channel);
            if (response) {
                yield put({ type: types.LISTEN_NEW_MSG_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_NEW_MSG_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_NEW_MSG_FAIL, error });
    }
}

//translate message
export function* translateMessagesSaga(payload) {
    try {
        var response = yield call(translateSingleMsgService, payload);
        if (response.status == 200) {
            yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_FAIL, error });
    }
}

// Submit reply message 
export function* submitReplyMsgSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(submitReplyMsgService, { payload, connect: communitySocket });

        if (response._id) {
            yield put({ type: types.SUBMIT_REPLY_MSG_SUCCESS, response });
        } else {
            yield put({ type: types.SUBMIT_REPLY_MSG_FAIL, response });
        }

    } catch (error) {
        yield put({ type: types.SUBMIT_REPLY_MSG_FAIL, error });
    }
}

// Ftech reply msg list
export function* fetchReplyListSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(fetchReplyListService, { payload, connect: communitySocket });
        if (response) {
            yield put({ type: types.FETCH_REPLY_LIST_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_REPLY_LIST_FAIL, response });
        }

    } catch (error) {
        yield put({ type: types.FETCH_REPLY_LIST_FAIL, error });
    }
}

//Listen reply count
export function* listenReplyCountSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var channel = yield call(listenReplyCountService, { connect: communitySocket });

        while (true) {
            var response = yield take(channel);
            if (response._id) {
                yield put({ type: types.LISTEN_REPLY_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_REPLY_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_REPLY_COUNT_FAIL, error });
    }
}

// Listen new reply
export function* listenNewReplySaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var channel = yield call(listenNewReplyService, { payload, connect: communitySocket });

        while (true) {
            var response = yield take(channel);
            if (response._id) {
                yield put({ type: types.LISTEN_NEW_REPLY_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_NEW_REPLY_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_NEW_REPLY_FAIL, error });
    }
}

// Support reply msg 
export function* supportReplySaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(supprtReplyService, { payload, connect: communitySocket });

        if (response._id) {
            yield put({ type: types.SUPPORT_REPLY_SUCCESS, response });
        } else {
            yield put({ type: types.SUPPORT_REPLY_FAIL, response });
        }

    } catch (error) {
        yield put({ type: types.SUPPORT_REPLY_FAIL, error });
    }
}

// Report reply msg 
export function* reportReplySaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var response = yield call(reportReplyService, { payload, connect: communitySocket });

        if (response._id) {
            yield put({ type: types.REPORT_REPLY_SUCCESS, response });
        } else {
            yield put({ type: types.REPORT_REPLY_FAIL, response });
        }

    } catch (error) {
        yield put({ type: types.REPORT_REPLY_FAIL, error });
    }
}

// Listen reply support count
export function* listenReplySupportCountSaga(payload) {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);

        var channel = yield call(listenReplySupportCountService, { payload, connect: communitySocket });

        while (true) {
            var response = yield take(channel);
            if (response._id) {
                yield put({ type: types.LISTEN_REPLY_SUPPORT_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_REPLY_SUPPORT_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_REPLY_SUPPORT_COUNT_FAIL, error });
    }
}

// Listen reply report count
export function* listenReplyReportCountSaga(payload) {
    try {
        var channel = yield call(listenReplyReportCountService, { payload, connect: communitySocket });

        while (true) {
            var response = yield take(channel);
            if (response._id) {
                yield put({ type: types.LISTEN_REPLY_REPORT_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_REPLY_REPORT_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_REPLY_REPORT_COUNT_FAIL, error });
    }
}

// track my reply msg
export function* trackMyReplyMsgSaga(payload) {
    try {
        var response = yield call(trackMyMessageService, payload);
        if (response.success) {
            yield put({ type: types.TRACK_MY_REPLY_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.TRACK_MY_REPLY_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.TRACK_MY_REPLY_MESSAGE_FAIL, error });
    }
}

//translate reply message
export function* translateReplyMessagesSaga(payload) {
    try {
        var response = yield call(translateReplyMsgService, payload);
        if (response.status == 200) {
            yield put({ type: types.TRANSLATE_REPLY_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.TRANSLATE_REPLY_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.TRANSLATE_REPLY_MESSAGE_FAIL, error });
    }
}

export function* fetchMessageSectorsSaga(payload) {
    try {
        var response = yield call(fetchMessageSectorsService, payload);
        if (response) {
            yield put({ type: types.FETCH_MESSAGE_SECTORS_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_MESSAGE_SECTORS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_MESSAGE_SECTORS_FAIL, error });
    }
}

export function* fetchSingleReplyMsgSaga(payload) {
    try {
        var response = yield call(fetchSingleReplyMsgService, payload);
        if (response.data.success) {
            yield put({ type: types.FETCH_SINGLE_REPLY_MSG_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_SINGLE_REPLY_MSG_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_SINGLE_REPLY_MSG_FAIL, error });
    }
}

//translate reply message
export function* translateSingleReplyMessagesSaga(payload) {
    try {
        var response = yield call(translateSingleMsgService, payload);
        if (response.status == 200) {
            yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_REPLY_SUCCESS, response });
        } else {
            yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_REPLY_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.TRANSLATE_SINGLE_MESSAGE_REPLY_FAIL, error });
    }
}

//social sharing email message
export function* shareEmailMessageSaga(payload) {
    try {
        var response = yield call(shareEmailMessageService, payload);
        if (response.status == 200) {
            yield put({ type: types.SHARE_EMAIL_MESSAGE_SUCCESS, response });
        } else {
            yield put({ type: types.SHARE_EMAIL_MESSAGE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.SHARE_EMAIL_MESSAGE_FAIL, error });
    }
}

// updated ditto count saga
export function* listenUpdatedDittoCountSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);
        var channel = yield call(listenUpdatedDittoCountService, { connect: communitySocket });
        while (true) {
            var response = yield take(channel);
            if (response.state === "SUCCESS") {
                yield put({ type: types.LISTEN_UPDATED_DITTO_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_UPDATED_DITTO_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_UPDATED_DITTO_COUNT_FAIL, error });
    }
}

export function* listenUpdatedDittoReplyCountSaga() {
    try {
        if (!communitySocket.connected)
            communitySocket = yield call(connectSocketCommunity);
        var channel = yield call(listenUpdatedDittoReplyCountService, { connect: communitySocket });
        while (true) {
            var response = yield take(channel);
            if (response.state === "SUCCESS") {
                yield put({ type: types.LISTEN_UPDATED_DITTO_REPLY_COUNT_SUCCESS, response });
            } else {
                yield put({ type: types.LISTEN_UPDATED_DITTO_REPLY_COUNT_FAIL, response });
            }
        }
    } catch (error) {
        yield put({ type: types.LISTEN_UPDATED_DITTO_REPLY_COUNT_FAIL, error });
    }
}

//fetch messages for search
export function* fetchMessagesForSearchSaga(payload) {
    try {
        var response = yield call(fetchMessagesForSearchService, payload);
        if (response.data.success) {
            yield put({ type: types.FETCH_MESSAGES_FOR_SEARCH_SUCCESS, response: response.data.messages });
        } else {
            yield put({ type: types.FETCH_MESSAGES_FOR_SEARCH_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_MESSAGES_FOR_SEARCH_FAIL, error });
    }
}

export function* donateRegistrationSaga(payload) {
    try {
      var response = yield call(donationRegistrationService, payload);
      //console.log("responce register", response);
      if (response.data.success) {
        yield put({ type: types.DONATION_REGISTRATION_SUCCESS, response });
      } else {
        yield put({ type: types.DONATION_REGISTRATION_SUCCESS, response });
      }
    } catch (error) {
      yield put({ type: types.DONATION_REGISTRATION_FAIL, error });
    }
  }