import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core"
import { connect } from "react-redux";
import "./gratitude-list.scss";
import { useTranslation } from "react-i18next";
import { YoutubePlayer, VimeoPlayer } from '../../../Common/VideoPlayer';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import play from "../../../../Resources/Images/playb.svg";


const StoryList = ({
  dataList = {},
  ...props
}) => {

  const [youtubePlayer, setYoutubePlayer] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(false);
  const [storyModal, setStoryModal] = useState(false);
  const { t, i18n } = useTranslation();

  const openVideoPlayer = () => {
    if(dataList.player == "youtube"){
      setYoutubePlayer(true);
    }else if(dataList.player == "vimeo"){
      setVimeoPlayer(true);
    }
  }

  const closeVideoPlayer = () => {
    setYoutubePlayer(false);
    setVimeoPlayer(false);
  }

  const openStoryModal = () => {
    setStoryModal(true);
  }

  const closeStoryModal = () => {
    setStoryModal(false);
  }

  return (
    <>
      <div className="massege-item story d-flex">
        {dataList.type == "video" ? (
          <>
          <div className="st-img positon-relative" style={{backgroundImage:`url(${dataList.thumb})`}}onClick={()=> openVideoPlayer()}>
            <img className="playb" src={play}></img>
            </div>

            <div className="st-wrap">
              <div className="descripton pl-0">
                <p className="name"><a onClick={()=> openVideoPlayer()} target="_blank">{dataList.title}</a></p>
                <p className="descripton">{dataList.description}</p>
              </div>
              <a className='more' onClick={()=> openVideoPlayer()}>{t('viewFeatured')}</a>
            </div>
          </>
        ): dataList.type == "story" ?(
          <>
            <div className="st-img" style={{backgroundImage:`url(${dataList.thumb})`}}>
            </div>

            <div className="st-wrap">
              <div className="descripton pl-0">
                <p className="name"><a  onClick={()=> openStoryModal()}>{dataList.title}</a></p>
                <small>{dataList.postDate}</small>
                
                <p className="descripton">{dataList.description}</p>
              </div>
              <a className='more'  onClick={()=> openStoryModal()}>{t('viewStory')}</a>
              
            </div>
          </>
        ): null}
      </div>

      <Dialog
        open={youtubePlayer || vimeoPlayer}
        onClose={closeVideoPlayer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="email-share vid"
      >
        <div className="mo-header">
          {" "} 
          {/* <img src={send} className='send-mo'></img> */}
          <span>{dataList.title} {" "} </span>
          <span onClick={closeVideoPlayer} color="secondary" autoFocus>
            {t('back')}
          </span>
        </div>
        <DialogContent>
          {/* <h5>{subHeader}</h5> */}
              <>
                {youtubePlayer ? 
                  dataList.hasOwnProperty('autoplay') && !dataList.autoplay ?
                    <YoutubePlayer 
                      videoId={dataList.videoId}
                      autoplay="0" 
                      rel="0" 
                      modest="1"
                  /> 
                  :
                  <YoutubePlayer 
                    videoId={dataList.videoId}
                    autoplay="1" 
                    rel="0" 
                    modest="1"
                  /> 
                : vimeoPlayer ? 
                  <VimeoPlayer 
                    videoId={dataList.videoId}
                  />
                  :
                  "No video"           
                }    

              </>

        </DialogContent>
      </Dialog>


      <Dialog
        open={storyModal}
        onClose={closeStoryModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="email-share story"
      >
        <div className="mo-header">
          {" "} 
          {/* <img src={send} className='send-mo'></img> */}
          <span>{dataList.title} {" "} </span>
          <span onClick={closeStoryModal} color="secondary" autoFocus>
            {t('back')}
          </span>
        </div>
        <DialogContent>
          {/* <h5>{subHeader}</h5> */}
         
              <>
                <iframe  
                  type="text/html" 
                  width="100%" 
                  height="500"
                  frameBorder="0"
                  src={dataList.url}
                />  

              </>
       

        </DialogContent>
      </Dialog>

    </>
  );
};

const mapStateToProps = (response) => ({
  //userPrecense: response.general.userPrecense,
});
export default connect(mapStateToProps)(StoryList);
