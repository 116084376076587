import { put, call } from "redux-saga/effects";
import { checkUserPrecenseService, getUserInfoService,getVolunteerInfoService } from "../Services/generalService";

import * as types from "../Actions";

export function* checkUserPrecense(payload) {

    try {
        var response = yield call(checkUserPrecenseService, payload);

        if (response) {
            yield put({ type: types.CHECK_PRECENSE_SUCCESS, response });
        } else {
            yield put({ type: types.CHECK_PRECENSE_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.CHECK_PRECENSE_FAIL, error });
    }
}

export function* getUserInfoSaga(payload) {

    try {
        var response = yield call(getUserInfoService, payload);

        if (response) {
            yield put({ type: types.GET_USER_INFO_SUCCESS, response });
        } else {
            yield put({ type: types.GET_USER_INFO_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.GET_USER_INFO_FAIL, error });
    }
}

export function* getVolunteerInfoSaga(payload) {

    try {
        var response = yield call(getVolunteerInfoService, payload);

        if (response) {
            yield put({ type: types.GET_VOLUNTEER_INFO_SUCCESS, response });
        } else {
            yield put({ type: types.GET_VOLUNTEER_INFO_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.GET_VOLUNTEER_INFO_FAIL, error });
    }
}
