import { takeLatest } from 'redux-saga/effects';

import * as types from '../Actions';
import { fetchRecipientsSaga, submitGratitudeSaga, trackMyMessageSaga ,RegisterVolunteerSaga } from './gratitudeSaga';
import {
    fetchMessagesSaga,
    makePromoteMsgSaga,
    reportMsgSaga,
    fetchSingleMsgSaga,
    listenUpdatedSupportCountSaga,
    listenUpdatedReportCountSaga,
    listenNewMsgSaga,
    submitReplyMsgSaga,
    fetchReplyListSaga,
    listenReplyCountSaga,
    listenNewReplySaga,
    supportReplySaga,
    reportReplySaga,
    listenReplySupportCountSaga,
    listenReplyReportCountSaga,
    translateMessagesSaga,
    trackMyReplyMsgSaga,
    translateReplyMessagesSaga,
    fetchMessageSectorsSaga,
    fetchSingleReplyMsgSaga,
    translateSingleReplyMessagesSaga,
    shareEmailMessageSaga,
    listenUpdatedDittoCountSaga,
    listenUpdatedDittoReplyCountSaga,
    fetchMessagesForSearchSaga,
    donateRegistrationSaga
} from './communitySaga';
import { checkUserPrecense, getUserInfoSaga ,getVolunteerInfoSaga} from './generalSaga';
import { fetchWorldCountsSaga, fetchUserDetailsSaga} from './worldSaga';
import { 
    createCustomerForPaymentSaga,
    paymentCheckoutSaga,
    fetchOrdersSaga,
    setGratitudeMsgSaga,
} from './paymentSaga';

export default function* watchUserAction() {
    //gratitude
    yield takeLatest(types.FETCH_RECIPIENTS, fetchRecipientsSaga);
    yield takeLatest(types.SUBMIT_GRATITUDE_MSG, submitGratitudeSaga);
    yield takeLatest(types.USER_REGISTRATION, trackMyMessageSaga);
    yield takeLatest(types.VOLUNTEER_REGISTRATION, RegisterVolunteerSaga);

    //community
    yield takeLatest(types.FETCH_MESSAGES, fetchMessagesSaga);
    yield takeLatest(types.MAKE_PROMOTE_MSG, makePromoteMsgSaga);
    yield takeLatest(types.REPORT_MESSAGE, reportMsgSaga);
    yield takeLatest(types.FETCH_SINGLE_MESSAGE, fetchSingleMsgSaga);
    yield takeLatest(types.LISTEN_UPDATED_SUPPORT_COUNT, listenUpdatedSupportCountSaga);
    yield takeLatest(types.LISTEN_UPDATED_REPORT_COUNT, listenUpdatedReportCountSaga);
    yield takeLatest(types.LISTEN_NEW_MSG, listenNewMsgSaga);
    yield takeLatest(types.TRANSLATE_SINGLE_MESSAGE, translateMessagesSaga);
    yield takeLatest(types.TRANSLATE_REPLY_MESSAGE, translateReplyMessagesSaga);
    yield takeLatest(types.FETCH_MESSAGE_SECTORS, fetchMessageSectorsSaga);
    yield takeLatest(types.SHARE_EMAIL_MESSAGE, shareEmailMessageSaga);
    yield takeLatest(types.LISTEN_UPDATED_DITTO_COUNT, listenUpdatedDittoCountSaga);
    yield takeLatest(types.LISTEN_UPDATED_DITTO_REPLY_COUNT, listenUpdatedDittoReplyCountSaga);
    yield takeLatest(types.FETCH_MESSAGES_FOR_SEARCH, fetchMessagesForSearchSaga);
    yield takeLatest(types.DONATION_REGISTRATION, donateRegistrationSaga);

    // reply to msg
    yield takeLatest(types.SUBMIT_REPLY_MSG, submitReplyMsgSaga);
    yield takeLatest(types.FETCH_REPLY_LIST, fetchReplyListSaga);
    yield takeLatest(types.LISTEN_REPLY_COUNT, listenReplyCountSaga);
    yield takeLatest(types.LISTEN_NEW_REPLY, listenNewReplySaga);
    yield takeLatest(types.SUPPORT_REPLY, supportReplySaga);
    yield takeLatest(types.REPORT_REPLY, reportReplySaga);
    yield takeLatest(types.LISTEN_REPLY_SUPPORT_COUNT, listenReplySupportCountSaga);
    yield takeLatest(types.LISTEN_REPLY_REPORT_COUNT, listenReplyReportCountSaga);
    yield takeLatest(types.TRACK_MY_REPLY_MESSAGE, trackMyReplyMsgSaga);
    yield takeLatest(types.FETCH_SINGLE_REPLY_MSG, fetchSingleReplyMsgSaga);
    yield takeLatest(types.TRANSLATE_SINGLE_REPLY_MESSAGE, translateSingleReplyMessagesSaga);

    //general
    yield takeLatest(types.CHECK_PRECENSE, checkUserPrecense);
    yield takeLatest(types.GET_USER_INFO, getUserInfoSaga);
    yield takeLatest(types.GET_VOLUNTEER_INFO, getVolunteerInfoSaga);

    //world
    yield takeLatest(types.FETCH_WORLD_COUNT, fetchWorldCountsSaga);

    //user
    yield takeLatest(types.FETCH_USER_DETAILS, fetchUserDetailsSaga);

    //payment
    yield takeLatest(types.PAYMENT_CREATE_CUSTOMER, createCustomerForPaymentSaga);
    yield takeLatest(types.PAYMENT_CHECKOUT, paymentCheckoutSaga);
    yield takeLatest(types.FETCH_ORDERS, fetchOrdersSaga);
    yield takeLatest(types.SET_GRATITUDE_MSG, setGratitudeMsgSaga);
}