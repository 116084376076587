import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WithContext as ReactTags } from "react-tag-input";
import { queryGooglePlaces } from "../../../../Utils/GoogleAPI";
import campains from "../../../../Resources/Files/campain";

import give from "../../../../Resources/Images/give.svg";
import leftArrow from "../../../../Resources/Images/left-arrow.svg";

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];

const TagRecipient = ({
  backToMainComponent = () => { },
  deleteRecipient = () => { },
  addRecipient = () => { },
  handleInputchange = () => { },
  handleSuggestions = () => { },
  tags = [],
  suggestions = [],
  textInput = '',
  senderLocation = {}
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  var dateObj = new Date();
  var currentData = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    var tagAr = [];
    tags.map((tag) => {
      tagAr.push(tag.text);
    });
    setSelectedTags(tagAr);
  }, [tags]);
  return (
    <div>
      <div className="header-wrapper">
        <img src={give}></img>
        <h3>Recipients</h3>
        <span className="" onClick={backToMainComponent}>
          Back
        </span>
      </div>

      <div className="tagger-wrapper">
        <span onClick={backToMainComponent} className="bk-btn">
          <img src={leftArrow} /> {t("back")}
        </span>

        <div className="tagger">
          <ReactTags
            id="recipientTag"
            tags={tags}
            handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => handleSuggestions(textInputValue, possibleSuggestionsArray)}
            suggestions={suggestions}
            allowDeleteFromEmptyInput={false}
            handleDelete={deleteRecipient}
            handleAddition={addRecipient}
            delimiters={delimiters}
            allowDragDrop={false}
            inputFieldPosition="top"
            placeholder={t("find_and_add_recipient")}
            minQueryLength={1}
            classNames={{
              tag: "tag-options",
              remove: "removeClass",
              tagInput: "serach-panel",
              tagInputField: "form-control",
            }}
            renderSuggestion={({ id, text, icon }, query) => <div style={{}}><img src={icon} height={12} /> {text}</div>}
          />
          {suggestions.length === 0 && textInput && (
            <span className='add-button' onClick={(e) => {
              addRecipient({ id: textInput, text: textInput });
              document.getElementById("recipientTag").value = "";
            }}>Add</span>
          )}

        </div>
        <span onClick={backToMainComponent} className="done-btn p-0">
          {t("Done")}
        </span>
      </div>

      { // Trending tags
        campains.map((campain) => {
          var startDate = campain.start_date.split("/");
          var endDate = campain.end_date.split("/");
          var today = currentData.split("/");

          var sDate = new Date(startDate[2], parseInt(startDate[1]) - 1, startDate[0]);
          var eDate = new Date(endDate[2], parseInt(endDate[1]) - 1, endDate[0]);
          var tDate = new Date(today[2], parseInt(today[1]) - 1, today[0]);

          if ((sDate <= tDate && eDate >= tDate) && campain.city_place_ids.includes(senderLocation.placeId)) {
            return campain.feature_tags.map((featureTag, key) => (
              <>
                {key == 0 && (<h5 className='tit mt-3'>Trending Tags</h5>)}
                <span className={`tag-options ${selectedTags.includes(featureTag) ? 'tag-exsist' : ''} `} onClick={() => addRecipient({ text: featureTag, type: "non-custom" })}>{`#${featureTag}`}</span>
              </>
            ))
          }
        })}
    </div>
  );
};

export default TagRecipient;
