import React from "react";
import Layout from "../../../Layout/Layout";
import Form from "../Components/Donation/PrintCard/Form";
import giftvine from "../../../../Resources/Images/gb.gif";

const WineBottle = () => {
  const priceList = [15, 30, 45];

  return (
    <Layout>
      <div className="text-wrapper">
        <div className="col-md-12 pl-0 pr-0 text-center give-little">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list no-border pt-0 mw-5 gift">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p className="text-uppercase tit">
                      Simple Way to <strong>Give a little</strong>
                    </p>
                    <Form
                      giftName="Bottle of Wine"
                      giftPrice={priceList[1]}
                      giftPriceList={priceList}
                      giftImg={giftvine}
                      giftDsc="Your beautiful, impactful gesture. You are also supporting a local winery in the region who will hand-select a bottle of wine on your behalf."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    </Layout>
  );
};

export default WineBottle;
