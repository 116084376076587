import React, { Fragment,useState } from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { validateEmail } from '../../../../../../Utils/Util';

const RecipientDetailsForm = ({
    gratitudeMsg = '',
    giftImg = '',
    giftName = '',
    giftDsc = '',
    formData,
    changeValue = () => {},
    setRecipientAddress = () => {},
    continueWizard = () => {},
}) => {
    const { recipientDetails } = formData;
    const address              = recipientDetails.address;
    const [isProccessValidation, setIsProccessValidation] = useState(false);

    const validateForm = () => {
        setIsProccessValidation(true);

        let isValidEmail = true;

        const isValid =  !recipientDetails.name ||
            !recipientDetails.address ?
                false :
                true;

        if (recipientDetails.email)
            isValidEmail = validateEmail(recipientDetails.email)

        if (isValid && isValidEmail)
            continueWizard('confirmation');
    }

    const renderGoogleSearchInput = (props) => {
        return (
            <div className="serach-panel">
                {" "}
                <input
                  {...props}
                  type="text"
                  className="form-control mb-0 js-search-place"
                  placeholder="Recipient Address"
                />
            </div>
        );
    }

    const renderGoogleSearchSuggestions = (
        suggestions,
        onSelectSuggestion
    ) => {
        return (
            <div>
                <div className="search-results">
                    {suggestions.map((suggestion) => (
                        <div
                            key={suggestion.place_id}
                            className="col-md-12 mt-3"
                            onClick={(event) => onSelectSuggestion(suggestion, event)}
                        >
                            <div className="row">
                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 pl-0">
                                    <p className="main-result">
                                        <i className="fas fa-map-marker-alt"></i>
                                        {suggestion.structured_formatting.main_text
                                        ? suggestion.structured_formatting.main_text
                                        : ""}
                                        <span className="sub-text">
                                        {suggestion.structured_formatting.secondary_text
                                            ? suggestion.structured_formatting.secondary_text
                                            : ""}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 mt-1 p-0">
                                    {" "}
                                    <i className="fas fa-plus-circle"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                    <img src='https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png?hl=fr'/>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <div className='giftwrapper'>
                <div className='giftvideo'>
                <img src={giftImg} alt="" />
                </div>
                <div className='giftdsc'>
                    <h3>
                        {giftName}
                        {gratitudeMsg.recipientName ? ` for ${gratitudeMsg.recipientName}`: ''}
                    </h3>
                    <small>By ShareGratitude</small>
                    <p>{giftDsc}</p>
                    <h2>{formData.giftAmount} {formData.currencyCode}</h2>
                    <small>One all-inclusive price includes gift purchase, postage & handling, transaction fees, and applicable taxes.</small>
                    
                </div>
            </div>
            <h4 className="tit mt-4">Recipient Details</h4>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                            <input
                                value={recipientDetails.name}
                                name="name"
                                type="text"
                                className="form-control form-control-sm pl-3 text-left"
                                maxLength={130}
                                placeholder={"Recipient's name or organization"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !recipientDetails.name ?
                                    <span className="inp-error">
                                        Name is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="input-list">
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                                    <input
                                        value={recipientDetails.email}
                                        name="email"
                                        type="text"
                                        className="form-control form-control-sm pl-3 text-left"
                                        maxLength={300}
                                        placeholder={"Recipient Email"}
                                        onChange={(e) => changeValue(e) }
                                    />
                                    {
                                        !validateEmail(recipientDetails.email) &&
                                        recipientDetails.email !== '' ?
                                            <span className="inp-error">
                                                Incorrect Format
                                            </span> :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="input-list">
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                                    <input
                                        value={recipientDetails.phoneNumber}
                                        name="phoneNumber"
                                        type="text"
                                        className="form-control form-control-sm pl-3 text-left"
                                        maxLength={15}
                                        placeholder={"Phone Number"}
                                        onChange={(e) => changeValue(e) }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                            <div className="tagger">
                                <GooglePlacesAutocomplete
                                    renderInput={(props) =>
                                        renderGoogleSearchInput(props)
                                    }
                                    renderSuggestions={(active, suggestions, onSelectSuggestion) =>
                                        renderGoogleSearchSuggestions(suggestions, onSelectSuggestion)
                                    }
                                    value={address}
                                    onSelect={async (suggestions) =>
                                        setRecipientAddress(suggestions.description)
                                    }
                                />
                                {/* <span>{address}</span> */}
                            </div>
                            {
                                isProccessValidation && !recipientDetails.address ?
                                    <span className="inp-error">
                                        Address is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <a
                    onClick={() => validateForm()}
                    href="#"
                    className={`
                        float-right
                        mt-0
                        track
                        btn
                        btn-secondary
                        ${
                            !recipientDetails.name    ||
                            !recipientDetails.address || !recipientDetails.email
                            ?
                                'inactive' :
                            'active'
                        }
                    `}
                >
                    CONTINUE
                </a>
            </div>
        </Fragment>
    );
}

export default RecipientDetailsForm;
