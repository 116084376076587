import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

const ModelDitto = ({
  open = false,
  modelHeader = "-",
  modelContent = "-",
  button1 = "Yes",
  button2 = "No",
  trigerFunction = () => { },
  closeModel = () => { },
}) => {
  const { t, i18n } = useTranslation();
  const [isEnableImpactOfMsg, setIsEnableImpactOfMsg] = useState(true);
  const [impactOfMsg, setImpactOfMsg] = useState(true);

  const handleClose = () => {
    closeModel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{modelHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className='pt-3'>
          {modelContent}
          <div className="row mt-4">
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-10">
              <label htmlFor="">
                {t("show_me_the_impact_of_my_message")}
              </label>
            </div>
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 pl-0 input-img">
              <label className="switch float-right">
                <input
                  checked={impactOfMsg ? true : false}
                  onChange={() => setImpactOfMsg(!impactOfMsg)}
                  type="checkbox"
                  checked={impactOfMsg}
                />{" "}
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          {button2}
        </Button>
        <Button onClick={() => trigerFunction(impactOfMsg)} color="primary">
          {button1}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelDitto;
