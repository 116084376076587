import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./header.scss";
import SimplePopover from "../../Common/Popover";

// import { Row, Col, Nav, Button } from 'react-bootstrap';
import globe from "../../../Resources/Images/globe.svg";
import CommunityImg from "../../../Resources/Images/Community.svg";
import Dn from "../../../Resources/Images/World.svg";
import StoryImg from "../../../Resources/Images/donation.svg";
import { setUserVisitBeforeAction } from "../../../Actions/generalAction";


const Footer = (props) => {
  /* popover */
  const writeGratitudeMobEl = useRef(null);
  const communityMobEl = useRef(null);
  const worldMapMobEl = useRef(null);

  const [openPopover, setOpenPopover] = useState(false);
  const [targetEl, setTargetEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  /* End popover */

  const { t, i18n } = useTranslation();
  let location = useLocation();
  let urlpath = location.pathname.replace("/", "");

  const [selectedTab, setSelectedTab] = useState(
    urlpath ? urlpath : "community"
  );
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (props.userPrecense.data) {
      if (window.innerWidth < 768 && props.userPrecense.toolTipsRequiered && props.tourGuideIsView) {
        /* Gratitude tab popover */
        // setTimeout(() => {
        //   setPopoverContent(t('Share your message'));
        //   setTargetEl(writeGratitudeMobEl.current);
        //   setOpenPopover(false);
        // }, 4000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 8000);

        /* Community tab popover */
        // setTimeout(() => {
        //   setPopoverContent('All community messages');
        //   setTargetEl(communityMobEl.current);
        //   setOpenPopover(true);
        // }, 6000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 10000);

        /* Wordmap tab popover */
        // setTimeout(() => {
        //   setPopoverContent('World map');
        //   setTargetEl(worldMapMobEl.current);
        //   setOpenPopover(true);
        // }, 11000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 15000);
        // props.userPrecense.toolTipsRequiered = false;
        // props.setUserVisitBeforeAction(props.userPrecense);
      }
    }

  }, [props.userPrecense, props.tourGuideIsView]);

  return (
    <div className="col-md-12 option-panel">
      <div className="row">
        {" "}
        <NavLink
          ref={writeGratitudeMobEl}
          exact
          to="/gratitude"
          className="col-4 col-md-4"
          href="gratitude.html"
          activeClassName="active"
        >
          <div className="icon">
            <img src={globe} alt="" />
          </div>
          {t("Write a message")}
        </NavLink>
        <NavLink
          ref={communityMobEl}
          exact
          to="/"
          className="col-4 col-md-4"
          href="index.html"
          activeClassName="active"
        >
          <div className="icon">
            <img src={CommunityImg} alt="" />
          </div>
          {t("View messages")}
        </NavLink>
        
        <NavLink
          ref={worldMapMobEl}
          exact
          to="/world"
          className="col-4 col-md-4"
          href=""
          activeClassName="active"
        >
          <div className="icon">
            <img src={Dn} alt="" />
          </div>
          <div className='blocker d-none'> Coming Soon</div>
          {t("world_view")}
        </NavLink>

        <SimplePopover
          open={openPopover}
          anchorEl={targetEl}
          content={popoverContent}
          anchorOriginVertical='top'
          anchorOriginHorizontal='center'
          transformOriginVertical='bottom'
          transformOriginHorizontal='center'
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserVisitBeforeAction: (data) => dispatch(setUserVisitBeforeAction(data))
  };
};

const mapStateToProps = (response) => ({
  userPrecense: response.general.userPrecense,
  tourGuideIsView: response.general.tourGuideIsView
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
