import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import Gratitude from './Pages/Gratitude';
import GratitudeCopy from './Pages/Gratitude copy';
const GratitudeRoutes = () => {
    return (
        <Fragment>
            <Route path="/gratitude" exact component={Gratitude} />
            <Route path="/copygratitude" exact component={GratitudeCopy} />
        </Fragment>
    )
};

export default GratitudeRoutes;

