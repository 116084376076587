import React from "react";

const PrivacyPolicyZH = () => {
  return (
    <>
    <div className='stc-wrapper'>
      <h4 className='text-center'>隱私政策</h4>
      <div className='text-center'>
        <p>
          感謝您的關注並訪問我們的平台。在下面，您會發現我們的律師事務所提供的一些法律術語，其明顯之處在於我們不會濫用您的個人信息並遵守與隱私權有關的法律。長話短說，我們確實尊重您的隱私，如果您對此政策有任何疑問或需要更多詳細信息，歡迎您
          <a href="https://about.sharegratitude.com/contact/" target="_blank">
            與我們聯繫
          </a>
          。再次感謝您對Share Gratitude TM的關注，並與我們一起度過這段時間。
        </p>
        <p className='text-center semi-bold'>Mikey Stojcevski，首席技術官兼全球運營</p>
      </div>
      <hr />
      <p>
        您的隱私對我們非常重要。共享GRATEITUDE公司的政策是尊重您在我們通過我們的平台
        <a href="http://sharegratitude.com/">http://sharegratitude.com</a>
        從您那裡收集的任何信息的隱私權, 以及我們擁有和經營的其他網站。
      </p>
      <p className='bold'>1.我們收集的信息</p>
      <p>記錄數據</p>
      <p>
        當您訪問我們的網站時，我們的服務器可能會自動記錄您的Web瀏覽器提供的標準數據。它可能包括計算機的Internet協議（IP）地址，瀏覽器類型和版本，您訪問的頁面，訪問的時間和日期，在每個頁面上花費的時間以及其他詳細信息。
      </p>
      <p>設備數據</p>
      <p>
        我們還可能收集有關您用於訪問我們網站的設備的數據。此數據可能包括設備類型，操作系統，唯一的設備標識符，設備設置和地理位置數據。我們收集的內容取決於您設備和軟件的個別設置。我們建議您檢查設備製造商或軟件提供商的政策，以了解他們向我們提供了哪些信息。
      </p>
      <p>個人信息</p>
      <p>我們可能會要求您提供個人信息，例如您的：</p>
      <ul type="disc">
        <li>名稱</li>
        <li>電子郵件</li>
        <li>社交媒體資料</li>
        <li>電話/手機號碼</li>
        <li>家庭/郵寄地址</li>
        <li>工作地址</li>
        <li>支付信息</li>
        <br />
      </ul>
      <p className='bold'>2.處理的法律依據</p>
      <p>
        我們將合法，公正和透明地處理您的個人信息。我們僅在有法律依據的情況下收集和處理有關您的信息。
      </p>
      <p>
        這些法律依據取決於您使用的服務以及使用方式，這意味著我們僅在以下情況下收集和使用您的信息：
      </p>
      <ul type="disc">
        <li>
          為了履行您作為當事方的合同，或者在您訂立這樣的合同之前（例如，當我們提供您要求我們的服務時）應您的要求採取措施是必要的；
        </li>
        <li>
          它滿足合法權益（不會被您的數據保護權益所取代），例如用於研發，推銷和推廣我們的服務以及保護我們的合法權益；
        </li>
        <li>
          您同意我們出於特定目的這樣做（例如，您可能同意我們向您發送新聞通訊）；要么
        </li>
        <li>我們需要處理您的數據以遵守法律義務。</li>
        <br />
      </ul>
      <p>
        如果您同意我們出於特定目的使用有關您的信息，則您有權隨時改變主意（但這不會影響已經進行的任何處理）。
      </p>
      <p>
        我們不保留個人信息的時間比是必要的。在保留這些信息的同時，我們將以商業上可接受的方式保護它，以防止丟失和盜竊以及未經授權的訪問，披露，複製，使用或修改。也就是說，我們建議，沒有任何一種電子傳輸或存儲方法是100％安全的，並且不能保證絕對的數據安全性。如有必要，我們可能會保留您的個人信息，以履行法律義務或保護您的切身利益或另一自然人的切身利益。
      </p>
      <p className='bold'>3.信息的收集和使用</p>
      <p>
        我們可能出於以下目的收集，保存，使用和披露信息，並且不會以與這些目的不兼容的方式進一步處理個人信息：
      </p>
      <ul type="disc">
        <li>
          使您能夠自定義或個性化您對我們平台和我們擁有和運營的其他網站的體驗；
        </li>
        <li>
          使您能夠訪問和使用我們的網站，關聯的應用程序和關聯的社交媒體平台；
        </li>
        <li>與您聯繫並溝通；</li>
        <li>用於內部記錄保存和管理目的；</li>
        <li>
          用於分析，市場研究和業務開發，包括運營和改進我們的網站，關聯的應用程序和關聯的社交媒體平台；
        </li>
        <li>舉辦比賽和/或為您提供其他好處；</li>
        <li>遵守我們的法律義務並解決我們可能遇到的任何爭議；和</li>
        <li>考慮您的就業申請。</li>
        <br />
      </ul>
      <p className='bold'>4.向第三方披露個人信息</p>
      <p>我們可能會將個人信息披露給：</p>
      <ul type="disc">
        <li>
          第三方服務提供商，目的是使他們能夠提供服務，包括（但不限於）IT服務提供商，數據存儲，託管和服務器提供商，廣告網絡，分析，錯誤記錄器，收債員，維護或解決問題的提供商，營銷或廣告提供商，專業顧問和支付系統運營商；
        </li>
        <li>我們的員工，承包商和/或相關實體；</li>
        <li>我們舉辦的任何比賽的讚助商或發起人；</li>
        <li>
          如果您不支付我們提供給您的商品或服務的費用，信用報告機構，法院，法庭和監管機構；
        </li>
        <li>
          根據法律要求，與任何實際或未來的法律程序有關，或為了確立，行使或捍衛我們的合法權利的法院，法庭，監管機構和執法人員；
        </li>
        <li>
          協助代理商向您提供信息，產品，服務或直接營銷的第三方，包括代理商或分包商；和
        </li>
        <li>第三方收集和處理數據。</li>
        <br />
      </ul>
      <p className='bold'>5.個人信息的國際轉移</p>
      <p>
        我們收集的個人信息在澳大利亞或我們或我們的合作夥伴，關聯公司和第三方提供商維護設施的地方存儲和處理。向我們提供您的個人信息，即表示您同意向這些海外第三方披露。
      </p>
      <p>
        我們將確保從歐洲經濟區（EEA）國家到歐洲經濟區以外國家的任何個人信息轉移都將受到適當保護措施的保護，例如使用歐盟委員會批准的標準數據保護條款，或使用具有約束力的公司規則或其他法律認可的手段。
      </p>
      <p>
        如果我們將個人信息從非EEA國家轉移到另一個國家，則您承認，其他司法管轄區的第三方可能不受與我們司法管轄區的第三方類似的數據保護法律的約束。如果任何第三方從事任何違反我們管轄範圍內的數據隱私法律的行為或做法，則存在風險，這可能意味著您將無法根據我們管轄範圍的隱私法律尋求補救。
      </p>
      <p className='bold'>6.您的權利和控制您的個人信息</p>
      <p>
        選擇與同意：向我們提供個人信息，即表示您同意我們根據本隱私政策收集，持有，使用和披露您的個人信息。如果您未滿16周歲，則必須擁有並在法律允許的範圍內保證我們擁有您的父母或法定監護人的許可，才能訪問和使用我們擁有和運營的平台和其他網站以及您（父母或監護人）同意您向我們提供您的個人信息。您不必向我們提供個人信息，但是，如果您不這樣做，則可能會影響您對我們擁有和運營的該平台以及其他站點的使用，或在該平台上或通過該平台提供的產品和/或服務的使用。
      </p>
      <p>
        來自第三方的信息：如果我們從第三方收到有關您的個人信息，我們將按照本隱私政策的規定予以保護。如果您是提供其他人的個人信息的第三方，則您聲明並保證您已獲得該人的同意向我們提供個人信息。
      </p>
      <p>
        限制：您可以選擇限制收集或使用您的個人信息。如果您先前已同意我們將您的個人信息用於直接營銷目的，則可以隨時通過使用以下詳細信息與我們聯繫來改變主意。如果您要求我們限製或限制我們處理您的個人信息的方式，我們將讓您知道該限制如何影響您對我們網站或產品和服務的使用。
      </p>
      <p>
        訪問和數據可移植性：您可以要求我們提供關於您的個人信息的詳細信息。您可以索取我們持有的有關您的個人信息的副本。在可能的情況下，我們將以CSV格式或其他易於閱讀的機器格式提供此信息。您可以隨時要求我們刪除我們持有的有關您的個人信息。您可能還會要求我們將個人信息轉移給另一第三方。
      </p>
      <p>
        更正：如果您認為我們掌握的有關您的任何信息不准確，過時，不完整，無關或具有誤導性，請使用以下詳細信息與我們聯繫。我們將採取合理的步驟來糾正任何發現的不准確，不完整，誤導或過時的信息。
      </p>
      <p>數據洩露通知：關於任何數據洩露，我們將遵守適用於我們的法律。</p>
      <p>
        投訴：如果您認為我們違反了相關的數據保護法並希望提出投訴，請使用以下詳細信息與我們聯繫，並向我們提供涉嫌違反的完整信息。我們將立即調查您的投訴並以書面形式答复您，列出我們的調查結果以及我們將採取的處理您的投訴的步驟。您也有權就您的投訴與監管機構或數據保護機構聯繫。
      </p>
      <p>
        退訂：要退訂我們的電子郵件數據庫或退出通訊（包括市場營銷通訊），請使用以下詳細信息與我們聯繫，或使用通訊中提供的退出功能退出。
      </p>
      <p className='bold'>7.餅乾</p>
      <p>
        我們使用“
        cookies”來收集有關您以及您在我們網站上的活動的信息。Cookie是我們網站存儲在您計算機上的一小部分數據，每次訪問時都會訪問，因此我們可以了解您如何使用我們的網站。這有助於我們根據您指定的首選項為您提供內容。請參考我們的Cookie政策以獲取更多信息。
      </p>
      <p className='bold'>8.業務轉移</p>
      <p>
        如果我們或我們的資產被收購，或者在極少數情況下我們破產或破產，我們會將數據包括在轉讓給收購我們的任何一方的資產中。您確認可能會發生此類轉移，並且收購我們的任何一方都可以根據本政策繼續使用您的個人信息。
      </p>
      <p className='bold'>9.我們政策的局限性</p>
      <p>
        我們的網站可能鏈接到非我們運營的外部網站。請注意，我們無法控制這些網站的內容和政策，並且不能對其各自的隱私慣例承擔任何責任。
      </p>
      <p>10.本政策的變更</p>
      <p>
        我們可以自行決定更改隱私政策以反映當前可接受的做法。我們將採取合理的步驟，使用戶通過我們的網站了解更改。在對本政策進行任何更改後，您繼續使用本網站將被視為接受我們有關隱私和個人信息的慣例。
      </p>
      <p>
        如果我們對該隱私政策進行了重大更改，例如更改了我們處理您的個人信息的合法依據，我們將要求您重新同意修改後的隱私政策。
      </p>
      <p>該政策自2020年8月16日起生效</p>
      <p>
        我們邀請並歡迎您
        <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>
          聯繫üS
        </a>
      </p>
      </div>
    </>
  );
};

export default PrivacyPolicyZH;
