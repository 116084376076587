import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomePageRoutes from '../Components/Modules/HomePage/HomePage.route';
import GratitudeRoutes from '../Components/Modules/Gratitude/Gratitude.route';
import TourGuideRoutes from '../Components/Modules/TourGiude/TourGuide.route';
import CommunityRoutes from '../Components/Modules/Community/Community.route';
import MessageFilterRoutes from '../Components/Modules/MessageFilter/MessageFilter.route';
import WorldRoutes from '../Components/Modules/World/World.route';
import MenuRoutes from '../Components/Modules/Menu/Menu.route';
import StoryRoutes from '../Components/Modules/Stories/Story.route';
import ReportRoutes from '../Components/Modules/Report/Report.route';
import OrderRoutes from '../Components/Modules/Orders/Order.route';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <React.Fragment>
                    <HomePageRoutes />
                    <GratitudeRoutes />
                    <TourGuideRoutes />
                    <CommunityRoutes />
                    <MessageFilterRoutes />
                    <WorldRoutes />
                    <MenuRoutes />
                    <StoryRoutes />
                    <ReportRoutes />
                    <OrderRoutes />
                </React.Fragment>
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;