import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import Orders from './Pages/Orders';

const OrderRoutes = () => {
    return (
        <Fragment>
            <Route path="/zjgagfevao" exact component={Orders} />
        </Fragment>
    )
};

export default OrderRoutes;

