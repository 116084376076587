import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
  LinkedinShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  ViberIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import EmailModal from './ModalShareEmail';

const SocialMediaSharingModel = ({
  shareMsgData = {},
  open = false,
  modelHeader = "Share Message",
  closeModel = () => { },
  openEmailModal = () => { },
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Dialog
        open={open}
        onClose={closeModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="shareModal"
      >
        <DialogTitle id="alert-dialog-title">{t('Share Message')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <button aria-label="email"
              className="react-share__ShareButton email-share-btn share-button mr-0"
              onClick={() => openEmailModal()}
            >
              <EmailOutlinedIcon />
                Email
            </button>
            {/* Facebook share */}
            <FacebookShareButton
              url={shareMsgData.shareUrl}
              quote={shareMsgData.message}
              className="share-button"
            >
              <FacebookIcon size={32} round={true} logoFillColor="white" />
              facebook
            </FacebookShareButton>

            {/* Twitter  */}
            {/* <TwitterShareButton
              url={shareMsgData.shareUrl}
              title="Gratitude"
              className="share-button"
            >
              <TwitterIcon size={32} round={true} logoFillColor="white" />
              Twitter
            </TwitterShareButton> */}

            {/* Whatsapp  */}
            {/* <WhatsappShareButton
              url={shareMsgData.shareUrl}
              title="Gratitude"
              className="share-button"
            >
              <WhatsappIcon size={32} round={true} logoFillColor="white" />
              Whatsapp
            </WhatsappShareButton> */}

            {/* Linkedin  */}
            {/* <LinkedinShareButton
              url={shareMsgData.shareUrl}
              title="Gratitude"
              className="share-button"
            >
              <LinkedinIcon size={32} round={true} logoFillColor="white" />
              LinkedIn
            </LinkedinShareButton> */}

            {/* Facebook massenger share */}
            {/* <FacebookMessengerShareButton
              appId={process.env.REACT_APP_FACEBOOK_ID}
              redirectUri={process.env.REACT_APP_UAT_URL}
              url={shareMsgData.shareUrl}
              className="share-button"
            >
              <FacebookMessengerIcon
                size={32}
                round={true}
                logoFillColor="white"
              />
              Massenger
            </FacebookMessengerShareButton> */}

            {/* Viber  */}
            {/* <ViberShareButton
              url={shareMsgData.shareUrl}
              title="Gratitude"
              className="share-button"
            >
              <ViberIcon size={32} round={true} logoFillColor="white" />
              Viber
            </ViberShareButton> */}

            {/* Email share */}
            {/* <EmailShareButton
              url={shareMsgData.shareUrl}
              subject="Gratitude"
              body={shareMsgData.message}
              className="share-button mr-0"
            >
              <EmailIcon size={32} round={true} logoFillColor="white" />
              Email
            </EmailShareButton> */}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={closeModel} color="primary" autoFocus>
                      Close
                  </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SocialMediaSharingModel;
