import careWorkerThumbnail from "../Images/stories/careworker.png";
import whoIsCharityThumbNail from "../Images/stories/who-is-charity.png";
import covidThumbnail from "../Images/stories/covid.jpg";
import oxfamThumbnail from "../Images/stories/oxfam.png";
import beyondblueThumbnail from "../Images/stories/beyondblue.png";
import whiteRibbonThumbnail from "../Images/stories/white-ribbon.jpg";
import headsTogetherThumbnail from "../Images/stories/heads-together.png";
import smileyTalksThumbnail from "../Images/stories/smily-talks.png";
import petsInParkThumbnail from "../Images/stories/pets-in-park.png";
import DIGThumbnail from "../Images/stories/DIG.png";
import GratitudeWildInArtThumbnail from "../Images/stories/gratitude-wild-in-art.png";
import WildInArtThumbnail from "../Images/stories/wild-in-art.png";
import WellsOnWheelsThumbnail from "../Images/stories/wells-on-wheels.png";
import SmileyMovementThumbnail from "../Images/stories/smiley-movement.png";
import NetflixVoxThumbnail from "../Images/stories/netflix-vox.png";
import story1Thumbnail from "../Images/stories/teachers_day.jpg";
import story2Thumbnail from "../Images/stories/story-2.jpg";
import story3Thumbnail from "../Images/stories/story-3.jpg";
import story4Thumbnail from "../Images/stories/story-4.jpg";
import story5Thumbnail from "../Images/stories/story-5.jpg";
import story6Thumbnail from "../Images/stories/story-6.png";
import story7Thumbnail from "../Images/stories/story-7.jpg";
import story8Thumbnail from "../Images/stories/story-8.jpg";
import story9Thumbnail from "../Images/stories/story-9.jpg";
import story10Thumbnail from "../Images/stories/story-10.jpeg";
import story11Thumbnail from "../Images/stories/story-11.jpg";
import story12Thumbnail from "../Images/stories/story-12.jpeg";
import story13Thumbnail from "../Images/stories/story-13.jpg";
import story14Thumbnail from "../Images/stories/story-14.jpg";
import story15Thumbnail from "../Images/stories/story-15.jpg";
import story16Thumbnail from "../Images/stories/story-16.jpg";
import story17Thumbnail from "../Images/stories/story-17.jpg";
import story18Thumbnail from "../Images/stories/story-18.jpg";
import story19Thumbnail from "../Images/stories/story-19.jpg";
import story20Thumbnail from "../Images/stories/story-20.jpg";
import story21Thumbnail from "../Images/stories/story-21.jpg";
import story22Thumbnail from "../Images/stories/story-22.jpg";
import story23Thumbnail from "../Images/stories/story-23.jpg";
import story24Thumbnail from "../Images/stories/story-24.jpg";
import story25Thumbnail from "../Images/stories/story-25.png";
import story26Thumbnail from "../Images/stories/story-26.png";

//import story27Thumbnail from "../Images/stories/story-27.png";
import story28Thumbnail from "../Images/stories/story-28.jpg";
import story29Thumbnail from "../Images/stories/story-29.jpg";
import story30Thumbnail from "../Images/stories/story-30.png";
import story31Thumbnail from "../Images/stories/story-31.jpg";
import world_mental_Health_day from "../Images/stories/mental_health.jpg";
import wonders from "../Images/stories/wonders.jpg";
import storyX from "../Images/stories/story-x.jpg";

const stories = [
        {
            type:"video",
            player:"youtube",
            videoId:'nXKYi_kw3NA',
            title:"Who is charity: water?",
            thumb:whoIsCharityThumbNail,
            url:"https://www.youtube.com/watch?v=nXKYi_kw3NA&ab_channel=charitywater",
            description:"We're charity: water, a non-profit organization on a mission to bring clean and safe drinking water to every person on the planet."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'Rs4JVoVGC8w',
            title:"WHO COVID-19 Solidarity Response Fund",
            thumb:covidThumbnail,
            url:"https://www.youtube.com/watch?v=Rs4JVoVGC8w&list=PLsgX7DowtT9kQ-hyXH6Du0EC4oqogtskR",
            description:"March 15 to April 30, Google.org and YouTube are teaming up to support the COVID-19 Solidarity Response Fund for the World Health Organization..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'taeAxFDJqQw',
            title:"Oxfam International",
            thumb:oxfamThumbnail,
            url:"https://www.youtube.com/watch?v=taeAxFDJqQw&feature=youtu.be&ab_channel=oxfaminternational",
            description:"1 in 3 women and girls will experience violence in their lifetime. But many are taking the fight to end this global crisis into their own hands."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'qMx1Q0lraPM',
            title:"Beyond Blue Australia",
            thumb:beyondblueThumbnail,
            autoplay:false,
            url:"https://www.youtube.com/watch?v=qMx1Q0lraPM&ab_channel=BeyondBlueOfficial",
            description:"A new, dedicated Coronavirus Mental Wellbeing Support Service is now available to support all people in Australia to manage the impact of the COVID-19..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'CzMX5HyHsbk',
            title:"White Ribbon Australia",
            thumb:whiteRibbonThumbnail,
            url:"https://www.youtube.com/watch?v=CzMX5HyHsbk&feature=youtu.be&ab_channel=WhiteRibbonAust",
            description:"Communicare Chief Executive Officer, Melissa Perry, said Communicare is well placed to provide the strong governance and leadership needed to secure..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'eE82L-l8l7o',
            title:"The Care Workers Charity",
            thumb:careWorkerThumbnail,
            url:"https://www.youtube.com/watch?v=eE82L-l8l7o",
            description:"The rainy day fund for everyday heroes. The Care Workers Charity is here to support care professionals going through tough times."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'BKeHpj6aUpc',
            title:"Heads together",
            thumb:headsTogetherThumbnail,
            url:"https://www.youtube.com/watch?v=BKeHpj6aUpc",
            description:"Heads Together and the FA have teamed up to bring you Heads Up - a new mental health campaign set to generate the biggest ever conversation around..."
        },
        {
            type:"video",
            player:"vimeo",
            videoId:'396914673',
            title:"Smiley Talks: What is the point of Education",
            thumb:smileyTalksThumbnail,
            url:"https://vimeo.com/396914673/b2acff2fc1",
            description:"Heads Together and the FA have teamed up to bring you Heads Up - a new mental health campaign set to generate the biggest ever conversation around..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'3o5USa1DoLU',
            title:"Pets in the Park Charity - 7 News",
            thumb:petsInParkThumbnail,
            url:"https://www.youtube.com/watch?v=3o5USa1DoLU",
            description:"Pets in the Park featured on 7 News."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'X6UcvCCsFBI',
            title:"Distinguished Gentleman's Ride | 2020",
            thumb:DIGThumbnail,
            url:"https://www.youtube.com/watch?v=X6UcvCCsFBI",
            description:"We are delighted to confirm that once again Triumph are the lead sponsor of the Distinguished Gentleman's Ride, our 7th landmark year of helping ..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'is35gRru8lM',
            title:"WildinArt",
            thumb:WildInArtThumbnail,
            url:"https://www.youtube.com/watch?v=is35gRru8lM&feature=youtu.be&ab_channel=WildinArt",
            description:"Learn more at wildinart.co.uk"
        },
        {
            type:"video",
            player:"youtube",
            videoId:'R08_Rhvxklk',
            title:"Gratitude 2021 | Wild in Art",
            thumb:GratitudeWildInArtThumbnail,
            url:"https://www.youtube.com/watch?v=R08_Rhvxklk&feature=youtu.be&ab_channel=WildinArt",
            description:"Gratitude is a spectacular public art installation to thank NHS staff and all key workers for their ongoing courage and dedication."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'sibDvwcEx2o',
            title:"Wells on Wheels",
            thumb:WellsOnWheelsThumbnail,
            url:"https://www.youtube.com/watch?v=sibDvwcEx2o&ab_channel=WellsonWheels",
            description:"Wells on Wheels"
        },
        {
            type:"video",
            player:"youtube",
            videoId:'eGl1sQWsfw0',
            title:"Smiley Movement",
            thumb:SmileyMovementThumbnail,
            url:"https://www.youtube.com/watch?v=eGl1sQWsfw0&ab_channel=SmileyMovement",
            description:"We are the matchmaker for good. Whether you need a hand or want to lend a hand our free social network and events help you find the right match..."
        },
        {
            type:"video",
            player:"youtube",
            videoId:'sgZ7RKyDrLg',
            title:"Coronavirus, Explained | Official Trailer | Netflix",
            thumb:NetflixVoxThumbnail,
            url:"https://www.youtube.com/watch?v=sgZ7RKyDrLg&ab_channel=Netflix",
            description:"In 2020, the world changed. This topical series examines the coronavirus pandemic, the efforts to combat it and ways to manage its mental health toll."
        },

        {
            type:"story",
            title:"PRAHRAN’S COMMUNITY PANTRY",
            postDate:"AUGUST 31, 2020",
            thumb:storyX,
            url:"https://stories.sharegratitude.com/prahrans-community-pantry/",
            description:"At first, it was seen as something off in the distance still an arm’s length away. But as the month of March rolled on, what had become known as..."
        },
        {
            type:"story",
            title:"THE NURSES OF LONDON",
            postDate:"SEPTEMBER 9, 2020",
            thumb:story2Thumbnail,
            url:"https://stories.sharegratitude.com/the-nurses-of-london/",
            description:"The great city of London and its residents have been as hard hit by the COVID-19 pandemic as anywhere in the world. For the bulk of the city’s population,..."
        },
        {
            type:"story",
            title:"TOURISTS OF SRI LANKA",
            postDate:"SEPTEMBER 14, 2020",
            thumb:story3Thumbnail,
            url:"https://stories.sharegratitude.com/tourists-of-sri-lanka/",
            description:"Each year 1.4 billion international trips are taken by tourists to every corner of the globe. It is an incredible movement of people,..."
        },
        {
            type:"story",
            title:"STAND ON YOUR FRONT PORCH AND SCREAM",
            postDate:"SEPTEMBER 16, 2020",
            thumb:story4Thumbnail,
            url:"https://stories.sharegratitude.com/when-tess-roberts-created-the-facebook-event-stand-on-your-front-porch-and-scream-as-a-response-to-the-events-of-covid19-she-had-no-idea-74000-people-would-get-involved/",
            description:"It’s no secret that 2020 has been – well, it’s been a year. With lockdown restrictions varying across the world, we’ve all experienced hiccups in our ..."
        },
        {
            type:"story",
            title:"RICK’S STREETSIDE CAFÉ, OFFERING FREE COFFEE AND CONVERSATION TO THOSE WHO NEED IT MOST",
            postDate:"SEPTEMBER 16, 2020",
            thumb:story5Thumbnail,
            url:"https://stories.sharegratitude.com/ricks-streetside-cafe-offering-free-coffee-and-conversation-to-those-who-need-it-most/",
            description:"After having to take a step back from his day job as an Acrobat, Rick Everett, who was able to claim JobKeeper payments, decided he wasn’t content ..."
        },
        {
            type:"story",
            title:"THE CURE FOR COVID HOMESICKNESS",
            postDate:"SEPTEMBER 16, 2020",
            thumb:story6Thumbnail,
            url:"https://stories.sharegratitude.com/the-cure-for-covid-homesickness/",
            description:"Kleon Papadimitriou, a Greek student studying in Aberdeen, Scotland, found himself feeling extremely homesick during the pandemic."
        },
        {
            type:"story",
            title:"PETS IN THE PARK",
            postDate:"SEPTEMBER 17, 2020",
            thumb:story7Thumbnail,
            url:"https://stories.sharegratitude.com/pets-in-the-park/",
            description:"It’s been a well-used phrase for centuries, a dog is a man’s best friend, and the world over people and animals share a special bond."
        },
        {
            type:"story",
            title:"I AM GRATEFUL FOR… MY BODY",
            postDate:"SEPTEMBER 18, 2020",
            thumb:story8Thumbnail,
            url:"https://stories.sharegratitude.com/i-am-grateful-for-my-body/",
            description:"We can be grateful for so many things, but we often forget to project that gratitude inward. We asked Melbourne Blogger Ellen Walker what gratitude ..."
        },
        {
            type:"story",
            title:"SPIDER-MAN JOGGER SPREADING SMILES AROUND MELBOURNE IN LOCKDOWN",
            postDate:"SEPTEMBER 18, 2020",
            thumb:story9Thumbnail,
            url:"https://stories.sharegratitude.com/spider-man-jogger-spreading-smiles-around-melbourne-in-lockdown/",
            description:"We’re all looking for something to bring a smile to our faces in lockdown and 36-year-old trainee psychologist Stuart Tyson is giving us just that."
        },
        {
            type:"story",
            title:"A TEACHER’S THOUGHTS ON REMOTE LEARNING",
            postDate:"SEPTEMBER 29, 2020",
            thumb:story10Thumbnail,
            url:"https://stories.sharegratitude.com/a-teachers-thoughts-on-remote-learning/",
            description:"Imagine trying to keep the attention of a class of 30 teenagers long enough to teach them something. It’s not a skill many of us possess."
        },
        {
            type:"story",
            title:"AC MILAN OFFER TICKETS TO FRONT LINE, HEALTH CARE WORKERS",
            postDate:"SEPTEMBER 26, 2020",
            thumb:story11Thumbnail,
            url:"https://stories.sharegratitude.com/ac-milan-offer-tickets-to-front-line-health-care-workers/",
            description:"Most countries have seen a ban on spectators in their arena for sports matches and other event during the pandemic, which has a tough blow to teams..."
        },
        {
            type:"story",
            title:"V FOR VICTORI-A, SENDING LOVE ACROSS THE NATION",
            postDate:"SEPTEMBER 25, 2020",
            thumb:story12Thumbnail,
            url:"https://stories.sharegratitude.com/v-for-victori-a-sending-love-across-the-nation/",
            description:"The residents of Melbourne and wider Victoria have experienced some of the longest and strictest lockdown rules in Australia."
        },
        {
            type:"story",
            title:"THE GIANT BALLOONS DELIVERING INTERNET TO AFRICA",
            postDate:"SEPTEMBER 22, 2020",
            thumb:story13Thumbnail,
            url:"https://stories.sharegratitude.com/the-giant-balloons-delivering-internet-to-africa/",
            description:"Across the globe internet usage has never been higher, with millions stuck at home to stay safe from the COVID-19 Pandemic, being online to work..."
        },
        {
            type:"story",
            title:"IT’S TIME FOR THE PARTNERS OF OUR CRITICAL CARE NURSES TO TAKE THE SPOTLIGHT",
            postDate:"SEPTEMBER 20, 2020",
            thumb:story14Thumbnail,
            url:"https://stories.sharegratitude.com/its-time-for-the-partners-of-our-critical-care-nurses-to-take-the-spotlight/",
            description:"Our gratitude for our Critical Care Nurses during this time is something that’s been highlighted since this pandemic began: but what about their..."
        },
        {
            type:"story",
            title:"THE POSITIVE EFFECTS OF GRATITUDE ON MENTAL HEALTH",
            postDate:"SEPTEMBER 20, 2020",
            thumb:story15Thumbnail,
            url:"https://stories.sharegratitude.com/the-positive-effects-of-gratitude-on-mental-health/",
            description:"Mental Health is an important aspect of our wellbeing that is still fighting to gain the respect and focus that it deserves."
        },
        {
            type:"story",
            title:"THE INCREDIBLE WORK OF SRI LANKA’S WOMEN IN NEED ORGANISATION",
            postDate:"SEPTEMBER 20, 2020",
            thumb:story16Thumbnail,
            url:"https://stories.sharegratitude.com/the-incredible-work-of-sri-lankas-women-in-need-organisation/",
            description:"It can be easy to see a story on the news and feel helpless, not knowing what we could possibly do to assist those in need. But we can equally..."
        },
        {
            type:"story",
            title:"THE AUSTRALIAN HELPING CAMBODIAN FAMILIES TO STAY TOGETHER",
            postDate:"SEPTEMBER 19, 2020",
            thumb:story17Thumbnail,
            url:"https://stories.sharegratitude.com/the-australian-helping-cambodian-families-to-stay-together/",
            description:"It’s the dream of many young people, to save up enough money to spend time overseas volunteering to help those most vulnerable. Fourteen years ago,..."
        },
        {
            type:"story",
            title:"INDIA’S FREE AMBULANCE SERVICE",
            postDate:"SEPTEMBER 18, 2020",
            thumb:story18Thumbnail,
            url:"https://stories.sharegratitude.com/indias-free-ambulance-service/",
            description:"It’s something we are taught from a very young age and is vital for every child to know; how to call an ambulance. It’s hard to imagine another time..."
        },
        {
            type:"story",
            title:"A PARENT’S EXPERIENCE OF HOME-SCHOOLING DURING A PANDEMIC",
            postDate:"OCTOBER 8, 2020",
            thumb:story19Thumbnail,
            url:"https://stories.sharegratitude.com/a-parents-experience-of-home-schooling-during-a-pandemic/",
            description:"It’s been challenge after challenge for parents during lockdown, from having to become Zoom experts to having the kids at home 24/7."
        },
        {
            type:"story",
            title:"THE YOUNG NURSE WHO CAUGHT COVID CARING FOR OTHERS",
            postDate:"OCTOBER 7, 2020",
            thumb:story20Thumbnail,
            url:"https://stories.sharegratitude.com/the-young-nurse-who-caught-covid-caring-for-others/",
            description:"We see the world over that when trouble strikes amazing people step forward to go to the aid of those suffering. Never before has that been more..."
        },
        {
            type:"story",
            title:"UK ‘CARE FARMS’ TO SUPPORT THOSE WITH MENTAL HEALTH CHALLENGES",
            postDate:"OCTOBER 20, 2020",
            thumb:story22Thumbnail,
            url:"https://stories.sharegratitude.com/uk-care-farms-to-support-those-with-mental-health-challenges/",
            description:"If you’re diagnosed with severe mental health problems, odds are you’ll be admitted to a specialised hospital to undergo treatment, but what if..."
        },
        {
            type:"story",
            title:"LICHFIELD TEENAGER COMBATS LONELINESS WITH ACTS OF KINDNESS",
            postDate:"OCTOBER 19, 2020",
            thumb:story23Thumbnail,
            url:"https://stories.sharegratitude.com/lichfield-teenager-combats-loneliness-with-acts-of-kindness/",
            description:"When schools closed in March due to coronavirus, Sebbie Hall, 17, from Whittington, Staffordshire, found an incredible way to keep himself busy:"
        },
        {
            type:"story",
            title:"ESSENTIAL WORKERS SUPPORTING EACH OTHER DURING THE PANDEMIC",
            postDate:"OCTOBER 17, 2020",
            thumb:story24Thumbnail,
            url:"https://stories.sharegratitude.com/essential-workers-supporting-each-other-during-the-pandemic/",
            description:"Our essential workers have been our lifeline during this difficult year, but who have they been able to turn to for support? Even if it’s been business..."
        },
        {
            type:"story",
            title:"UNIVERSITY INTRODUCES NEW SUPPORT FOR STUDENTS’ MENTAL WELLBEING",
            postDate:"OCTOBER 14, 2020",
            thumb:story25Thumbnail,
            url:"https://stories.sharegratitude.com/university-introduces-new-support-for-students-mental-wellbeing/",
            description:"2020 has been the year of adapting and shifting priorities: we’ve been challenges not to sweat the small stuff and, having lived more restricted lives,"
        },
        {
            type:"story",
            title:"THE HOLLYWOOD ACTRESS CHANGING THOUSANDS OF YOUNG LIVES",
            postDate:"OCTOBER 13, 2020",
            thumb:story26Thumbnail,
            url:"https://stories.sharegratitude.com/the-hollywood-actress-changing-thousands-of-young-lives/",
            description:"She’s walked the runways of the world for the largest brands on the planet, starred in TV shows, and in movies that have grossed more than $1billion."
        },
        {
            type:"story",
            title:"THE REALITY OF OPERATING A SMALL BUSINESS DURING A PANDEMIC",
            postDate:"OCTOBER 27, 2020",
            thumb:story28Thumbnail,
            url:"https://stories.sharegratitude.com/the-reality-of-operating-a-small-business-during-a-pandemic/",
            description:"When the pandemic hit early on this year, there was a fluster of adjustment around having to work from home and adjust to the ‘new normal’, however,"
        },
        {
            type:"story",
            title:"QUEENSLAND POLICE OFFICER GOES ABOVE AND BEYOND, HEROICALLY SAVING 10-WEEK-OLD BABY",
            postDate:"OCTOBER 27, 2020",
            thumb:story29Thumbnail,
            url:"https://stories.sharegratitude.com/queensland-police-officer-goes-above-and-beyond-heroically-saving-10-week-old-baby/",
            description:"Not all heroes wear capes, some wear navy blue, carry silver badges and work to keep our streets safe. One of the most important qualities of being..."        
        },
        {
            type:"story",
            title:"'BOY UNDER THE BRIDGE' – THE LOCAL MAN HIDING POETRY TREASURES FOR OTHERS TO FIND",
            postDate:"OCTOBER 26, 2020",
            thumb:story30Thumbnail,
            url:"https://stories.sharegratitude.com/boy-under-the-bridge-the-local-man-hiding-poetry-treasures-for-others-to-find/",
            description:"He writes under an alias and leaves his work as a gift to the city in his signature type-written signal: so basically Batman, but with poetry."        
        },
        {
            type:"story",
            title:"WOOLIES STAFF WENT ABOVE AND BEYOND FOR DISTRAUGHT CUSTOMER",
            postDate:"OCTOBER 22, 2020",
            thumb:story31Thumbnail,
            url:"https://stories.sharegratitude.com/woolies-staff-went-above-and-beyond-for-distraught-customer/",
            description:"Sometimes it’s the little things in life that can cause the largest amount of stress. For Leanne Page, 40, from Morayfield, it was being unable..."        
        },
        {
            type:"story",
            featured:true,
            title:"World Teachers' Day",
            postDate:"OCTOBER 05, 2021",
            thumb:story1Thumbnail,
            url:"https://stories.sharegratitude.com/its-the-teacher-that-makes-the-difference-not-the-classroom/",
            description:"Please post a message of gratitude for your favourite teachers Don't hold back. Let's tell our teachers why we are grateful!"
        },
        {
            type:"story",
            featured:true,
            postDate:"OCTOBER 10, 2021",
            title:"World Mental Health Day",
            thumb:world_mental_Health_day,
            url:"https://stories.sharegratitude.com/mental-health-initiatives-we-are-grateful-for/",
            description:"Please post a message of gratitude for the wonderful work by our local mental health initiatives to help us all cope during these trying times, and let's give generously."
        },
        {
            type:"video",
            featured:true,
            player:"youtube",
            videoId:'qnIniMHL38Y',
            title:"THE SEVEN WONDERS OF GRATITUDE",
            thumb:wonders,
            url:"https://www.youtube.com/watch?v=qnIniMHL38Y",
            description:"At Share Gratitude, we strive to make sharing gratitude easy,fun, and impactful. Our dedicated space for all the beautiful people in our lives and to support causes you are passionate about!"
        },
        {
            type:"story",
            featured:true,
            title:"Smiley Interview",
            postDate:"2021-09-21",
            thumb:story21Thumbnail,
            url:"https://smileymovement.org/smiley-news/share-gratitude",
            description:"Today, World Gratitude Day, sees the launch of a unique platform designed to bring humanity together in gratitude at a time of crisis."
        },
        
    ];

export default stories;
