import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import RightImg from "../../../../Resources/Images/right.svg";
import marker from "../../../../Resources/Images/marker.svg";
import leftArrow from "../../../../Resources/Images/left-arrow.svg";
import Location from "./Location";
import { changeHeaderAction } from "../../../../Actions/generalAction";
import {
  submitReplyMsgAction,
  trackMyReplyMsgAction,
  resetReplyMsgAction,
  resetTrackReplyMsgAction,
} from "../../../../Actions/communityAction";
import ReplyMsgImpact from "./ReplyMsgImpact";
import ReplyMsgSubmitSuccess from "./ReplyMsgSubmitSuccess";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
} from "../../../../Utils/GoogleAPI";

const WriteReply = ({ msgData = {}, closeWriteReply = () => { }, ...props }) => {
  const gratitudeMsgLength = 240;
  const { t, i18n } = useTranslation();

  // Submit button enable/disable
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(true);

  // Screens
  const [successScreen, setSuccessScreen] = useState(false);
  const [registerScreen, setRegisterScreen] = useState(false);

  // validation
  const [validDisplayName, setValidDisplayName] = useState(true);

  const [replyMsg, setReplyMsg] = useState(props.dittoReply.replyMessage ? props.dittoReply.replyMessage : "");
  const [location, setLocation] = useState("");
  const [displayName, setDisplayName] = useState(props.userPrecense.user.userName ? props.userPrecense.user.userName : "");
  const [submitReplyID, setSubmitReplyID] = useState("");

  //Search location
  const [isSearchLocation, setIsSearchLocation] = useState(false);
  const [isFrontliner, setIsFrontliner] = useState(false);
  const [showReplyMsgImpact, setShowReplyMsgImpact] = useState(false);
  const [impactOfReplyMsg, setImpactOfReplyMsg] = useState(true);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const inputRef = useRef();

  // Form disabled
  const [formDisable, setFormDisable] = useState(props.userPrecense.isRegistered ? true : false);

  const backToMainComponent = () => {
    setIsSearchLocation(false);
    // props.changeHeaderAction(false);
  };

  // Message length
  const [msgLength, setMsgLength] = useState(gratitudeMsgLength);

  // Submit reply message
  const submitReply = () => {
    const formData = {
      messageId: msgData._id,
      replyMessage: replyMsg,
      author: displayName,
      authorLocation: location.description,
      isImpactEnable: impactOfReplyMsg,
      isDitto: props.dittoReply.replyMessage ? true : false
    };

    if (props.dittoReply) {
      formData.omId = props.dittoReply._id;
      formData.replyMessageIcon = props.dittoReply.replyMessageIcon;
      formData.language = props.dittoReply.language;
    }

    props.submitReplyMsgAction(formData);
    setEnableSubmitBtn(false);
  };

  if (!location) {
    // Geo location get by geo code
    const setLatLong = async (geoLocation) => {
      var lat = geoLocation.coords.latitude;
      var long = geoLocation.coords.longitude;
      var response = await getGeoLocationByLatLong(lat, long);

      const arLength = response.results.length;
      const geoLoc = response.results[arLength - 3].formatted_address;

      setLocation({
        latitude: lat,
        longitude: long,
        description: geoLoc,
        location: [
          {
            name: "world",
            type: "city",
            placeId: 0,
          },
          {
            name: "world",
            type: "country",
            placeId: 0,
          },
        ],
      });
    };

    // Geo location get by ip
    const setByIPaddress = async (error) => {
      var geoData = await getGeocodeByIPaddress();
      var geoCodeAr = geoData.loc.split(",");
      var lat = geoCodeAr[0];
      var long = geoCodeAr[1];
      var response = await getGeoLocationByLatLong(lat, long);

      const arLength = response.results.length;
      const geoLocation = response.results[arLength - 3].formatted_address;

      setLocation({
        latitude: lat,
        longitude: long,
        description: geoLocation,
      });
    };
    var loc = navigator.geolocation.getCurrentPosition(
      setLatLong,
      setByIPaddress
    );
  }

  useEffect(() => {
    if (props.submitReplyMsg._id) {
      props.changeHeaderAction({ type: "reply-impact", headerTitle: t('Thank you') });
      // setShowReplyMsgImpact(true);
      setSubmitReplyID(props.submitReplyMsg._id);
      if (props.userPrecense.isRegistered || !impactOfReplyMsg) {
        setSuccessScreen(true);
        props.resetReplyMsgAction();
      } else {
        setRegisterScreen(true);
      }
      // props.resetReplyMsgAction();
    } else if (props.submitReplyMsg.error) {
      setEnableSubmitBtn(true);
      setSeverity("error");
      setErrorMsg(props.submitReplyMsg.error.message);
      setErrorNotifification(!errorNotifification);
      // props.resetReplyMsgAction();
    }
  }, [props.submitReplyMsg]);

  // Track my msg
  const trackMyMsg = (formData) => {
    formData.msgID = submitReplyID;
    props.trackMyReplyMsgAction(formData);
  };

  useEffect(() => {
    if (props.trackReplyMsg.data) {
      if (props.trackReplyMsg.data.success) {
        closeWriteReply(true);
        props.resetTrackReplyMsgAction();
        props.changeHeaderAction({ type: 'reply', headerTitle: `${msgData.author}` });
      }
    } else if (props.trackReplyMsg.name === "Error") {
      setSeverity("error");
      setErrorMsg("Please check again and re-submit.");
      setErrorNotifification(!errorNotifification);
      props.resetTrackReplyMsgAction();
    }
  }, [props.trackReplyMsg]);

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  const resetImpactOfReply = () => {
    setShowReplyMsgImpact(false);
    closeWriteReply(true);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [])

  const validateDisplayName = (name) => {
    var isValidName = (name || displayName) ? true : false;
    setValidDisplayName(isValidName);
  }
  return (
    <div className="input-grat">
      {!isSearchLocation && !showReplyMsgImpact && !successScreen && !registerScreen && (
        <div className="col-md-12">
          <span className="ab-btn rep" onClick={closeWriteReply}>{t("back")}
          </span>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
              <textarea
                disabled={props.dittoReply.replyMessage ? true : false}
                value={replyMsg}
                onChange={(e) => {
                  let msg = e.target.value;
                  setReplyMsg(e.target.value);
                  var msgLength = msg.length;
                  var remainingChars = gratitudeMsgLength - msgLength;
                  setMsgLength(remainingChars);
                }}
                onKeyUp={(e) => { }}
                name=""
                maxLength={gratitudeMsgLength}
                id=""
                cols="30"
                rows="5"
                className="form-control form-control-sm"
                placeholder={t("write_reply")}
                ref={inputRef}
              ></textarea>
              {replyMsg ? (
                <label className="charleft">{msgLength} {t('chars_left')}</label>
              ) : (
                  ""
                )}
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <div className="input-list">
                <div
                  className="row"
                  onClick={() => {
                    setIsSearchLocation(true);
                    // props.changeHeaderAction(true);
                  }}
                >
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                    <label htmlFor="">{t("your_location")}</label>
                  </div>
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 input-img">
                    <span className="tag-location">
                      <img src={marker} alt="" />
                      {location.description}
                    </span>
                    <a>
                      <img src={RightImg} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 input-img">
                    <input
                      disabled={formDisable}
                      value={displayName}
                      onChange={(e) => { setDisplayName(e.target.value); validateDisplayName(e.target.value); }}
                      type="text"
                      className="form-control form-control-sm text-left"
                      placeholder={t("your_display_name")}
                    />
                    {!validDisplayName ? <span className='inp-error'>{t('Display name is required')}</span> : ''}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 d-none">
              <div className="input-list">
                <div className="row">
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <label htmlFor="">{t("i_am_frontliner_worker")}</label>
                  </div>
                  <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-img">
                    <label className="switch float-right">
                      <input
                        onChange={() => setIsFrontliner(!isFrontliner)}
                        type="checkbox"
                      />{" "}
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 d-none">
              <div className="input-list">
                <div className="row">
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <label htmlFor="">{t("Impact of reply message")}</label>
                  </div>
                  <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-img">
                    <label className="switch float-right">
                      <input
                        checked={impactOfReplyMsg}
                        onChange={() => setImpactOfReplyMsg(!impactOfReplyMsg)}
                        type="checkbox"
                      />{" "}
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <a
                onClick={
                  replyMsg &&
                    location &&
                    displayName &&
                    enableSubmitBtn
                    ? () => submitReply()
                    : () => validateDisplayName()
                }

                className={`float-right track text-uppercase ${replyMsg && location && displayName && enableSubmitBtn ? "active" : "inactive"
                  }`}
              >
                {t("send")}
              </a>
            </div>
          </div>
        </div>
      )}

      {isSearchLocation && (
        <Location
          backToMainComponent={backToMainComponent}
          setLocation={(location) => setLocation(location)}
        />
      )}

      {successScreen && (
        <ReplyMsgSubmitSuccess
          trackMyMsg={trackMyMsg}
          closeWriteReply={resetImpactOfReply}
        />
      )}

      {registerScreen && (
        <ReplyMsgImpact
          trackMyMsg={trackMyMsg}
          closeWriteReply={resetImpactOfReply}
        />
      )}

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={2000}
      />

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHeaderAction: (data) => dispatch(changeHeaderAction(data)),
    submitReplyMsgAction: (data) => dispatch(submitReplyMsgAction(data)),
    trackMyReplyMsgAction: (data) => dispatch(trackMyReplyMsgAction(data)),
    resetReplyMsgAction: () => dispatch(resetReplyMsgAction()),
    resetTrackReplyMsgAction: () => dispatch(resetTrackReplyMsgAction())
  };
};

const mapStateToProps = (response) => ({
  submitReplyMsg: response.community.submitReplyMsg,
  trackReplyMsg: response.community.trackReplyMsg,
  userPrecense: response.general.userPrecense.data,
  dittoReply: response.community.dittoReply
});
export default connect(mapStateToProps, mapDispatchToProps)(WriteReply);
