import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TermsOfService from "../Modules/Menu/Pages/TermsOfService/TermsOfService"

const TransitionsModel = ({
  open = false,
  button2 = "Close",
  closeModel = () => {},
}) => {
  const handleClose = () => {
    closeModel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="terms"
    >
      {/* <DialogTitle id="alert-dialog-title">{modelHeader}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="text-wrapper">
           <TermsOfService/>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {button2}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransitionsModel;
