import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./header.scss";

// import { Row, Col, Nav, Button } from 'react-bootstrap';
import GroupImg from "../../../Resources/Images/Group.svg";
import MenuImg from "../../../Resources/Images/menu.svg";
import close from "../../../Resources/Images/close.svg";
import logoEn from "../../../Resources/Images/logo-en-f.svg";
import smile from "../../../Resources/Images/smile.png";
import like from "../../../Resources/Images/like.gif";

// import util
import { getGeocodeByIPaddress } from "../../../Utils/GoogleAPI";
import { setLangAction } from "../../../Actions/generalAction";
import {
  setLanguage,
  getLanguage,
  removeLanguage,
} from "../../../Utils/Storage";
import { emailList } from "../../../Resources/Files/emailList";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = getLanguage();

  let defaultLang = selectedLanguage;
  if (!selectedLanguage) {
    defaultLang = "en";
  }

  useEffect(() => {
    setLang();
  }, []);

  /**
   * change logo according to the user's country and it's language, default is English
   */
  let logoImg = logoEn;

  if (selectedLanguage == "en") {
    logoImg = logoEn;
  } else if (selectedLanguage == "es") {
    logoImg = logoEn;
  } else if (selectedLanguage == "zh") {
    logoImg = logoEn;
  }

  let location = useLocation();
  let urlpath = location.pathname.replace("/", "");

  const [selectedTab, setSelectedTab] = useState(
    urlpath ? urlpath : "community"
  );
  const [toggle, setToggle] = useState(false);
  const [selectedLang, setSelectedLang] = useState(defaultLang);

  const setLang = async () => {
    var geoCode = await getGeocodeByIPaddress();
    var lang = "";
    var country = geoCode.country;
    if (country === "CN") lang = "zh";
    else if (country === "ES") lang = "es";
    else lang = "en";

    if (!selectedLanguage) {
      setLanguage(lang);
      props.setLangAction(lang);
      i18n.changeLanguage(lang);
      setSelectedLang(lang);
    } else {
      props.setLangAction(selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  };

  const changeLang = async (lang) => {
    setSelectedLang(lang);
    setLanguage(lang);
    props.setLangAction(lang);
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };

  const compareEmail = () => {
    if (!props.loggedInUserEmail) return false;
    if (emailList.includes(props.loggedInUserEmail)) {
      return true;
    } else return false;
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-md navbar-light bg-light fixed-top ${
          toggle ? "toggle" : ""
        }`}
      >
        <div className="nav-cnt">
          {props.changeHeader.page === "community" && (
            <NavLink exact to="/filter">
              <button
                className="navbar-toggler filter-tog toggle-group pl-0 pr-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img src={GroupImg} alt="" />
              </button>
            </NavLink>
          )}

          <select
            onChange={(e) => {
              changeLang(e.target.value);
            }}
            value={selectedLang}
          >
            <option value="en">EN</option>
            <option value="zh">ZH</option>
            <option value="es">ES</option>
            <option value="fr">FR</option>
            <option value="de">DE</option>
            <option value="it">IT</option>
            <option value="ja">JA</option>
            <option value="fa">FA</option>
            <option value="pt">PT</option>
          </select>
          <div className="menu-group mr-4 text-uppercase">
            <a
              onClick={() =>
                window.open("https://www.subscribepage.com/v6v1l0", "_blank")
              }
            >
              {t("About Us")}
            </a>
          </div>

          <a className="navbar-brand text-center" href="/">
            {" "}
            <img src={logoImg} alt="" />{" "}
          </a>

          <button
            className="navbar-toggler pl-0 pr-0"
            onClick={() => setToggle(!toggle)}
          >
            <img className="menu" src={MenuImg} alt="" />
          </button>
        </div>
      </nav>

      <div className="drawer">
        <div className="drawer-header">
          <h3>{t("Menu")}</h3>
          <button
            className="navbar-toggler pl-0 pr-0"
            onClick={() => setToggle(!toggle)}
          >
            <img className="menu" src={close} alt="" />
          </button>
        </div>

        <div className="menu-wrapper">
          <div className="menu-group">
            <NavLink className="nav-link" to="/subscribe">
              <p>{t("Subscribe")}</p>
            </NavLink>
          </div>
          {/* <div className="menu-group">
            <a
              onClick={() =>
                window.open(
                  "https://stories.sharegratitude.com/how-to-share-gratitude-a-users-guide/",
                  "_blank"
                )
              }
            >
              <p>{t("user_guide")}</p>
            </a>
          </div> */}

          <div className="menu-group">
            <p>{t("share_gratitude")}</p>
            <ul>
              <li className="d-none">
                {" "}
                <NavLink className="nav-link" to="/gratitude">
                  {t("write_message")}
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className="nav-link" to="/">
                  {t("Community Messages")}
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink className="nav-link" to="/give">
                  {t("Give")}
                </NavLink>
              </li>

            
              <li>
                {" "}
                <a
                  className="nav-link"
                  onClick={() =>
                    window.open("https://stories.sharegratitude.com/", "_blank")
                  }
                >
                  {t("stories")}
                </a>
              </li>
              <li className="d-none">
                <NavLink className="nav-link" to="/charities">
                  {t("charities")}
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className="nav-link" to="/world">
                  {t("global_impact")}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/volunteer">
                  {t("Volunteer")}
                </NavLink>
              </li>
              <li>
                {" "}
                <a
                  className="nav-link"
                  onClick={() =>
                    window.open("https://humansofsharegratitude.com/", "_blank")
                  }
                >
                  {t("Humans of Sharegratitude")}
                </a>
              </li>
            </ul>
          </div>

          <div className="menu-group">
            <p>{t("Language")}</p>
            <ul>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="en">ENGLISH</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="es">ESPAÑOL (SPANISH)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="zh">中文 (CHINESE)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="fr">français (FRENCH)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="de">Deutsche (GERMAN)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="it">italiano (ITALIAN)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="ja">日本人 (JAPANESE)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="fa">فارسی (PERSIAN)</a>
              </li>
              <li
                onClick={(e) => {
                  changeLang(e.target.getAttribute("data-lan"));
                }}
              >
                <a data-lan="pt">Português (PORTUGUESE)</a>
              </li>
            </ul>
          </div>

          <div className="menu-group">
            <p>{t("Give local & Charity partners")}</p>
            <ul>
              <li>
                {" "}
                <NavLink className="nav-link" to="/giveLocal">
                  {t("supporting small businesses")}
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className="nav-link" to="/charityPartners">
                  {t("charity partners")}
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="menu-group">
            <p>{t("founding_partners")}</p>
            <ul>
              <li>
                <a>{t("Yamaha Motor Company")}</a>
              </li>
              <li>
                <a>{t("Uber Eats")}</a>
              </li>
              <li>
                <a>{t("Hallmark")}</a>
              </li>
            </ul>
          </div>

          <div className="menu-group">
            <p>{t("stories_of_gratitude")}</p>
            <ul>
              <li>
                <a>{t("Yamahas Global Community")}</a>
              </li>
              <li>
                <a>{t("Stories of Endurance")}</a>
              </li>
              <li>
                <a>{t("Frontliners of Central Park")}</a>
              </li>
              <li>
                <a>{t("Meet The Italian Hero")}</a>
              </li>
            </ul>
          </div> */}

          <div className="menu-group">
            <p>{t("corporate_information")}</p>
            <ul>
              <li>
                <a
                  onClick={() =>
                    window.open(
                      "https://www.subscribepage.com/v6v1l0",
                      "_blank"
                    )
                  }
                >
                  {t("about")}
                </a>
              </li>
              {/* <li>
                <a onClick={() => window.open("https://about.sharegratitude.com/board/", "_blank")}>
                  {t("board")}
                </a>
              </li>
              <li>
                <a onClick={() => window.open("https://about.sharegratitude.com/team/", "_blank")}>
                  {t("team")}
                </a>
              </li> */}
              <li>
                <NavLink className="nav-link" to="/contact">
                  {t("Contact")}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="menu-group">
            <p>{t("general_information")}</p>
            <ul>
              <li>
                <a
                  onClick={() =>
                    window.open(
                      "https://about.sharegratitude.com/media-info/",
                      "_blank"
                    )
                  }
                >
                  {t("media")}
                </a>
              </li>
              <li>
                <NavLink className="nav-link" to="/volunteer">
                  {t("Join Us")}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/contact">
                 {t("philanthropic_support")}
                </NavLink>
              </li>

              <li>
                <NavLink className="nav-link" to="/contact">
                   {t("sponsors_and_partners")}
                </NavLink>
              </li>

              <li>
                <NavLink className="nav-link" to="/contact">
                   {t("charities")}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="menu-group">
            <p>{t("policies_&_terms")}</p>
            <ul>
              <li>
                <NavLink className="nav-link" to="/privacyPolicy">
                  {t("privacy_policy")}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/cookiePolicy">
                  {t("cookie_policy")}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/termsOfService">
                  {t("terms_of_service")}
                </NavLink>
              </li>
            </ul>
          </div>
          {props.loggedInUserEmail && compareEmail() && (
            <div className="menu-group">
              <NavLink className="nav-link" to="/report">
                <p>{t("reports")}</p>
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLangAction: (data) => dispatch(setLangAction(data)),
  };
};

const mapStateToProps = (response) => ({
  lang: response.community.messages,
  changeHeader: response.general.changeHeader,
  loggedInUserEmail:
    typeof response.general.userPrecense.data === "undefined"
      ? false
      : response.general.userPrecense.data.user.userEmail,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
