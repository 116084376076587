import React from "react";

const TermsOfServiceES = () => {
  return (
    <>
       <div className='stc-wrapper'>
        <h4 className='text-center'>Términos de servicio</h4>
        <div className='text-center'>
          <p>
            Gracias por su interés y visitar nuestra plataforma . A continuación
            , encontrará un poco de jerga legal de nuestra firma legal, que
            básicamente describe los términos de servicio de la plataforma y
            otros sitios que poseemos y operamos. Lo invitamos a comunicarse con
            nosotros directamente si tiene alguna pregunta o inquietud con
            respecto a estos términos. Nuevamente, gracias por su interés en
            Gratitude TM y por pasar este tiempo con nosotros.
          </p>
          <p className='semi-bold text-center'>Mikey Stojcevski, director técnico y operaciones globales</p>
        </div>
        <hr />
        <p className='bold'>1. Condiciones</p>
        <p>
          Al acceder a la plataforma en&nbsp;
          <a href="http://sharegratitude.com/">http://sharegratitude.com</a>
          &nbsp;y otros sitios que poseemos y operamos , usted acepta estar
          sujeto a estos términos de servicio, todas las leyes y regulaciones
          aplicables, y acepta que es responsable del cumplimiento de cualquier
          aplicación local aplicable. leyes. Si no está de acuerdo con alguno de
          estos términos, tiene prohibido utilizar o acceder a este sitio. Los
          materiales contenidos en este sitio web están protegidos por las leyes
          de derechos de autor y marcas registradas aplicables.
        </p>
        <p className='bold'>2. Licencia de uso</p>
        <ol type="a">
          <li>
            Se concede permiso para descargar temporalmente una copia de los
            materiales (información o software) en el recurso compartido
            GRATITUD EMPRESA ‘s sitio web para transitoria personal, no
            comercial únicamente. Esta es la concesión de una licencia, no una
            transferencia de título, y bajo esta licencia no puede:
          </li>
          <ol type="i">
            <li>modificar o copiar los materiales;</li>
            <li>
              utilizar los materiales para cualquier propósito comercial o para
              cualquier exhibición pública (comercial o no comercial );
            </li>
            <li>
              intentar descompilar o aplicar ingeniería inversa a cualquier
              software contenido en la plataforma THE SHARE GRATITUDE COMPANY y
              otros sitios que poseemos y operamos.
            </li>
            <li>
              eliminar los derechos de autor u otras anotaciones de propiedad de
              los materiales; o
            </li>
            <li>
              transferir los materiales a otra persona o "reflejar" los
              materiales en cualquier otro servidor.
            </li>
          </ol>
          <li>
            Esta licencia terminará automáticamente si usted viola cualquiera de
            estas restricciones y THE SHARE GRATITUDE COMPANY puede cancelarla
            en cualquier momento. Al terminar la visualización de estos
            materiales o al finalizar esta licencia, debe destruir cualquier
            material descargado en su poder, ya sea en formato electrónico o
            impreso.
          </li>
        </ol>
        <p className='bold'>3. Descargo de responsabilidad</p>
        <ol type="a">
          <li>
            Los materiales de la plataforma de THE SHARE GRATITUDE COMPANY y
            otros sitios que poseemos y operamos se proporcionan "tal cual". THE
            SHARE GRATITUDE COMPANY no ofrece ninguna garantía, expresa o
            implícita, y por la presente renuncia y niega todas las demás
            garantías, incluidas, entre otras, las garantías implícitas o las
            condiciones de comerciabilidad, idoneidad para un propósito
            particular o no infracción de la propiedad intelectual u otra
            violación de derechos. .
          </li>
          <li>
            Además, THE SHARE GRATITUDE COMPANY no garantiza ni hace ninguna
            representación con respecto a la precisión, los resultados probables
            o la confiabilidad del uso de los materiales en su plataforma o
            relacionados con dichos materiales o en cualquier sitio vinculado a
            este sitio.
          </li>
        </ol>
        <p className='bold'>4. Limitaciones</p>
        <p>
          En ningún caso, THE SHARE GRATITUDE COMPANY o sus proveedores serán
          responsables de los daños (incluidos, entre otros, daños por pérdida
          de datos o ganancias, o debido a la interrupción del negocio) que
          surjan del uso o la imposibilidad de usar los materiales en THE SHARE.
          Sitio web de LA COMPAÑÍA DE GRATITUD , incluso si THE SHARE GRATITUDE
          COMPANY o un representante autorizado de THE SHARE GRATITUDE COMPANY
          han sido notificados oralmente o por escrito de la posibilidad de tal
          daño. Debido a que algunas jurisdicciones no permiten limitaciones
          sobre garantías implícitas o limitaciones de responsabilidad por daños
          consecuentes o incidentales, estas limitaciones pueden no aplicarse en
          su caso.
        </p>
        <p className='bold'>5. Precisión de los materiales</p>
        <p>
          Los materiales que aparecen en la plataforma de THE SHARE GRATITUDE
          COMPANY y otros sitios que poseemos y operamos podrían incluir errores
          técnicos, tipográficos o fotográficos. THE SHARE GRATITUDE COMPANY no
          garantiza que ninguno de los materiales de su sitio web sea exacto,
          completo o actualizado. THE SHARE GRATITUDE COMPANY puede realizar
          cambios en los materiales contenidos en su sitio web en cualquier
          momento sin previo aviso. Sin embargo, THE SHARE GRATITUDE COMPANY no
          se compromete a actualizar los materiales.
        </p>
        <p className='bold'>6. Enlaces</p>
        <p>
          THE SHARE GRATITUDE COMPANY no ha revisado todos los sitios vinculados
          a su sitio web y no es responsable del contenido de dichos sitios
          vinculados. La inclusión de cualquier enlace no implica el respaldo de
          THE SHARE GRATITUDE COMPANY del sitio. El uso de cualquier sitio web
          vinculado es por cuenta y riesgo del usuario.
        </p>
        <p className='bold'>7. Modificaciones</p>
        <p>
          THE SHARE GRATITUDE COMPANY puede revisar estos términos de servicio
          para su sitio web en cualquier momento sin previo aviso. Al usar esta
          plataforma y otros sitios que poseemos y operamos, usted acepta estar
          sujeto a la versión actual de estos términos de servicio.
        </p>
        <p className='bold'>8. Ley aplicable</p>
        <p>
          Estos términos y condiciones se rigen e interpretan de acuerdo con las
          leyes de Nueva Gales del Sur, Australia , y usted se somete
          irrevocablemente a la jurisdicción exclusiva de los tribunales de ese
          estado o ubicación.
        </p>
        <p>Esta política es efectiva a partir del 16 de agosto de 2020.</p>
        <p>
          Invitamos y damos la bienvenida a&nbsp;
          <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>contacto us</a>
          &nbsp; si tiene alguna duda en cuanto a esta política.
        </p>
      </div>
    </>
  );
};

export default TermsOfServiceES;
