import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

import { getGeocodeByPlaceId, getGeoCodeByAddress, getContinentByCoutryCode } from "../../../../Utils/GoogleAPI";

import loc from "../../../../Resources/Images/loc.svg";

const SearchLocation = ({
  backToMainComponent = () => { },
  setLocation = () => { },
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="header-wrapper">
        <img src={loc}></img>
        <h3>location</h3>
        <span className='' onClick={backToMainComponent}>Back</span>
      </div>
      <div className="tagger-wrapper">
        {/* <button
        onClick={backToMainComponent}
        type="button"
        className="btn btn-link"
      >
        {t("back")}
      </button> */}

        <div className="tagger">
          <GooglePlacesAutocomplete
            renderInput={(props) => (
              <div className="serach-panel">
                {" "}
                <input
                  {...props}
                  type="text"
                  className="form-control js-search-place"
                  placeholder={t("search_location")}
                />
              </div>
            )}
            renderSuggestions={(active, suggestions, onSelectSuggestion) => (
              <div className="">
                <div className="search-results">
                  {suggestions.map((suggestion) => (
                    <div
                      class="col-md-12 mt-3"
                      onClick={(event) => onSelectSuggestion(suggestion, event)}
                    >
                      <div class="row">
                        <div class="col-10 col-sm-10 col-md-10 col-lg-10 pl-0">
                          <p class="main-result">
                            <i class="fas fa-map-marker-alt"></i>
                            {suggestion.structured_formatting.main_text
                              ? suggestion.structured_formatting.main_text
                              : ""}
                            <span class="sub-text">
                              {suggestion.structured_formatting.secondary_text
                                ? suggestion.structured_formatting.secondary_text
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 mt-1 p-0">
                          {" "}
                          <i class="fas fa-plus-circle"></i>
                        </div>
                      </div>
                    </div>
                  ))}
                   <img src='https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png?hl=fr'/>
                </div>
              </div>
            )}
            onSelect={async (suggestions) => {
              var termsAr = suggestions.terms;
              var city = termsAr[termsAr.length - 2];
              var country = termsAr[termsAr.length - 1];
              var cityGeocode = '';
              var countryGeocode = '';

              const geoCode = await getGeocodeByPlaceId(suggestions.place_id);
              if (city !== undefined) cityGeocode = await getGeoCodeByAddress(city.value);
              if (country !== undefined) countryGeocode = await getGeoCodeByAddress(country.value);
              // 'geo code --->', cityGeocode[0].place_id);
              var countryCode = countryGeocode[0].address_components[0].short_name;
              const continent = await getContinentByCoutryCode(countryCode);
              setLocation({
                latitude: geoCode.lat,
                longitude: geoCode.long,
                description: suggestions.structured_formatting.main_text,
                location: [
                  {
                    name: city,
                    type: 'city',
                    placeId: cityGeocode ? cityGeocode[0].place_id : 0
                  },
                  {
                    name: country,
                    type: 'country',
                    placeId: countryGeocode ? countryGeocode[0].place_id : 0
                  },
                ],
                continent: continent
              });
              backToMainComponent();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SearchLocation;
