import React from "react";
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Layout from "../../../Layout/Layout";
import Form from "../Components/Donation/PrintCard/Form";
import giftcard from "../../../../Resources/Images/gc.gif";

const PrintCard = () => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const writeMsg = () => {
    history.push("/gratitude");
  }

  return (
    <Layout>
      <div className="text-wrapper">
        <div className="col-md-12 pl-0 pr-0 text-center give-little">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list no-border pt-0 mw-5 gift">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p className="text-uppercase tit">
                      Simple Way to <strong>Give a little</strong>
                    </p>
                    <Form
                      giftName="Printed Card"
                      giftPrice={9.90}
                      giftImg={giftcard}
                      giftDsc="A beautiful, impactful gesture.  Printed on the finest stock, hand-stamped, and posted on your behalf. "
                    />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    </Layout>
  );
};

export default PrintCard;
