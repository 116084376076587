import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchWorldCountsService = async (request) => {
  const REGISTER_API_ENDPOINT = `${BASE_URL}/statistic/getWorldViewData`;

  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};

export const fetchUserDetailsService = async (request) => {
  const GET_USER_DETAILS_API_ENDPOINT = `${BASE_URL}/statistic/getuserStatistics`;

  let res = await axios
    .get(GET_USER_DETAILS_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};


