import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

// import Community from './Pages/Community';
import CommunityMsg from './Pages/CommunityMsg';
import ReplySingleMsg from './Pages/ReplySingleMsg';
import Donation from "./Pages/Donation";
const communityRoutes = () => {
    return (
        <Fragment>
            <Route path="/msg/:id" exact component={CommunityMsg} />
            <Route path="/reply/:id" exact component={ReplySingleMsg} />
            <Route path="/donation" exact component={Donation} />
        </Fragment>
    )
};

export default communityRoutes;

