import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LocationFilter from "../../Modules/MessageFilter/Pages/LocationFilter";
import RecipientFilter from "../../Modules/MessageFilter/Pages/RecipientFilter";

import {
  setFilterLocationAction,
  setFilterRecipientAction,
  removeFilterLocationAction,
  removeFilterRecipientAction,
  setInitialRecipientFlterAction,
} from "../../../Actions/communityAction";

import { useTranslation } from "react-i18next";

const MessageFilter = () => {
  const { t, i18n } = useTranslation();

  const locations = useSelector((state) => state.community.locationFilter);
  const recipients = useSelector((state) => state.community.recipientFilter);
  const messageSectors = useSelector((state) => state.community.messageSectors);

  const dispatch = useDispatch();

  const addLocations = (newLocation) => {
    let isAwailable = false;
    locations.forEach((loc) => {
      if (loc.placeId === newLocation.placeId) isAwailable = true;
    });
    if (isAwailable) return;
    dispatch(setFilterLocationAction([...locations, newLocation]));
  };

  const removeLocations = (locationId) => {
    const list = [...locations].filter(
      (location) => location.placeId !== locationId
    );
    dispatch(removeFilterLocationAction(list));
  };

  const addRecipient = (value) => {
    dispatch(setFilterRecipientAction([...recipients, value]));
  };
  const removeRecipient = (value) => {
    const list = [...recipients].filter((word) => word !== value);
    dispatch(removeFilterRecipientAction(list));
  };

  return (
    <>
      <LocationFilter
        backToMainComponent={() => {}}
        addLocations={addLocations}
        removeLocations={removeLocations}
        locations={locations}
        header={false}
      />

      <RecipientFilter
        backToMainComponent={() => {}}
        messageSectors={messageSectors}
        addRecipient={addRecipient}
        removeRecipient={removeRecipient}
        recipients={recipients}
        header={false}
      />
    </>
  );
};
export default MessageFilter;
