import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.scss";

// import { Row, Col, Nav, Button } from 'react-bootstrap';
import give from "../../../Resources/Images/give.svg";
import MenuImg from "../../../Resources/Images/menu.svg";
import close from "../../../Resources/Images/close.svg";

const HeaderMsg = ({ backToMainComponent = () => {} }) => {
  return (
    <div className="header-wrapper d-none">
      <div className="nav-cnt">
        <img src={give}></img>
        <h3>Recipients</h3>
        <span className="d-none" onClick={backToMainComponent}>
          Back
        </span>
      </div>
    </div>
  );
};

export default HeaderMsg;
