import * as types from "../Actions";

const initailState = {
  worldCount: { isLoading: true },
  userData:[]
};

export default function (state = initailState, action) {
  //console.log("world action", action);
  let response = action.response;
  switch (action.type) {
    // fetch world menu counts
    case types.FETCH_WORLD_COUNT_SUCCESS:
      return { ...state, worldCount: { isLoading: false, data: response.data.worldViewData } };
    case types.FETCH_WORLD_COUNT_FAIL:
      return { ...state };

      case types.FETCH_USER_DETAILS_SUCCESS:
        return { ...state, userData: response};
      case types.FETCH_USER_DETAILS_FAIL:
        return { ...state };
    default:
      return state;
  }
}
