import React, { Fragment, useState } from "react";

const DonationSelector = ({
    changeAmount = () => {},
    donationAmount = 0,
    currencyCode = 'USD'
}) => {
    const [customDonationAmount, setCustomDonationAmount] = useState(0);

    const donationAmounts = [0, 2, 5, 10];

    const changeDonation = (donation) => {
        const isvalidNumber = donation.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/);

        if (!isvalidNumber) donation = 0;

        donation = parseFloat(donation);

        setCustomDonationAmount(donation);

        if (donationAmounts[0] <= donation) 
            changeAmount(donation);
        else 
            changeAmount(donationAmounts[0]);
    }

    const donationAmountSelector = () => {
        return (
            <Fragment>
                {donationAmounts.map((amount, index) => (
                    <div
                        key={index}
                        className={donationAmount === amount ? 'active' : ''}
                    >
                        <input
                            id={index}
                            type="radio"
                            name="donationAmount"
                            value={amount}
                            checked={donationAmount === amount}
                            onChange={(e) => {
                                    setCustomDonationAmount(0);
                                    changeAmount(e.target.value)
                                }
                            }
                        />
                        <label htmlFor={index}>{amount} {currencyCode}</label><br></br>
                    </div>
                ))}
            </Fragment>
        );
    }

    const donationAmountInputField = () => {
        return (
            <input
                name="donation"
                type="text"
                className="form-control form-control-sm pl-3 text-left"
                value={customDonationAmount ? customDonationAmount: ''}
                placeholder={'Enter other amount'}
                onChange={(e) => changeDonation(e.target.value) }
            />
        );
    }

    return (
        <div>
            <div className="d-wrapper">
        <div className="d-radio">
            {donationAmountSelector()}</div>
            {donationAmountInputField()}
            </div>
        </div>
    );
}

export default DonationSelector;