import React, { useState } from 'react'
import { Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper } from '@material-ui/core/';

import { useTranslation } from "react-i18next";

const cityHeadCells = [
    { id: 'city', label: 'Name of the City' },
    { id: 'msgCount', label: 'Number of Messages' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const CityDataModel = (props) => {
    const { openState, onCloseHandler, countryData, cityData } = props;

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('msgCount');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const { t, i18n } = useTranslation();

    return (
        typeof countryData !== 'undefined' ?
            <Dialog
                open={openState}
                onClose={() => onCloseHandler()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="email-share vid"
            >
                <div className="mo-header">
                    {" "}
                    {/* <img src={send} className='send-mo'></img> */}
                    <span>{countryData.country} {" "} </span>
                    <span onClick={() => onCloseHandler()} color="secondary" autoFocus>
                        {t('back')}
                    </span>
                </div>
                <DialogContent style={{ overflowY: 'scroll' }}>

                    <Paper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '5%' }} align="left" padding={'default'}>{""}</TableCell>
                                        {cityHeadCells.map((headCell) => (
                                            <TableCell
                                                style={{ width: '50%' }}
                                                key={headCell.id}
                                                padding={'default'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => handleRequestSort(headCell.id)}
                                                >
                                                    <b>{headCell.label}</b>
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(cityData, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={`${row.city}_${index}`}
                                                >
                                                    <TableCell style={{ width: '5%' }} align="left" padding={'default'}>{index + 1}</TableCell>
                                                    <TableCell style={{ width: '50%' }}>
                                                        {row.city}
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%' }} align="left" padding={'default'}>{row.msgCount}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>


                </DialogContent>
            </Dialog> : null
    )
}

export default CityDataModel