import React, { useState } from 'react'
import { Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper } from '@material-ui/core/';

import { useTranslation } from "react-i18next";
import Marker from "../../../../Resources/Images/marker.svg";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const DataModel = (props) => {
    const { openState, onCloseHandler, locationDataList, modelType } = props;

    const HeadCells = modelType === 'country' ? [
        { id: 'country', label: 'Country' },
        // { id: 'msgCount', label: 'Number of Messages' },
    ] :
        [
            { id: 'city', label: 'City' },
            // { id: 'msgCount', label: 'Number of Messages' },
        ];


    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(modelType === 'country' ? 'country' : 'city');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const { t, i18n } = useTranslation();

    return (
        typeof locationDataList !== 'undefined' ?
            <Dialog
                open={openState}
                onClose={() => onCloseHandler()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="email-share vid"
            >
                <div className="mo-header">
                    {" "}
                    {/* <img src={send} className='send-mo'></img> */}
                    <span>{modelType === 'country' ? 'Country View' : 'City View'} {" "} </span>
                    <span onClick={() => onCloseHandler()} color="secondary" autoFocus>
                        {t('back')}
                    </span>
                </div>
                <DialogContent style={{ overflowY: 'scroll' }}>

                    <ul className='mapdata'>
                    {stableSort(locationDataList, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            return (
                        <li key={index}>
                            
                            <span>{index + 1}</span>
                            {modelType === 'country' ? row.country : row.city}

                            <img src={Marker}/>
                        </li>
                        )
                    })}
                    </ul>


                </DialogContent>
            </Dialog> : null
    )
}

export default DataModel