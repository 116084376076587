import React from "react";

const TermsOfServiceEN = () => {
  return (
    <>
    <div className='stc-wrapper'>
      <h4 className='text-center'>Terms of Service</h4>
      <p className='text-center'>
        Thank you for your interest and visiting our platform. Below you’ll find
        some legal jargon from our legal firm, which basically outlines the
        terms of service of the platform and other sites we own and operate. We
        invite you to reach out to us directly if you have any questions or
        concerns regarding these terms. Again thank you for your interest in
        Gratitude <sup>TM </sup> and spending this time with us.
      </p>
      <p className='text-center semi-bold'>
        Mikey Stojcevski, Chief Technical Officer &amp; Global Operations
      </p>
      <div className='text-center'>
        <hr size="2" width="100%" className='text-center' />
      </div>
      <p className='bold'>1. Terms</p>
      <p>
        By accessing the platform at{" "}
        <a href="http://sharegratitude.com/">http://sharegratitude.com</a> and
        other sites we own and operate, you are agreeing to be bound by these
        terms of service, all applicable laws and regulations, and agree that
        you are responsible for compliance with any applicable local laws. If
        you do not agree with any of these terms, you are prohibited from using
        or accessing this site. The materials contained in this website are
        protected by applicable copyright and trademark law.
      </p>
      <p className='bold'>2. Use License</p>
      <ol start="1" type="a">
        <li>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on THE SHARE GRATITUDE COMPANY’s
          website for personal, non-commercial transitory viewing only. This is
          the grant of a license, not a transfer of title, and under this
          license you may not:
        </li>
        <ol start="1" type="i">
          <li>modify or copy the materials;</li>
          <li>
            use the materials for any commercial purpose, or for any public
            display (commercial or non-commercial);
          </li>
          <li>
            attempt to decompile or reverse engineer any software contained on
            THE SHARE GRATITUDE COMPANY platform and other sites we own and
            operate.
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transfer the materials to another person or "mirror" the materials
            on any other server.
          </li>
        </ol>
        <li>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by THE SHARE GRATITUDE COMPANY at
          any time. Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded materials
          in your possession whether in electronic or printed format.
        </li>
      </ol>
      <p className='bold'>3. Disclaimer</p>
      <ol start="1" type="a">
        <li>
          The materials on THE SHARE GRATITUDE COMPANY platform and other sites
          we own and operate are provided on an 'as is' basis. THE SHARE
          GRATITUDE COMPANY makes no warranties, expressed or implied, and
          hereby disclaims and negates all other warranties including, without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights.
        </li>
        <li>
          Further, THE SHARE GRATITUDE COMPANY does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its platform or otherwise
          relating to such materials or on any sites linked to this site.
        </li>
      </ol>
      <p className='bold'>4. Limitations</p>
      <p>
        In no event shall THE SHARE GRATITUDE COMPANY or its suppliers be liable
        for any damages (including, without limitation, damages for loss of data
        or profit, or due to business interruption) arising out of the use or
        inability to use the materials on THE SHARE GRATITUDE COMPANY website,
        even if THE SHARE GRATITUDE COMPANY or a THE SHARE GRATITUDE COMPANY
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.
      </p>
      <p className='bold'>5. Accuracy of materials</p>
      <p>
        The materials appearing on THE SHARE GRATITUDE COMPANY platform and
        other sites we own and operate could include technical, typographical,
        or photographic errors. THE SHARE GRATITUDE COMPANY does not warrant
        that any of the materials on its website are accurate, complete or
        current. THE SHARE GRATITUDE COMPANY may make changes to the materials
        contained on its website at any time without notice. However, THE SHARE
        GRATITUDE COMPANY does not make any commitment to update the materials.
      </p>
      <p className='bold'>6. Links</p>
      <p>
        THE SHARE GRATITUDE COMPANY has not reviewed all of the sites linked to
        its website and is not responsible for the contents of any such linked
        site. The inclusion of any link does not imply endorsement by THE SHARE
        GRATITUDE COMPANY of the site. Use of any such linked website is at the
        user's own risk.
      </p>
      <p className='bold'>7. Modifications</p>
      <p>
        THE SHARE GRATITUDE COMPANY may revise these terms of service for its
        website at any time without notice. By using this platform and other
        sites we own and operate you are agreeing to be bound by the then
        current version of these terms of service.
      </p>
      <p className='bold'>8. Governing Law</p>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of New South Wales, Australia and you irrevocably submit
        to the exclusive jurisdiction of the courts in that State or location.
      </p>
      <p>This policy is effective as of August 16, 2020.</p>
      <p>
        We invite and welcome you to{" "}
        <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>Contact us</a> if
        you have any further questions in regards to this policy.
      </p>
      </div>
    </>
  );
};

export default TermsOfServiceEN;
