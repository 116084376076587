import * as types from '../Actions';

const initailState = {
    createdCustomer: [],
    checkoutSession: [],
    orders         : [],
    gratitudeMsg   : {},
};

export default function (state = initailState, action) {
    let response = action.response;

    switch (action.type) {
        case types.PAYMENT_CREATE_CUSTOMER_SUCCESS:
            return { ...state, createdCustomer: response.data };
        case types.PAYMENT_CREATE_CUSTOMER_FAIL:
            return { ...state, createdCustomer: response.data };
        case types.PAYMENT_CHECKOUT_SUCCESS:
            return { ...state, checkoutSession: response.data };
        case types.PAYMENT_CHECKOUT_FAIL:
            return { ...state, checkoutSession: response.data };
        case types.FETCH_ORDERS_SUCCESS:
            return { ...state, orders: response.data };
        case types.FETCH_ORDERS_FAIL:
            return { ...state, orders: response.data };
        case types.SET_GRATITUDE_MSG_SUCCESS:
            return { ...state, gratitudeMsg: response.payload };
        default:
            return state;
    }
}