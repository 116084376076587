import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

import Layout from "../../../Layout/Layout";
import Carousel from "react-bootstrap/Carousel";

import StoryItem from "./StoryItem";
import storyData from "../../../../Resources/Files/communityData";
import { shuffleStories } from "../../../../Utils/FormatData";

const StoryList = (props) => {
  const { t, i18n } = useTranslation();
  const [stories, setStories] = useState(storyData);

  useEffect(() => {
    const type = props.type ? props.type : "";
    let shuffledStories = shuffleStories(stories, type);
    setStories(shuffledStories);
  }, []);

  return (
    <Layout>
      <div className="row">
        <div id="list" className="massage-list col-12">
        <Carousel fade={true} controls={false}>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="smilee ban"
                  href="https://youtu.be/qnIniMHL38Y"
                >
                  <div>
                    <p>https://humansofsharegratitude.com</p>
                    <h1>The Seven Wonders of Gratitude</h1>
                    <button className="btn btn-secondary">WATCH NOW</button>
                  </div>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="uk ban"
                  href="https://www.globalgiving.org/fundraisers/sharegratitude4ukraine"
                >
                  <div>
                    <p>GlobalGiving Ukraine Crisis Relief Fund</p>
                    <h1>SUPPORT UKRAINE</h1>
                    <button className="btn btn-secondary">Donate Today</button>
                  </div>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="smilee ban"
                  href="https://www.youtube.com/watch?v=MEqxa0l6FqA&t"
                >
                  <div>
                    <p>https://humansofsharegratitude.com</p>
                    <h1>...being extraordinarily HUMAN</h1>
                    <button className="btn btn-secondary">WATCH NOW</button>
                  </div>
                </a>
              </Carousel.Item>
              </Carousel>
          {stories.length !== 0 &&
            stories.map((data, key) => <StoryItem dataList={data} />)}
        </div>
      </div>
    </Layout>
  );
};

export default StoryList;
