import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import "./tour-guid.scss";
import logoEn from "../../../../Resources/Images/logo-en-f.svg";
import logoEs from "../../../../Resources/Images/logo-es-f.svg";
import logoZh from "../../../../Resources/Images/logo-zh-f.svg";
import logoImg1 from "../../../../Resources/Images/logo-en-f.svg";
import logoB from "../../../../Resources/Images/blank.jpg";
import i1 from "../../../../Resources/Images/Icon1.svg";
import i2 from "../../../../Resources/Images/Icon2.svg";
import i3 from "../../../../Resources/Images/Icon3.svg";
import { getLanguage } from '../../../../Utils/Storage';

const TourGuide = ({ userPrecense = () => { } }) => {

  const [fade, setFade] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setFade(!fade);
    }, 2000);
  }, []);

  const language = getLanguage();

  /**
   * change logo according to the user's country and it's language, default is English
   */
  let logoImg = logoB;

  if(language=="en"){
    logoImg = logoEn;

  }else if(language=="es"){
    logoImg = logoEs;

  }else if(language=="zh"){
    logoImg = logoZh;
  }

  return (
    <>
      <div className={`splash ${fade ? "fading" : ""}`}>
        <div className="splash-group">
          <img className="d-block img-fluid" src={logoImg} alt="logo-gratitude" />
          <p>{t("loading_msg")}</p>
        </div>
      </div>

      <div className="carousel-wrapper">
        <div>
          <div className="carousel-group">
            <Carousel
             interval={5999000}
             slide={true}>
              <Carousel.Item className="item-one">
              <p>{t('welcome_msg_1_detail')}</p> 
                <Carousel.Caption>
                <img className="d-block img-fluid" src={logoImg1} alt="logo-gratitude" />
                  {/* <h3>{t('welcome_msg_1_title')}</h3> */}
                  {/* <p>
                    {t('welcome_msg_1_detail')}
                  </p> */}
                
                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item className="item-two">
                <Carousel.Caption>
                  <h3>Share messsages</h3>
                  <p>
                    Become part of a stronger global community and help boost
                    these much needed messagess of support
                  </p>
                </Carousel.Caption>
              </Carousel.Item> */}
              <Carousel.Item className="item-two">
              <ul>
                    <li><img className="d-block img-fluid" src={i3} alt="logo-gratitude" /> <span>{t('welcome_msg_2-1_detail')}</span> </li>
                    <li><img className="d-block img-fluid" src={i2} alt="logo-gratitude" /> <span>{t('welcome_msg_2-2_detail')}</span> </li>
                    <li><img className="d-block img-fluid" src={i1} alt="logo-gratitude" /> <span>{t('welcome_msg_2-3_detail')}</span> </li>
                  </ul>
                <Carousel.Caption>
  
                <img className="d-block img-fluid" src={logoImg1} alt="logo-gratitude" />
                  {/* <h3>{t('welcome_msg_2_title')}</h3>
                  <p>
                    {t('welcome_msg_2_detail')}
                  </p> */}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

            <button
              onClick={() => userPrecense()}
              className="btn btn-primary w-75"
            >
              {t('get_started')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourGuide;
