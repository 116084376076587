import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TransitionsModal from "../../../Common/TransitionsModal";
import RightImg from "../../../../Resources/Images/right.svg";
import FrontLinerList from "../../../Common/FrontLinerList";
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import { checkUserPrecenseAction } from "../../../../Actions/generalAction";
import {
  resetReplyMsgAction,
  resetTrackReplyMsgAction,
} from "../../../../Actions/communityAction";

import { validateEmail } from "../../../../Utils/Util";
import { NavLink, Link } from "react-router-dom";

import {
  userRegistrationAction,
  resetUserRegistrationAction
} from "../../../../Actions/gratitudeAction";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const ReplyMsgImpact = ({
  submitMsgID = 0,
  trackMyMsg = () => { },
  closeWriteReply = () => { },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [enableImpact, setEnableImpact] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [openUseOfTerms, setOpenUseOfTerms] = useState(false);
  const [frontLinerDes, setFrontLinerDes] = useState(false);
  const [frontLineDesAr, setFrontLineDesAr] = useState(["I am a caring community member"]);
  const [customDescription, setCustomDescription] = useState("");
  const [enableWriteMsg, setEnableWriteMsg] = useState(true);
  const [isDoneProfession, setIsDoneProfession] = useState(false);

  // validation
  const [validName, setValidName] = useState(true);
  const [isvalidEmail, setIsvalidEmail] = useState(true);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const emailValidation = (email) => {
    setUserEmail(email);
    setValidEmail(validateEmail(email));
    setIsvalidEmail(validateEmail(email));
  };

  const trackMessage = () => {
    const formData = {
      userName: userName,
      primaryEmail: userEmail,
      profession: frontLineDesAr,
      customProfession: [customDescription],
      // isAgreedTerms: agreeWithTerms,
      // isImpactEnable: enableImpact,
      browserHash: localStorage.getItem("browserHash").toString(),
      selectedLanguage: props.lang,
      messageId: props.submitReplyMsg._id
    };
    // trackMyMsg(formData);
    props.userRegistrationAction(formData);
  };

  useEffect(() => {
    if (props.userRegistration) {
      if (props.userRegistration.data) {
        if (props.userRegistration.data.success) {
          if (enableWriteMsg) {
            history.push("/gratitude");
            props.checkUserPrecenseAction();
          }
          else
            closeWriteReply();
        }
        props.resetUserRegistrationAction();
        props.resetReplyMsgAction();
      } else if (props.userRegistration.name === "Error") {
        setSeverity("error");
        setErrorMsg("Please check again and re-submit.");
        setErrorNotifification(!errorNotifification);
        props.resetUserRegistrationAction();
        props.resetReplyMsgAction();
      }
    }
  }, [props.userRegistration]);

  useEffect(() => {
  }, [props.trackMyMsg]);

  const validateNameField = (name) => {
    var isValidName = name ? true : false;
    setValidName(isValidName);
  }

  const validateFields = () => {
    setIsvalidEmail(validateEmail(userEmail));
    setValidName(userName ? true : false);
  }

  const addDescription = (checked, value) => {
    if (checked) {
      setFrontLineDesAr([...frontLineDesAr, value]);
    } else {
      var index = frontLineDesAr.indexOf(value);
      frontLineDesAr.splice(index, 1);
      setFrontLineDesAr([...frontLineDesAr]);
    }
  }

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  return (
    <div className="col-md-12">
      {!frontLinerDes && (
        <div className="col-md-12">
          <span className="ab-btn" onClick={closeWriteReply}>
            {t("skip")}
          </span>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list no-border">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 impact-header">
                    <p>{t("Amazing! Your reply message has been sent")}</p>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p>{t("We are excited to share with you the impact of your reply message")}</p>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p className="italic">{t("This info isn't required in order to proceed, but simply so we can share with you the impact of your reply message.")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                    <input
                      value={userName}
                      onChange={(e) => { setUserName(e.target.value); validateNameField(e.target.value); }}
                      type="text"
                      className="form-control form-control-sm pl-3"
                      placeholder={t("full_name")}
                    />
                    {!validName ? <span className='inp-error'>Full Name is requierd</span> : ''}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                    <input
                      value={userEmail}
                      onChange={(e) => emailValidation(e.target.value)}
                      type="text"
                      className="form-control form-control-sm pl-3"
                      placeholder={t("email")}
                    />
                    {!isvalidEmail ? <span className='inp-error'>Input a Valid email address</span> : ''}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row"
                  onClick={() => setFrontLinerDes(true)}
                >
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                    <input
                      // value={frontLineDesAr.toString() + (frontLineDesAr.length > 0 && customDescription ? "," : "") + (customDescription ? customDescription : '')}
                      value={(props.userPrecense && props.userPrecense.isRegistered) || isDoneProfession ? frontLineDesAr.map((profession) => { return t(profession) }) + (frontLineDesAr.length > 0 && customDescription ? "," : "") + (customDescription ? customDescription : '') : ""}
                      type="text"
                      className="self-desc form-control form-control-sm pl-3"
                      placeholder={t("What describes you best")}
                    />
                    <a>
                      <img src={RightImg} alt="" />
                    </a>
                    {/* {!isvalidEmail ? <span className='inp-error'>Requied valid email</span> : ''} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row">
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 link">
                    <Link>
                      <label
                        onClick={() => setOpenUseOfTerms(!openUseOfTerms)}
                        htmlFor=""
                      >
                        {t("agree_to_terms_of_service")}
                      </label>
                    </Link>
                  </div>
                  <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                    <label className="switch float-right">
                      <input
                        checked={agreeWithTerms ? true : false}
                        onChange={() => setAgreeWithTerms(!agreeWithTerms)}
                        type="checkbox"
                      />{" "}
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="input-list">
                <div className="row">
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <label htmlFor="">{t("I would like to send my own message to gratitude")}</label>
                  </div>
                  <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                    <label className="switch float-right">
                      <input
                        checked={enableWriteMsg ? true : false}
                        onChange={() => setEnableWriteMsg(!enableWriteMsg)}
                        type="checkbox"
                      />{" "}
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              {" "}
              <a
                onClick={
                  userName && userEmail && agreeWithTerms
                    ? () => trackMessage()
                    : () => validateFields()
                }
                href="#"
                className={`float-right track ${
                  userName && validEmail && agreeWithTerms ? "active" : "inactive"
                  }`}
              >
                {enableWriteMsg ? t("Write Message") : t("OK")}
              </a>
            </div>
          </div>
        </div>)}
      {frontLinerDes && (<FrontLinerList
        backToMain={() => { setFrontLinerDes(false); setIsDoneProfession(true); }}
        addedDescription={frontLineDesAr}
        addDescription={(checked, value) => addDescription(checked, value)}
        addCustomDescription={(value) => setCustomDescription(value)}
        customDescription={customDescription}
      />)}

      <TransitionsModal
        open={openUseOfTerms}
        closeModel={() => setOpenUseOfTerms(!openUseOfTerms)}
      />

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={3000}
      />

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userRegistrationAction: (data) => dispatch(userRegistrationAction(data)),
    resetReplyMsgAction: () => dispatch(resetReplyMsgAction()),
    resetTrackReplyMsgAction: () => dispatch(resetTrackReplyMsgAction()),
    resetUserRegistrationAction: () => dispatch(resetUserRegistrationAction()),
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction())
  };
};

const mapStateToProps = (response) => ({
  submitReplyMsg: response.community.submitReplyMsg,
  lang: response.general.lang,
  userRegistration: response.gratitude.userRegistration,
  userPrecense: response.general.userPrecense.data
});
export default connect(mapStateToProps, mapDispatchToProps)(ReplyMsgImpact);
