import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Marker from "../../../../Resources/Images/marker.svg";
import { useTranslation } from "react-i18next";

import leftArrow from "../../../../Resources/Images/left-arrow.svg";

const Ditto = ({
    closeDittoComponent = () => { },
    userList = []
}
) => {
    const { t, i18n } = useTranslation();
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    const classes = useStyles();
    return (
        <div className="w-100">
            <div className="reply-wrapper">
                <div className="reply-title">
                    <h5 className='mb-0'>{t("Reposted by")}</h5>
                    <span className="ab-btn" onClick={closeDittoComponent}>
                        <img src={leftArrow} /> {t("back")}
                    </span>
                </div>
                <div>
                {userList.map((user, key) => (
                <div className="massege-item d-flex" key={key}>
                <div className="w-100">
            <div className="descripton pl-0">
              <p className="name">{user.userName}</p>
              <p className="location">
                <img src={Marker} alt="" />
                {user.userLocation ? user.userLocation : "-"}
              </p>
            </div>
                </div>
                </div>
                 ))}
                </div>
            </div>
        </div>

    );
}

export default Ditto;