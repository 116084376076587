
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const YoutubePlayer = ({
    videoId="",
    autoplay="1",
    rel="",
    modest="",
    handleClose = () => { }
}) => {
    const videoSrc = "https://www.youtube.com/embed/" + 
                        videoId + "?autoplay=" + 
                        autoplay + "&rel=" + 
                        rel + "&modestbranding=" +
                        modest;
    
    return (
        <div className="vc">
            <iframe className="player" type="text/html" width="100%" height="364"
                src={videoSrc}
                frameBorder="0"
                allow="autoplay"
            />
      </div>
    );
}

const VimeoPlayer = ({
    videoId="",
    handleClose = () => { }
}) => {
    const videoSrc = "https://player.vimeo.com/video/" + videoId;
    
    return (
        <div className="vc">
            <iframe
                src={videoSrc}
                width="100%" 
                height="564" 
                frameBorder="0" 
                webkitallowfullscreen 
                mozallowfullscreen 
                allowfullscreen
            />
      </div>
    );
}

export {
    YoutubePlayer,
    VimeoPlayer
};
