import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom"
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import Layout from "../../../Layout/Layout";

const Give = () => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const writeMsg = () => {
    history.push("/gratitude");
  }

  
  return (
    <Layout>
      <div className="text-wrapper">
        <div className="col-md-12 pl-0 pr-0 text-left give-little text-center">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list no-border pt-0 mw-5">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p className="text-center overflow-hidden">
                    <span className="tit">{t("SIMPLE WAY TO")}&nbsp;<strong>{t("GIVE A LITTLE")}</strong></span>
                    <br />
            
                    {/* <label>{t("give_a_title_second_sentence")}</label> */}
                  </p>

                    <p className='text-center'>
                      {t(
                        "Select from the below, and we will hand select a local small business near your recipient to support with sending your gift."
                      )}
                    </p>

                    

                    <a className="btn btn-primary btn-block" href="/printCard">
                      {t('Send a printed')}&nbsp;<strong>{t('card')}</strong>
                    </a>
                    {/* <small className='mt-1 italic'>{t("This requires a")} <u className='cp'>{t("recipient postal address")}</u></small> */}

                    <a className="btn btn-primary btn-block" href="/SendFlowers">
                      {t('Send some')}&nbsp;<strong>{t('flowers')}</strong>
                    </a>
                    {/* <small className='mt-1 italic'>{t("This requires a")} <u className='cp'>{t("recipient postal address")}</u></small> */}

                    <a className="btn btn-primary btn-block" href="/WineBottle">
                      Send a bottle of<strong>&nbsp;Wine</strong>
                    </a>
                    {/* <small className='mt-1 italic'>{t("This requires a")} <u className='cp'>{t("recipient postal address")}</u></small> */}
                    <Tooltip title={t("100% of your generosity will go towards your chosen cause")} enterDelay={500} placement="top" arrow>
                    <a className="btn btn-primary btn-block" href="/donation">
                      <strong>{t('Donate')}&nbsp;</strong> {t('to charity')}
                     
                    </a>
                    </Tooltip>


                    {/* <a onClick={writeMsg} className="btn btn-primary btn-block">
                      {t('Write a')}&nbsp;<strong>{t('message')}&nbsp;</strong>{t('of Gratitude')}
                    </a> */}

                    <p className='mt-3 text-center'>
                      {t("The impact of your gesture is real and far-reaching. Together we are making a real difference in people's lives, their families, and our community")}
                    </p>

                    <div className="dscl">
         <p><b className="text-uppercase">Disclaimer</b> <br/> ShareGratitude does not charge any fees or keep a profit margin. Like you, we believe 100% of your generosity should be enjoyed by the recipient or go towards supporting your chosen cause. When you donate, your chosen charity will process your donation and directly provide a tax receipt.</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    </Layout>
  );
};

export default Give;
