import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import GiveLocal from './Pages/GiveLocal';
import CharityPartners from './Pages/CharityPartners';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CookiePolicy from './Pages/CookiePolicy';
import TermsOfService from './Pages/TermsOfService';
import registration from './Pages/Registration';
import Give from './Pages/Give';
import Volunteer from './Pages/Volunteer';
import PrintCard from './Pages/PrintCard';
import SendFlowers from './Pages/SendFlowers';
import WineBottle from './Pages/WineBottle';
import paymentStatus from './Components/Donation/PrintCard/paymnetStatus';
import Contact from './Pages/Contact';

const MenuRoutes = () => {
	return (
		<Fragment>
			<Route path="/subscribe" exact component={registration} />
			<Route path="/giveLocal" exact component={GiveLocal} />
			<Route path="/charityPartners" exact component={CharityPartners} />
			<Route path="/privacyPolicy" exact component={PrivacyPolicy} />
			<Route path="/cookiePolicy" exact component={CookiePolicy} />
			<Route path="/termsOfService" exact component={TermsOfService} />
			<Route path="/give" exact component={Give} />
			<Route path="/volunteer" exact component={Volunteer} />
			<Route path="/contact" exact component={Contact} />
			<Route path="/printCard" exact component={PrintCard} />
			<Route path="/sendFlowers" exact component={SendFlowers} />
			<Route path="/wineBottle" exact component={WineBottle} />
			<Route path="/paymentStatus" exact component={paymentStatus} />
		</Fragment>
	);
};

export default MenuRoutes;
