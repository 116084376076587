import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom'

import { fetchWorldCountAction } from "../../../../Actions/worldAction";
import loading from '../../../../Resources/Images/worldLoading.svg'
import DataModel from './DataModel'
import ma from "../../../../Resources/Images/ssa.svg";

const WorldWievCounts = ({ ...props }) => {
    const [countryModelOpen, setOpenCountryModel] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [modelType, setModelType] = useState('country');

    const handleModelClose = () => {
        setOpenCountryModel(false);
    }

    const handleModelOpen = (modelType) => {
        const selectedModel = modelType === 'country' ? 'countryData' : 'cityData';
        setDataList(props.worldViewData.data[selectedModel]);
        setModelType(modelType);
        setOpenCountryModel(true);
    }

    const { t, i18n } = useTranslation();

    useEffect(() => {
        props.fetchWorldCountAction();
    }, []);

    return (
        <Fragment>
            {props.worldViewData.isLoading ?
                (<center><img src={loading}></img></center>) :
                (<div className="stats pb-5">
                    <NavLink className="nav-link" to="/">
                       <h4>3,139,636</h4>
                        <p>{t("liveReached")}</p>
                    </NavLink>
                    <div className='map-wrap'>
                    <img className='img-fluid' src={ma}/>
                    <div className='d-flex'>
                    <div
                        role="button"
                        onClick={() => handleModelOpen('city')}
                    >
                        {/* <h4>{[...props.worldViewData.data.cityData].length}</h4> */}
                        <h4>81</h4>
                        <p>{t("Towns/Cities")}</p>
                    </div>
                    <div
                        role="button"
                        onClick={() => handleModelOpen('country')}
                    >
                        {/* <h4>{[...props.worldViewData.data.countryData].length}</h4> */}
                        <h4>18</h4>
                        <p>{t("Countries")}</p>
                    </div>
                    <div
                        role="button"
                        onClick={() => handleModelOpen('livereached')}
                    >
                       <h4>{
                            (765 +
                            props.worldViewData.data.msgData.totalMsgs +
                            props.worldViewData.data.msgData.totalLikes +
                            props.worldViewData.data.msgData.totalDitto +
                            props.worldViewData.data.replyData.totalreplies +
                            props.worldViewData.data.replyData.totalLikes +
                            props.worldViewData.data.replyData.totalDittos).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }</h4>
                        <p>{t("messages_and_acts_of_gratitude")}</p>
                    </div>
                </div></div></div>)}
            <DataModel
                openState={countryModelOpen}
                onCloseHandler={handleModelClose}
                locationDataList={dataList}
                modelType={modelType}
            />
        </Fragment>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchWorldCountAction: () => dispatch(fetchWorldCountAction()),
    };
};

const mapStateToProps = (response) => ({
    worldViewData: response.world.worldCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorldWievCounts);
