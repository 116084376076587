import * as types from '../Actions';

const initailState = {
    lang: "",
    changeHeader: false,
    tourGuideIsView: false,
    userPrecense: [],
    volunteerInfo:[],
};

export default function (state = initailState, action) {
    //console.log('general action', action);
    let response = action.response;
    switch (action.type) {
        // set languge
        case types.SET_LANG:
            return { ...state, lang: action.payload };
        // set header
        case types.CHANGE_HEADER:
            return { ...state, changeHeader: action.payload };
        // User precense
        case types.CHECK_PRECENSE_SUCCESS:
            return { ...state, userPrecense: response };
        case types.CHECK_PRECENSE_FAIL:
            return { ...state, userPrecense: response };
        case types.SET_TOUR_GUIDE_IS_VIEW:
            return { ...state, tourGuideIsView: true };
        case types.RESET_TOUR_GUIDE_IS_VIEW:
            return { ...state, tourGuideIsView: false };
        case types.SET_USER_VISIT_BEFORE:
            return { ...state, userPrecense: response };
        case types.GET_USER_INFO_SUCCESS:
            return { ...state, userInfo: response };
        case types.GET_USER_INFO_FAIL:
            return { ...state, userInfo: response };
        case types.GET_VOLUNTEER_INFO_SUCCESS:
                return { ...state, volunteerInfo: response };
        case types.GET_VOLUNTEER_INFO_FAIL:
                return { ...state, volunteerInfo: response };
        default:
            return state;
    }
}