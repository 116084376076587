import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Layout from "../../../Layout/Layout";
import world from "../../../../Resources/Images/world-map.svg";
import { changeHeaderAction } from "../../../../Actions/generalAction";
import { resetDittoMsgContent } from "../../../../Actions/communityAction";
import WorldViewCounts from './WorldViewCounts'

const World = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    props.changeHeaderAction({ type: "main" });
    props.resetDittoMsgContent();
  }, []);


  return (
    <Layout>
      <div className="world">
        <div className="msg d-none">
          <h4>{t("Global impact is Coming...")}</h4>
          <p>{t("Please check back soon")}</p>
        </div>
        <img className="" src={world} alt="" />
      </div>
      <WorldViewCounts />
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHeaderAction: (data) => dispatch(changeHeaderAction(data)),
    resetDittoMsgContent: () => dispatch(resetDittoMsgContent())
  };
};

export default connect(null, mapDispatchToProps)(World);
