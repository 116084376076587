import { put, call } from "redux-saga/effects";

import {
    createCustomerService,
    paymentCheckoutService,
    fetchOrdersService,
} from "../Services/paymentService";

import * as types from "../Actions";

export function* createCustomerForPaymentSaga(payload) {
    try {
        var response = yield call(createCustomerService, payload);

        if (response) {
            yield put({ type: types.PAYMENT_CREATE_CUSTOMER_SUCCESS, response });
        } else {
            yield put({ type: types.PAYMENT_CREATE_CUSTOMER_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.PAYMENT_CREATE_CUSTOMER_FAIL, error });
    }
}

export function* paymentCheckoutSaga(payload) {
    try {
        var response = yield call(paymentCheckoutService, payload);

        if (response) {
            yield put({ type: types.PAYMENT_CHECKOUT_SUCCESS, response });
        } else {
            yield put({ type: types.PAYMENT_CHECKOUT_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.PAYMENT_CHECKOUT_FAIL, error });
    }
}

export function* fetchOrdersSaga(payload) {
    try {
        var response = yield call(fetchOrdersService, payload);

        if (response) {
            yield put({ type: types.FETCH_ORDERS_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_ORDERS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_ORDERS_FAIL, error });
    }
}

export function* setGratitudeMsgSaga(payload) {
    const response = payload;
    if (response) {
        yield put({ type: types.SET_GRATITUDE_MSG_SUCCESS, response});
    }
}