import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core"
import "./header.scss";

// import { Row, Col, Nav, Button } from 'react-bootstrap';
import globe from "../../../Resources/Images/globe.svg";
import CommunityImg from "../../../Resources/Images/Community.svg";
import WorldImg from "../../../Resources/Images/World.svg";
import filter from "../../../Resources/Images/Group.svg";
import plus from "../../../Resources/Images/plus.svg";
import minus from "../../../Resources/Images/minus.svg";
import Reg from "../../../Resources/Images/reg.svg";
import st from "../../../Resources/Images/story.svg";
import give from "../../../Resources/Images/give.svg";
import dn from "../../../Resources/Images/donation.svg";
import SimplePopover from "../../Common/Popover";
import vol from "../../../Resources/Images/volunteer.svg";
import { setUserVisitBeforeAction } from "../../../Actions/generalAction";

import MessageFilter from "./MessageFilter";
import SearchBar from '../../Modules/Search/Search'

const Footer = (props) => {
  /* popover */
  const writeGratitudeEl = useRef(null);
  const communityEl = useRef(null);
  const worldMapEl = useRef(null);
  const viewStories = useRef(null);
  const viewCharities = useRef(null);
  const register = useRef(null);
  const volunteer = useRef(null);
  
  const [openPopover, setOpenPopover] = useState(false);
  const [targetEl, setTargetEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  /* End popover */

  const { t, i18n } = useTranslation();
  let location = useLocation();
  let urlpath = location.pathname.replace("/", "");

  const [selectedTab, setSelectedTab] = useState(
    urlpath ? urlpath : "community"
  );
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const tootipIsAppear = sessionStorage.getItem("tooltip");
    if (props.userPrecense.data) {
      if (window.innerWidth >= 768 && !tootipIsAppear) {
        /* Gratitude tab popover */
        // setTimeout(() => {
        //   setPopoverContent(t('Share your message'));
        //   setTargetEl(writeGratitudeEl.current);
        //   setOpenPopover(true);
        // }, 4000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 8000);

        /* Community tab popover */
        // setTimeout(() => {
        //   setPopoverContent('All community messages');
        //   setTargetEl(communityEl.current);
        //   setOpenPopover(true);
        // }, 6000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 10000);

        /* Wordmap tab popover */
        // setTimeout(() => {
        //   setPopoverContent('World map');
        //   setTargetEl(worldMapEl.current);
        //   setOpenPopover(true);
        // }, 11000);
        // setTimeout(() => {
        //   setOpenPopover(false);
        // }, 15000);

        /* Set tootip view as true */
        sessionStorage.setItem("tooltip", true);
      }
    }
  }, [props.userPrecense, props.tourGuideIsView]);

  return (
    <div className="sidenav">
      <div className="menu">
        {" "}
        <Tooltip title= {t("share_gratitude")} enterDelay={500} placement="top" arrow>
          <NavLink
            aria-describedby="writeGratitude"
            ref={writeGratitudeEl}
            exact
            to="/gratitude"
            className="sh-btn d-none"
            href="gratitude.html"
            activeClassName="active"
          >
            <div className="icon">
              <img src={globe} alt="" />
            </div>
            {t("Write a message")}
           
          </NavLink>
        </Tooltip>
        <Tooltip title={t("community")} enterDelay={500} placement="bottom" arrow>
          <NavLink
            ref={communityEl}
            exact
            to="/"
            className=""
            href="index.html"
            activeClassName="active"
          >
            <div className="icon">
              <img src={WorldImg} alt="" />
            </div>
            {t("Community messages")}
            
          </NavLink>
        </Tooltip>
        {/* <Tooltip title={t("show_you")} enterDelay={500} placement="top" arrow>
          <NavLink
            aria-describedby="stories"
            ref={viewStories}
            exact
            to="/give"
            className=""
            href="give.html"
            activeClassName="active"
          >
            <div className="icon">
              <img src={dn} alt="" />
            </div>
            {t("send_gift")}
          </NavLink>
        </Tooltip> 
        

        <Tooltip title={t("view_charities")} enterDelay={500} placement="top" arrow>
          <NavLink
            aria-describedby="charities"
            ref={viewCharities}
            to="/charities"
            className=""
            href="charities.html"
            activeClassName="active"
          >
            <div className="icon">
              <img src={give} alt="" />
            </div>
            {t("charities")}
          </NavLink>
        </Tooltip>
        */}
                
        <Tooltip title={t("view_stories")} enterDelay={500} placement="top" arrow>
          <NavLink
            aria-describedby="stories"
            ref={viewStories}
            exact
            to="/stories"
            className=""
            href="stories.html"
            activeClassName="active"
          >
            <div className="icon">
              <img src={st} alt="" />
            </div>
            {t("stories")}
          </NavLink>
        </Tooltip>
        <Tooltip title={t("View impact")} enterDelay={500} placement="bottom" arrow>
          <NavLink
            ref={worldMapEl}
            to="/world"
            className="position-relative"
            href=""
          >
            <div className="icon">
              <img src={CommunityImg} alt="" />
            </div>
            <div className='blocker d-none'> Coming Soon</div>
            {t("world_view")}
          </NavLink>
        </Tooltip>
        <Tooltip title={t("register_user")} enterDelay={500} placement="bottom" arrow>
          <NavLink
            ref={register}
            to="/subscribe"
            className="position-relative"
            href=""
          >
            <div className="icon">
              <img src={Reg} alt="" />
            </div>
            <div className='blocker d-none'> reg</div>
            {t("Register")}<span className=''>&nbsp;{t("now")}</span>
          </NavLink>
        </Tooltip>
        <Tooltip title={t("volunteeer")} enterDelay={500} placement="bottom" arrow>
          <NavLink
            ref={volunteer}
            to="/volunteer"
            className="position-relative"
            href=""
          >
            <div className="icon">
              <img src={vol} alt="" />
            </div>
            <div className='blocker d-none'> reg</div>
            {t("Volunteer")}
          </NavLink>
        </Tooltip>
      </div>
      {/* {props.changeHeader.page === 'community' && <SearchBar />} */}
      {/* <LocationFilter /> */}
      {props.changeHeader.page === 'community' && (
        <div className={`side-filter ${toggle ? "toggle" : ""}`}>
          <div className="filter-header" onClick={() => setToggle(!toggle)}>
            <img src={filter} alt="" />
            {t('Filters')}
            <img src={toggle ? minus : plus} alt="" />
          </div>
          <div className="filter-body">
            <MessageFilter />
          </div>
        </div>
      )}

      {/* <div className="menu-group">
        <p>{t('Stories of Gratitude')}</p>
        <ul>
          <li>
            <a>
              {t('Yamahas Global Community')} <img src={chev} alt="" />
            </a>
          </li>
          <li>
            <a>
              {t('Stories of Endurance')} <img src={chev} alt="" />
            </a>
          </li>
          <li>
            <a>
              {t('Frontliners of Central Park')} <img src={chev} alt="" />
            </a>
          </li>
          <li>
            <a>
              {t('Meet The Italian Hero')} <img src={chev} alt="" />
            </a>
          </li>
        </ul>
      </div> */}

      <SimplePopover
        open={openPopover}
        anchorEl={targetEl}
        content={popoverContent}
        anchorOriginVertical='top'
        anchorOriginHorizontal='left'
        transformOriginVertical={40}
        transformOriginHorizontal='left'
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserVisitBeforeAction: (data) => dispatch(setUserVisitBeforeAction(data))
  };
};

const mapStateToProps = (response) => ({
  changeHeader: response.general.changeHeader,
  userPrecense: response.general.userPrecense,
  tourGuideIsView: response.general.tourGuideIsView
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
