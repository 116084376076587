import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
export const checkUserPrecenseService = async (request) => {
    const data = JSON.stringify({
        browserHash: localStorage.getItem("browserHash") ? localStorage.getItem("browserHash").toString() : "null"
    });
     const CHECK_PRECENSE_URL = `${BASE_URL}/browser/hash`;
   // const CHECK_PRECENSE_URL = `http://localhost:5000/browser/hash`;
    const res = await axios.post(CHECK_PRECENSE_URL, data,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    return res;
};

export const getUserInfoService = async (request) => {
    const data = JSON.stringify({
        browserHash: localStorage.getItem("browserHash") ? localStorage.getItem("browserHash").toString() : "null"
    });
    const CHECK_PRECENSE_URL = `${BASE_URL}/browser/getUser`;
    const res = await axios.post(CHECK_PRECENSE_URL, data,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    return res;
};

export const getVolunteerInfoService = async (request) => {
    const data = JSON.stringify({
        browserHash: localStorage.getItem("browserHash") ? localStorage.getItem("browserHash").toString() : "null"
    });
    const CHECK_PRECENSE_URL = `${BASE_URL}/browser/getVolunteer`;
  //  const CHECK_PRECENSE_URL = `http://localhost:5000/browser/getVolunteer`;
    const res = await axios.post(CHECK_PRECENSE_URL, data,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            // console.log('[getVolunteerInfoService] success' , response)
            return response;
        })
        .catch(error => {
            // console.log('[getVolunteerInfoService] error' , error)
            return error;
        })
    return res;
};

