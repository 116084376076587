import React, { Fragment, useState } from "react";

const PriceSelector = ({
    changeAmount = () => {},
    giftAmount = 0,
    currencyCode = 'USD',
    giftAmounts = [20, 30, 50, 100],
    minAmountWarning = false
}) => {
    const [customGiftAmount, setCustomGiftAmount] = useState(0);

    const changeGiftAmount = (amount) => {
        const isvalidNumber = amount.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/);

        if (!isvalidNumber) amount = 0;

        amount = parseFloat(amount);

        setCustomGiftAmount(amount);
        changeAmount(amount);
    }

    const giftAmountSelector = () => {
        return (
            <Fragment>
                {giftAmounts.map((amount, index) => (
                    <div
                        key={index}
                        className={parseFloat(giftAmount) === parseFloat(amount) ? 'active' : ''}
                    >
                        <input
                            id={index}
                            type="radio"
                            name="donationAmount"
                            value={amount}
                            checked={giftAmount === amount}
                            onChange={(e) => {
                                    setCustomGiftAmount(0);
                                    changeAmount(e.target.value)
                                }
                            }
                        />
                        <label htmlFor={index}>{amount} {currencyCode}</label><br></br>
                    </div>
                ))}
            </Fragment>
        );
    }

    const giftAmountInputField = () => {
        return (
            <input
                name="donation"
                type="text"
                className="form-control form-control-sm pl-3 text-left"
                value={customGiftAmount ? customGiftAmount: ''}
                placeholder={'Enter a custom amount'}
                onChange={(e) => changeGiftAmount(e.target.value) }
            />
        );
    }

    return (
        <div>
            <div className="d-wrapper">
                <div className="d-radio">
                    {giftAmountSelector()}
                </div>
                {giftAmountInputField()}
                {minAmountWarning &&
                    <span className="inp-waring">
                        Minimum amount is {giftAmounts[0]} {currencyCode}. Please enter an amount again
                    </span>
                }
            </div>
        </div>
    );
}

export default PriceSelector;