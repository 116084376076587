import 
    React,
    { Fragment, useEffect, useState }
from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    gerOrdersAction,
} from '../../../../Actions/paymentAction';
import { formatDate } from '../../../../Utils/Util';

const Orders = (props) => {
    const statusOptions = ['ALL', 'PAID', 'UNPAID'];

    const [orders, setOrders]   = useState([]);
    const [filters, setFilters] = useState({
        selectedDate: new Date(),
        status      : 'ALL'
    });

    const changeFilter = (value, type) => {
        switch (type) {
            case 'date':
                setFilters({
                    ...filters,
                    selectedDate: value,
                });
                break;
            case 'status':
                setFilters({
                    ...filters,
                    status: value,
                });
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        props.gerOrdersAction(filters);
    }, []);

    useEffect(() => {
        if (props.orders) {
            if (props.orders.success) {
                setOrders(props.orders.orders);
            }
        }
    }, [props.orders]);

    const tableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    <th>PI</th>
                    <th>Date</th>
                    <td>Message</td>
                    <th>Sender</th>
                    <th>Recipient</th>
                    <th>Payment Status</th>
                    <th>Amount</th>
                </tr>
            </thead>
        );
    }

    const tableBody = () => {
        return (
            <tbody>
            {
                orders.map((order, index) =>
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{order.paymentId}</td>
                        <td>{formatDate(order.date)}</td>
                        <td>{order.message}</td>
                        <td>
                            {`${order.senderFirstName} ${order.senderLastName}`}<br/>
                            {order.senderEmail}<br/>
                            {order.senderPhoneNumber ? order.senderPhoneNumber : null}
                        </td>
                        <td>
                            {order.recipientName} <br/>
                            {order.recipientEmail ? order.recipientEmail : null}<br/>
                            {order.recipientPhoneNumber ? order.recipientPhoneNumber : null}<br/>
                            {order.recipientAddress},<br/>
                            {order.recipientCity},<br/>
                            {order.recipientCountry} {order.zipCode}
                        </td>
                        <td>{order.paymentStatus}</td>
                        <td>$ {order.totalAmount}</td>
                    </tr>
                )
            }
            </tbody>
        );
    }

    const recordsNotFound = () => {
        return (
            <tbody>
                <tr>
                    <td colSpan={7}>
                        Records Not Found
                    </td>
                </tr>
            </tbody>
        );
    }

    const dataFilters = () => {
        return (
            <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <select
                    className="form-control"
                        defaultValue={'ALL'}
                        onChange={
                            (event) =>changeFilter(
                                event.target.value,
                                'status'
                            )
                        }
                    >
                        {
                            statusOptions.map((option, index) => 
                                <option
                                    key={index}
                                    value={option}
                                >
                                    {option}
                                </option>
                            )
                        }
                    </select>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <DatePicker
                    className="form-control"
                        selected={filters.selectedDate}
                        onChange={(date) => changeFilter(date, 'date')}
                    />
                </div>
                <div
                    className="col-sm-2 col-md-2 col-lg-2"
                    onClick={() =>  props.gerOrdersAction(filters)}
                >
                    <Button>Filter</Button>
                </div>
            </div>
        );
    }

    const orderTable = () => {
        return (
            <Fragment>
                { dataFilters() }
                <table
                    className={'table table-borderd mt-3'}
                >
                    { tableHeader() }
                    { orders.length ? tableBody() : recordsNotFound()}
                </table>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="p-4">
            <h2 className="mb-1">Orders</h2>
            <p className="mb-4">List of all gifting orders</p>
            {
                orders ? orderTable() : ''
            }
             </div>
             </div>
        </Fragment>
       
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
            gerOrdersAction: (payload) => dispatch(gerOrdersAction(payload)),
        };
    };

const mapStateToProps = (response) => ({
    orders: response.payment.orders,
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);