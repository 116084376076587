import React, { Fragment, useState } from 'react';

import { validateEmail } from '../../../../../../Utils/Util';
import PriceSelector  from './PriceSelector';

const SenderDetailsForm = ({
    gratitudeMsg = {},
    giftImg = '',
    giftName = '',
    giftDsc = '',
    formData = {},
    giftPriceList = [],
    minAmountWarning = false,
    changeValue = () => {},
    changeGiftAmount = () => {},
    continueWizard = () => {},
}) => {
    const { senderDetails } = formData;
    const [isProccessValidation, setIsProccessValidation] = useState(false);

    const validateForm = () => {
        setIsProccessValidation(true);

        const isValid = !senderDetails.firstName ||
            !senderDetails.lastName              ||
            !senderDetails.email                 ||
            !validateEmail(senderDetails.email)  ||
            !senderDetails.phoneNumber           ||
            !senderDetails.message ?
                false :
                true;

        if (isValid)
            continueWizard('recipientDetails');
    }

    return (
        <Fragment>
            <div className='giftwrapper'>
                <div className='giftvideo'>
                <img src={giftImg} alt="" />
                </div>
                <div className='giftdsc'>
                    <h3>
                        {giftName}
                        {gratitudeMsg.recipientName ? ` for ${gratitudeMsg.recipientName}`: ''}
                    </h3>
                    <small>By ShareGratitude</small>
                    <p>{giftDsc}</p>
                    {giftPriceList.length ? (
                        <PriceSelector
                            changeAmount={changeGiftAmount}
                            giftAmount={formData.giftAmount}
                            currencyCode={formData.currencyCode}
                            giftAmounts={giftPriceList}
                            minAmountWarning={minAmountWarning}
                        />
                    ): null}
                    {giftName === 'Printed Card' &&
                        <h2>{formData.giftAmount}&nbsp;{formData.currencyCode}</h2>
                    }
                    <small>One all-inclusive price includes gift purchase, postage & handling, transaction fees, and applicable taxes.</small>
                </div>
            </div>
            <h4 className="tit mt-4">About you</h4>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pl-0 input-img">
                            <input
                                value={senderDetails.firstName}
                                name="firstName"
                                type="text"
                                className="form-control form-control-sm pl-3 text-left"
                                maxLength={30}
                                placeholder={"Your First Name"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !senderDetails.firstName ?
                                    <span className="inp-error">
                                        First name is reqiured
                                    </span> :
                                    null
                            }
                            </div>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pl-0 input-img">
                            <input
                                value={senderDetails.lastName}
                                name="lastName"
                                type="text"
                                className="form-control form-control-sm pl-3 text-left"
                                maxLength={30}
                                placeholder={"Your Last Name"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !senderDetails.lastName ?
                                    <span className="inp-error">
                                        Last name is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                            <input
                                value={senderDetails.email}
                                name="email"
                                type="text"
                                className="form-control form-control-sm pl-3 text-left"
                                maxLength={300}
                                placeholder={"Your Email"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !senderDetails.email ?
                                    <span className="inp-error">
                                        Email is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-img">
                            <input
                                value={senderDetails.phoneNumber}
                                name="phoneNumber"
                                type="text"
                                className="form-control form-control-sm pl-3 text-left"
                                maxLength={15}
                                placeholder={"Phone Number"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !senderDetails.phoneNumber ?
                                    <span className="inp-error">
                                        Phone number is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                            <textarea
                                cols="30"
                                rows="5"
                                value={senderDetails.message}
                                name="message"
                                type="text"
                                className="form-control textarea form-control-sm pl-3 text-left"
                                placeholder={"Write Your Message"}
                                onChange={(e) => changeValue(e) }
                            />
                            {
                                isProccessValidation && !senderDetails.message ?
                                    <span className="inp-error">
                                        Message is reqiured
                                    </span> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <a
                    onClick={() => validateForm()}
                    href="#"
                    className={`
                        float-right
                        track
                        btn btn-secondary
                        ${
                            !senderDetails.firstName            ||
                            !senderDetails.lastName             ||
                            !senderDetails.email                ||
                            !senderDetails.phoneNumber          ||
                            !validateEmail(senderDetails.email) ||
                            !senderDetails.message ?
                                'inactive' :
                            'active'
                        }
                    `}
                >
                    CONTINUE
                </a>
            </div>
        </Fragment>
    );
}

export default SenderDetailsForm;
