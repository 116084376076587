import React, { useState, useEffect, useQuery } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import GratitudeList from "./GratitudeList";
import {
    fetchSingleMsgAction,
    makePromoteMsgAction,
    reportMsgAction,
    resetMakePromoteAction,
    resetReportMsgAction,
    translateSingleMsgAction
} from "../../../../Actions/communityAction";
import {
    changeHeaderAction
} from "../../../../Actions/generalAction";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import Model from "../../../Common/Model";
import SocialMediaSharingModel from "../../../Common/SocialMediaShareModel";
import { checkUserPrecenseAction } from "../../../../Actions/generalAction";
import ModelGiveLittle from "../../../Common/ModelGiveLittle";
import Reply from "./Reply";

const CommunityMsg = (props) => {
    const { t, i18n } = useTranslation();
    const [listItem, setListItem] = useState([]);
    const [singleMsgData, setSingleMsgData] = useState({});

    //report message id
    const [reportMsgID, setReportMsgID] = useState([]);

    // Reply msg 
    const [showReply, setShowReply] = useState(false);

    // Notification message
    // Notification
    const [errorNotifification, setErrorNotifification] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [severity, setSeverity] = useState("");

    // Model
    const [openModel, setOpenModel] = useState(false);
    const [modelHeader, setModelHeader] = useState("");
    const [modelContent, setModelContent] = useState("");
    const [modelButton1, setModelButton1] = useState("");
    const [modelButton2, setModelButton2] = useState("");

    // Model social media
    const [openSocialMediaModel, setOpenSocialMediaModel] = useState(false);


    // Model Donation
    const [donationMsg, setDonationMsg] = useState([]);
    const [openModelDonation, setOpenModelDonation] = useState(false);
    const [openModelGiveLittle, setOpenModelGiveLittle] = useState(false);
    const [modelDonationHeader, setModelDonationHeader] = useState("");
    const [modelDonationContent, setModelDonationContent] = useState("");
    const [modelDonationButton1, setModelDonationButton1] = useState("");
    const [modelDonationButton2, setModelDonationButton2] = useState("");
    const [modelDonationSubHeader, setModelDonationSubHeader] = useState("");

    var history = useHistory();
    useEffect(() => {
        var formData = {
            id: props.match.params.id
        }

        props.fetchSingleMsgAction(formData);

        props.checkUserPrecenseAction();
    }, []);

    useEffect(() => {
        if (props.singleMsg.data) {
            if (props.singleMsg.data.success) {
                setListItem(props.singleMsg.data.message);
            } else {

            }
        }
    }, [props.singleMsg]);


    // Support for message
    const makePromo = (msgData) => {
        var formData = {
            _id: msgData._id,
            locations: msgData.locations
        };

        props.makePromoteMsgAction(formData);
    };

    useEffect(() => {
        if (props.makePromote.support) {
            if (props.makePromote.support) {

                // Update count
                listItem.support.count = props.makePromote.support.count;
                listItem.support.supportedHashes = props.makePromote.support.supportedHashes;

                setListItem(listItem);
                setSeverity("success");
                setErrorMsg("Thank you for your support..!");
                setErrorNotifification(!errorNotifification);
                // props.resetMakePromoteAction();
            } else {
                setSeverity("error");
                setErrorMsg("Ooops..Some thing went wrong..!");
                setErrorNotifification(!errorNotifification);
                // props.resetMakePromoteAction();
            }
        }
    }, [props.makePromote]);

    // Report message
    const reportMsgModel = (msgID, isReported) => {
        var modelContent = '';
        var button = '';
        var header = '';
        if (isReported) {
            header = 'Unreport';
            modelContent = 'Thank you for helping us to keep our messages true to the cause. To undo your action please select "Unreport".';
            button = 'Unreport';
        } else {
            header = 'Report';
            modelContent = 'Thank you for helping us to keep our messages true to the cause. Please select "Report" below you feel this messge should be removed.';
            button = 'Report';
        }

        setReportMsgID(msgID);
        setModelHeader(header);
        setModelContent(modelContent);
        setModelButton1(button);
        setModelButton2("Cancel");
        setOpenModel(!openModel);

    };

    const reportMsg = () => {
        var formData = {
            _id: reportMsgID._id,
            locations: reportMsgID.locations
        };
        props.reportMsgAction(formData);
    };

    useEffect(() => {
        if (props.reportMsg.indecentReport) {
            if (props.reportMsg.indecentReport) {
                var updatedID = props.reportMsg._id;

                // Update count
                listItem.indecentReport.reportedHashes = props.reportMsg.indecentReport.reportedHashes;
                setListItem(listItem);

                setSeverity("success");
                setErrorMsg("Message has been reported..!");
                setErrorNotifification(!errorNotifification);
                setOpenModel(!openModel);
                props.resetReportMsgAction();
            } else {
                setSeverity("error");
                setErrorMsg("Ooops..Some thing went wrong..!");
                setErrorNotifification(!errorNotifification);
                setOpenModel(!openModel);
                props.resetReportMsgAction();
            }

        }
    }, [props.reportMsg]);

    // Share message
    const shareMsg = (singleMsgData) => {
        singleMsgData.shareUrl = `${process.env.REACT_APP_LIVE_URL}/msg/${singleMsgData._id}`;

        setSingleMsgData(singleMsgData);
        setOpenSocialMediaModel(!openSocialMediaModel);
    }

    // Close error notification
    const closeErrorNotifification = () => {
        setErrorNotifification(!errorNotifification);
    };

    // translate message
    const translateMsg = (msgData) => {
        var formData = {
            id: msgData._id
        };
        props.translateSingleMsgAction(formData);
    }

    // Replay msg 
    const replyMsg = (dataList) => {
        setSingleMsgData(dataList);
        setShowReply(!showReply);
        props.changeHeaderAction({ type: 'reply', headerTitle: `${dataList.author}'s Replies` });
    }

    const closeReply = () => {
        setShowReply(false);
        props.changeHeaderAction({ type: 'main' });
    }
    const handleClose = () => {
        history.push("/");
      };
    // Donation 
    const donate = (dataMsg) => {
        // console.log("msg data", dataMsg);
        // var modelContent = '';
        // var button = '';
        // var header = '';
        // var subHeader = '';

        // header = `${t('Support')} ${dataMsg.author}`;
        // dataMsg.charity.modelContent.map((donation) => {
        //     if (donation.language == props.language || donation.language == `${props.language}-TW`) {
        //         subHeader = donation.modelHeadline;
        //         modelContent = donation.paraSeg01 + ' <b>' + dataMsg.charity.charity.name + '</b > ' + donation.paraSeg02;
        //     }
        // });

        // button = 'Donation';

        // setDonationMsg(dataMsg);
        // setModelDonationHeader(header);
        // setModelDonationSubHeader(subHeader);
        // setModelDonationContent(modelContent);
        // setModelDonationButton1(button);
        // setModelDonationButton2("Cancel");
        setOpenModelGiveLittle(true);
        // setOpenModelDonation(true);
    }

    return (
        <Layout>
            {listItem.length !== 0 && !showReply && (
                <div class="row">
                  
                 <div className="mo-header" >
                    <span class='bk' onClick={handleClose} color="secondary" autoFocus>
                    {t('Home')}
                    </span>
                </div>
                <br></br>
              
                    <div id="list" class="massage-list col-12">
                        <GratitudeList
                            dataList={listItem}
                            makePromo={makePromo}
                            reportMsg={reportMsgModel}
                            shareMsg={shareMsg}
                            replyMsg={replyMsg}
                            translateMsg={translateMsg}
                            donation={donate}
                        />
                    </div>
                </div>)
            }


            <CustomizedSnackbars
                closePopup={closeErrorNotifification}
                popupOpen={errorNotifification}
                notifyMsg={errorMsg}
                severity={severity}
                autoHideDuration={500}
            />

            <ModelGiveLittle
                open={openModelGiveLittle}
                closeModel={() => setOpenModelGiveLittle(false)}
            />

            <Model
                open={openModel}
                modelHeader={modelHeader}
                modelContent={modelContent}
                button1={modelButton1}
                button2={modelButton2}
                trigerFunction={reportMsg}
                closeModel={() => setOpenModel(!openModel)}
            />

            <SocialMediaSharingModel
                shareMsgData={singleMsgData}
                open={openSocialMediaModel}
                closeModel={() => setOpenSocialMediaModel(!openSocialMediaModel)}
            />

            {showReply &&
                <Reply
                    msgData={singleMsgData}
                    closeReply={closeReply}
                />
            }

        </Layout>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleMsgAction: (data) => dispatch(fetchSingleMsgAction(data)),
        makePromoteMsgAction: (data) => dispatch(makePromoteMsgAction(data)),
        reportMsgAction: (data) => dispatch(reportMsgAction(data)),
        resetMakePromoteAction: () => dispatch(resetMakePromoteAction()),
        resetReportMsgAction: () => dispatch(resetReportMsgAction()),
        checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
        translateSingleMsgAction: (data) => dispatch(translateSingleMsgAction(data)),
        changeHeaderAction: (data) => dispatch(changeHeaderAction(data))
    };
};

const mapStateToProps = (response) => ({
    singleMsg: response.community.singleMsg,
    makePromote: response.community.makePromote,
    reportMsg: response.community.reportMsg,
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunityMsg);
