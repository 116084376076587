import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./Store/configureStore";
import './i18n';
import Helmet from 'react-helmet'

const store = configureStore();

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'DC-10340840');
}

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=DC-10340840"></script>
      <script>{injectGA()}</script>
    </Helmet>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
