import React, { useState, useEffect, useQuery } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import ReplyList from "./ReplyList";
import {
    fetchSingleReplyAction,
    makeSupportReplyAction,
    makeReportReplyAction,
    translateSingleReplyMsgAction,
    makePromoteMsgAction,
    reportMsgAction,
    resetMakePromoteAction,
    resetReportMsgAction,
    translateSingleMsgAction
} from "../../../../Actions/communityAction";
import {
    changeHeaderAction
} from "../../../../Actions/generalAction";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import Model from "../../../Common/Model";
import SocialMediaSharingModel from "../../../Common/SocialMediaShareModel";
import { checkUserPrecenseAction } from "../../../../Actions/generalAction";
import Reply from "./Reply";

const CommunityMsg = (props) => {

    const [replyMsg, setReplyMsg] = useState([]);
    const [singleMsgData, setSingleMsgData] = useState({});

    //report message id
    const [reportMsgID, setReportMsgID] = useState([]);

    // Reply msg 
    const [showReply, setShowReply] = useState(false);

    // Notification message
    // Notification
    const [errorNotifification, setErrorNotifification] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [severity, setSeverity] = useState("");

    // Model
    const [openModel, setOpenModel] = useState(false);
    const [modelHeader, setModelHeader] = useState("");
    const [modelContent, setModelContent] = useState("");
    const [modelButton1, setModelButton1] = useState("");
    const [modelButton2, setModelButton2] = useState("");

    // Model social media
    const [openSocialMediaModel, setOpenSocialMediaModel] = useState(false);

    useEffect(() => {
        var formData = {
            id: props.match.params.id
        }
        props.fetchSingleReplyAction(formData);

        props.checkUserPrecenseAction();
    }, []);

    useEffect(() => {
        if (props.singleReplyMsg) {
            if (props.singleReplyMsg.success) {
                setReplyMsg(props.singleReplyMsg.message);
            }
        }
    }, [props.singleReplyMsg]);


    // Support for reply
    const makePromo = (replyData) => {
        var formData = {
            _id: replyData._id,
            messageId: replyData.messageId,
        };
        props.makeSupportReplyAction(formData);
    };

    useEffect(() => {
        if (props.supportReply.support) {
            if (props.supportReply.support) {
                var updatedID = props.supportReply._id;

                // Update count
                replyMsg.support.count = props.supportReply.support.count;
                replyMsg.support.supportedHashes = props.supportReply.support.supportedHashes;

                setReplyMsg(replyMsg);
                setSeverity("success");
                setErrorMsg("Thank you for your feedback");
                setErrorNotifification(!errorNotifification);
                // props.resetMakePromoteAction();
            } else {
                setSeverity("error");
                setErrorMsg("Ooops..Some thing went wrong..!");
                setErrorNotifification(!errorNotifification);
                // props.resetMakePromoteAction();
            }
        }
    }, [props.supportReply]);

    // Report Reply
    const reportMsgModel = (dataMsg, isReported) => {
        var modelContent = "";
        var button = "";
        var header = "";
        if (isReported) {
            header = "Unreport";
            modelContent =
                'Thank you for helping us to keep our messages true to the cause. To undo your action please select "Unreport".';
            button = "Unreport";
        } else {
            header = "Report";
            modelContent =
                'Thank you for helping us to keep our messages true to the cause. Please select "Report" below you feel this messge should be removed.';
            button = "Report";
        }

        setReportMsgID(dataMsg);
        setModelHeader(header);
        setModelContent(modelContent);
        setModelButton1(button);
        setModelButton2("Cancel");
        setOpenModel(!openModel);
    };

    const reportMsg = () => {
        var formData = {
            _id: replyMsg._id,
            messageId: replyMsg.messageId,
        };
        props.makeReportReplyAction(formData);
    };

    useEffect(() => {
        if (props.reportReply.indecentReport) {
            if (props.reportReply.indecentReport) {
                var reportCount = props.reportReply.indecentReport.count;
                // Update count
                replyMsg.indecentReport.reportedHashes = props.reportReply.indecentReport.reportedHashes;
                replyMsg.indecentReport.shouldMessageVisible = reportCount >= 3 ? false : true;

                setReplyMsg(replyMsg);

                setSeverity("success");
                setErrorMsg("Thank you for your feedback");
                setErrorNotifification(!errorNotifification);
                setOpenModel(!openModel);
                // props.resetReportMsgAction();
            } else {
                setSeverity("error");
                setErrorMsg("Ooops..Some thing went wrong..!");
                setErrorNotifification(!errorNotifification);
                setOpenModel(!openModel);
                // props.resetReportMsgAction();
            }
        }
    }, [props.reportReply]);

    // Share message
    const shareMsg = (singleMsgData) => {
        singleMsgData.shareUrl = `${process.env.REACT_APP_UAT_URL}/reply/${singleMsgData._id}`;
      
        setSingleMsgData(singleMsgData);
        setOpenSocialMediaModel(!openSocialMediaModel);
    }

    // Close error notification
    const closeErrorNotifification = () => {
        setErrorNotifification(!errorNotifification);
    };

    // translate message
    const translateMsg = (msgData) => {
        var formData = {
            id: msgData._id
        };
        props.translateSingleReplyMsgAction(formData);
    }

    // Replay msg 
    // const replyMsg = (dataList) => {
    //     setSingleMsgData(dataList);
    //     setShowReply(!showReply);
    //     props.changeHeaderAction({ type: 'reply', headerTitle: `${dataList.author}'s Replies` });
    // }

    const closeReply = () => {
        setShowReply(false);
        props.changeHeaderAction({ type: 'main' });
    }

    return (
        <Layout>
            {replyMsg.length !== 0 && (
                <div class="row">
                    <div id="list" class="massage-list col-12">
                        <a href={`${process.env.REACT_APP_DEV_URL}/msg/${replyMsg.messageId}`}>Main Post</a>
                        <ReplyList
                            replyData={replyMsg}
                            makePromo={makePromo}
                            reportMsg={reportMsgModel}
                            shareMsg={shareMsg}
                            viewTranslateIcon={false}
                            translateMsg={translateMsg}
                        />
                    </div>
                </div>)
            }
            <CustomizedSnackbars
                closePopup={closeErrorNotifification}
                popupOpen={errorNotifification}
                notifyMsg={errorMsg}
                severity={severity}
                autoHideDuration={1000}
            />

            <Model
                open={openModel}
                modelHeader={modelHeader}
                modelContent={modelContent}
                button1={modelButton1}
                button2={modelButton2}
                trigerFunction={reportMsg}
                closeModel={() => setOpenModel(!openModel)}
            />

            <SocialMediaSharingModel
                shareMsgData={singleMsgData}
                open={openSocialMediaModel}
                closeModel={() => setOpenSocialMediaModel(!openSocialMediaModel)}
            />

            {showReply &&
                <Reply
                    msgData={singleMsgData}
                    closeReply={closeReply}
                />
            }

        </Layout>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleReplyAction: (data) => dispatch(fetchSingleReplyAction(data)),
        makeSupportReplyAction: (data) => dispatch(makeSupportReplyAction(data)),
        makeReportReplyAction: (data) => dispatch(makeReportReplyAction(data)),
        // makePromoteMsgAction: (data) => dispatch(makePromoteMsgAction(data)),
        // reportMsgAction: (data) => dispatch(reportMsgAction(data)),
        // resetMakePromoteAction: () => dispatch(resetMakePromoteAction()),
        // resetReportMsgAction: () => dispatch(resetReportMsgAction()),
        checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
        translateSingleReplyMsgAction: (data) => dispatch(translateSingleReplyMsgAction(data)),
        changeHeaderAction: (data) => dispatch(changeHeaderAction(data))
    };
};

const mapStateToProps = (response) => ({
    singleReplyMsg: response.community.singleReplyMsg,
    supportReply: response.community.supportReply,
    reportReply: response.community.reportReply
    // makePromote: response.community.makePromote,
    // reportMsg: response.community.reportMsg,
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunityMsg);
