import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setGratitudeMsgAction } from "../../../../Actions/paymentAction";

const GiveLittle =
  ({
    ...props
  }) => {
    const { t, i18n } = useTranslation();

    var history = useHistory();

    const backToMain = () => {
      history.push("/");
    }

    const sendPrintedCard = () => {
      const msg = {
        msg          : props.msg,
        recipientName: props.recipientName,
      }
  
      props.setGratitudeMsgAction(msg);
      history.push("/printCard");
    }
  
    const sendFlowers = () => {
      const msg = {
        msg          : props.msg,
        recipientName: props.recipientName,
      }
  
      props.setGratitudeMsgAction(msg);
      history.push("/SendFlowers");
    }
  
    const sendWineBottle = () => {
      const msg = {
        msg          : props.msg,
        recipientName: props.recipientName,
      }
  
      props.setGratitudeMsgAction(msg);
      history.push("/WineBottle");
    }

    return (
      <div className="col-md-12 pl-0 pr-0 give-little ">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
            <div className="input-list no-border pt-0 text-center mw-5">
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p>
                    <span>{t("SIMPLE WAY TO")}&nbsp;<strong>{t("GIVE A LITTLE")}</strong></span>
                  </p>
                  {/* <label>{t("give_a_title_second_sentence")}</label> */}
                  <p className='px-sm-4 italic'>
                    {t(
                      "Select from the below, and we will hand select a local small business near your recipient to support with sending your gift."
                    )}
                  </p>

                  <a onClick={sendPrintedCard} className="btn btn-primary btn-block">
                      {t('Send a printed')}&nbsp;<strong>{t('card')}</strong>
                    </a>
                  {/* <small className='mt-1 italic'>{t("This requires a ")}<u className='cp'>{t('recipient postal address')}</u></small> */}

                  <a onClick={sendFlowers} className="btn btn-primary btn-block">
                      {t('Send some')}&nbsp;<strong>{t('flowers')}</strong>
                    </a>
                  {/* <small className='mt-1 italic'>{t("This requires a ")}<u className='cp'>{t('recipient postal address')}</u></small> */}

                  <a onClick={sendWineBottle} className="btn btn-primary btn-block">
                      Send a bottle of<strong>&nbsp;Wine</strong>
                    </a>
                  
                  <a className="btn btn-primary btn-block "  href="/donation">
                    <strong>{t('Donate')}&nbsp;</strong> {t('to charity')}
                    {/* <span className='blocker'>{t('Coming Soon')}</span> */}
                  </a>


                  <a onClick={backToMain} className="btn btn-primary btn-block">
                    {t('Sent the')}&nbsp;<strong>{t('message')}&nbsp;</strong>{t('only thanks')}
                  </a>

                  <p className='mt-3'>
                    {t('It is more than a message and gift of gratitude, you are helping build stronger communities.')}
                  </p>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div >
    );
  };

const mapDispatchToProps = (dispatch) => {
  return {
      setGratitudeMsgAction: (payload) => dispatch(setGratitudeMsgAction(payload)),
    };
  };

export default connect(null, mapDispatchToProps)(GiveLittle);
