import { combineReducers } from 'redux';
import gratitude from './gratitudeReducer'
import community from './communityReducer'
import general from './generalReducer';
import world from './worldReducer';
import payment from './paymentReducer';


const rootReducer = combineReducers({
    gratitude,
    community,
    general,
    world,
    payment
});
export default rootReducer;