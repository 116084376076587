import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import TourGuide from './Pages/TourGuide';

const TourGuideRoutes = () => {
    return (
        <Fragment>
            <Route path="/tour-guide" exact component={TourGuide} />
        </Fragment>
    )
};

export default TourGuideRoutes;

