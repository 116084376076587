import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const CustomizedSnackbars = ({
    popupOpen = false,
    closePopup = () => { },
    notifyMsg = "Sample",
    severity = "error",
    autoHideDuration = 1000
}) => {

    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closePopup(false);
    };

    return (
        <Snackbar open={popupOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {notifyMsg}
            </Alert>
        </Snackbar>
    );
}

export default CustomizedSnackbars;
