import * as types from '../Actions';

const initailState = {
    messages: [],
    makePromote: [],
    reportMsg: [],
    singleMsg: [],
    updatedSupportCount: [],
    updatedReportCount: [],
    updatedReplyCount: [],
    submitReplyMsg: [],
    replyList: [],
    newReply: [],
    latestMsg: [],
    supportReply: [],
    reportReply: [],
    replySupportCount: [],
    replyReportCount: [],
    locationFilter: [],
    recipientFilter: [],
    translatedMsg: [],
    trackReplyMsg: [],
    translatedReplyMsg: [],
    messageSectors: [],
    singleReplyMsg: [],
    communitySocket: null,
    shareMsg: "",
    ditto: [],
    dittoReply: [],
    updatedDittoCount: [],
    updatedReplyDittoCount: [],
    search: {
        messageListForSearch: [],
        messageSearchString: "",
        filteredMessageList: [],
        skip: 0
    },
    donationRegistration:[],
};

export default function (state = initailState, action) {
    let response = action.response;
    switch (action.type) {
        // Fetch messages
        case types.FETCH_MESSAGES_SUCCESS:
            return { ...state, messages: response, communitySocket: action.connect };
        case types.FETCH_MESSAGES_FAIL:
            return { ...state, messages: response };
        case types.FETCH_MESSAGES_RESET:
            return { ...state, messages: [] };

        // Promote msg 
        case types.MAKE_PROMOTE_MSG_SUCCESS:
            return { ...state, makePromote: response };
        case types.MAKE_PROMOTE_MSG_FAIL:
            return { ...state, makePromote: response };
        case types.RESET_MAKE_PROMOTE_MSG:
            return { ...state, makePromote: [] };

        // Report msg
        case types.REPORT_MESSAGE_SUCCESS:
            return { ...state, reportMsg: response };
        case types.REPORT_MESSAGE_FAIL:
            return { ...state, reportMsg: response };
        case types.RESET_REPORT_MESSAGE:
            return { ...state, reportMsg: [] };

        case types.FETCH_SINGLE_MESSAGE_SUCCESS:
            return { ...state, singleMsg: response };
        case types.FETCH_SINGLE_MESSAGE_FAIL:
            return { ...state, singleMsg: response };

        // Listening to updated support count
        case types.LISTEN_UPDATED_SUPPORT_COUNT_SUCCESS:
            return { ...state, updatedSupportCount: response };
        case types.LISTEN_UPDATED_SUPPORT_COUNT_FAIL:
            return { ...state, updatedSupportCount: response };

        // Listening to updated report count
        case types.LISTEN_UPDATED_REPORT_COUNT_SUCCESS:
            return { ...state, updatedReportCount: response };
        case types.LISTEN_UPDATED_REPORT_COUNT_FAIL:
            return { ...state, updatedReportCount: response };

        // Listening to new msg
        case types.LISTEN_NEW_MSG_SUCCESS:
            return { ...state, latestMsg: response };
        case types.LISTEN_NEW_MSG_FAIL:
            return { ...state, latestMsg: response };

        // Translate single message
        case types.TRANSLATE_SINGLE_MESSAGE_SUCCESS:
            return { ...state, translatedMsg: response };
        case types.TRANSLATE_SINGLE_MESSAGE_FAIL:
            return { ...state, translatedMsg: response };
        case types.TRANSLATE_MESSAGE_RESET:
            return { ...state, translatedMsg: [] };

        // Reply to msg
        case types.SUBMIT_REPLY_MSG_SUCCESS:
            return { ...state, submitReplyMsg: response };
        case types.SUBMIT_REPLY_MSG_FAIL:
            return { ...state, submitReplyMsg: response };

        // Fetch reply list
        case types.FETCH_REPLY_LIST_SUCCESS:
            return { ...state, replyList: response };
        case types.FETCH_REPLY_LIST_FAIL:
            return { ...state, replyList: response };

        // Clear reply list
        case types.CLEAR_REPLY_LIST:
            return { ...state, replyList: response }

        // Update reply count 
        case types.LISTEN_REPLY_COUNT_SUCCESS:
            return { ...state, updatedReplyCount: response };
        case types.FETCH_REPLY_LIST_FAIL:
            return { ...state, updatedReplyCount: response };

        // New reply
        case types.LISTEN_NEW_REPLY_SUCCESS:
            return { ...state, newReply: response };
        case types.LISTEN_NEW_REPLY_FAIL:
            return { ...state, newReply: response };

        // Support reply
        case types.SUPPORT_REPLY_SUCCESS:
            return { ...state, supportReply: response };
        case types.SUPPORT_REPLY_FAIL:
            return { ...state, supportReply: response };
        case types.SUPPORT_REPLY_RESET:
            return { ...state, supportReply: [] };

        // Report reply
        case types.REPORT_REPLY_SUCCESS:
            return { ...state, reportReply: response };
        case types.REPORT_REPLY_FAIL:
            return { ...state, reportReply: response };
        case types.REPORT_REPLY_RESET:
            return { ...state, reportReply: [] }

        // Support count update
        case types.LISTEN_REPLY_SUPPORT_COUNT_SUCCESS:
            return { ...state, replySupportCount: response };
        case types.LISTEN_REPLY_SUPPORT_COUNT_FAIL:
            return { ...state, replySupportCount: response };

        // Support count update
        case types.LISTEN_REPLY_REPORT_COUNT_SUCCESS:
            return { ...state, replyReportCount: response };
        case types.LISTEN_REPLY_REPORT_COUNT_FAIL:
            return { ...state, replyReportCount: response };

        // Track my reply msg 
        case types.TRACK_MY_REPLY_MESSAGE_SUCCESS:
            return { ...state, trackReplyMsg: response }
        case types.TRACK_MY_REPLY_MESSAGE_FAIL:
            return { ...state, trackReplyMsg: response };

        //message filter
        case types.SET_INITIAL_RECIPIENT_FILTER:
            return { ...state, recipientFilter: action.payload };
        case types.SET_FILTER_RECIPIENT:
            return { ...state, recipientFilter: action.payload };
        case types.REMOVE_FILTER_RECIPIENT:
            return { ...state, recipientFilter: action.payload };
        case types.SET_FILTER_LOCATION:
            return { ...state, locationFilter: action.payload };
        case types.REMOVE_FILTER_LOCATION:
            return { ...state, locationFilter: action.payload };

        // reset submit reply msg 
        case types.RESET_SUBMIT_REPLY_MSG:
            return { ...state, submitReplyMsg: [] }

        // Translate reply message
        case types.TRANSLATE_REPLY_MESSAGE_SUCCESS:
            return { ...state, translatedReplyMsg: response };
        case types.TRANSLATE_REPLY_MESSAGE_FAIL:
            return { ...state, translatedReplyMsg: response };
        case types.TRANSLATE_REPLY_MESSAGE_RESET:
            return { ...state, translatedReplyMsg: [] };

        // reset submit reply msg 
        case types.RESET_UPDATED_SUPPORT_COUNT:
            return { ...state, updatedSupportCount: [] }

        // reset update report count
        case types.RESET_UPDATED_REPORT_COUNT:
            return { ...state, updatedReportCount: [] }

        // reset update report count
        case types.RESET_UPDATED_REPLY_COUNT:
            return { ...state, updatedReplyCount: [] }

        // reset track reply msg
        case types.RESET_TRACK_REPLY_MSG:
            return { ...state, trackReplyMsg: [] }

        // fetch message sectors
        case types.FETCH_MESSAGE_SECTORS_SUCCESS:
            return { ...state, messageSectors: response.data };
        case types.FETCH_MESSAGE_SECTORS_FAIL:
            return { ...state, messageSectors: [] }

        // fetch single reply msg
        case types.FETCH_SINGLE_REPLY_MSG_SUCCESS:
            return { ...state, singleReplyMsg: response.data };
        case types.FETCH_SINGLE_REPLY_MSG_FAIL:
            return { ...state, singleReplyMsg: [] }

        // social sharing email
        case types.SHARE_EMAIL_MESSAGE_SUCCESS:
            return { ...state, shareMsg: response.data };
        case types.SHARE_EMAIL_MESSAGE_FAIL:
            return { ...state, shareMsg: "" }

        // ditto msg 
        case types.SET_DITTO_MSG_CONTENT:
            return { ...state, ditto: response }

        // ditto reply msg
        case types.SET_DITTO_REPLY_MSG_CONTENT:
            return { ...state, dittoReply: response }

        // reset ditto msg 
        case types.RESET_DITTO_MSG_CONTENT:
            return { ...state, ditto: [] }

        // reset ditto reply msg
        case types.RESET_DITTO_REPLY_MSG_CONTENT:
            return { ...state, dittoReply: [] }
        // updated ditto count
        case types.LISTEN_UPDATED_DITTO_COUNT_SUCCESS:
            return { ...state, updatedDittoCount: response }
        case types.LISTEN_UPDATED_DITTO_COUNT_FAIL:
            return { ...state, updatedDittoCount: response }
        // updated reply ditto count
        case types.LISTEN_UPDATED_DITTO_REPLY_COUNT_SUCCESS:
            return { ...state, updatedReplyDittoCount: response }
        case types.LISTEN_UPDATED_DITTO_REPLY_COUNT_FAIL:
            return { ...state, updatedReplyDittoCount: response }
        default:
            return state;

        // Fetch Messages for search
        case types.FETCH_MESSAGES_FOR_SEARCH_SUCCESS:
            return { ...state, search: { ...state.search, messageListForSearch: [...state.search.messageListForSearch, ...response] } };
        case types.CLEAR_FETCHED_MESSAGES_FOR_SEARCH:
            return { ...state, search: { ...state.search, messageListForSearch: [] } };
        case types.FETCH_MESSAGES_FOR_SEARCH_FAIL:
            return { ...state };

        // Set search message string
        case types.SET_MESSAGE_SEARCH_STRING:
            return { ...state, search: { ...state.search, messageSearchString: action.payload } };
        case types.CLEAR_MESSAGE_SEARCH_STRING:
            return { ...state, search: { ...state.search, messageSearchString: "" } };

        // Set filtered message list
        case types.SET_FILTERED_MESSAGE_LIST:
            return { ...state, search: { ...state.search, filteredMessageList: action.payload } };
        case types.CLEAR_FILTERED_MESSAGE_LIST:
            return { ...state, search: { ...state.search, filteredMessageList: [] } };

        //set skip ammount in message fetch for search
        case types.SET_SKIP_FOR_SEARCH:
            return { ...state, search: { ...state.search, skip: action.payload } };

             // User registration
        case types.DONATION_REGISTRATION_SUCCESS:
            return { ...state, donationRegistration: response };
        case types.DONATION_REGISTRATION_FAIL:
            return { ...state, donationRegistration: response };
        case types.DONATION_REGISTRATION_RESET:
            return { ...state, donationRegistration: [] };

    }
}