import React, { useState, useEffect, useRef } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

import { getGeocodeByPlaceId } from "../../../../Utils/GoogleAPI";

import loc from "../../../../Resources/Images/loc.svg";

const Location = ({
  backToMainComponent = () => { },
  setLocation = () => { },
}) => {
  const { t, i18n } = useTranslation();
  const inputRefLocation = useRef();

  useEffect(() => {
    inputRefLocation.current.focus();
  },[])

  return (
    <>
      <div className="header-wrapper">
        <img src={loc}></img>
        <h3>location</h3>
        <span className='' onClick={backToMainComponent}>Back</span>
      </div>
      <div className="tagger-wrapper">
        {/* <button
        onClick={backToMainComponent}
        type="button"
        className="btn btn-link"
      >
        {t("back")}
      </button> */}

        <div className="tagger">
          <GooglePlacesAutocomplete
            renderInput={(props) => (
              <div className="serach-panel">
                {" "}
                <input
                  {...props}
                  type="text"
                  className="form-control js-search-place"
                  placeholder={t("search_location")}
                  ref={inputRefLocation}
                />
              </div>
            )}
            renderSuggestions={(active, suggestions, onSelectSuggestion) => (
              <div className="">
                <div className="search-results">
                  {suggestions.map((suggestion) => (
                    <div
                      class="col-md-12 mt-3"
                      onClick={(event) => onSelectSuggestion(suggestion, event)}
                    >
                      <div class="row">
                        <div class="col-10 col-sm-10 col-md-10 col-lg-10 pl-0">
                          <p class="main-result">
                            <i class="fas fa-map-marker-alt"></i>
                            {suggestion.structured_formatting.main_text
                              ? suggestion.structured_formatting.main_text
                              : ""}
                            <span class="sub-text">
                              {suggestion.structured_formatting.secondary_text
                                ? suggestion.structured_formatting.secondary_text
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 mt-1 p-0">
                          {" "}
                          <i class="fas fa-plus-circle"></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            onSelect={async (suggestions) => {
              const geoCode = await getGeocodeByPlaceId(suggestions.place_id);
              setLocation({
                latitude: geoCode.lat,
                longitude: geoCode.long,
                description: suggestions.structured_formatting.main_text,
              });
              backToMainComponent();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Location;
