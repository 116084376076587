import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setFilterLocationAction,
  setFilterRecipientAction,
  removeFilterLocationAction,
  removeFilterRecipientAction,
  setInitialRecipientFlterAction,
} from "../../../../Actions/communityAction";

import { useTranslation } from "react-i18next";

import GroupImg from "../../../../Resources/Images/Group.svg";
import RightImg from "../../../../Resources/Images/right.svg";
import marker from "../../../../Resources/Images/marker.svg";

import RecipientFilter from "./RecipientFilter";
import LocationFilter from "./LocationFilter";

const MessageFilter = () => {
  const { t, i18n } = useTranslation();

  const [locationFilter, setLocationFilter] = useState(false);
  const [recipientFlter, setRecipientFilter] = useState(false);

  const locations = useSelector((state) => state.community.locationFilter);
  const recipients = useSelector((state) => state.community.recipientFilter);
  const messageSectors = useSelector((state) => state.community.messageSectors);

  const dispatch = useDispatch();

  const addLocations = (newLocation) => {
    let isAwailable = false;
    locations.forEach((loc) => {
      if (loc.placeId === newLocation.placeId) isAwailable = true;
    });
    if (isAwailable) return;
    dispatch(setFilterLocationAction([...locations, newLocation]));
  };

  const removeLocations = (locationId) => {
    const list = [...locations].filter(
      (location) => location.placeId !== locationId
    );
    dispatch(removeFilterLocationAction(list));
  };

  const addRecipient = (value) => {
    dispatch(setFilterRecipientAction([...recipients, value]));
  };
  const removeRecipient = (value) => {
    const list = [...recipients].filter((word) => word !== value);
    dispatch(removeFilterRecipientAction(list));
  };

  const backToMainComponent = () => {
    setRecipientFilter(false);
    setLocationFilter(false);
  };
  return (
    <>
      {!recipientFlter && !locationFilter && (
        <>
          <div className="header-wrapper">
            <img src={GroupImg}></img>
            <h3>{t("Filters")}</h3>
            <NavLink exact to="/">
              <span style={{ color: "black" }} onClick={() => {}}>
                {t("back")}
              </span>
            </NavLink>
          </div>
          <div className="filter-menu">
            {/* Location Filter */}
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list">
                <div
                  className="row"
                  onClick={() => {
                    setLocationFilter(true);
                  }}
                >
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                <label>{t("location")}</label>
                  </div>
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 input-img">
                    <span readOnly={true} className="tag-location">
                      <img src={marker} alt="" />
                      {locations.length !== 0
                        ? locations.length === 1
                          ? locations[0].mainText
                          : locations.length + " locations"
                        : t("world")}
                    </span>
                    <a>
                      <img src={RightImg} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Recipient Filter */}
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list">
                <div
                  className="row"
                  onClick={() => {
                    setRecipientFilter(true);
                  }}
                >
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                <label>{t("recipient_type")}</label>
                  </div>
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 input-img">
                    <input
                      readOnly={true}
                      value={
                        recipients.length !== 0
                          ? recipients.length === 1
                            ? recipients[0].split("_").join(" ")
                            : recipients.length === messageSectors.length
                            ? t("All")
                            : recipients.length + " recipients"
                          : t("All")
                      }
                      type="text"
                      className="form-control form-control-sm"
                    />
                    <a>
                      <img src={RightImg} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {locationFilter && (
        <LocationFilter
          backToMainComponent={backToMainComponent}
          addLocations={addLocations}
          removeLocations={removeLocations}
          locations={locations}
          header={true}
        />
      )}
      {recipientFlter && (
        <RecipientFilter
          backToMainComponent={backToMainComponent}
          messageSectors={messageSectors}
          addRecipient={addRecipient}
          removeRecipient={removeRecipient}
          recipients={recipients}
          header={true}
        />
      )}
    </>
  );
};
export default MessageFilter;
