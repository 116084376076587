import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Layout from "../../../Layout/Layout";
import leftArrow from "../../../../Resources/Images/left-arrow.svg";
import Ditto from "./Ditto";

import ReplyList from "./ReplyList";
import {
  fetchReplyListAction,
  listenNewReplyAction,
  makeSupportReplyAction,
  makeReportReplyAction,
  listenUpdatedReplySupportCountAction,
  listenUpdatedReplyReportCountAction,
  translateReplyMsgAction,
  resetTranslationReplyMsgAction,
  resetReplyMsgAction,
  clearReplyListAction,
  resetReplyPromoteResetAction,
  resetReplyReportAction,
  shareEmailMsgAction,
  setDittoReplyMsgContent,
  submitReplyMsgAction,
  resetDittoReplyMsgContent,
  listenUpdatedReplyDittoCountAction
} from "../../../../Actions/communityAction";
import WriteReply from "./WriteReply";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import Model from "../../../Common/Model";
import ModelDitto from "../../../Common/ModelDitto";
import SocialMediaSharingModel from "../../../Common/SocialMediaShareModel";
import EmailModel from "../../../Common/ModalShareEmail";
import { getLanguage } from "../../../../Utils/Storage";
import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
  getGeoCodeByAddress
} from "../../../../Utils/GoogleAPI";

const Reply = ({ msgData = {}, closeReply = () => { }, ...props }) => {
  const { t, i18n } = useTranslation();
  const [replyList, setReplyList] = useState([]);
  const [writeMsg, setWriteMsg] = useState(false);

  const [singleMsgData, setSingleMsgData] = useState({});

  const [isOpenDittoComponent, setIsOpenDittoComponent] = useState(false);

  //Ditto user list
  const [dittoUserList, setDittoUserList] = useState([]);

  //Sender location
  const [senderLocation, setSenderLocation] = useState("");

  //report message id
  const [reportMsgID, setReportMsgID] = useState([]);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // Model
  const [openModel, setOpenModel] = useState(false);
  const [modelHeader, setModelHeader] = useState("");
  const [modelContent, setModelContent] = useState("");
  const [modelButton1, setModelButton1] = useState("");
  const [modelButton2, setModelButton2] = useState("");

  // Model Ditto
  const [openModelDitto, setOpenModelDitto] = useState(false);
  const [modelHeaderDitto, setModelHeaderDitto] = useState("");
  const [modelContentDitto, setModelContentDitto] = useState("");
  const [modelButton1Ditto, setModelButton1Ditto] = useState("");
  const [modelButton2Ditto, setModelButton2Ditto] = useState("");

  // Model social media
  const [openSocialMediaModel, setOpenSocialMediaModel] = useState(false);

  //store translated message data
  const [translatedMsgs, setTranslatedMsgs] = useState([]);

  const [openModelEmail, setOpenModelEmail] = useState(false);
  const [shareMsgHeader, setShareMsgHeader] = useState(false);

  const [emailShareSuccess, setEmailShareSuccess] = useState();

  const [isSubmitDitto, setIsSubmitDitto] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     props.communitySocket.emit('leave-room', { room: msgData._id }, (error, response) => {
  //       if (error) {
  //         console.log(error);
  //       } else {
  //         // console.log(response);
  //       }
  //       props.clearReplyListAction([])
  //     })
  //   }
  // }, [])

  useEffect(() => {
    const formData = {
      room: msgData._id,
    };
    props.fetchReplyListAction(formData);
    props.listenNewReplyAction();
    props.listenUpdatedReplySupportCountAction();
    props.listenUpdatedReplyReportCountAction();
    props.listenUpdatedReplyDittoCountAction();
  }, []);

  useEffect(() => {
    if (props.replyList) {
      //console.log('reply msg -->', props.replyList);
      // if (props.replyList.length === 0)
      //   setWriteMsg(true);
      // else
      //   setWriteMsg(false);

      setReplyList(props.replyList);
    }
  }, [props.replyList]);

  useEffect(() => {
    if (props.newReply._id && props.newReply.messageId._id === msgData._id) {
      setReplyList([props.newReply, ...replyList]);
    }
  }, [props.newReply]);

  // Support for message
  const makePromo = (replyData) => {
    var formData = {
      _id: replyData._id,
      messageId: msgData._id,
    };
    props.makeSupportReplyAction(formData);
  };

  useEffect(() => {
    if (props.supportReply.support) {
      if (props.supportReply.support) {
        var updatedID = props.supportReply._id;

        // Update count
        replyList.find((msg) => msg._id === updatedID).support.count =
          props.supportReply.support.count;
        replyList.find((msg) => msg._id === updatedID).support.supportedHashes =
          props.supportReply.support.supportedHashes;

        setReplyList([...replyList]);
        // setSeverity("success");
        // setErrorMsg(t('Thank you for your feedback'));
        // setErrorNotifification(!errorNotifification);
        props.resetReplyPromoteResetAction();
      } else {
        setSeverity("error");
        setErrorMsg(t('Ooops..Some thing went wrong..!'));
        setErrorNotifification(!errorNotifification);
        props.resetReplyPromoteResetAction();
      }
    }
  }, [props.supportReply]);

  // Report Reply
  const reportMsgModel = (dataMsg, isReported) => {
    var modelContent = "";
    var button = "";
    var header = "";
    if (isReported) {
      header = t('Unreport');
      modelContent = t('To undo your action please select Unreport');
      button = t('Unreport');
    } else {
      header = t('Report');
      modelContent =
        t('Please select Report below you feel this messge should be removed');
      button = t('Report');
    }

    setReportMsgID(dataMsg);
    setModelHeader(header);
    setModelContent(modelContent);
    setModelButton1(button);
    setModelButton2(t('cancel'));
    setOpenModel(!openModel);
  };

  const reportMsg = () => {
    var formData = {
      _id: reportMsgID._id,
      messageId: msgData._id,
    };
    props.makeReportReplyAction(formData);
  };

  useEffect(() => {
    if (props.reportReply.indecentReport) {
      if (props.reportReply.indecentReport) {
        var updatedID = props.reportReply._id;
        var reportCount = props.reportReply.indecentReport.count;
        // Update count
        replyList.find(
          (msg) => msg._id === updatedID
        ).indecentReport.reportedHashes =
          props.reportReply.indecentReport.reportedHashes;

        replyList.find(
          (msg) => msg._id === updatedID
        ).indecentReport.shouldMessageVisible = reportCount >= 3 ? false : true;

        setReplyList([...replyList]);

        // setSeverity("success");
        // setErrorMsg(t('Thank you for your feedback'));
        // setErrorNotifification(!errorNotifification);
        setOpenModel(!openModel);
        props.resetReplyReportAction();
      } else {
        setSeverity("error");
        setErrorMsg(t('Ooops..Some thing went wrong..!'));
        setErrorNotifification(!errorNotifification);
        setOpenModel(!openModel);
        props.resetReplyReportAction();
      }
    }
  }, [props.reportReply]);

  // Update msg support count
  useEffect(() => {
    if (props.replySupportCount.support) {
      var messages = replyList;
      const index = replyList.findIndex(
        (msg) => msg._id === props.replySupportCount._id
      );
      if (index != -1) {
        messages[index].support.count = props.replySupportCount.support.count;
        setReplyList([...messages]);
      }
    }
  }, [props.replySupportCount]);

  // Update reply ditto count
  useEffect(() => {
    if (props.updatedReplyDittoCount.state === "SUCCESS") {
      var messages = replyList;
      const index = replyList.findIndex(
        (msg) => msg._id === props.updatedReplyDittoCount.omId
      );
      if (index != -1) {
        messages[index].ditto.dittoCount = props.updatedReplyDittoCount.dittoCount;
        messages[index].ditto.dittoedUsers = props.updatedReplyDittoCount.dittoedUsers;
        setReplyList([...messages]);
      }
    }
  }, [props.updatedReplyDittoCount]);

  useEffect(() => {
    if (props.replyReportCount.indecentReport) {
      var messages = replyList;
      const index = replyList.findIndex(
        (msg) => msg._id === props.replyReportCount._id
      );
      if (index != -1) {
        messages[index].shouldReplyVisible =
          props.replyReportCount.indecentReport.count >= 3 ? false : true;
        setReplyList([...messages]);
      }
    }
  }, [props.replyReportCount]);

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  // Share message
  const shareMsg = (msgData) => { //console.log("msgData",msgData);
    msgData.shareUrl = `${process.env.REACT_APP_LIVE_URL}/reply/${msgData._id}`;
    msgData.message = msgData.replyMessage;
    setSingleMsgData(msgData);
    setOpenSocialMediaModel(!openSocialMediaModel);
    setShareMsgHeader(t('Share your message'));
  };

  const closeWriteReplyMsg = () => {
    //console.log('Write msg run');
    const formData = {
      room: msgData._id,
    };
    props.fetchReplyListAction(formData);
    setReplyList([props.submitReplyMsg, ...replyList]);
    props.resetReplyMsgAction();
    setWriteMsg(false);
    props.resetDittoReplyMsgContent(); // Reset ditto reply msg content
  };

  // If translate msg
  useEffect(() => {
    if (
      props.translatedMsg &&
      props.translatedMsg.status == 200 &&
      props.translatedMsg.hasOwnProperty("data")
    ) {
      if (props.translatedMsg.data.hasOwnProperty("translatedMessage")) {
        var messages = replyList;
        const index = replyList.findIndex(
          (msg) => msg._id === props.translatedMsg.data.translatedMessage._id
        );
        if (index != -1) {
          messages[index].replyMessage =
            props.translatedMsg.data.translatedMessage.replyMessage;
          // messages[index].indecentReport.shouldMessageVisible = props.updatedMsg.updatedMessage.indecentReport.count >= 3 ? false : true;
          setReplyList([...messages]);
        }
        props.resetTranslationReplyMsgAction();
      }
    }
  }, [props.translatedMsg]);

  //translate msg
  const translateMsg = (msgData) => {

    let lang = "";
    let messages = translatedMsgs;
    let index = translatedMsgs.findIndex((msg) => msg._id === msgData._id);
    if (index != -1) {
      let isTranslatedMsg = translatedMsgs[index].isTranslated;
      if (isTranslatedMsg) {
        lang = "";
        messages[index].isTranslated = false;
      } else {
        lang = getLanguage();
        messages[index].isTranslated = true;
      }
    } else {
      lang = getLanguage();
      messages.push({
        _id: msgData._id,
        isTranslated: true,
      });
    }

    setTranslatedMsgs([...messages]);

    var formData = {
      id: msgData._id,
      lang: lang,
    };

    props.translateReplyMsgAction(formData);
  };

  const OpenEmailModal = () => {
    setOpenModelEmail(true);
  }

  const closeEmailModel = () => {
    setOpenModelEmail(false);
  }

  const validateEmailForm = (data) => {

  }

  const submitEmailShareForm = (data) => {
    data.messageId = singleMsgData._id;
    data.shareUrl = singleMsgData.shareUrl;
    data.selectedLanguage = getLanguage();
    props.shareEmailMsgAction(data);
  }

  useEffect(() => {
    if (props.shareMsg) {
      if (props.shareMsg.success) {
        setEmailShareSuccess(true);
      } else {
        setEmailShareSuccess(false);
      }

      setTimeout(function () {
        closeEmailModel();
        setEmailShareSuccess("");
      }, 3000);
    }
  }, [props.shareMsg]);

  /* Ditto Msg */
  const dittoMsg = (data) => {
    props.setDittoReplyMsgContent(data);
    if (props.userPrecense.data.isRegistered) {
      setOpenModelDitto(true);
      setModelHeaderDitto(t("Repost Message"));
      setModelContentDitto(t("Do you want Repost this reply message ?"));
      setModelButton1Ditto(t("Repost"));
      setModelButton2Ditto(t('cancel'));
      navigator.geolocation.getCurrentPosition(
        setLatLong,
        setByIPaddress
      );
    } else {
      setWriteMsg(!writeMsg);
    }
  }

  /*Ditto user list component */
  const dittoComponent = (userList) => {
    setIsOpenDittoComponent(true);
    setDittoUserList(userList);
  }

  // Geo location get by geo code
  const setLatLong = async (geoLocation) => {
    var lat = geoLocation.coords.latitude;
    var long = geoLocation.coords.longitude;
    var response = await getGeoLocationByLatLong(lat, long);
    const arLength = response.results.length;
    const geoLoc = response.results[arLength - 3].formatted_address;

    const cityData = await getGeoCodeByAddress(response.results[arLength - 3].address_components[0].long_name);
    const countryData = await getGeoCodeByAddress(response.results[arLength - 1].address_components[0].long_name);

    setSenderLocation({
      latitude: lat,
      longitude: long,
      description: geoLoc,
      location: [
        {
          name: { offset: "auto", value: response.results[arLength - 3].address_components[0].long_name },
          type: "city",
          placeId: cityData[0].place_id,
        },
        {
          name: { offset: "auto", value: response.results[arLength - 1].address_components[0].long_name },
          type: "country",
          placeId: countryData[0].place_id,
        },
      ],
    });
  };


  // Geo location get by ip
  const setByIPaddress = async (error) => {
    var geoData = await getGeocodeByIPaddress();
    var geoCodeAr = geoData.loc.split(",");
    var lat = geoCodeAr[0];
    var long = geoCodeAr[1];
    var response = await getGeoLocationByLatLong(lat, long);

    const arLength = response.results.length;
    const geoLocation = response.results[arLength - 3].formatted_address;

    const cityData = await getGeoCodeByAddress(response.results[arLength - 3].address_components[0].long_name);
    const countryData = await getGeoCodeByAddress(response.results[arLength - 1].address_components[0].long_name);

    setSenderLocation({
      latitude: lat,
      longitude: long,
      description: geoLocation,
      location: [
        {
          name: { offset: "auto", value: response.results[arLength - 3].address_components[0].long_name },
          type: "city",
          placeId: cityData[0].place_id,
        },
        {
          name: { offset: "auto", value: response.results[arLength - 1].address_components[0].long_name },
          type: "country",
          placeId: countryData[0].place_id,
        },
      ],
    });
  };

  /* Post ditto msg directly */
  const postDittoMsg = async (impactOfMsg) => {
    const formData = {
      messageId: props.dittoReply.messageId,
      replyMessage: props.dittoReply.replyMessage,
      author: props.userPrecense.data.user.userName,
      authorLocation: senderLocation.description,
      isImpactEnable: impactOfMsg,
      isDitto: true,
      omId: props.dittoReply._id,
      replyMessageIcon: props.dittoReply.replyMessageIcon,
      language: props.dittoReply.language
    };
    // Set is submit reply ditto
    setIsSubmitDitto(true);

    props.submitReplyMsgAction(formData);
  }

  /* Reply msg submit */
  useEffect(() => {
    if (isSubmitDitto) {
      if (props.submitReplyMsg._id) {
        setSeverity("success");
        setErrorMsg(t('Wow..! Ditto success..!'));
        setErrorNotifification(!errorNotifification);
        props.resetReplyMsgAction();
        /* Fetch Messages*/
        const formData = {
          room: msgData._id,
        };
        props.fetchReplyListAction(formData);
      } else if (props.submitReplyMsg.error) {
        setSeverity("error");
        setErrorMsg(props.submitGratitudeMsg.error.message);
        setErrorNotifification(!errorNotifification);
        props.resetReplyMsgAction();
      }
      //Set is submit ditto  - false
      setIsSubmitDitto(false);

      setOpenModelDitto(false);
      props.resetDittoReplyMsgContent(); // Reset ditto reply msg content
    }
  }, [props.submitReplyMsg]);

  return (
    <div className="w-100">
      {!writeMsg && !isOpenDittoComponent && (
        <div className="reply-wrapper">
          <div className="reply-title">
            <h5>{props.header.headerTitle}'{t('s')} {t('Replies')}</h5>
            <span className="ab-btn" onClick={closeReply}>
              <img src={leftArrow} /> {t("back")}
            </span>
          </div>

          <div className="massege-reply d-flex">
            <div className="w-100">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <textarea
                  onClick={(e) => setWriteMsg(!writeMsg)}
                  name=""
                  maxLength={120}
                  id=""
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder={t("write_your_reply")}
                ></textarea>
              </div>
            </div>
          </div>

          {replyList.map((reply, key) =>
            reply.shouldReplyVisible ? (
              <ReplyList
                key={key}
                msgData={msgData}
                replyData={reply}
                makePromo={makePromo}
                reportMsg={reportMsgModel}
                //shareMsg={shareMsg}
                //temporary allow email share only
                shareMsg={OpenEmailModal}
                translateMsg={translateMsg}
                openDittoComponent={(dittoUsers) => dittoComponent(dittoUsers)}
                dittoMsg={(data) => dittoMsg(data)}
              />
            ) : (
                ""
              )
          )}
        </div>
      )}
      {writeMsg && (
        <WriteReply msgData={msgData} closeWriteReply={closeWriteReplyMsg} />
      )}

      {isOpenDittoComponent &&
        <Ditto
          closeDittoComponent={() => setIsOpenDittoComponent(false)}
          userList={dittoUserList}
        />
      }

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={2000}
      />

      <Model
        open={openModel}
        modelHeader={modelHeader}
        modelContent={modelContent}
        button1={modelButton1}
        button2={modelButton2}
        trigerFunction={reportMsg}
        closeModel={() => setOpenModel(!openModel)}
      />

      <ModelDitto
        open={openModelDitto}
        modelHeader={modelHeaderDitto}
        modelContent={modelContentDitto}
        button1={modelButton1Ditto}
        button2={modelButton2Ditto}
        trigerFunction={postDittoMsg}
        closeModel={() => setOpenModelDitto(!openModelDitto)}
      />

      <SocialMediaSharingModel
        shareMsgData={singleMsgData}
        open={openSocialMediaModel}
        closeModel={() => setOpenSocialMediaModel(!openSocialMediaModel)}
        openEmailModal={OpenEmailModal}

      />

      <EmailModel
        open={openModelEmail}
        modelHeader={shareMsgHeader}
        closeModel={closeEmailModel}
        shareMsgData={singleMsgData}
        submitForm={(data) => submitEmailShareForm(data)}
        emailShareSuccess={emailShareSuccess}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearReplyListAction: (data) => dispatch(clearReplyListAction(data)),
    fetchReplyListAction: (data) => dispatch(fetchReplyListAction(data)),
    listenNewReplyAction: (data) => dispatch(listenNewReplyAction(data)),
    makeSupportReplyAction: (data) => dispatch(makeSupportReplyAction(data)),
    makeReportReplyAction: (data) => dispatch(makeReportReplyAction(data)),
    listenUpdatedReplySupportCountAction: (data) =>
      dispatch(listenUpdatedReplySupportCountAction(data)),
    listenUpdatedReplyReportCountAction: (data) =>
      dispatch(listenUpdatedReplyReportCountAction(data)),
    translateReplyMsgAction: (data) => dispatch(translateReplyMsgAction(data)),
    resetTranslationReplyMsgAction: () =>
      dispatch(resetTranslationReplyMsgAction()),
    resetReplyMsgAction: () => dispatch(resetReplyMsgAction()),
    resetReplyPromoteResetAction: () => dispatch(resetReplyPromoteResetAction()),
    resetReplyReportAction: () => dispatch(resetReplyReportAction()),
    shareEmailMsgAction: (data) => dispatch(shareEmailMsgAction(data)),
    setDittoReplyMsgContent: (data) => dispatch(setDittoReplyMsgContent(data)),
    submitReplyMsgAction: (data) => dispatch(submitReplyMsgAction(data)),
    resetDittoReplyMsgContent: () => dispatch(resetDittoReplyMsgContent()),
    listenUpdatedReplyDittoCountAction: () => dispatch(listenUpdatedReplyDittoCountAction())
  };
};

const mapStateToProps = (response) => ({
  replyList: response.community.replyList,
  newReply: response.community.newReply,
  supportReply: response.community.supportReply,
  reportReply: response.community.reportReply,
  replySupportCount: response.community.replySupportCount,
  replyReportCount: response.community.replyReportCount,
  submitReplyMsg: response.community.submitReplyMsg,
  translatedMsg: response.community.translatedReplyMsg,
  header: response.general.changeHeader,
  communitySocket: response.community.communitySocket,
  shareMsg: response.community.shareMsg,
  userPrecense: response.general.userPrecense,
  dittoReply: response.community.dittoReply,
  updatedReplyDittoCount: response.community.updatedReplyDittoCount
});
export default connect(mapStateToProps, mapDispatchToProps)(Reply);
