import React from "react";

const TermsOfServiceZH = () => {
  return (
    <>
    <div className='stc-wrapper'>
      <h4 className='text-center'>服務條款</h4>
      <div className='text-center'>
        <p>
          感謝您的關注並訪問我們的平台。在下面，您會發現我們律師事務所的一些法律術語，基本上概述了平台以及我們擁有和運營的其他站點的服務條款。如果您對這些條款有任何疑問或疑慮，歡迎您直接與我們聯繫。再次感謝您對Gratitude
          TM 的關注，並與我們一起度過這段時間。
        </p>
        <p className='semi-bold'>Mikey Stojcevski，首席技術官兼全球運營</p>
      </div>
      <hr />
      <p className='bold'>1.條款</p>
      <p>
        通過訪問平台的&nbsp;
        <a href="http://sharegratitude.com/">http://sharegratitude.com</a>
        ，我們擁有和經營的其他網站，即表明您同意遵守這些服務條款，所有適用法律和法規的約束，並同意您有責任遵守任何適用的地方法律。如果您不同意這些條款中的任何一項，則禁止您使用或訪問本網站。本網站所含材料受適用的版權和商標法保護。
      </p>
      <p className='bold'>2.使用許可</p>
      <ol type="a">
        <li>
          權限被授予臨時下載上的共享感激公司的材料（信息或軟件）的一個副本的網站為個人，非商業短暫僅供查看。這是授予許可，而不是所有權的轉讓，根據此許可，您不得：
        </li>
        <ol type="i">
          <li>修改或複製材料；</li>
          <li>
            將這些材料用於任何商業目的，或用於任何公共展示（商業或非商業用途）；
          </li>
          <li>
            嘗試對SHARE GRATITUDE COMPANY
            平台和我們擁有和運營的其他站點上包含的任何軟件進行反編譯或反向工程。
          </li>
          <li>從資料中刪除任何版權或其他專有符號；要么</li>
          <li>將資料轉移給其他人或“鏡像”任何其他服務器上的資料。</li>
        </ol>
        <li>
          如果您違反這些限制中的任何一項，則本許可將自動終止，並且可能隨時由SHARE
          GRATITUDE公司終止。在終止查看這些材料或終止本許可後，您必須銷毀所有電子或印刷格式的下載材料。
        </li>
      </ol>
      <p className='bold'>3.免責聲明</p>
      <ol type="a">
        <li>
          SHARE GRATITUDE COMPANY
          平台以及我們擁有和運營的其他站點上的材料按“原樣”提供。股份擔保公司不作任何明示或暗示的保證，並由此否認並否認所有其他保證，包括但不限於對適銷性，適用於特定目的的隱含保證或條件，不侵犯知識產權或其他侵犯權利的行為。
        </li>
        <li>
          此外，SHARE
          GRATITUDE公司對於其平台上或與此類材料或與本網站鏈接的任何網站相關的材料使用的準確性，可能的結果或可靠性，不作任何保證或聲明。
        </li>
      </ol>
      <p className='bold'>4.局限性</p>
      <p>
        在任何情況下，SHARE
        GRATITUDE公司或其供應商對因使用或無法使用SHARE上的資料而造成的任何損失（包括但不限於因數據或利潤損失或業務中斷引起的損失）概不負責。即使已通過口頭或書面形式告知SHARE
        GRATITUDE COMPANY 或SHARE GRATITUDE
        COMPANY授權代表這種損害的可能性，也可以從GRATITUDE COMPANY
        網站上獲得。由於某些司法管轄區不允許限制默示擔保或對間接損失或附帶損失的責任限制，因此這些限制可能不適用於您。
      </p>
      <p className='bold'>5.材料的準確性</p>
      <p>
        在SHARE GRATITUDE COMPANY
        平台和我們擁有和運營的其他站點上出現的材料可能包括技術，印刷或攝影錯誤。股份擔保公司不保證其網站上的任何材料都是準確，完整或最新的。股份擔保公司可以隨時更改其網站上包含的資料，恕不另行通知。但是，SHARE
        GRATITUDE公司不承擔任何更新材料的承諾。
      </p>
      <p className='bold'>6.鏈接</p>
      <p>
        股份擔保公司尚未審查與其網站鏈接的所有網站，並且對任何此類鏈接網站的內容概不負責。包含任何鏈接並不表示該網站得到了SHARE
        GRATITUDE公司的認可。用戶自行承擔使用任何此類鏈接網站的風險。
      </p>
      <p className='bold'>7.修改</p>
      <p>
        股份擔保公司可以隨時修改其網站的服務條款，恕不另行通知。通過使用此平台和我們擁有並運營的其他網站，您同意受這些服務條款的當前版本的約束。
      </p>
      <p className='bold'>8.準據法</p>
      <p>
        這些條款和條件受澳大利亞新南威爾士州法律管轄並據其解釋，您不可撤銷地服從該州或所在地法院的專屬管轄權。
      </p>
      <p>該政策自2020年8月16日起生效。</p>
      <p>
        我們邀請並歡迎您
        <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>聯繫üS ^</a>
        如果你在問候這一政策任何進一步的問題。
      </p>
      </div>
    </>
  );
};

export default TermsOfServiceZH;
