import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


import "./donation.scss";

import give from "../../Resources/Images/give.svg";
import { useHistory } from "react-router-dom";
const DonationSucessModal = ({
  open = false,
  donationMsg = {},
  modelHeader = "-",
  donationAmount={},
  reason={},
  type={},
  subHeader = "-",
  modelContent = "-",
  button1 = "Yes",
  button2 = "No",
  trigerFunction = () => { },
  closeModel = () => { },
}) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();
  const handleClose = () => {
    history.push("/");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="donation"
    >
      <div className="mo-header">
        {" "}
        <img src={give} />
        <span>{modelHeader}</span>
        <span onClick={handleClose} color="secondary" autoFocus>
          {t('back')}
        </span>
      </div>
      <DialogContent>
      
        <DialogContentText id="alert-dialog-description" >
          <p className='text-center'>{t('Thank you for your interest to donate')} <b>${donationAmount}</b> {t('to help support')}<b> {reason}</b> {t('initiatives')}. {t('A representative from')}<b> {type}</b> {t('will be in contact with you to process your donation')}.</p>
        </DialogContentText>
      

        <a
          className="btn btn-primary btn-block "
          href="/"
         
          
        >
          {t('OK')}
          
        </a>

       
      </DialogContent>
    </Dialog>
  );
};

export default DonationSucessModal;
