import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useHistory } from "react-router-dom";

import { setGratitudeMsgAction } from "../../Actions/paymentAction";
import "./donation.scss";
import give from "../../Resources/Images/give.svg";

const ModelGiveLittle = ({
  open = false,
  donationMsg = {},
  modelHeader = "-",
  subHeader = "-",
  modelContent = "-",
  button1 = "Yes",
  button2 = "No",
  trigerFunction = () => { },
  closeModel = () => { },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const handleClose = () => {
    closeModel();
  };

  const writeMsg = () => {
    history.push("/gratitude");
  }

  const donate=()=> {
    history.push("/donation");
  }

  const sendPrintedCard = () => {
    const msg = {
      msg          : donationMsg.message,
      recipientName: donationMsg.recipientName,
    }

    props.setGratitudeMsgAction(msg);
    history.push("/printCard");
  }

  const sendFlowers = () => {
    const msg = {
      msg          : donationMsg.message,
      recipientName: donationMsg.recipientName,
    }

    props.setGratitudeMsgAction(msg);
    history.push("/SendFlowers");
  }

  const sendWineBottle = () => {
    const msg = {
      msg          : donationMsg.message,
      recipientName: donationMsg.recipientName,
    }

    props.setGratitudeMsgAction(msg);
    history.push("/WineBottle");
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="donation"
    >
      <div className="mo-header">
        {" "}
        <img src={give} />
        {/* {modelHeader} */}
        <span>{t( "SIMPLE WAY TO" )}&nbsp;<strong>{t( "GIVE A LITTLE" )}</strong></span>
        <span onClick={handleClose} color="secondary" autoFocus>
          {t('back')}
        </span>
      </div>
      <DialogContent className='pt-0 pb-3'>
        {/* <h5>{subHeader}</h5> */}
        <DialogContentText id="alert-dialog-description" >
          {/* <p dangerouslySetInnerHTML={{ __html: modelContent }}></p> */}
        </DialogContentText>
        <div className="col-md-12 pl-0 pr-0 text-center give-little">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list no-border p-4">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p className='px-sm-4'>
                      {t(
                        "Select from the below, and we will hand select a local small business near your recipient to support with sending your gift."
                      )}
                    </p>
                    <a onClick={sendPrintedCard} className="btn btn-primary btn-block">
                      {t('Send a printed')}&nbsp;<strong>{t('card')}</strong>
                    </a>
                      {/* <small className='mt-1 italic'>{t("This requires a")} <u className='cp'>{t("recipient postal address")}</u></small> */}
                    <a onClick={sendFlowers} className="btn btn-primary btn-block">
                      {t('Send some')}&nbsp;<strong>{t('flowers')}</strong>
                    </a>
                    {/* <small className='mt-1 italic'>{t("This requires a")} <u className='cp'>{t("recipient postal address")}</u></small> */}
                   
                    <a onClick={sendWineBottle} className="btn btn-primary btn-block">
                      Send a bottle <strong>&nbsp;Wine</strong>
                    </a>
                    <a onClick={donate}  className="btn btn-primary btn-block">
                      <strong>{t('Donate')}&nbsp;</strong> {t('to charity')}
                    </a>
                    {/* <a onClick={writeMsg} className="btn btn-primary btn-block">
                      {t('Write a')}&nbsp;<strong>{t('message')}&nbsp;</strong>{t('of Gratitude')}
                    </a>  */}

                    {/* <p className='mt-3'>{t("give_a_title_second_sentence")}</p> */}
                    {/* <p className='mt-3'>
                      {t('It is more than a message and gift of gratitude, you are helping build stronger communities.')}
                    </p> */}
                     <p className='mt-3 text-center'>
                      {t("The impact of your gesture is real and far-reaching. Together we are making a real difference in people's lives, their families, and our community")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
      setGratitudeMsgAction: (payload) => dispatch(setGratitudeMsgAction(payload)),
    };
  };

export default connect(null, mapDispatchToProps)(ModelGiveLittle);
