import React from "react";

const CookiePolicyES = () => {
  return (
    <>
      <div className='stc-wrapper'>
        <h4 className='text-center'>Política de cookies</h4>
        <div>
          <p>
            Gracias por su interés y visitar nuestra plataforma . A continuación ,
            encontrará un poco de jerga legal de nuestra firma legal, que
            básicamente describe la Política de cookies para la plataforma y otros
            sitios que poseemos y operamos. Lo invitamos a comunicarse con
            nosotros directamente si tiene alguna pregunta o inquietud con
            respecto a estos términos. Nuevamente, gracias por su interés en
            Gratitude TM y por pasar este tiempo con nosotros.
        </p>
          <p className='semi-bold text-center'>Mikey Stojcevski, director técnico y operaciones globales</p>
        </div>
        <hr />
        <p>
          Utilizamos cookies para ayudar a mejorar su experiencia en la
          plataforma&nbsp;
        <a href="http://sharegratitude.com">http://sharegratitude.com</a>&nbsp;
        y otros sitios que poseemos y operamos . Esta política de cookies es
        parte de la cuota de GRATITUD EMPRESA ‘s privacidad política, y cubre el
        uso de cookies entre el dispositivo y nuestro sitio. También
        proporcionamos información básica sobre los servicios de terceros que
        podemos usar, quienes también pueden usar cookies como parte de su
        servicio, aunque no están cubiertos por nuestra política.
      </p>
        <p>
          Si no desea aceptar nuestras cookies, debe indicarle a su navegador que
          rechace las cookies de&nbsp;
        <a href="http://sharegratitude.com/">http://sharegratitude.com</a>&nbsp;
        y de otros sitios que poseemos y operamos , en el entendimiento de que
        es posible que no podamos proporcionarle algunos de los su contenido y
        servicios deseados.
      </p>
        <h5>
          ¿Qué es una cookie?
      </h5>
        <p>
          Una cookie es un pequeño fragmento de datos que un sitio web almacena en
          su dispositivo cuando lo visita, que generalmente contiene información
          sobre el sitio web en sí, un identificador único que permite que el
          sitio reconozca su navegador web cuando regresa, datos adicionales que
          sirven para el propósito de la cookie y la vida útil de la propia
          cookie.
      </p>
        <p>
          Las cookies se utilizan para habilitar ciertas funciones ( por ejemplo,
          iniciar sesión), rastrear el uso del sitio ( por ejemplo, análisis),
          almacenar su configuración de usuario ( por ejemplo , zona horaria ,
          preferencias de notificación) y personalizar su contenido ( por ejemplo,
          idioma).
      </p>
        <p>
          Las cookies establecidas por el sitio web que está visitando se
          denominan normalmente "cookies de origen" y, por lo general, solo
          rastrean su actividad en ese sitio en particular . Las cookies
          establecidas por otros sitios y empresas ( es decir, terceros) se
          denominan "cookies de terceros " y se pueden utilizar para rastrearlo en
          otros sitios web que utilizan el mismo servicio de terceros.
      </p>
        <h5>
          Tipos de cookies y como las usamos
      </h5>
        <h6>

          <i>Cookies esenciales</i>

        </h6>
        <p>
          Las cookies esenciales son cruciales para su experiencia en un sitio
          web, ya que permiten funciones básicas como inicios de sesión de
          usuarios, administración de cuentas, carritos de compras y procesamiento
          de pagos. Usamos cookies esenciales para habilitar ciertas funciones en
          nuestro sitio web.
      </p>
        <h6>

          <i>Cookies de rendimiento</i>

        </h6>
        <p>
          Las cookies de rendimiento se utilizan en el seguimiento de cómo usa un
          sitio web durante su visita, sin recopilar información personal sobre
          usted. Por lo general, esta información es anónima y se agrega con
          información rastreada en todos los usuarios del sitio , para ayudar a
          las empresas a comprender los patrones de uso de los visitantes,
          identificar y diagnosticar problemas o errores que puedan encontrar sus
          usuarios y tomar mejores decisiones estratégicas para mejorar la
          experiencia general del sitio web de su audiencia. Estas cookies pueden
          ser configuradas por el sitio web que está visitando (primera parte) o
          por servicios de terceros. Usamos cookies de rendimiento en nuestro
          sitio.
      </p>
        <h6>

          <i>Cookies de funcionalidad</i>

        </h6>
        <p>
          Las cookies de funcionalidad se utilizan para recopilar información
          sobre su dispositivo y cualquier configuración que pueda configurar en
          el sitio web que está visitando (como la configuración de idioma y zona
          horaria). Con esta información, los sitios web pueden proporcionarle
          contenido y servicios personalizados, mejorados u optimizados. Estas
          cookies pueden ser configuradas por el sitio web que está visitando
          (primera parte) o por un servicio de terceros. Usamos cookies de
          funcionalidad para funciones seleccionadas en nuestro sitio.
      </p>
        <h6>

          <i>Cookies de segmentación / publicidad</i>

        </h6>
        <p>
          Las cookies de orientación / publicidad se utilizan para determinar qué
          contenido promocional es más relevante y apropiado para usted y sus
          intereses. Los sitios web pueden utilizarlos para ofrecer publicidad
          dirigida o para limitar la cantidad de veces que ve un anuncio. Esto
          ayuda a las empresas a mejorar la eficacia de sus campañas y la calidad
          del contenido que se le presenta. Estas cookies pueden ser establecidas
          por el sitio web que está visitando (de primera parte) o por servicios
          de terceros. Las cookies de orientación / publicidad establecidas por
          terceros pueden usarse para rastrearlo en otros sitios web que usan el
          mismo servicio de terceros. Usamos cookies de orientación / publicidad
          en nuestro sitio.
      </p>
        <h5>
          Cookies de terceros en nuestro sitio
      </h5>
        <p>
          Podemos emplear empresas e individuos de terceros en nuestros sitios
          web, por ejemplo, proveedores de análisis y socios de contenido.
          Concedemos estas terceras partes el acceso a la información seleccionada
          para realizar tareas específicas en nuestro nombre. También pueden
          establecer cookies de terceros para brindar los servicios que brindan.
          Las cookies de terceros se pueden utilizar para rastrearlo en otros
          sitios web que utilizan el mismo servicio de terceros. Ya que no tenemos
          control sobre las cookies de terceros, que no están cubiertos por la
          participación GRATITUD EMPRESA ‘s política de cookies.
      </p>
        <h5>
          Nuestra promesa de privacidad de terceros
      </h5>
        <p>
          Nunca incluiremos a sabiendas servicios de terceros que comprometan o
          violen la privacidad de nuestros usuarios.
      </p>
        <h5>
          Cómo puede controlar u optar por no recibir cookies
      </h5>
        <p>
          Si no desea aceptar nuestras cookies, puede indicarle a su navegador que
          rechace las cookies de nuestro sitio web. La mayoría de los navegadores
          están configurados para aceptar cookies de forma predeterminada, pero
          puede actualizar esta configuración para rechazar las cookies por
          completo o para notificarle cuando un sitio web está intentando
          establecer o actualizar una cookie.
      </p>
        <p>
          Si navega por sitios web desde varios dispositivos, es posible que deba
          actualizar su configuración en cada dispositivo individual.
      </p>
        <p>
          Aunque algunas cookies se pueden bloquear con poco impacto en su
          experiencia en un sitio web, bloquear todas las cookies puede significar
          que no puede acceder a ciertas funciones y contenido en los sitios que
          visita.
      </p>
        <p>
          <a name="_Hlk48457241">
            Esta política es efectiva a partir del 16 de agosto de 2020.
        </a>
        </p>
        <p>
          Invitamos y damos la bienvenida a&nbsp;
        <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>
            contacto us
        </a>
        &nbsp; si tiene alguna duda en cuanto a esta política.
      </p>
      </div>
    </>
  );
};
export default CookiePolicyES;
