import React from "react";
import { useSelector } from "react-redux";

import Layout from "../../../../Layout/Layout";
import EN from "./en";
import ES from "./es";
import ZH from "./zh";

const CookiePolicy = () => {
  const lng = useSelector((state) => state.general.lang);
  return (
    <Layout>
      {lng === "en" && <EN />}
      {lng === "es" && <ES />}
      {lng === "zh" && <ZH />}
    </Layout>
  );
};

export default CookiePolicy;