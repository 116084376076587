import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TransitionsModal from "../../../Common/TransitionsModal";
import RightImg from "../../../../Resources/Images/right.svg";
import FrontLinerList from "../../../Common/FrontLinerList";
import GiveLittle from "./GiveLittle";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";

import { validateEmail } from "../../../../Utils/Util";
import { NavLink, Link } from "react-router-dom";
import {
  resetGratitudeMsgAction,
  userRegistrationAction,
  resetUserRegistrationAction
} from "../../../../Actions/gratitudeAction";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const MsgImpact = ({ submitMsgID = 0, trackMyMsg = () => { }, ...props }) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [enableImpact, setEnableImpact] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [openUseOfTerms, setOpenUseOfTerms] = useState(false);
  const [giveLittle, setGiveLittle] = useState(true);
  const [enableGiveLittleScreen, setEnableGiveLittleScreen] = useState(false);
  const [frontLinerDes, setFrontLinerDes] = useState(false);
  const [frontLineDesAr, setFrontLineDesAr] = useState(["I am a caring community member"]);
  const [customDescription, setCustomDescription] = useState("");
  const [isDoneProfession, setIsDoneProfession] = useState(false);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // validation
  const [validName, setValidName] = useState(true);
  const [isvalidEmail, setIsvalidEmail] = useState(true);

  const emailValidation = (email) => {
    setUserEmail(email);
    setValidEmail(validateEmail(email));
    setIsvalidEmail(validateEmail(email));
  };

  const userRegistration = () => {
    const formData = {
      userName: userName,
      primaryEmail: userEmail,
      profession: frontLineDesAr,
      customProfession: [customDescription],
      // isAgreedTerms: agreeWithTerms,
      // isImpactEnable: enableImpact,
      browserHash: localStorage.getItem("browserHash").toString(),
      selectedLanguage: props.lang,
      messageId: props.submitGratitudeMsg.message._id
    };
    props.userRegistrationAction(formData);
  };

  // useEffect(() => {
  //   if (props.userInfo) {
  //     if (props.userInfo.data) {
  //       var nameOfSender = props.userInfo.data.user ? props.userInfo.data.user.userName : '';
  //       var emailOfSender = props.userInfo.data.user.messageEmail && props.userInfo.data.user.messageEmail[1] ? props.userInfo.data.user.messageEmail[1] : '';
  //       setSenderName(nameOfSender);
  //       setSenderEmail(emailOfSender);
  //       setValidEmail(validateEmail(emailOfSender));
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (props.userRegistration) {
      if (props.userRegistration.data) {
        if (props.userRegistration.data.success) {
          if (giveLittle)
            setEnableGiveLittleScreen(true);
          else
            history.push("/");
        }
        props.resetUserRegistrationAction();
        props.resetGratitudeMsgAction();
      } else if (props.userRegistration.name === "Error") {
        setSeverity("error");
        setErrorMsg("Please check again and re-submit.");
        setErrorNotifification(!errorNotifification);
        props.resetUserRegistrationAction();
        props.resetGratitudeMsgAction();
      }
    }
  }, [props.userRegistration]);

  const validateNameField = (name) => {
    var isValidName = name ? true : false;
    setValidName(isValidName);
  }

  const validateFields = () => {
    setIsvalidEmail(validateEmail(userEmail));
    setValidName(userName ? true : false);
  }

  const addDescription = (checked, value) => {
    if (checked) {
      setFrontLineDesAr([...frontLineDesAr, value]);
    } else {
      var index = frontLineDesAr.indexOf(value);
      frontLineDesAr.splice(index, 1);
      setFrontLineDesAr([...frontLineDesAr]);
    }
  }

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  const redirectPage = () => {
    if (giveLittle)
      setEnableGiveLittleScreen(true);
    else

      history.push("/copygratitude");
  }

  return (
    <div className="col-md-12">
      {!frontLinerDes && !enableGiveLittleScreen && (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="input-list no-border">
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 impact-header">
                  <p className="tit">{t("amazing_your_message_has_been_sent")}</p>
                </div>
                {!props.userPrecense.isRegistered && (
                  <div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      {/* <p>
                        {t(
                          "we_are_excited_to_share_with_you_the_impact_of_your_message"
                        )}
                      </p> */}
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <p className="italic">{t("impact_of_msg_description")}</p>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>

          {!props.userPrecense.isRegistered && (
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div>
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 input-">
                      <input
                        value={userName}
                        onChange={(e) => { setUserName(e.target.value); validateNameField(e.target.value); }}
                        type="text"
                        className="form-control"
                        placeholder={t("full_name")}
                      />
                      {!validName ? <span className='inp-error'>Fullname is required</span> : ''}
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 input-">
                      <input
                        value={userEmail}
                        onChange={(e) => emailValidation(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder={t("email")}
                      />
                      {!isvalidEmail ? <span className='inp-error'>Requied valid email</span> : ''}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div className="row"
                    onClick={() => setFrontLinerDes(true)}
                  >
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                      <input
                   
                        value={(props.userPrecense && props.userPrecense.isRegistered) || isDoneProfession ? frontLineDesAr.map((profession) => { return t(profession) }) + (frontLineDesAr.length > 0 && customDescription ? "," : "") + (customDescription ? customDescription : '') : ""}
                        type="text"
                        className="self-desc form-control pl-3"
                        placeholder={t("What describes you best")}
                      />
                      <a>
                        <img src={RightImg} alt="" />
                      </a>
                     
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="mb-3">
                <div className="input-list">
                  <div className="row">
                    <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 link">
                      <Link>
                        <label
                          onClick={() => setOpenUseOfTerms(!openUseOfTerms)}
                          for=""
                        >
                          {t("agree_to_terms_of_service")}
                        </label>
                      </Link>
                    </div>
                    <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 input-">
                      <label className="switch float-right">
                        <input
                          checked={agreeWithTerms ? true : false}
                          onChange={() => setAgreeWithTerms(!agreeWithTerms)}
                          type="checkbox"
                        />{" "}
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
            <div className="input-list">
              <div className="row">
                <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <label for="">{t("send_me_the_impact_of_my_message")}</label>
                </div>
                <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                  <label className="switch float-right">
                    <input
                      checked={enableImpact ? true : false}
                      onChange={() => setEnableImpact(!enableImpact)}
                      type="checkbox"
                    />{" "}
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          )}

          <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
            <div className="input-list">
              <div className="row">
                <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <label className='sway' for="">{t("I would like to explore")} <span>{t("simple ways to")}<strong>&nbsp;{t("give a little")}</strong></span></label>
                </div>
                <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 input-">
                  <label className="switch float-right">
                    <input
                      checked={giveLittle ? true : false}
                      onChange={() => setGiveLittle(!giveLittle)}
                      type="checkbox"
                    />{" "}
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            {" "}
            <a
              onClick={
                !props.userPrecense.isRegistered ?
                  userName && validEmail && agreeWithTerms
                    ? () => userRegistration()
                    : () => validateFields()
                  : () => { redirectPage() }
              }

              className={`float-right track ${
                !props.userPrecense.isRegistered ? userName && validEmail && agreeWithTerms ? "active" : "inactive" : 'active'
                }`}
            >
              {!props.userPrecense.isRegistered ? t("TRACK_IMPACT") : t("OK")}
            </a>
          </div>
        </div>
      )}

      {frontLinerDes && (<FrontLinerList
        backToMain={() => { setFrontLinerDes(false); setIsDoneProfession(true); }}
        addedDescription={frontLineDesAr}
        addDescription={(checked, value) => addDescription(checked, value)}
        addCustomDescription={(value) => setCustomDescription(value)}
        customDescription={customDescription}
      />)}

      {enableGiveLittleScreen && <GiveLittle />}

      <TransitionsModal
        open={openUseOfTerms}
        closeModel={() => setOpenUseOfTerms(!openUseOfTerms)}
      />

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={3000}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetGratitudeMsgAction: () => dispatch(resetGratitudeMsgAction()),
    userRegistrationAction: (data) => dispatch(userRegistrationAction(data)),
    resetUserRegistrationAction: () => dispatch(resetUserRegistrationAction()),
  };
};

const mapStateToProps = (response) => ({
  lang: response.general.lang,
  userInfo: response.general.userInfo,
  userRegistration: response.gratitude.userRegistration,
  submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  userPrecense: response.general.userPrecense.data
});
export default connect(mapStateToProps, mapDispatchToProps)(MsgImpact);
