import React from "react";
import { useSelector } from "react-redux";

import EN from "./en";
import ES from "./es";
import ZH from "./zh";

const TermsOfServiceIndex = () => {
  const lng = useSelector((state) => state.general.lang);
  return (
    <>
      {lng === "en" && <EN />}
      {lng === "es" && <ES />}
      {lng === "zh" && <ZH />}
    </>
  );
};

export default TermsOfServiceIndex;