// Fetch recipients
export const FETCH_RECIPIENTS = 'FETCH_RECIPIENTS';
export const FETCH_RECIPIENTS_SUCCESS = 'FETCH_RECIPIENTS_SUCCESS';
export const FETCH_RECIPIENTS_FAIL = 'FETCH_RECIPIENTS_FAIL';

// Submit gratitude msg
export const SUBMIT_GRATITUDE_MSG = 'SUBMIT_GRATITUDE_MSG';
export const SUBMIT_GRATITUDE_MSG_SUCCESS = 'SUBMIT_GRATITUDE_MSG_SUCCESS';
export const SUBMIT_GRATITUDE_MSG_FAIL = 'SUBMIT_GRATITUDE_MSG_FAIL';
export const SUBMIT_GRATITUDE_MSG_RESET = 'SUBMIT_GRATITUDE_MSG_RESET';

// User registration
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAIL = 'USER_REGISTRATION_FAIL';
export const USER_REGISTRATION_RESET = 'USER_REGISTRATION_RESET';

// Fetch all messages
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAIL = 'FETCH_MESSAGES_FAIL';
export const FETCH_MESSAGES_RESET = 'FETCH_MESSAGES_RESET';

// Make promote msg
export const MAKE_PROMOTE_MSG = 'MAKE_PROMOTE_MSG';
export const MAKE_PROMOTE_MSG_SUCCESS = 'MAKE_PROMOTE_MSG_SUCCESS';
export const MAKE_PROMOTE_MSG_FAIL = 'MAKE_PROMOTE_MSG_FAIL';
export const RESET_MAKE_PROMOTE_MSG = 'RESET_MAKE_PROMOTE_MSG';

// Report msg 
export const REPORT_MESSAGE = "REPORT_MESSAGE";
export const REPORT_MESSAGE_SUCCESS = 'REPORT_MESSAGE_SUCCESS';
export const REPORT_MESSAGE_FAIL = 'REPORT_MESSAGE_FAIL';
export const RESET_REPORT_MESSAGE = 'RESET_REPORT_MESSAGE';

// Set language
export const SET_LANG = "SET_LANG";

// Swap header
export const CHANGE_HEADER = "CHANGE_HEADER";

// Check precense
export const CHECK_PRECENSE = "CHECK_PRECENSE";
export const CHECK_PRECENSE_SUCCESS = "CHECK_PRECENSE_SUCCESS";
export const CHECK_PRECENSE_FAIL = "CHECK_PRECENSE_FAIL";

// Fetch single message
export const FETCH_SINGLE_MESSAGE = "FETCH_SINGLE_MESSAGE";
export const FETCH_SINGLE_MESSAGE_SUCCESS = "FETCH_SINGLE_MESSAGE_SUCCESS";
export const FETCH_SINGLE_MESSAGE_FAIL = "FETCH_SINGLE_MESSAGE_FAIL";

// Listen to update support count
export const LISTEN_UPDATED_SUPPORT_COUNT = "LISTEN_UPDATED_SUPPORT_COUNT";
export const LISTEN_UPDATED_SUPPORT_COUNT_SUCCESS = "LISTEN_UPDATED_SUPPORT_COUNT_SUCCESS";
export const LISTEN_UPDATED_SUPPORT_COUNT_FAIL = "LISTEN_UPDATED_SUPPORT_COUNT_FAIL";

// Listen to update report count
export const LISTEN_UPDATED_REPORT_COUNT = "LISTEN_UPDATED_REPORT_COUNT";
export const LISTEN_UPDATED_REPORT_COUNT_SUCCESS = "LISTEN_UPDATED_REPORT_COUNT_SUCCESS";
export const LISTEN_UPDATED_REPORT_COUNT_FAIL = "LISTEN_UPDATED_REPORT_COUNT_FAIL";

// Listen to new msg
export const LISTEN_NEW_MSG = "LISTEN_NEW_MSG";
export const LISTEN_NEW_MSG_SUCCESS = "LISTEN_NEW_MSG_SUCCESS";
export const LISTEN_NEW_MSG_FAIL = "LISTEN_NEW_MSG_FAIL";

// translate single message
export const TRANSLATE_SINGLE_MESSAGE = "TRANSLATE_SINGLE_MESSAGE";
export const TRANSLATE_SINGLE_MESSAGE_SUCCESS = "TRANSLATE_SINGLE_MESSAGE_SUCCESS";
export const TRANSLATE_SINGLE_MESSAGE_FAIL = "TRANSLATE_SINGLE_MESSAGE_FAIL";
export const TRANSLATE_MESSAGE_RESET = "TRANSLATE_MESSAGE_RESET";

// Submit reply msg
export const SUBMIT_REPLY_MSG = "SUBMIT_REPLY_MSG";
export const SUBMIT_REPLY_MSG_SUCCESS = "SUBMIT_REPLY_MSG_SUCCESS";
export const SUBMIT_REPLY_MSG_FAIL = "SUBMIT_REPLY_MSG_FAIL";

// Fetch reply list
export const FETCH_REPLY_LIST = "FETCH_REPLY_LIST";
export const FETCH_REPLY_LIST_SUCCESS = "FETCH_REPLY_LIST_SUCCESS";
export const FETCH_REPLY_LIST_FAIL = "FETCH_REPLY_LIST_FAIL";

// clear reply list
export const CLEAR_REPLY_LIST = "CLEAR_REPLY_LIST";


// Reply count update
export const LISTEN_REPLY_COUNT = "LISTEN_REPLY_COUNT";
export const LISTEN_REPLY_COUNT_SUCCESS = "LISTEN_REPLY_COUNT_SUCCESS";
export const LISTEN_REPLY_COUNT_FAIL = "LISTEN_REPLY_COUNT_FAIL";

// Listen new reply 
export const LISTEN_NEW_REPLY = "LISTEN_NEW_REPLY";
export const LISTEN_NEW_REPLY_SUCCESS = "LISTEN_NEW_REPLY_SUCCESS";
export const LISTEN_NEW_REPLY_FAIL = "LISTEN_NEW_REPLY_FAIL";

// Support to reply 
export const SUPPORT_REPLY = "SUPPORT_REPLY";
export const SUPPORT_REPLY_SUCCESS = "SUPPORT_REPLY_SUCCESS";
export const SUPPORT_REPLY_FAIL = "SUPPORT_REPLY_FAIL";
export const SUPPORT_REPLY_RESET = "SUPPORT_REPLY_RESET";

// Support to reply 
export const REPORT_REPLY = "REPORT_REPLY";
export const REPORT_REPLY_SUCCESS = "REPORT_REPLY_SUCCESS";
export const REPORT_REPLY_FAIL = "REPORT_REPLY_FAIL";
export const REPORT_REPLY_RESET = "REPORT_REPLY_RESET";

// Listen reply support count
export const LISTEN_REPLY_SUPPORT_COUNT = "LISTEN_REPLY_SUPPORT_COUNT";
export const LISTEN_REPLY_SUPPORT_COUNT_SUCCESS = "LISTEN_REPLY_SUPPORT_COUNT_SUCCESS";
export const LISTEN_REPLY_SUPPORT_COUNT_FAIL = "LISTEN_REPLY_SUPPORT_COUNT_FAIL";

// Listen reply support count
export const LISTEN_REPLY_REPORT_COUNT = "LISTEN_REPLY_REPORT_COUNT";
export const LISTEN_REPLY_REPORT_COUNT_SUCCESS = "LISTEN_REPLY_REPORT_SUCCESS";
export const LISTEN_REPLY_REPORT_COUNT_FAIL = "LISTEN_REPLY_REPORT_FAIL";

//message filter
export const SET_FILTER_LOCATION = "SET_FILTER_LOCATION";
export const REMOVE_FILTER_LOCATION = "REMOVE_FILTER_LOCATION";
export const SET_FILTER_RECIPIENT = "SET_FILTER_RECIPIENT";
export const REMOVE_FILTER_RECIPIENT = "REMOVE_FILTER_RECIPIENT";
export const SET_INITIAL_RECIPIENT_FILTER = "SET_INITIAL_RECIPIENT_FILTER";

// track my reply message
export const TRACK_MY_REPLY_MESSAGE = "TRACK_MY_REPLY_MESSAGE";
export const TRACK_MY_REPLY_MESSAGE_SUCCESS = "TRACK_MY_REPLY_MESSAGE_SUCCESS";
export const TRACK_MY_REPLY_MESSAGE_FAIL = "TRACK_MY_REPLY_MESSAGE_FAIL";

// reset submit reply msg msg
export const RESET_SUBMIT_REPLY_MSG = "RESET_SUBMIT_REPLY_MSG";

// reset updated support count
export const RESET_UPDATED_SUPPORT_COUNT = "RESET_UPDATED_SUPPORT_COUNT";

// reset updated report count
export const RESET_UPDATED_REPORT_COUNT = "RESET_UPDATED_REPORT_COUNT";

// reset updated report count
export const RESET_UPDATED_REPLY_COUNT = "RESET_UPDATED_REPLY_COUNT";

// reset track my msg
export const RESET_TRACK_REPLY_MSG = "RESET_TRACK_REPLY_MSG";

//translate reply message
export const TRANSLATE_REPLY_MESSAGE = "TRANSLATE_REPLY_MESSAGE";
export const TRANSLATE_REPLY_MESSAGE_SUCCESS = "TRANSLATE_REPLY_MESSAGE_SUCCESS";
export const TRANSLATE_REPLY_MESSAGE_FAIL = "TRANSLATE_REPLY_MESSAGE_FAIL";
export const TRANSLATE_REPLY_MESSAGE_RESET = "TRANSLATE_REPLY_MESSAGE_RESET";

//fetching message sectors
export const FETCH_MESSAGE_SECTORS = "FETCH_MESSAGE_SECTORS";
export const FETCH_MESSAGE_SECTORS_SUCCESS = "FETCH_MESSAGE_SECTORS_SUCCESS";
export const FETCH_MESSAGE_SECTORS_FAIL = "FETCH_MESSAGE_SECTORS_FAIL";

// fetch single msg
export const FETCH_SINGLE_REPLY_MSG = "FETCH_SINGLE_REPLY_MSG";
export const FETCH_SINGLE_REPLY_MSG_SUCCESS = "FETCH_SINGLE_REPLY_MSG_SUCCESS";
export const FETCH_SINGLE_REPLY_MSG_FAIL = "FETCH_SINGLE_REPLY_MSG_FAIL";

// translate single reply 
export const TRANSLATE_SINGLE_REPLY_MESSAGE = "TRANSLATE_SINGLE_REPLY_MESSAGE";
export const TRANSLATE_SINGLE_MESSAGE_REPLY_SUCCESS = "TRANSLATE_SINGLE_MESSAGE_REPLY_SUCCESS";
export const TRANSLATE_SINGLE_MESSAGE_REPLY_FAIL = "TRANSLATE_SINGLE_MESSAGE_REPLY_FAIL";
export const TRANSLATE_SINGLE_REPLY_MESSAGE_RESET = "TRANSLATE_REPLY_MESSAGE_RESET";

// set  tour guide is view
export const SET_TOUR_GUIDE_IS_VIEW = "SET_TOUR_GUIDE_IS_VIEW";
export const RESET_TOUR_GUIDE_IS_VIEW = "RESET_TOUR_GUIDE_IS_VIEW";

// set user visit before
export const SET_USER_VISIT_BEFORE = "SET_USER_VISIT_BEFORE";

// get user info 
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";

//get volunteer info 
export const GET_VOLUNTEER_INFO = "GET_VOLUNTEER_INFO";
export const GET_VOLUNTEER_INFO_SUCCESS = "GET_VOLUNTEER_INFO_SUCCESS";
export const GET_VOLUNTEER_INFO_FAIL = "GET_VOLUNTEER_INFO_FAIL";

//social sharing email
export const SHARE_EMAIL_MESSAGE = "SHARE_EMAIL_MESSAGE";
export const SHARE_EMAIL_MESSAGE_SUCCESS = "SHARE_EMAIL_MESSAGE_SUCCESS";
export const SHARE_EMAIL_MESSAGE_FAIL = "SHARE_EMAIL_MESSAGE_FAIL";

//world view counts
export const FETCH_WORLD_COUNT = "FETCH_WORLD_COUNT";
export const FETCH_WORLD_COUNT_SUCCESS = "FETCH_WORLD_COUNT_SUCCESS";
export const FETCH_WORLD_COUNT_FAIL = "FETCH_WORLD_COUNT_FAIL";

//Ditto msg 
export const SET_DITTO_MSG_CONTENT = "SET_DITTO_MSG_CONTENT";
export const SET_DITTO_REPLY_MSG_CONTENT = "SET_DITTO_REPLY_MSG_CONTENT";

export const RESET_DITTO_MSG_CONTENT = "RESET_DITTO_MSG_CONTENT";
export const RESET_DITTO_REPLY_MSG_CONTENT = "RESET_DITTO_REPLY_MSG_CONTENT";

// Ditto listen updated count
export const LISTEN_UPDATED_DITTO_COUNT = "LISTEN_UPDATED_DITTO_COUNT";
export const LISTEN_UPDATED_DITTO_COUNT_SUCCESS = "LISTEN_UPDATED_DITTO_COUNT_SUCCESS";
export const LISTEN_UPDATED_DITTO_COUNT_FAIL = "LISTEN_UPDATED_DITTO_COUNT_FAIL";

// Ditto reply count updated
export const LISTEN_UPDATED_DITTO_REPLY_COUNT = "LISTEN_UPDATED_DITTO_REPLY_COUNT";
export const LISTEN_UPDATED_DITTO_REPLY_COUNT_SUCCESS = "LISTEN_UPDATED_DITTO_REPLY_COUNT_SUCCESS";
export const LISTEN_UPDATED_DITTO_REPLY_COUNT_FAIL = "LISTEN_UPDATED_DITTO_REPLY_COUNT_FAIL";

//fetching messages for search
export const FETCH_MESSAGES_FOR_SEARCH = "FETCH_MESSAGES_FOR_SEARCH";
export const FETCH_MESSAGES_FOR_SEARCH_SUCCESS = "FETCH_MESSAGES_FOR_SEARCH_SUCCESS";
export const FETCH_MESSAGES_FOR_SEARCH_FAIL = "FETCH_MESSAGES_FOR_SEARCH_FAIL";

//set message search string
export const SET_MESSAGE_SEARCH_STRING = "SET_MESSAGE_SEARCH_STRING";
export const CLEAR_MESSAGE_SEARCH_STRING = "CLEAR_MESSAGE_SEARCH_STRING";

//set filtered messsages list
export const SET_FILTERED_MESSAGE_LIST = "SET_FILTERED_MESSAGE_LIST";
export const CLEAR_FILTERED_MESSAGE_LIST = "CLEAR_FILTERED_MESSAGE_LIST";

//clear fetched messages for search
export const CLEAR_FETCHED_MESSAGES_FOR_SEARCH = "CLEAR_FETCHED_MESSAGES_FOR_SEARCH";

//set skip mmount in message fetch for search
export const SET_SKIP_FOR_SEARCH = "SET_SKIP_FOR_SEARCH";


//volunteer  registration 

export const VOLUNTEER_REGISTRATION = 'VOLUNTEER_REGISTRATION';
export const VOLUNTEER_REGISTRATION_SUCCESS = 'VOLUNTEER_REGISTRATION_SUCCESS';
export const VOLUNTEER_REGISTRATION_FAIL = 'VOLUNTEER_REGISTRATION_FAIL';
export const VOLUNTEER_REGISTRATION_RESET = 'VOLUNTEER_REGISTRATION_RESET';

// Donation registration
export const DONATION_REGISTRATION = 'DONATION_REGISTRATION';
export const DONATION_REGISTRATION_SUCCESS = 'DONATION_REGISTRATION_SUCCESS';
export const DONATION_REGISTRATION_FAIL = 'DONATION_REGISTRATION_FAIL';
export const DONATION_REGISTRATION_RESET = 'DONATION_REGISTRATION_RESET';

//User Details
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAIL = "FETCH_USER_DETAILS_FAIL";

//Payment
export const PAYMENT_CREATE_CUSTOMER = "PAYMENT_CREATE_CUSTOMER";
export const PAYMENT_CREATE_CUSTOMER_SUCCESS = "PAYMENT_CREATE_CUSTOMER_SUCCESS";
export const PAYMENT_CREATE_CUSTOMER_FAIL = "PAYMENT_CREATE_CUSTOMER_FAIL";

export const PAYMENT_CHECKOUT = "PAYMENT_CHECKOUT";
export const PAYMENT_CHECKOUT_SUCCESS = "PAYMENT_CHECKOUT_SUCCESS";
export const PAYMENT_CHECKOUT_FAIL = "PAYMENT_CHECKOUT_FAIL";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const SET_GRATITUDE_MSG = "SET_GRATITUDE_MSG";
export const RESET_GRATITUDE_MSG = "RESET_GRATITUDE_MSG";
export const SET_GRATITUDE_MSG_SUCCESS = "SET_GRATITUDE_MSG_SUCCESS";