export const formatRecipients = (recipients) => {
    var recipientList = []
    recipients.recipientList.map((recipient, index) => {
        recipientList.push({ id: index.toString(), text: recipient });
    });
    recipients.recipientList = recipientList;
    return recipients;
} 

export const shuffleStories = (stories, type="") => {
    let nonFeaturedArray = stories.filter(function (story){
        return (type)? story.type==type && !story.hasOwnProperty('featured'): !story.hasOwnProperty('featured');
    });
    let featuredArray = stories.filter(function (story) {
        return (type)? story.type==type && story.hasOwnProperty('featured') && story.featured == true
                :  story.hasOwnProperty('featured') && story.featured == true ;
    });          
    nonFeaturedArray.sort(() => Math.random() - 0.5);
    featuredArray.sort(() => Math.random() - 0.5);
     
    let joinedArray = featuredArray.concat(nonFeaturedArray);
    return joinedArray;
}