import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom"

import { useTranslation } from "react-i18next";
import Layout from "../../../Layout/Layout";

const CharityPartners = () => {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <div className="text-wrapper">
        <h3>{t("Charity partners")}</h3>
        <br />
        <h5>{t("charity partners heading 01")}</h5>
        <br />
        <p>{t("charity partners content 01")}</p>
        <br />
        <h5>{t("charity partners heading 02")}</h5>
        <br />
        <p>{t("charity partners content 02.1")}</p>
        <p>{t("charity partners content 02.2")}</p>
        <br />
        <NavLink exact to="/contact">
          <Button className="btn btn-primary w-50">{t("Contact Us")}</Button>
        </NavLink>
        <br />
        <br />
       
      </div>
    </Layout>
  );
};

export default CharityPartners;
