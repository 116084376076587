import * as types from './index';

export const createCustomerAction = (payload) => {
    return {
        type: types.PAYMENT_CREATE_CUSTOMER,
        payload
    }
};

export const checkoutAction = (payload) => {
    return {
        type: types.PAYMENT_CHECKOUT,
        payload
    }
};

export const gerOrdersAction = (payload) => {
    return {
        type: types.FETCH_ORDERS,
        payload
    }
};

export const setGratitudeMsgAction = (payload) => {
    return {
        type: types.SET_GRATITUDE_MSG,
        payload
    }
};