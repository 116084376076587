import React from "react";
import CookiePolicyES from "./es";

const CookiePolicyZH = () => {
  return (
    <>
     <div className='stc-wrapper'>
      <h4 className='text-center'>Cookie政策</h4>
      <div>
        <p>
          感謝您的關注並訪問我們的平台。您可以在下面找到我們律師事務所的一些法律術語，基本上概述了平台以及我們擁有和運營的其他網站的Cookie政策。如果您對這些條款有任何疑問或疑慮，歡迎您直接與我們聯繫。再次感謝您對Gratitude
          TM 的關注，並與我們一起度過這段時間。
        </p>
        <p className='text-center semi-bold'>Mikey Stojcevski，首席技術官兼全球運營</p>
      </div>
      <hr />
      <p>
        我們使用Cookie來改善您對&nbsp;
        <a href="http://sharegratitude.com">http://sharegratitude.com</a>&nbsp;
        平台以及我們擁有和運營的其他網站的體驗。這個cookie政策是股票感激公司的一部分的隱私政策，並介紹了如何使用您的設備和我們的網站之間的cookie。我們還提供了有關我們可能使用的第三方服務的基本信息，這些第三方服務也可能將Cookie用作其服務的一部分，儘管我們的政策未涵蓋它們。
      </p>
      <p>
        如果您不希望接受我們的cookie，則應指示您的瀏覽器拒絕&nbsp;
        <a href="http://sharegratitude.com/">http://sharegratitude.com</a>&nbsp;
        和我們擁有和運營的其他網站的cookie，但應了解我們可能無法為您提供某些您想要的內容和服務。
      </p>
      <h5>
        什麼是Cookie？
      </h5>
      <p>
        Cookie是網站在您訪問設備時存儲在您設備上的一小部分數據，通常包含有關網站本身的信息，一個唯一的標識符，該標識符使網站在您返回時可以識別您的Web瀏覽器，以及用於以下目的的其他數據：
        cookie，以及cookie本身的壽命。
      </p>
      <p>
        Cookies用於啟用某些功能（例如登錄），跟踪網站使用情況（例如分析），存儲您的用戶設置（例如時區，通知首選項）以及個性化您的內容（例如語言）。
      </p>
      <p>
        您訪問的網站設置的Cookie通常稱為“第一方Cookie”，通常僅跟踪您在該特定網站上的活動。由其他網站和公司（即第三方）設置的Cookie稱為“第三方Cookie
        ”，可用於在使用相同第三方服務的其他網站上跟踪您。
      </p>
      <h5>
        Cookie的類型以及我們的使用方式
      </h5>
      <h6>
        
          <i>基本餅乾</i>
        
      </h6>
      <p>
        基本Cookie對您訪問網站至關重要，它啟用了諸如用戶登錄，帳戶管理，購物車和付款處理之類的核心功能。我們使用必要的cookie來啟用我們網站上的某些功能。
      </p>
      <h6>
        
          <i>性能曲奇</i>
        
      </h6>
      <p>
        效果Cookie用於跟踪您在訪問期間如何使用網站，而不會收集有關您的個人信息。通常，此信息是匿名的，並且與在所有站點用戶之間跟踪的信息進行匯總，以幫助公司了解訪問者的使用方式，識別和診斷用戶可能遇到的問題或錯誤，並在改善受眾整體網站體驗方面做出更好的戰略決策。這些Cookie可以由您訪問的網站（第一方）或第三方服務設置。我們在網站上使用性能cookie。
      </p>
      <h6>
        
          <i>功能性Cookie</i>
        
      </h6>
      <p>
        功能cookie用於收集有關您的設備以及您可以在訪問的網站上配置的任何設置（例如語言和時區設置）的信息。有了這些信息，網站可以為您提供定制，增強或優化的內容和服務。這些Cookie可以由您訪問的網站（第一方）或第三方服務設置。我們將功能cookie用於網站上的選定功能。
      </p>
      <h6>
        
          <i>定位/廣告Cookie</i>
        
      </h6>
      <p>
        定位/廣告Cookie用於確定哪些促銷內容與您和您的興趣更相關和更合適。網站可能會使用它們來投放有針對性的廣告或限制您看到廣告的次數。這可以幫助公司提高其廣告系列的效果以及提供給您的內容的質量。這些Cookie可以由您訪問的網站（第一方）或第三方服務設置。第三方設置的定位/廣告Cookie
        可能會用於在使用相同第三方服務的其他網站上跟踪您。我們在我們的網站上使用定位/廣告Cookie。
      </p>
      <h5>
        我們網站上的第三方Cookie
      </h5>
      <p>
        我們可能會在我們的網站上僱用第三方公司和個人，例如，分析提供商和內容合作夥伴。我們授予這些第三方訪問選定信息的權限，以代表我們執行特定任務。他們還可能設置第三方Cookie
        ，以便提供他們所提供的服務。第三方Cookie可以用來在使用相同第三方服務的其他網站上跟踪您。由於我們無法控制第三方Cookie，因此SHARE
        GRATITUDE COMPANY 的Cookie政策未涵蓋這些內容。
      </p>
      <h5>
        我們的第三方隱私承諾
      </h5>
      <p>我們決不會故意包含損害或侵犯用戶隱私的第三方服務。</p>
      <h5>
        您如何控製或選擇退出Cookie
      </h5>
      <p>
        如果您不希望接受我們的cookie，則可以指示您的瀏覽器拒絕我們網站的cookie。默認情況下，大多數瀏覽器都配置為接受cookie，但是您可以更新這些設置以完全拒絕cookie，或者在網站嘗試設置或更新cookie時通知您。
      </p>
      <p>如果您從多個設備瀏覽網站，則可能需要在每個設備上更新設置。</p>
      <p>
        儘管可以阻止某些cookie，而對您的網站體驗影響不大，但是阻止所有cookie可能意味著您無法訪問所訪問站點的某些功能和內容。
      </p>
      <p>該政策自2020年8月16日起生效。</p>
      <p>
        我們邀請並歡迎您&nbsp;
        <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>聯繫üS ^</a>&nbsp;
        如果你在問候這一政策任何進一步的問題。
      </p>
      </div>
    </>
  );
};

export default CookiePolicyZH;
