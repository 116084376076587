import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Model = ({
  open = false,
  modelHeader = "-",
  modelContent = "-",
  button1 = "Yes",
  button2 = "No",
  trigerFunction = () => {},
  closeModel = () => {},
}) => {
  const handleClose = () => {
    closeModel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{modelHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {modelContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          {button2}
        </Button>
        <Button onClick={trigerFunction} color="primary">
          {button1}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Model;
