import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "./Pages/Header";
import HeaderMsg from "./Pages/HeaderMsg";
import HeaderReply from "./Pages/HeaderReply";
import Footer from "./Pages/Footer";
import SideNav from "./Pages/SideNav";

const Layout = (props) => {
  const { t, i18n } = useTranslation();
  var header;
  if (props.changeHeader.type == "msg")
    header = <div><Header /><HeaderMsg /></div>;
  else if (props.changeHeader.type == "reply")
    header = <div><Header /><HeaderReply
      headerTitle={props.changeHeader.headerTitle}
    /></div>;
  else if (props.changeHeader.type == "reply-impact")
    header = <div><Header /><HeaderReply
      headerTitle={t('Thank you')}
    /></div>;
  else
    header = <Header />;


  return (
    <>
      {header}
      <Container fluid className="pt-80">
        <div className='msg-wrapper'>
          <SideNav />
          <div className='cnt-group'>
            {props.children}
          </div>
        </div>
      </Container>
      <Footer />

    </>
  );
};

const mapStateToProps = (response) => ({
  changeHeader: response.general.changeHeader
});
export default connect(mapStateToProps)(Layout);