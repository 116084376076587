import { put, call } from "redux-saga/effects";
import { fetchRecipientsService, submitGratitudeMsg, trackMyMessageService, RegisterVolunteerService } from "../Services/gratitudeService";
import { connectSocket } from "../Services/socketService";
import { formatRecipients } from "../Utils/FormatData";

import * as types from "../Actions";

export function* fetchRecipientsSaga(payload) {
    try {
        var response = yield call(fetchRecipientsService, payload);
        if (response.success) {
            response = yield call(formatRecipients, response);
            yield put({ type: types.FETCH_RECIPIENTS_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_RECIPIENTS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_RECIPIENTS_FAIL, error });
    }
}

export function* submitGratitudeSaga(payload) {
    try {
        var connect = yield call(connectSocket);
        var response = yield call(submitGratitudeMsg, { payload, connect });

        if (response.success) {
            // response = yield call(formatRecipients, response);
            yield put({ type: types.SUBMIT_GRATITUDE_MSG_SUCCESS, response });
        } else {
            yield put({ type: types.SUBMIT_GRATITUDE_MSG_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.SUBMIT_GRATITUDE_MSG_FAIL, error });
    }


    // try {
    //     var response = yield call(submitGratitudeMsg, payload);
    //     if (response.success) {
    //         response = yield call(formatRecipients, response);
    //         yield put({ type: types.SUBMIT_GRATITUDE_MSG_SUCCESS, response });
    //     } else {
    //         yield put({ type: types.SUBMIT_GRATITUDE_MSG_FAIL, response });
    //     }
    // } catch (error) {
    //     yield put({ type: types.SUBMIT_GRATITUDE_MSG_FAIL, error });
    // }
}

export function* trackMyMessageSaga(payload) {
    try {
        var response = yield call(trackMyMessageService, payload);
        //console.log("responce register", response);
        if (response.data.success) {
            yield put({ type: types.USER_REGISTRATION_SUCCESS, response });
        } else {
            yield put({ type: types.USER_REGISTRATION_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.USER_REGISTRATION_FAIL, error });
    }
}

//register volunteer
export function* RegisterVolunteerSaga(payload) {
    try {
        var response = yield call(RegisterVolunteerService, payload);
        //console.log("responce register", response);
        if (response.data.success) {
            yield put({ type: types.VOLUNTEER_REGISTRATION_SUCCESS, response });
        } else {
            yield put({ type: types.VOLUNTEER_REGISTRATION_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.VOLUNTEER_REGISTRATION_FAIL, error });
    }
}

