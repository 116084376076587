import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";

import SenderDetailsForm from './senderDetailsForm';
import RecipientDetailsForm from './RecipientDetailsForm';
import Confirmation from "./Confirmation";
import { checkUserPrecenseAction } from "../../../../../../Actions/generalAction";
import { getGeocodeByIPaddress } from '../../../../../../Utils/GoogleAPI';
import currencyTypes from '../../../../../../Resources/Files/currencyTypes.json';

const Form = ({
    giftName = "Printed Card",
    giftImg = "img",
    giftDsc = "",
    giftPrice = 0,
    giftPriceList = [],
    ...props
}) => {
    const donationAmount  = 5;
    const initTotalAmount = giftPrice + donationAmount;
    const allowedCurrencyTypes = ['USD', 'EUR', 'AUD', 'GBP', 'CAD', 'NZD'] ;

    const [enableForm, setEnableForm] = useState('senderDetails');

    const [formData, setFormData] = useState({
        senderDetails: {
            firstName  : '',
            lastName   : '',
            email      : '',
            phoneNumber: '',
            message  : props.gratitudeMsg ? props.gratitudeMsg.msg : '',
        },
        recipientDetails: {
            name       : '',
            email      : '',
            phoneNumber: '',
            address    : '',
        },
        giftAmount    : giftPrice.toFixed(2),
        donationAmount: donationAmount,
        totalAmount   : initTotalAmount.toFixed(2),
        acceptCondtion: false,
        currencyCode  : 'USD'
    });

    const [minAmountWarning, enableMinAmountWarning] = useState(false);

    useEffect(() => {
        if (!props.userPrecense) {
            props.checkUserPrecenseAction();
        }

        setCurrencyType();
    },[]);

    useEffect(() => {
        if (props.userPrecense) {
            let senderFirstName = '';
            let senderLastName  = '';
            let senderEmail     = '';

            if (props.userPrecense.user.userName) {
                const senderName = props.userPrecense.user.userName;
                const splitName  = senderName.split(" ");

                senderFirstName = splitName[0];
                senderLastName  = splitName.length > 1 ?
                    splitName[splitName.length - 1] : '';
            }

            if (props.userPrecense.user.userEmail) {
                senderEmail = props.userPrecense.user.userEmail;
            }

            setFormData({
                ...formData,
                senderDetails: {
                    ...formData.senderDetails,
                    message  : props.gratitudeMsg ? props.gratitudeMsg.msg : '',
                    firstName: senderFirstName,
                    lastName : senderLastName,
                    email    : senderEmail,
                }
            });
        }
    }, [props.userPrecense]);

    const setCurrencyType = async () => {
        const geocodeRes = await getGeocodeByIPaddress();
        const { country } = geocodeRes;
  
        const currency = currencyTypes.hasOwnProperty(country) ? currencyTypes[country.toUpperCase()] : "USD";
  
        if (allowedCurrencyTypes.includes(currency)) {
            setFormData({
                ...formData,
                currencyCode: currency
            });
        }
    }

    const changeSenderValue = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            senderDetails: {
                ...formData.senderDetails,
                [name]: value,
            }
        });
    }

    const changeRecipientValue = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            recipientDetails: {
                ...formData.recipientDetails,
                [name]: value,
            }
        });
    }

    const changeGiftAmount = (amount) => {
        if (giftPriceList[0] > amount) {
            amount = giftPriceList[0];
            enableMinAmountWarning(true);
        } else {
            enableMinAmountWarning(false);
        }

        const giftAmount = parseFloat(amount).toFixed(2);
        const total  = parseFloat(giftAmount) + parseFloat(donationAmount);

        setFormData({
            ...formData,
            giftAmount: giftAmount,
            totalAmount: total.toFixed(2)
        });

    }

    const setRecipientAddress = (value) => {
        setFormData({
            ...formData,
            recipientDetails: {
                ...formData.recipientDetails,
                address: value,
            }
        });
    }

    const setAccecptCondition = () => {
        setFormData({
            ...formData,
            acceptCondtion: !formData.acceptCondtion,
        });
    }

    const changeDonationAmount = (donateAmount) => {
        donateAmount = donateAmount ? donateAmount : 0;
        let amount = parseFloat(formData.giftAmount) + parseFloat(donateAmount);

        amount = amount.toFixed(2)

        setFormData({
            ...formData,
            donationAmount: parseFloat(donateAmount),
            totalAmount   : amount,
        });
    }

    const continueWizard = (formName) => {
        setEnableForm(formName);
    }

    return (
        <Fragment>
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 pb-0">
                {
                    enableForm === 'senderDetails' &&
                    <SenderDetailsForm
                        gratitudeMsg={props.gratitudeMsg}
                        giftName={giftName}
                        giftImg = {giftImg}
                        formData={formData}
                        giftDsc={giftDsc}
                        changeValue={changeSenderValue}
                        changeGiftAmount={changeGiftAmount}
                        continueWizard={continueWizard}
                        giftPriceList={giftPriceList}
                        minAmountWarning={minAmountWarning}
                    />
                }
                {
                    enableForm === 'recipientDetails' &&
                    <RecipientDetailsForm
                        gratitudeMsg={props.gratitudeMsg}
                        giftName={giftName}
                        giftImg = {giftImg}
                        giftDsc={giftDsc}
                        formData={formData}
                        changeValue={changeRecipientValue}
                        setRecipientAddress={setRecipientAddress}
                        continueWizard={continueWizard}
                    />
                }
                {
                    enableForm === 'confirmation' &&
                    <Confirmation
                        giftName={giftName}
                        giftImg = {giftImg}
                        giftDsc={giftDsc}
                        formData={formData}
                        changeAmount={changeDonationAmount}
                        editDetails={(editSection) => continueWizard(editSection)}
                        setAccecptCondition={setAccecptCondition}
                    />
                }
            </div>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
      checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
    };
};

const mapStateToProps = (response) => ({
    gratitudeMsg: response.payment.gratitudeMsg,
    userPrecense: response.general.userPrecense.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
