import { put, call } from "redux-saga/effects";
import { fetchWorldCountsService,fetchUserDetailsService } from "../Services/worldService";

import * as types from "../Actions";

export function* fetchWorldCountsSaga(payload) {
  try {
    var response = yield call(fetchWorldCountsService, payload);
    if (response.data.success) {
      yield put({ type: types.FETCH_WORLD_COUNT_SUCCESS, response });
    } else {
      yield put({ type: types.FETCH_WORLD_COUNT_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_WORLD_COUNT_FAIL, error });
  }
}

export function* fetchUserDetailsSaga(payload) {
  try {
    var response = yield call(fetchUserDetailsService, payload);
    if (response.data.success) {
      yield put({ type: types.FETCH_USER_DETAILS_SUCCESS, response });
    } else {
      yield put({ type: types.FETCH_USER_DETAILS_SUCCESS, response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_USER_DETAILS_FAIL, error });
  }
}