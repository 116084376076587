import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import englishLang from "./Resources/Lang/english.json";
import spanishLang from "./Resources/Lang/spain.json";
import chineeseLang from "./Resources/Lang/chineese.json";
import frenchLang from "./Resources/Lang/french.json";
import germanLang from "./Resources/Lang/german.json";
import italianLang from "./Resources/Lang/italian.json";
import japaneseLang from "./Resources/Lang/japanese.json";
import persianLang from "./Resources/Lang/persian.json";
import portugueseLang from "./Resources/Lang/portuguese.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: englishLang,
    },
    es: {
        translation: spanishLang,
    },
    zh: {
        translation: chineeseLang,
    },
    fr: {
        translation: frenchLang,
    },
    de: {
        translation: germanLang,
    },
    it: {
        translation: italianLang,
    },
    ja: {
        translation: japaneseLang,
    },
    fa: {
        translation: persianLang,
    },
    pt: {
        translation: portugueseLang,
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        defaultNS: "translation",
        lng: "en",
        keySeparator: true, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;