import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GiveLittle from "./GiveLittle";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const ThankYouMsg = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();


  const [giveLittle, setGiveLittle] = useState(true);
  const [enableGiveLittleScreen, setEnableGiveLittleScreen] = useState(false);

  const redirectPage = () => {
    if (giveLittle)
      setEnableGiveLittleScreen(true);
    else
      history.push("/");
  }

  return (
    <div className="col-md-12">
      {!enableGiveLittleScreen && (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="input-list no-border">
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 impact-header">
                  <p className='tit'>{t("amazing_your_message_has_been_sent")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="input-list">
              <div className="row">
                <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <label className='sway' for="">{t("I would like to explore")} <span>{t("simple ways to")}<strong>&nbsp;{t("give a little")}</strong></span></label>
                </div>
                <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                  <label className="switch float-right">
                    <input
                      checked={giveLittle ? true : false}
                      onChange={() => setGiveLittle(!giveLittle)}
                      type="checkbox"
                    />{" "}
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            {" "}
            <a
              onClick={() => redirectPage()}
              className={`float-right track active text-uppercase`}
            >
              {giveLittle ? t("Give") : t("Done")}
            </a>
          </div>
        </div>
      )}
      {
        enableGiveLittleScreen &&
        <GiveLittle
          msg={props.msg}
          recipientName={props.recipientName}
        />
      }
    </div>
  );
};

export default ThankYouMsg;
