import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import TransitionsModal from "../../../Common/TransitionsModal";
import RightImg from "../../../../Resources/Images/right.svg";
import FrontLinerList from "../../../Common/FrontLinerList";
import Layout from "../../../Layout/Layout";
import marker from "../../../../Resources/Images/marker.svg";
import SearchLocation from "./SearchLocation";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import { Widget } from "@typeform/embed-react";

import { validateEmail } from "../../../../Utils/Util";
import { Link } from "react-router-dom";
import {
  resetGratitudeMsgAction,
  VolunteerRegistrationAction,
  resetVolunteerRegistrationAction,
} from "../../../../Actions/gratitudeAction";
import {
  getUserInfoAction,
  checkUserPrecenseAction,
  getVolunteerInfoAction,
} from "../../../../Actions/generalAction";

import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
} from "../../../../Utils/GoogleAPI";

import RegistrationSuccess from "./RegistrationSuccess";

const Volunteer = ({ submitMsgID = 0, trackMyMsg = () => {}, ...props }) => {
  const { t, i18n } = useTranslation();

  const [formDisable, setFormDisable] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [openUseOfTerms, setOpenUseOfTerms] = useState(false);
  const [frontLinerDes, setFrontLinerDes] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [frontLineDesAr, setFrontLineDesAr] = useState([
    "I am a caring community member",
  ]);
  const [customDescription, setCustomDescription] = useState("");
  const [location, setLocation] = useState({});
  const [isDoneProfession, setIsDoneProfession] = useState(false);
  const [isVolunteerRegistrationSuccess, setIsVolunteerRegistrationSuccess] =
    useState(false);

  // validation
  const [validName, setValidName] = useState(true);
  const [isvalidEmail, setIsvalidEmail] = useState(true);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    if (!props.userPrecense) {
      props.checkUserPrecenseAction();
    }
  }, []);

  useEffect(() => {
    if (props.userPrecense) {
      // Set browser hash
      if (props.userPrecense.browserHash) {
        localStorage.setItem("browserHash", props.userPrecense.browserHash);
      }
      props.getUserInfoAction();
      props.getVolunteerInfoAction();
    }
  }, [props.userPrecense]);

  useEffect(() => {
    if (props.volunteerInfo) {
      if (props.volunteerInfo.data) {
        setUserName(
          props.volunteerInfo.data.Volunteer.userName
            ? props.volunteerInfo.data.Volunteer.userName
            : ""
        );
        setUserEmail(
          props.volunteerInfo.data.Volunteer.primaryEmail
            ? props.volunteerInfo.data.Volunteer.primaryEmail
            : ""
        );
        setCompanyName(
          props.volunteerInfo.data.Volunteer.companyName
            ? props.volunteerInfo.data.Volunteer.companyName
            : ""
        );
        setLocation({
          description: props.volunteerInfo.data.Volunteer.userLocation
            ? props.volunteerInfo.data.Volunteer.userLocation
            : setDefaultLocation(),
        });
        setFrontLineDesAr(
          props.volunteerInfo.data.Volunteer.profession
            ? props.volunteerInfo.data.Volunteer.profession
            : ["I am a caring community member"]
        );
        setCustomDescription(
          props.volunteerInfo.data.Volunteer.customProfession
            ? props.volunteerInfo.data.Volunteer.customProfession[0]
            : ""
        );
        setAgreeWithTerms(
          props.volunteerInfo.data.Volunteer.userName ? true : false
        );
      }
    }
  }, [props.userInfo, props.volunteerInfo]);

  const setDefaultLocation = () => {
    if (!location.description) {
      // Geo location get by geo code
      const setLatLong = async (geoLocation) => {
        var lat = geoLocation.coords.latitude;
        var long = geoLocation.coords.longitude;
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLoc = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLoc,
          location: [
            {
              name: "world",
              type: "city",
              placeId: 0,
            },
            {
              name: "world",
              type: "country",
              placeId: 0,
            },
          ],
        });
      };

      // Geo location get by ip
      const setByIPaddress = async (error) => {
        var geoData = await getGeocodeByIPaddress();
        var geoCodeAr = geoData.loc.split(",");
        var lat = geoCodeAr[0];
        var long = geoCodeAr[1];
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLocation = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLocation,
        });
      };
      var loc = navigator.geolocation.getCurrentPosition(
        setLatLong,
        setByIPaddress
      );
    }
  };

  useEffect(() => {
    if (userEmail) setValidEmail(validateEmail(userEmail));
  }, [userEmail]);

  const emailValidation = (email) => {
    setUserEmail(email);
    setValidEmail(validateEmail(email));
    setIsvalidEmail(validateEmail(email));
  };

  const VolunteerRegistration = () => {
    const VolunteerData = {
      userName: userName,
      primaryEmail: userEmail,
      profession: frontLineDesAr,
      customProfession: [customDescription],
      companyName: companyName,
      userLocation: location.description,
      browserHash: localStorage.getItem("browserHash").toString(),
      selectedLanguage: props.lang,
    };
    props.volunteerRegistrationAction(VolunteerData);
  };

  useEffect(() => {
    if (props.VolunteerRegistration) {
      if (props.VolunteerRegistration.data) {
        if (props.VolunteerRegistration.data.success) {
          setIsVolunteerRegistrationSuccess(true);
        }
        props.resetVolunteerRegistrationAction();
      } else if (props.VolunteerRegistration.name === "Error") {
        setSeverity("error");
        setErrorMsg("Please check again and re-submit.");
        setErrorNotifification(!errorNotifification);
        props.resetVolunteerRegistrationAction();
      }
    }
  }, [props.VolunteerRegistration]);

  const validateNameField = (name) => {
    var isValidName = name ? true : false;
    setValidName(isValidName);
  };

  const validateFields = () => {
    setIsvalidEmail(validateEmail(userEmail));
    setValidName(userName ? true : false);
  };

  const addDescription = (checked, value) => {
    if (checked) {
      setFrontLineDesAr([...frontLineDesAr, value]);
    } else {
      var index = frontLineDesAr.indexOf(value);
      frontLineDesAr.splice(index, 1);
      setFrontLineDesAr([...frontLineDesAr]);
    }
  };

  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  return (
    <Layout>
      <div className="col-md-12  pb-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="input-list bt-0 pt-0">
              <h3 className="tit mb-3"> {t("REGISTER AS A VOLUNTEER")}</h3>
              <div className="row">
                <div className="typewrap">
                  <Widget
                    id="JruwqHYF"
                    style={{ width: "100%", height: 500 }}
                    className="my-form"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetGratitudeMsgAction: () => dispatch(resetGratitudeMsgAction()),
    resetVolunteerRegistrationAction: () =>
      dispatch(resetVolunteerRegistrationAction()),
    getUserInfoAction: () => dispatch(getUserInfoAction()),
    getVolunteerInfoAction: () => dispatch(getVolunteerInfoAction()),
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
    volunteerRegistrationAction: (data) =>
      dispatch(VolunteerRegistrationAction(data)),
  };
};

const mapStateToProps = (response) => ({
  lang: response.general.lang,
  userInfo: response.general.userInfo,
  submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  userPrecense: response.general.userPrecense.data,
  VolunteerRegistration: response.gratitude.VolunteerRegistration,
  volunteerInfo: response.general.volunteerInfo,
});
export default connect(mapStateToProps, mapDispatchToProps)(Volunteer);
