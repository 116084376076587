export const setLanguage = (lang) => {
    sessionStorage.removeItem('lang');
    sessionStorage.setItem('lang', lang);
};

export const getLanguage = () => {
    var lang = sessionStorage.getItem('lang');
    return lang;
}; 

export const removeLanguage = () => {
    sessionStorage.removeItem('lang');
};