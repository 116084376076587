import React, { Fragment } from "react";
import logo from "../../../../../../Resources/Images/logo-en-f.svg";
import don from "../../../../../../Resources/Images/don.png";

const paymentStatus = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isSuccess = urlParams.get("success");
  const senderName = urlParams.get("senderName");

  const paymentSuccess = () => {
    return (
      <Fragment>
        <img className="logo" src={logo} alt="" />
        <h2 className="tit">Your payment Was successful</h2>

        <div className="d-text">
        <p>
          Dear <strong>{senderName}</strong>,
        </p>
        <p>
          Thank you for choosing ShareGratitude to send your messages & gifts of
          appreciation. We are honoured that you think of us when it comes to
          genuine appreciation of those who have made a genuine impact to you. 
        </p>

        <p>
          Your gesture is being processed, and you will be notified by email of
          its progress.
        </p>

        <p>
          We love our community members, the beautiful people in our lives, the
          caring healthcare and essential services personel who make our every
          day lives so much easier. You are part of this grateful community, and
          we thank you for your help and continued support as we strive to
          improve and innovate ways to share gratitude with those that make us
          feel genuinely grateful. 
        </p>

        <p>
          <strong>{senderName}</strong>, the impact of your gesture is real and
          far-reaching. Together we are making a real difference in people's
          lives, their families, and our community. We're ready to help you with
          any questions you might have. Preferably related to ShareGratitude,
          but we've also been known to give pretty decent Netflix
          recommendations. Just FYI.
        </p>
        <p>
          You can reach me anytime at <a href="mailto:don@sharegratitude.com">
                  {" "}
                  don@sharegratitude.com
                </a> or contact your
          team at <a href="mailto:gratitude@sharegratitude.com">
                  {" "}
                  gratitude@sharegratitude.com
                </a>
        </p>

        <img src={don} alt="" />
              <p className="mb-0 font-weight-bold">
                Don Amal Francis & Dr Jani Warusavitarne
              </p>
              <p>Co-Founders</p>

        <p className="tit char mt-5">ABOUT SG <span>CHARITY COLLECTION</span></p>
        <p>
          Share Gratitude Charities are a collection of inspirational and
          impactful local initiatives from around the world. They are highly
          effective, underfunded and often unknown charities. With a sea of
          charity initiatives globally, it isn't easy to navigate and find those
          worthy of support. Also, many fundraising initiatives have hidden
          costs and charges. </p><p>Like you, we believe 100% of your generosity should
          go towards your chosen cause. The charities are carefully selected to
          ensure they align with this principle and are impactful and dedicated
          to the communities they seek to help. We are constantly researching
          and updating this list to provide you with a simple and easy space to
          donate, stay updated and support the causes you are passionate about.
        </p>
        </div>
      </Fragment>
    );
  };

  const paymentFail = () => {
    return (
      <Fragment>
        <h2>Payment Failed</h2>
        <p>
          Ooops, <b>{senderName}</b>, Your payment has been faild, Try again or
          please contact us at <a href="mailto:gratitude@sharegratitude.com">
                  {" "}
                  gratitude@sharegratitude.com
                </a>
        </p>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="thank pb-5">
        {isSuccess ? paymentSuccess() : paymentFail()}
        <div className="col-sm-4 col-md-4 col-lg-4 float-right mt-3">
          <a
            href={process.env.REACT_APP_LIVE_URL}
            className="btn btn-primary btn-block"
          >
            Back to Share Gratitude
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default paymentStatus;
