import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import GroupImg from "../../../../Resources/Images/Group.svg";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#18a9bd",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#C7C7C7",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const RecipientFilter = ({
  backToMainComponent,
  messageSectors,
  addRecipient,
  removeRecipient,
  recipients,
  header,
}) => {
  const { t, i18n } = useTranslation();
 
  return (
    <>
      {header && (
        <div className="header-wrapper">
          <img src={GroupImg}></img>
      <h3>{t("Filters")} - {t("recipient_type")}</h3>
      <span onClick={backToMainComponent}>{t("back")}</span>
        </div>
      )}
      <div className="filter-menu">
        <div className="input-list fl">
          {messageSectors.map((sector) => {
            return (
              <RecipientFilterItem
                key={sector.value}
                value={sector.value}
                placeholder={sector.placeholder}
                addRecipient={addRecipient}
                removeRecipient={removeRecipient}
                recipients={recipients}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const RecipientFilterItem = ({
  value,
  placeholder,
  addRecipient,
  removeRecipient,
  recipients,
}) => {
  const { t, i18n } = useTranslation();
  const handleChange = (event) => {
    event.target.checked ? addRecipient(value) : removeRecipient(value);
  };
  return (
    <>
      <div className="resp-type">
        <div>{t(placeholder)}</div>
        <IOSSwitch
          checked={recipients.includes(value)}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default RecipientFilter;
