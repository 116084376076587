import React from "react";
import Routes from "./Bootstrap/Routers";
import "./App.scss";
import "./Resources/Style/Main.scss";
import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//   gtmId: 'DC-10340840'
// };
// TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
