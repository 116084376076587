import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from "react-redux";
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";

import {
    fetchMessagesForSearchAction,
    setSearchMessageStringAction,
    clearSearchMessageStringAction,
    setFilteredMessageListAction,
    clearFilteredMessageListAction,
    clearFetchedMessagesForSearchAction,
    setSkipAmmountAction
} from '../../../Actions/communityAction';

const SearchBar = (props) => {
    const { t, i18n } = useTranslation();

    const [isTyping, setIsTyping] = useState(true);
    const [curLimit, setCurLimit] = useState(100);
    const [fetchedAll, setFetchedAll] = useState(false);
    const [text, setText] = useState("");
    const [isFiltering, setIsFiltering] = useState(false);

    const searchTextHandler = (event) => {
        setText(event.target.value);
        props.setSearchMessageStringAction(event.target.value);
    }

    const getMessges = (limit, skip) => {
        let locationIdList = props.locationFilter ? props.locationFilter.map(locObj => locObj.placeId) : [];
        let formData = {
            limit: limit,
            skip: skip,
            locations: locationIdList ? locationIdList : [],
            recipients: props.recipientFilter ? props.recipientFilter : []
        };
        props.fetchMessagesForSearchAction(formData);
    }

    useEffect(() => {
        if (isTyping && props.messageSearchString.length === 1) {
            console.log("typing and getting messages");
            getMessges(curLimit, props.curSkip);
            setIsTyping(false);
        }
        if (!isTyping && props.messageSearchString.length === 0) {
            console.log("cleared message box");
            setIsTyping(true);
            props.setSkipAmmountAction(0);
            props.clearFetchedMessagesForSearchAction();
            props.clearFilteredMessageListAction();
        }
    }, [props.messageSearchString]);

    // const match = () => {
    //     console.log("filtering.....")
    //     const regex = new RegExp(`${props.messageSearchString}`, 'gi');
    //     return props.messageListForSearch.filter(message => {
    //         return message.message.match(regex);
    //     });
    // }

    const messageFeedSearch = useCallback(debounce((messageSearchString, messageListForSearch) => {
        if (messageSearchString.length > 0) {
            // setIsFiltering(true);
            console.log("filtering started.....searching mesages for : " + messageSearchString + ", " + messageListForSearch.length);
            let currentList = messageListForSearch;

            let newList = currentList.filter(item => {

                const message = item.message.toLowerCase();
                const recipientsLocation = item.recipientsLocation.toLowerCase();
                const recipientName = item.recipientName.toLowerCase();
                const authorName = item.author.toLowerCase();
                const authorLocation = item.authorLocation.toLowerCase();

                const filter = messageSearchString.trim().toLowerCase().split(" ");//split the string into an array

                return contains(message, filter)
                    || contains(recipientsLocation, filter)
                    || contains(recipientName, filter)
                    || contains(authorName, filter)
                    || contains(authorLocation, filter)
                    || (item.taggedRecipient.filter(function (tag) {
                        const tagName = tag.text.toLowerCase();
                        return contains(tagName, filter);
                    }).length > 0)

                    || item.locations.length && item.locations.filter(function (location) {
                        const locationName = location.hasOwnProperty("name")
                            ?
                            (typeof location.name === "string")
                                ? location.name.toLowerCase()
                                : location.name.hasOwnProperty("value")
                                    ? location.name.value
                                    : ""
                            : "";
                        return contains(locationName, filter);
                    }).length > 0;
            });
            console.log("new List in search", newList);
            if (messageSearchString.length > 1) {
                props.setFilteredMessageListAction(newList);
                // setIsFiltering(false);
            }
        } else {
            props.clearFilteredMessageListAction();
        }
    }, 2000), [])

    const contains = (target, pattern) => {
        return pattern.some(function (word) {
            let regex = new RegExp(`${word}`, 'gi');
            return regex.test(target);
        });
    }

    useEffect(() => {
        if (props.messageListForSearch.length > 0) {
            //props.setFilteredMessageListAction(match());
            messageFeedSearch(props.messageSearchString, props.messageListForSearch);
        }
    }, [props.messageSearchString, props.messageListForSearch])

    useEffect(() => {
        console.log(props.filteredMessageList);
        if (props.filteredMessageList.length < 10 && props.messageSearchString.length > 1 && !fetchedAll) {
            getMessges(curLimit, props.curSkip);
        }
    }, [props.filteredMessageList])

    useEffect(() => {
        console.log("message list length ==> " + props.messageListForSearch.length);
        console.log("skip " + props.curSkip + " , limit " + curLimit);
        if (props.curSkip === props.messageListForSearch.length && props.messageListForSearch.length !== 0) {
            setFetchedAll(true);
        }
        props.setSkipAmmountAction(props.messageListForSearch.length);
    }, [props.messageListForSearch])

    return (
        <Fragment>
            <input placeholder={t("search_shareGratitude")} className='form-control se mb-3' type="text" onChange={searchTextHandler} />
        </Fragment>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchMessagesForSearchAction: (data) => dispatch(fetchMessagesForSearchAction(data)),
        setSearchMessageStringAction: (data) => dispatch(setSearchMessageStringAction(data)),
        clearSearchMessageStringAction: () => dispatch(clearSearchMessageStringAction()),
        setFilteredMessageListAction: (data) => dispatch(setFilteredMessageListAction(data)),
        clearFilteredMessageListAction: () => dispatch(clearFilteredMessageListAction()),
        clearFetchedMessagesForSearchAction: () => dispatch(clearFetchedMessagesForSearchAction()),
        setSkipAmmountAction: (data) => dispatch(setSkipAmmountAction(data)),
    };
};

const mapStateToProps = (response) => ({
    locationFilter: response.community.locationFilter,
    recipientFilter: response.community.recipientFilter,
    messageListForSearch: response.community.search.messageListForSearch,
    messageSearchString: response.community.search.messageSearchString,
    filteredMessageList: response.community.search.filteredMessageList,
    curSkip: response.community.search.skip,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
