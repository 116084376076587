import * as types from './index';

export const fetchWorldCountAction = () => {
    return {
        type: types.FETCH_WORLD_COUNT
    }
}

export const fetchUserDetailAction = () => {
    return {
        type: types.FETCH_USER_DETAILS
    }
}