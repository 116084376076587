import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TransitionsModal from "../../../Common/TransitionsModal";
import { useHistory } from "react-router-dom";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const ReplyMsgSubmitSuccess = ({
  submitMsgID = 0,
  trackMyMsg = () => { },
  closeWriteReply = () => { },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();
  const [enableWriteMsg, setEnableWriteMsg] = useState(true);

  const redirectTo = () => {
    if (enableWriteMsg)
      history.push("/gratitude");
    else
      closeWriteReply();
  }

  return (
    <div className="col-md-12">
      <span className="ab-btn" onClick={closeWriteReply}>
        {t("skip")}
      </span>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="input-list no-border">
            <div className="row">
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 impact-header">
                <p className='mb-0'>{t("Amazing! Your reply message has been sent")}</p>
              </div>
              {/*
               <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p>{t("We are excited to share with you the impact of your message")}</p>
              </div>
              
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p className="italic">{t("This info isn't required in order to proceed, but simply so we can share with you the impact of your reply message.")}</p>
              </div> */}
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="input-list">
            <div className="row">
              <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <label htmlFor="">{t("Write your very own personalised message of Gratitude")}</label>
              </div>
              <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                <label className="switch float-right">
                  <input
                    checked={enableWriteMsg ? true : false}
                    onChange={() => setEnableWriteMsg(!enableWriteMsg)}
                    type="checkbox"
                  />{" "}
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12">
          {" "}
          <a
            onClick={() => redirectTo()}
            href="#"
            className={`float-right track active`}
          >
            {enableWriteMsg ? t("Write Message") : t("Done")}
          </a>
        </div>
      </div>

      {/* <TransitionsModal
        open={openUseOfTerms}
        closeModel={() => setOpenUseOfTerms(!openUseOfTerms)}
      /> */}
    </div>
  );
};

const mapStateToProps = (response) => ({
  lang: response.general.lang
});
export default connect(mapStateToProps, null)(ReplyMsgSubmitSuccess);
