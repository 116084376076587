
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const SimplePopover = ({
    open = false,
    id = '',
    anchorEl = '',
    content = '',
    anchorOriginVertical = 'center',
    anchorOriginHorizontal = 'right',
    transformOriginVertical = 'bottom',
    transformOriginHorizontal = 'left',
    handleClose = () => { }
}) => {
    const classes = useStyles();
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
                vertical: -8,
                horizontal: anchorOriginHorizontal,
            }}
            transformOrigin={{
                vertical: transformOriginVertical,
                horizontal: transformOriginHorizontal,
            }}
        >
            <Typography className={classes.typography}>{content}</Typography>
        </Popover>
    );
}

export default SimplePopover;
