import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TransitionsModal from "../../../Common/TransitionsModal";
import RightImg from "../../../../Resources/Images/right.svg";
import FrontLinerList from "../../../Common/FrontLinerList";
import Layout from "../../../Layout/Layout";
import marker from "../../../../Resources/Images/marker.svg";
import SearchLocation from "./SearchLocation";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import { Widget } from "@typeform/embed-react";

import { validateEmail } from "../../../../Utils/Util";
import { NavLink, Link } from "react-router-dom";
import {
  resetGratitudeMsgAction,
  userRegistrationAction,
  resetUserRegistrationAction,
} from "../../../../Actions/gratitudeAction";
import {
  getUserInfoAction,
  checkUserPrecenseAction,
} from "../../../../Actions/generalAction";

import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
} from "../../../../Utils/GoogleAPI";

import RegistrationSuccess from "./RegistrationSuccess";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const Registration = ({ submitMsgID = 0, trackMyMsg = () => {}, ...props }) => {
  const { t, i18n } = useTranslation();
  var history = useHistory();

  const [formDisable, setFormDisable] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [writeMsg, setWriteMsg] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [openUseOfTerms, setOpenUseOfTerms] = useState(false);
  // const [giveLittle, setGiveLittle] = useState(true);
  const [frontLinerDes, setFrontLinerDes] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [frontLineDesAr, setFrontLineDesAr] = useState([
    "I am a caring community member",
  ]);
  const [customDescription, setCustomDescription] = useState("");
  const [location, setLocation] = useState({});
  const [isDoneProfession, setIsDoneProfession] = useState(false);
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);

  // validation
  const [validName, setValidName] = useState(true);
  const [isvalidEmail, setIsvalidEmail] = useState(true);

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    if (!props.userPrecense) {
      props.checkUserPrecenseAction();
    }
  }, []);

  useEffect(() => {
    if (props.userPrecense) {
      // Set browser hash
      if (props.userPrecense.browserHash) {
        localStorage.setItem("browserHash", props.userPrecense.browserHash);
      }
      props.getUserInfoAction();
      // setFormDisable(props.userPrecense.isRegistered ? props.userPrecense.isRegistered : false);
    }
  }, [props.userPrecense]);

  useEffect(() => {
    if (props.userInfo) {
      if (props.userInfo.data) {
        //console.log("user location", props.userInfo.data.user.userLocation);
        setUserName(
          props.userInfo.data.user.userName
            ? props.userInfo.data.user.userName
            : ""
        );
        setUserEmail(
          props.userInfo.data.user.primaryEmail
            ? props.userInfo.data.user.primaryEmail
            : ""
        );
        setCompanyName(
          props.userInfo.data.user.companyName
            ? props.userInfo.data.user.companyName
            : ""
        );
        setLocation({
          description: props.userInfo.data.user.userLocation
            ? props.userInfo.data.user.userLocation
            : setDefaultLocation(),
        });
        setFrontLineDesAr(
          props.userInfo.data.user.profession.length > 0
            ? props.userInfo.data.user.profession
            : ["I am a caring community member"]
        );
        setCustomDescription(
          props.userInfo.data.user.customProfession
            ? props.userInfo.data.user.customProfession[0]
            : ""
        );
        setAgreeWithTerms(props.userPrecense.isRegistered ? true : false);
      }
    }
  }, [props.userInfo]);

  const data = `<div data-tf-widget="IpTuNfm6" data-tf-opacity="100" data-tf-iframe-props="title=Subscribe to Collection (SG Website) - No Landing" data-tf-transitive-search-params data-tf-medium="snippet" style="width:100%;height:500px;"></div><script src="//embed.typeform.com/next/embed.js"></script>`;

  const setDefaultLocation = () => {
    if (!location.description) {
      // Geo location get by geo code
      const setLatLong = async (geoLocation) => {
        var lat = geoLocation.coords.latitude;
        var long = geoLocation.coords.longitude;
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLoc = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLoc,
          location: [
            {
              name: "world",
              type: "city",
              placeId: 0,
            },
            {
              name: "world",
              type: "country",
              placeId: 0,
            },
          ],
        });
      };

      // Geo location get by ip
      const setByIPaddress = async (error) => {
        var geoData = await getGeocodeByIPaddress();
        var geoCodeAr = geoData.loc.split(",");
        var lat = geoCodeAr[0];
        var long = geoCodeAr[1];
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLocation = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLocation,
        });
      };
      var loc = navigator.geolocation.getCurrentPosition(
        setLatLong,
        setByIPaddress
      );
    }
  };

  useEffect(() => {
    if (userEmail) setValidEmail(validateEmail(userEmail));
  }, [userEmail]);

  const emailValidation = (email) => {
    setUserEmail(email);
    setValidEmail(validateEmail(email));
    setIsvalidEmail(validateEmail(email));
  };

  const userRegistration = () => {
    const formData = {
      userName: userName,
      primaryEmail: userEmail,
      profession: frontLineDesAr,
      customProfession: [customDescription],
      companyName: companyName,
      userLocation: location.description,
      browserHash: localStorage.getItem("browserHash").toString(),
      selectedLanguage: props.lang,
    };
    props.userRegistrationAction(formData);
  };

  useEffect(() => {
    if (props.userRegistration) {
      if (props.userRegistration.data) {
        if (props.userRegistration.data.success) {
          setIsRegistrationSuccess(true);
        }

        props.resetUserRegistrationAction();
        props.checkUserPrecenseAction();
      } else if (props.userRegistration.name === "Error") {
        setSeverity("error");
        setErrorMsg("Please check again and re-submit.");
        setErrorNotifification(!errorNotifification);
        props.resetUserRegistrationAction();
      }
    }
  }, [props.userRegistration]);

  // useEffect(() => {
  //   if (props.userInfo) {
  //     if (props.userInfo.data) {
  //       var nameOfSender = props.userInfo.data.user ? props.userInfo.data.user.userName : '';
  //       var emailOfSender = props.userInfo.data.user.messageEmail && props.userInfo.data.user.messageEmail[1] ? props.userInfo.data.user.messageEmail[1] : '';
  //       setUserName(nameOfSender);
  //       setUserEmail(emailOfSender);
  //       setValidEmail(validateEmail(emailOfSender));
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  // }, [props.trackMyMsg]);

  const validateNameField = (name) => {
    var isValidName = name ? true : false;
    setValidName(isValidName);
  };

  const validateFields = () => {
    //console.log('email validation');
    setIsvalidEmail(validateEmail(userEmail));
    setValidName(userName ? true : false);
  };

  const addDescription = (checked, value) => {
    if (checked) {
      setFrontLineDesAr([...frontLineDesAr, value]);
    } else {
      var index = frontLineDesAr.indexOf(value);
      frontLineDesAr.splice(index, 1);
      setFrontLineDesAr([...frontLineDesAr]);
    }
  };

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  return (
    <Layout>
      <div className="col-md-12  pb-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="input-list bt-0 pt-0">
              <h3 className="tit mb-3"> {t("subscribe")}</h3>
              <div className="row">
                <div className="typewrap">
                  <Widget
                    id="JruwqHYF"
                    style={{ width: "100%", height: 500 }}
                    className="my-form"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetGratitudeMsgAction: () => dispatch(resetGratitudeMsgAction()),
    userRegistrationAction: (data) => dispatch(userRegistrationAction(data)),
    resetUserRegistrationAction: () => dispatch(resetUserRegistrationAction()),
    getUserInfoAction: () => dispatch(getUserInfoAction()),
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
  };
};

const mapStateToProps = (response) => ({
  lang: response.general.lang,
  userInfo: response.general.userInfo,
  userRegistration: response.gratitude.userRegistration,
  submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  userPrecense: response.general.userPrecense.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(Registration);
