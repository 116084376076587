import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { NavLink, Link } from "react-router-dom";
// import {
//     trackMyMsgAction
// } from '../../../../Actions/gratitudeAction';

const FrontLinerList =
  ({
    backToMain = () => { },
    addedDescription = [],
    addDescription = () => { },
    addCustomDescription = () => { },
    customDescription = ''
  }) => {

    const { t, i18n } = useTranslation();
    const descriptionAr = [
      { "name": t("I am a caring community member"), "value": "I am a caring community member" },
      { "name": t("Frontline worker"), "value": "Frontline worker" },
      { "name": t("Essential services worker"), "value": "Essential services worker" },
      { "name": t("Emergency services worker"), "value": "Emergency services worker" },
      { "name": t("Community services worker"), "value": "Community services worker" },
      { "name": t("Public services worker"), "value": "Public services worker" },
      { "name": t("Small business operator"), "value": "Small business operator" },
      { "name": t("I am interested in volunteering"), "value": "I am interested in volunteering" }
    ];

    return (
      <div className="col-md-12 pl-0 pr-0">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
            <div className="input-list no-border">
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p>
                    {t(
                      "Please select one or more that best describes you"
                    )}
                    <span onClick={backToMain} className="bk-btn">
                      {t("Back")}
                    </span>
                  </p>

                </div>
              </div>
            </div>
          </div>

          {descriptionAr.map((desc) => (
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list">
                <div className="row">
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <label for="">{desc.name}</label>
                  </div>
                  <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                    <label className="switch float-right">
                      <input
                        disabled={desc.value === "I am a caring community member" ? true : false}
                        checked={addedDescription.includes(desc.value)}
                        onClick={(e) => addDescription(e.target.checked, desc.value)}
                        type="checkbox"
                      />{" "}
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
          )}

          <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
            <div className="input-list bb">
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                  <input
                    value={customDescription}
                    type="text"
                    onChange={(e) => addCustomDescription(e.target.value)}
                    className="form-control form-control-sm pl-3"
                    placeholder={t("Other") + "..."}
                  />
                </div>
              </div>
            </div>
          </div>
          <span onClick={backToMain} className="done-btn">
                      {t("Done")}
                    </span>
        </div>

      </div >
    );
  };

export default FrontLinerList;
