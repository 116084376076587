import S3 from 'react-aws-s3';
import VideoSnapshot from 'video-snapshot';
const MODARATION_PRESENTAGE = 30;
const MODERATION_USER = process.env.REACT_APP_MODERATION_API_USER;
const MODERATION_SECRET = process.env.REACT_APP_MODERATION_API_SECRET;

export const CheckImageModeration = async (img) => {
    var sightengine = require('sightengine')(MODERATION_USER, MODERATION_SECRET);
    return await sightengine.check(['nudity', 'wad']).set_url(img.location).then(async function (result) {
        // read the output (result)
        let weaponContent = result.weapon * 100;
        let alcoholContent = result.alcohol * 100;
        let nuditySafe = result.nudity.safe * 100;

        if (weaponContent > MODARATION_PRESENTAGE || alcoholContent > MODARATION_PRESENTAGE || nuditySafe < MODARATION_PRESENTAGE) {
            DeleteFileFromS3(img.key); // Delete file from s3 bucket
            return { result: false, msg: "You can't upload inappropiate images.Please select differnt media file." }
        } else {
            return { result: true }
        }
    }).catch(function (err) {
        DeleteFileFromS3(img.key); // Delete file from s3 bucket
        return { result: false, msg: "Something went wrong...!" };
    });
}

export const CheckVideoModeration = async (video) => {
    let weaponContent = 0;
    let alcoholContent = 0;
    let nuditySafe = 0;
    let res = {};

    var sightengine = require('sightengine')(MODERATION_USER, MODERATION_SECRET);
    return await sightengine.check(['nudity', 'wad']).video_sync(video.location).then(async function (result) {
        // return { result: true, msg: "You can't upload inappropiate video. Please select differnt media file." };

        if (result.status === 'success') {
            result.data.frames.map((frame) => {
                weaponContent = frame.weapon * 100;
                alcoholContent = frame.alcohol * 100;
                nuditySafe = frame.nudity.safe * 100;

                if (weaponContent > MODARATION_PRESENTAGE || alcoholContent > MODARATION_PRESENTAGE || nuditySafe < MODARATION_PRESENTAGE) {
                    DeleteFileFromS3(video.key); // Delete file from s3 bucket
                    res = { result: false, msg: "You can't upload inappropiate video. Please select differnt media file." }
                    return;
                } else {
                    res = { result: true }
                }
            });
        } else {
            DeleteFileFromS3(video.key); // Delete file from s3 bucket
            if (result.error.code == 710)
                res = { result: false, msg: "Video duration is too long. (Max: 60 sec)" };
            else
                res = { result: false, msg: "File upload process failed" };
        }

        if (res.result === true) { // If media medaration is fine, upload thumbnail to s3 bucket
            var thumbUploadRes = await UploadFileToS3(video.thumb, 'thumbnail');
            res.thumbnails3Key = thumbUploadRes.key;
        }
        return res;
    }).catch(function (err) {
        // handle the error
        return err;
    });
}

export const UploadFileToS3 = async (file, type) => {
    var thumbImg = '';
    if (type == 'video') { // Create thumbnail for video file
        const snapshoter = new VideoSnapshot(file);
        const previewSrc = await snapshoter.takeSnapshot(); // Thumbnail base64
        var thumbImgName = `${Math.random()}-${file.lastModified}-thumb.png`;
        var thumbImg = await base64ToFile(previewSrc, thumbImgName, 'image/png'); // base64 file convert to image file
    }

    const S3config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: type == 'thumbnail' ? 'thumbnail' : type == 'image' ? 'images' : 'videos',
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    }

    const ReactS3Client = new S3(S3config);
    var fileName = `${Date.now()}-${Math.random()}-${file.lastModified}`
    return ReactS3Client
        .uploadFile(file, fileName)
        .then((data) => {
            if (thumbImg)
                data.thumb = thumbImg;
            return data
        })
        .catch((err) => { return err });
}

const DeleteFileFromS3 = async (file) => {
    const S3config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }

    const ReactS3Client = new S3(S3config);
    return await ReactS3Client
        .deleteFile(file)
        .then(response => { return response; })
        .catch(err => { return err; });
}

const base64ToFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}