import React from "react";

import Layout from "../../../../Layout/Layout";
import TermsOfServiceIndex from "./TermsOfService"

const TermsOfService = () => {
  return (
    <Layout>
     <TermsOfServiceIndex/>
    </Layout>
  );
};

export default TermsOfService;