import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
// import RangeSlider from "react-bootstrap-range-slider";
import DonationSelector from './DonationSelector';
import Stripel from '../../../../../../Resources/Images/stripe.png'
import {
  createCustomerAction,
  checkoutAction,
} from '../../../../../../Actions/paymentAction';

const Confirmation = ({
  giftImg = "",
  giftName = "",
  giftDsc = "",
  formData = {},
  changeAmount = () => {},
  editDetails = () => {},
  setAccecptCondition = () => {},
  ...props
}) => {
  const { senderDetails, recipientDetails } = formData;

  useEffect(() => {
    if (props.checkoutSession.success) {
      window.location = props.checkoutSession.paymentUrl;
    } else {
      // Do something here
    }
  }, [props.checkoutSession]);

  const checkoutPayment = () => {
    const checkoutData = {
      browserHash: localStorage.getItem("browserHash").toString(),
      senderDetails: senderDetails,
      recipientDetails: recipientDetails,
      totalAmount: formData.totalAmount,
      giftType: giftName,
      giftAmount: formData.giftAmount,
      donationAmount: formData.donationAmount,
      currencyCode: formData.currencyCode,
    };

    props.checkoutAction(checkoutData);
  };

  return (
    <Fragment>
      <div className="giftwrapper">
        <div className="giftvideo">
          <img src={giftImg} alt="" />
        </div>
        <div className="giftdsc">
          <h3>
            {giftName} for {recipientDetails.name}
          </h3>
          {/* <small>from {senderDetails.firstName}</small> */}
          <p>{giftDsc}</p>
          <h2>{formData.giftAmount} {formData.currencyCode}</h2>
          <small>One all-inclusive price in {formData.currencyCode} includes gift purchase, postage & handling, transaction fees, and applicable taxes.</small>
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-sm-6 col-md-6 col-lg-6">
          <div className="rd">
            <h3>Recipient Details</h3>

            <p>{recipientDetails.name}</p>
            <p>{recipientDetails.email ? recipientDetails.email : null}</p>
            <p>{recipientDetails.phoneNumber ? recipientDetails.phoneNumber : null}</p>
            <p>
              {recipientDetails.address}
            </p>
            <p>{recipientDetails.country}</p>

            <a
              onClick={() => editDetails("recipientDetails")}
              href="#"
              className="avtive"
            >
              Edit
            </a>
          </div>
        </div>
        <div className="col-sm-5 col-md-5 col-lg-5">
          <div className="sd">
            <h3>Your Details</h3>
            <p>
              {" "}
              {`${senderDetails.firstName}
              ${senderDetails.lastName}`}
            </p>
            <p>{senderDetails.email}</p>
            <p>{senderDetails.phoneNumber ? senderDetails.phoneNumber : null}</p>
            <a
              onClick={() => editDetails("senderDetails")}
              href="#"
              className="active"
            >
              Edit
            </a>
          </div>
        </div>
      </div>
      <div className="gifmsg">
        <p>{senderDetails.message}</p>
        <a
          onClick={() => editDetails("senderDetails")}
          href="#"
          className="avtive"
        >
          Edit Message
        </a>
      </div>
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <h4 className="tit">SUPPORT SHARE GRATITUDE</h4>
       <div>
         <p>ShareGratitude does not charge any fees or keep a profit margin. 100% of your generosity will go towards facilitating your gift or chosen cause. We ask that you help us in any way that you can, be that a pledge to help us operate the platform, volunteer, or promote the platform to your friends and family.</p>
       {/* <p>Like you, we believe 100% of your generosity should be enjoyed by the recipient or go towards supporting your chosen cause. When you donate, your chosen charity will process your donation and directly provide a tax receipt. And when you gift, a small local business near your recipient helps facilitate your gift.</p> */}
       </div>
        <DonationSelector
          changeAmount={changeAmount}
          currencyCode={formData.currencyCode}
          donationAmount={formData.donationAmount}
        />
      </div>
      </div>
 
      <div className="row mt-3">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <h2 className="giftot">Total : {formData.currencyCode} {formData.totalAmount}</h2>
      </div>
      </div>

      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <label className="">
          <input
            checked={formData.acceptCondtion}
            onChange={() => setAccecptCondition()}
            type="checkbox"
            className="mr-2"
          />
          <span>
            I have read and agree to the Terms and Conditions and Privacy
            Policy.
          </span>
        </label>
      </div>
      <div className="mt-2">
      <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="str">
        <img src={Stripel} alt="" />
      Your payment details are submitted securely with Stripe, one of the world's leading payment processors.
      </div>
      </div>
      </div>
      </div>
      
      <div className="">
          <div className="text-right">
        <a
          onClick={() => checkoutPayment()}
          href="#"
          className={`
                        float-right
                        track
                        btn
                        btn-secondary
                        ${formData.acceptCondtion ? "active" : "inactive"}
                    `}
        >
          CONTINUE TO CHECKOUT
        </a>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCustomerAction: (payload) => dispatch(createCustomerAction(payload)),
    checkoutAction: (payload) => dispatch(checkoutAction(payload)),
  };
};

const mapStateToProps = (response) => ({
  createdCustomer: response.payment.createdCustomer,
  checkoutSession: response.payment.checkoutSession,
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
