import * as types from './index';

export const fetchMessagesAction = (payload) => {
    return {
        type: types.FETCH_MESSAGES,
        payload
    }
};

export const resetFetchMessagesAction = () => {
    return {
        type: types.FETCH_MESSAGES_RESET
    }
};

export const makePromoteMsgAction = (payload) => {
    return {
        type: types.MAKE_PROMOTE_MSG,
        payload
    }
}

export const resetMakePromoteAction = () => {
    return {
        type: types.RESET_MAKE_PROMOTE_MSG
    }
}

export const reportMsgAction = (payload) => {
    return {
        type: types.REPORT_MESSAGE,
        payload
    }
}

export const resetReportMsgAction = () => {
    return {
        type: types.RESET_REPORT_MESSAGE
    }
}

export const fetchSingleMsgAction = (payload) => {
    return {
        type: types.FETCH_SINGLE_MESSAGE,
        payload
    }
}

export const listenUpdatedSupportCountAction = () => {
    return {
        type: types.LISTEN_UPDATED_SUPPORT_COUNT
    }
}

export const listenUpdatedReportCountAction = () => {
    return {
        type: types.LISTEN_UPDATED_REPORT_COUNT
    }
}

export const listenNewMsgAction = () => {
    return {
        type: types.LISTEN_NEW_MSG
    }
}

export const submitReplyMsgAction = (payload) => {
    return {
        type: types.SUBMIT_REPLY_MSG,
        payload: payload
    }
}

export const fetchReplyListAction = (payload) => {
    return {
        type: types.FETCH_REPLY_LIST,
        payload: payload
    }
}

export const clearReplyListAction = (payload) => {
    return {
        type: types.CLEAR_REPLY_LIST,
        payload: payload
    }
}

export const listenUpdatedReplyCountAction = () => {
    return {
        type: types.LISTEN_REPLY_COUNT,
    }
}

export const listenNewReplyAction = (payload) => {
    return {
        type: types.LISTEN_NEW_REPLY,
        payload: payload
    }
}

export const makeSupportReplyAction = (payload) => {
    return {
        type: types.SUPPORT_REPLY,
        payload: payload
    }
}

export const makeReportReplyAction = (payload) => {
    return {
        type: types.REPORT_REPLY,
        payload: payload
    }
}

export const listenUpdatedReplySupportCountAction = (payload) => {
    return {
        type: types.LISTEN_REPLY_SUPPORT_COUNT,
        payload: payload
    }
}

export const listenUpdatedReplyReportCountAction = (payload) => {
    return {
        type: types.LISTEN_REPLY_REPORT_COUNT,
        payload: payload
    }
}

export const setFilterLocationAction = (payload) => {
    return {
        type: types.SET_FILTER_LOCATION,

        payload
    }
}

export const removeFilterLocationAction = (payload) => {
    return {
        type: types.REMOVE_FILTER_LOCATION,
        payload
    }
}

export const setFilterRecipientAction = (payload) => {
    return {
        type: types.SET_FILTER_RECIPIENT,
        payload
    }
}

export const removeFilterRecipientAction = (payload) => {
    return {
        type: types.REMOVE_FILTER_RECIPIENT,
        payload
    }
}

export const setInitialRecipientFlterAction = (payload) => {
    return {
        type: types.SET_INITIAL_RECIPIENT_FILTER,
        payload
    }
}

export const translateSingleMsgAction = (payload) => {
    return {
        type: types.TRANSLATE_SINGLE_MESSAGE,
        payload: payload
    }
}

export const resetTranslationMsgAction = () => {
    return {
        type: types.TRANSLATE_MESSAGE_RESET
    }
}

export const trackMyReplyMsgAction = (payload) => {
    return {
        type: types.TRACK_MY_REPLY_MESSAGE,
        payload
    }
}

export const resetReplyMsgAction = (payload) => {
    return {
        type: types.RESET_SUBMIT_REPLY_MSG,
        payload
    }
}

export const resetUpdatedSupportCountAction = (payload) => {
    return {
        type: types.RESET_UPDATED_SUPPORT_COUNT,
        payload
    }
}

export const resetUpdatedReportCountAction = (payload) => {
    return {
        type: types.RESET_UPDATED_REPORT_COUNT,
        payload
    }
}

export const resetUpdatedReplyCountAction = (payload) => {
    return {
        type: types.RESET_UPDATED_REPLY_COUNT,
        payload
    }
}

export const translateReplyMsgAction = (payload) => {
    return {
        type: types.TRANSLATE_REPLY_MESSAGE,
        payload: payload
    }
}

export const resetTranslationReplyMsgAction = () => {
    return {
        type: types.TRANSLATE_REPLY_MESSAGE_RESET
    }
}

export const resetTrackReplyMsgAction = () => {
    return {
        type: types.RESET_TRACK_REPLY_MSG
    }
}

export const fetchMessageSectorAction = () => {
    return {
        type: types.FETCH_MESSAGE_SECTORS
    }
}

export const fetchSingleReplyAction = (payload) => {
    return {
        type: types.FETCH_SINGLE_REPLY_MSG,
        payload
    }
}

export const translateSingleReplyMsgAction = (payload) => {
    return {
        type: types.TRANSLATE_SINGLE_REPLY_MESSAGE,
        payload: payload
    }
}

export const resetReplyPromoteResetAction = () => {
    return {
        type: types.SUPPORT_REPLY_RESET
    }
}

export const resetReplyReportAction = () => {
    return {
        type: types.REPORT_REPLY_RESET
    }
}

export const shareEmailMsgAction = (payload) => {
    return {
        type: types.SHARE_EMAIL_MESSAGE,
        payload: payload
    }
}

export const setDittoMsgContent = (data) => {
    return {
        type: types.SET_DITTO_MSG_CONTENT,
        response: data
    }
}

export const setDittoReplyMsgContent = (data) => {
    return {
        type: types.SET_DITTO_REPLY_MSG_CONTENT,
        response: data
    }
}

export const resetDittoMsgContent = (data) => {
    return {
        type: types.RESET_DITTO_MSG_CONTENT,
        response: data
    }
}

export const resetDittoReplyMsgContent = (data) => {
    return {
        type: types.RESET_DITTO_REPLY_MSG_CONTENT,
        response: data
    }
}

export const listenUpdatedDittoCountAction = () => {
    return {
        type: types.LISTEN_UPDATED_DITTO_COUNT
    }
}

export const listenUpdatedReplyDittoCountAction = () => {
    return {
        type: types.LISTEN_UPDATED_DITTO_REPLY_COUNT
    }
}

export const fetchMessagesForSearchAction = (payload) => {
    return {
        type: types.FETCH_MESSAGES_FOR_SEARCH,
        payload
    }
}

export const setSearchMessageStringAction = (payload) => {
    return {
        type: types.SET_MESSAGE_SEARCH_STRING,
        payload
    }
}

export const clearSearchMessageStringAction = () => {
    return {
        type: types.CLEAR_MESSAGE_SEARCH_STRING,
    }
}

export const setFilteredMessageListAction = (payload) => {
    return {
        type: types.SET_FILTERED_MESSAGE_LIST,
        payload
    }
}

export const clearFilteredMessageListAction = () => {
    return {
        type: types.CLEAR_FILTERED_MESSAGE_LIST,
    }
}

export const clearFetchedMessagesForSearchAction = () => {
    return {
        type: types.CLEAR_FETCHED_MESSAGES_FOR_SEARCH,
    }
}

export const setSkipAmmountAction = (payload) => {
    return {
        type: types.SET_SKIP_FOR_SEARCH,
        payload
    }
}

export const donationRegistrationAction = (payload) => {
    return {
        type: types.DONATION_REGISTRATION,
        payload
    }
}

export const resetUserDonationAction = () => {
    return {
        type: types.DONATION_REGISTRATION_RESET
    }
}