import * as types from './index';

export const setLangAction = (payload) => {
    return {
        type: types.SET_LANG,
        payload
    }
};

export const changeHeaderAction = (payload) => {
    return {
        type: types.CHANGE_HEADER,
        payload
    }
};

export const checkUserPrecenseAction = (payload) => {
    return {
        type: types.CHECK_PRECENSE,
        payload
    }
};

export const setTourGuideIsViewAction = () => {
    return {
        type: types.SET_TOUR_GUIDE_IS_VIEW
    }
};

export const resetTourGuideIsViewAction = () => {
    return {
        type: types.RESET_TOUR_GUIDE_IS_VIEW
    }
}

export const setUserVisitBeforeAction = (response) => {
    return {
        type: types.SET_USER_VISIT_BEFORE,
        response: response
    }
}

export const getUserInfoAction = () => {
    return {
        type: types.GET_USER_INFO
    }
}
export const getVolunteerInfoAction = () => {
    return {
        type: types.GET_VOLUNTEER_INFO
    }
}


