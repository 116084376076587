import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core/";
import { connect } from "react-redux";

import {
  fetchWorldCountAction,
  fetchUserDetailAction,
} from "../../../../Actions/worldAction";
import { checkUserPrecenseAction } from "../../../../Actions/generalAction";
import loading from "../../../../Resources/Images/worldLoading.svg";
import CityDataModel from "./CityDataModel";
import { emailList } from "../../../../Resources/Files/emailList";

import Layout from "../../../Layout/Layout";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const countryHeadCells = [
  { id: "country", label: "Name of the Country" },
  { id: "msgCount", label: "Number of Messages" },
];

const userHeadCells = [
  { id: "name", label: "User Name" },
  { id: "email", label: "Email" },
];
const Report = ({ ...props }) => {
  const [order, setOrder] = React.useState(["desc", "desc"]);
  const [orderBy, setOrderBy] = React.useState(["msgCount", "msgCount"]);
  const [cityModelOpn, setCityModelOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [selectedCityList, setSelectedCityList] = React.useState([]);

  const handleModelClose = () => {
    setCityModelOpen(false);
  };

  const handleModelOpen = (data) => {
    setSelectedCountry(data);
    const list = props.worldViewData.data.cityData.filter(
      (city) =>
        city.countryPlaceId === data.placeId &&
        typeof city.msgCount !== "undefined"
    );
    const count = list.reduce((counter, { msgCount }) => counter + msgCount, 0);
    const cityList =
      data.msgCount > count
        ? [
            ...list,
            {
              city: "( Messages without city data )",
              msgCount: data.msgCount - count,
            },
          ]
        : list;
    setSelectedCityList(cityList);
    setCityModelOpen(true);
  };

  const handleRequestSort = (property, tableId) => {
    const isAsc = orderBy[tableId] === property && order[tableId] === "asc";
    const oldOrder = [...order];
    oldOrder[tableId] = isAsc ? "desc" : "asc";
    const newOrder = oldOrder;
    setOrder(newOrder);
    const oldOrderBy = [...orderBy];
    oldOrderBy[tableId] = property;
    const newOrderBy = oldOrderBy;
    setOrderBy(newOrderBy);
  };

  useEffect(() => {
    props.checkUserPrecenseAction();
  }, []);

  useEffect(() => {
    if (props.loggedInUserEmail) props.fetchWorldCountAction();
  }, [props.loggedInUserEmail]);

  useEffect(() => {
    props.fetchUserDetailAction();
  }, []);

  const compareEmail = () => {
    if (!props.loggedInUserEmail) return false;
    if (emailList.includes(props.loggedInUserEmail)) {
      return true;
    } else return false;
  };

  return props.loggedInUserEmail && compareEmail() ? (
    <Layout>
      <div>
        {props.worldViewData.isLoading ? (
          <center>
            <img src={loading}></img>
          </center>
        ) : (
          <>
            <h4>Basic Overview</h4>
            <Paper>
              <TableContainer>
                <Table style={{ width: "100%" }}>
                  <TableBody>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total messages
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.msgData.totalMsgs}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total likes for messages
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.msgData.totalLikes}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total Ditto count for messages
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.msgData.totalDitto}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total report count of messages
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.msgData.totalReports}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total replies
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.replyData.totalreplies}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total likes for replies
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.replyData.totalLikes}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total Ditto count for replies
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.replyData.totalDittos}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total report count of replies
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.replyData.totalReports}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total countries
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {[...props.worldViewData.data.countryData].length}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total cities
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {[...props.worldViewData.data.cityData].length}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total visited users
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {props.worldViewData.data.usersData.totalVisitedUsers}
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ width: "50%" }}>
                        Total registered users
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {
                          props.worldViewData.data.usersData
                            .totalRegisteredUsers
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <br />
            <h4>Country Data</h4>
            <CityDataModel
              openState={cityModelOpn}
              onCloseHandler={handleModelClose}
              countryData={selectedCountry}
              cityData={selectedCityList}
            />
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ width: "5%" }}
                        align="left"
                        padding={"default"}
                      >
                        {""}
                      </TableCell>
                      {countryHeadCells.map((headCell) => (
                        <TableCell
                          style={{ width: "45%" }}
                          key={headCell.id}
                          padding={"default"}
                          sortDirection={
                            orderBy[0] === headCell.id ? order[0] : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy[0] === headCell.id}
                            direction={
                              orderBy[0] === headCell.id ? order[0] : "asc"
                            }
                            onClick={() => handleRequestSort(headCell.id, 0)}
                          >
                            <b>{headCell.label}</b>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(
                      props.worldViewData.data.countryData.filter((country) => {
                        return typeof country.msgCount !== "undefined";
                      }),
                      getComparator(order[0], orderBy[0])
                    ).map((row, index) => {
                      return row.msgCount ? (
                        <TableRow
                          role="button"
                          hover
                          onClick={() => handleModelOpen(row)}
                          key={`row.country_${index}`}
                        >
                          <TableCell
                            style={{ width: "5%" }}
                            align="left"
                            padding={"default"}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ width: "45%" }}>
                            {row.country}
                          </TableCell>
                          <TableCell
                            style={{ width: "50%" }}
                            align="left"
                            padding={"default"}
                          >
                            {row.msgCount}
                          </TableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <br />
            {/* <h4>User Data</h4>

            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ width: "5%" }}
                        align="left"
                        padding={"default"}
                      >
                        {""}
                      </TableCell>
                      {userHeadCells.map((headCell) => (
                        <TableCell
                          style={{ width: "45%" }}
                          key={headCell.id}
                          padding={"default"}
                          sortDirection={
                            orderBy[0] === headCell.id ? order[0] : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy[0] === headCell.id}
                            direction={
                              orderBy[0] === headCell.id ? order[0] : "asc"
                            }
                            onClick={() => handleRequestSort(headCell.id, 0)}
                          >
                            <b>{headCell.label}</b>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.userData.data !== undefined
                      ? props.userData.data.userDataList.map((row, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                style={{ width: "5%" }}
                                align="left"
                                padding={"default"}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell style={{ width: "45%" }}>
                                {row.userName}
                              </TableCell>
                              <TableCell
                                style={{ width: "50%" }}
                                align="left"
                                padding={"default"}
                              >
                                {row.primaryEmail}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper> */}
          </>
        )}
      </div>
    </Layout>
  ) : null;
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorldCountAction: () => dispatch(fetchWorldCountAction()),
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
    fetchUserDetailAction: () => dispatch(fetchUserDetailAction()),
  };
};

const mapStateToProps = (response) => ({
  worldViewData: response.world.worldCount,
  loggedInUserEmail:
    typeof response.general.userPrecense.data === "undefined"
      ? false
      : response.general.userPrecense.data.user.userEmail,
  userData: response.world.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
