import React from "react";

const PrivacyPolicyES = () => {
  return (
    <>
     <div className='stc-wrapper'>
        <h4 className='text-center'>Política de privacidad</h4>
        <p className='text-center'>
          Gracias por su interés y visitar nuestra plataforma. A continuación,
          encontrará una jerga legal de nuestro bufete de abogados, que
          establece lo obvio en el sentido de que no haremos mal uso de su
          información personal y cumpliremos con las leyes en relación con la
          privacidad. En pocas palabras, realmente respetamos su privacidad y le
          invitamos a que se ponga en&nbsp;
          <a href="https://about.sharegratitude.com/contact/" target="_blank">
            contacto con nosotros
          </a>
          &nbsp; si tiene alguna inquietud o necesita más detalles sobre esta
          política. Nuevamente, gracias por su interés en Share Gratitude TM y
          por pasar este tiempo con nosotros.
        </p>
        <p className='text-center semi-bold'>
          Mikey Stojcevski, director técnico y operaciones globales
        </p>
        <hr />
        <p>
          Su privacidad es importante para nosotros. Que es la proporción
          GRATITUD EMPRESA ‘s política de respetar su privacidad con respecto a
          cualquier información que podemos obtener de usted a través de nuestra
          plataforma de&nbsp;
          <a href="http://sharegratitude.com/">http://sharegratitude.com</a>
          ,&nbsp;y otros sitios que poseemos y operamos.
        </p>
        <p className='bold'>1. Información que recopilamos</p>
        <p>Dato de registro</p>
        <p>
          Cuando visita nuestro sitio web, nuestros servidores pueden registrar
          automáticamente los datos estándar proporcionados por su navegador
          web. Puede incluir la dirección de Protocolo de Internet (IP) de su
          computadora, el tipo y la versión de su navegador, las páginas que
          visita, la hora y fecha de su visita, el tiempo dedicado a cada página
          y otros detalles.
        </p>
        <p>Datos del dispositivo</p>
        <p>
          También podemos recopilar datos sobre el dispositivo que está
          utilizando para acceder a nuestro sitio web. Estos datos pueden
          incluir el tipo de dispositivo, el sistema operativo, los
          identificadores únicos del dispositivo, la configuración del
          dispositivo y los datos de ubicación geográfica. Lo que recopilamos
          puede depender de la configuración individual de su dispositivo y
          software. Recomendamos consultar las políticas del fabricante de su
          dispositivo o proveedor de software para saber qué información nos
          ponen a disposición.
        </p>
        <p>Informacion personal</p>
        <p>Podemos pedirle información personal, como su:</p>
        <ul type="disc">
          <li>Nombre</li>
          <li>Email</li>
          <li>Perfiles de redes sociales</li>
          <li>Número de teléfono / móvil</li>
          <li>Inicio / Dirección postal</li>
          <li>Dirección de trabajo</li>
          <li>Información del pago</li>
        </ul>
        <br />
        <p className='bold'>2. Bases legales para el procesamiento</p>
        <p>
          Procesaremos su información personal de manera legal, justa y
          transparente. Recopilamos y procesamos información sobre usted solo
          cuando tenemos bases legales para hacerlo.
        </p>
        <p>
          Estas bases legales dependen de los servicios que utilice y cómo los
          utilice, lo que significa que recopilamos y usamos su información solo
          cuando:
        </p>
        <ul type="disc">
          <li>
            es necesario para la ejecución de un contrato en el que usted es
            parte o para tomar las medidas que usted solicite antes de celebrar
            dicho contrato (por ejemplo, cuando proporcionamos un servicio que
            usted nos solicita);
          </li>
          <li>
            satisface un interés legítimo (que no es anulado por sus intereses
            de protección de datos), como la investigación y el desarrollo, para
            comercializar y promover nuestros servicios y para proteger nuestros
            derechos e intereses legales;
          </li>
          <li>
            nos da su consentimiento para hacerlo con un propósito específico
            (por ejemplo, puede dar su consentimiento para que le enviemos
            nuestro boletín informativo); o
          </li>
          <li>
            Necesitamos procesar sus datos para cumplir con una obligación
            legal.
          </li>
          <br />
        </ul>
        <p>
          Cuando usted da su consentimiento para que usemos su información para
          un propósito específico, tiene derecho a cambiar de opinión en
          cualquier momento (pero esto no afectará ningún procesamiento que ya
          haya tenido lugar).
        </p>
        <p>
          Nosotros no guardamos información personal durante más tiempo del
          necesario. Mientras retenemos esta información, la protegeremos dentro
          de medios comercialmente aceptables para evitar pérdidas y robos, así
          como acceso, divulgación, copia, uso o modificación no autorizados.
          Dicho esto, aconsejamos que ningún método de transmisión o
          almacenamiento electrónico sea 100% seguro y no pueda garantizar la
          seguridad absoluta de los datos. Si es necesario, podemos retener su
          información personal para nuestro cumplimiento de una obligación legal
          o para proteger sus intereses vitales o los intereses vitales de otra
          persona física.
        </p>
        <p className='bold'>3. Recopilación y uso de información</p>
        <p>
          Podemos recopilar, retener, usar y divulgar información para los
          siguientes propósitos y la información personal no se procesará más de
          una manera que sea incompatible con estos propósitos:
        </p>
        <ul type="disc">
          <li>
            para permitirle personalizar o personalizar su experiencia de
            nuestra plataforma y otros sitios que poseemos y operamos ;
          </li>
          <li>
            para permitirle acceder y utilizar nuestro sitio web, aplicaciones
            asociadas y plataformas de redes sociales asociadas;
          </li>
          <li>para contactarlo y comunicarnos con usted;</li>
          <li>
            para fines administrativos y de mantenimiento de registros internos;
          </li>
          <li>
            para análisis, investigación de mercado y desarrollo comercial,
            incluso para operar y mejorar nuestro sitio web, aplicaciones
            asociadas y plataformas de redes sociales asociadas;
          </li>
          <li>
            para organizar competiciones y / u ofrecerle beneficios adicionales;
          </li>
          <li>
            para cumplir con nuestras obligaciones legales y resolver cualquier
            disputa que podamos tener; y
          </li>
          <li>para considerar su solicitud de empleo.</li>
          <br />
        </ul>
        <p className='bold'>4. Divulgación de información personal a terceros</p>
        <p>Podemos divulgar información personal a:</p>
        <ul type="disc">
          <li>
            proveedores de servicios de terceros con el fin de permitirles
            prestar sus servicios, incluidos (sin limitación) proveedores de
            servicios de TI, proveedores de almacenamiento de datos, alojamiento
            y servidores, redes publicitarias, análisis, registradores de
            errores, cobradores de deudas, proveedores de mantenimiento o
            resolución de problemas, proveedores de marketing o publicidad,
            asesores profesionales y operadores de sistemas de pago;
          </li>
          <li>
            nuestros empleados, contratistas y / o entidades relacionadas;
          </li>
          <li>
            patrocinadores o promotores de cualquier competencia que
            organicemos;
          </li>
          <li>
            agencias de informes crediticios, juzgados, tribunales y autoridades
            reguladoras, en caso de que no pague los bienes o servicios que le
            hemos proporcionado;
          </li>
          <li>
            cortes, tribunales, autoridades reguladoras y funcionarios
            encargados de hacer cumplir la ley, según lo requiera la ley, en
            relación con cualquier procedimiento legal actual o potencial, o
            para establecer, ejercer o defender nuestros derechos legales;
          </li>
          <li>
            terceros, incluidos agentes o subcontratistas, que nos ayudan a
            proporcionarle información, productos, servicios o marketing
            directo; y
          </li>
          <li>terceros para recopilar y procesar datos.</li>
          <br />
        </ul>
        <p className='bold'>5. Transferencias internacionales de información personal</p>
        <p>
          La información personal que recopilamos se almacena y procesa en
          Australia, o donde nosotros o nuestros socios, afiliados y proveedores
          externos tenemos instalaciones. Al proporcionarnos su información
          personal, acepta la divulgación a estos terceros en el extranjero.
        </p>
        <p>
          Nos aseguraremos de que cualquier transferencia de información
          personal desde países del Espacio Económico Europeo (EEE) a países
          fuera del EEE esté protegida por las salvaguardias adecuadas, por
          ejemplo, mediante el uso de cláusulas estándar de protección de datos
          aprobadas por la Comisión Europea o el uso de reglas corporativas u
          otros medios legalmente aceptados.
        </p>
        <p>
          Cuando transferimos información personal de un país que no pertenece
          al EEE a otro país, usted reconoce que es posible que terceros en
          otras jurisdicciones no estén sujetos a leyes de protección de datos
          similares a las de nuestra jurisdicción. Existen riesgos si dicho
          tercero participa en cualquier acto o práctica que contravenga las
          leyes de privacidad de datos en nuestra jurisdicción y esto podría
          significar que usted no podrá buscar compensación bajo las leyes de
          privacidad de nuestra jurisdicción.
        </p>
        <p className='bold'>6. Sus derechos y control de su información personal</p>
        <p>
          Elección y consentimiento: al proporcionarnos información personal,
          usted da su consentimiento para que recopilemos, retengamos, usemos y
          divulguemos su información personal de acuerdo con esta política de
          privacidad. Si es menor de 16 años, debe tener, y garantizar en la
          medida en que la ley nos lo permita, que tiene el permiso de su padre
          o tutor legal para acceder y utilizar la plataforma y otros sitios que
          poseemos y operamos y ellos (su padres o tutores) han dado su
          consentimiento para que nos proporcione su información personal. No es
          necesario que nos brinde información personal; sin embargo, si no lo
          hace, puede afectar su uso de esta plataforma y otros sitios que
          poseemos y operamos o los productos y / o servicios ofrecidos en ella
          oa través de ella.
        </p>
        <p>
          Información de terceros: si recibimos información personal sobre usted
          de un tercero, la protegeremos como se establece en esta política de
          privacidad. Si usted es un tercero que proporciona información
          personal sobre otra persona, declara y garantiza que tiene el
          consentimiento de dicha persona para proporcionarnos la información
          personal.
        </p>
        <p>
          Restringir: Usted puede optar por restringir la recopilación o el uso
          de su información personal. Si previamente ha aceptado que usemos su
          información personal para fines de marketing directo, puede cambiar de
          opinión en cualquier momento comunicándose con nosotros utilizando los
          detalles a continuación. Si nos solicita que restrinjamos o limitemos
          la forma en que procesamos su información personal, le informaremos
          cómo la restricción afecta su uso de nuestro sitio web o productos y
          servicios.
        </p>
        <p>
          Acceso y portabilidad de datos: puede solicitar detalles de la
          información personal que tenemos sobre usted. Puede solicitar una
          copia de la información personal que tenemos sobre usted. Siempre que
          sea posible, proporcionaremos esta información en formato CSV u otro
          formato de máquina de fácil lectura. Puede solicitar que borremos la
          información personal que tenemos sobre usted en cualquier momento.
          También puede solicitar que transfiramos esta información personal a
          otro tercero.
        </p>
        <p>
          Corrección: Si cree que la información que tenemos sobre usted es
          inexacta, desactualizada, incompleta, irrelevante o engañosa,
          comuníquese con nosotros utilizando los detalles a continuación.
          Tomaremos medidas razonables para corregir cualquier información que
          se considere inexacta, incompleta, engañosa o desactualizada.
        </p>
        <p>
          Notificación de violaciones de datos: Cumpliremos las leyes que nos
          sean aplicables con respecto a cualquier violación de datos.
        </p>
        <p>
          Quejas: si cree que hemos violado una ley de protección de datos
          relevante y desea presentar una queja, comuníquese con nosotros
          utilizando los detalles a continuación y bríndenos todos los detalles
          de la supuesta violación. Investigaremos su queja de inmediato y le
          responderemos, por escrito, exponiendo el resultado de nuestra
          investigación y los pasos que tomaremos para tratar su queja. También
          tiene derecho a ponerse en contacto con un organismo regulador o una
          autoridad de protección de datos en relación con su queja.
        </p>
        <p>
          Darse de baja: para darse de baja de nuestra base de datos de correo
          electrónico u optar por no recibir comunicaciones (incluidas las
          comunicaciones de marketing), comuníquese con nosotros utilizando los
          detalles a continuación o cancele la suscripción utilizando las
          funciones de exclusión voluntaria proporcionadas en la comunicación.
        </p>
        <p className='bold'>7. Cookies</p>
        <p>
          Usamos "cookies" para recopilar información sobre usted y su actividad
          en nuestro sitio. Una cookie es un pequeño fragmento de datos que
          nuestro sitio web almacena en su computadora y al que accede cada vez
          que lo visita, para que podamos comprender cómo utiliza nuestro sitio.
          Esto nos ayuda a ofrecerle contenido según las preferencias que haya
          especificado. Consulte nuestra Política de cookies para obtener más
          información.
        </p>
        <p className='bold'>8. Transferencias comerciales</p>
        <p>
          Si nosotros o nuestros activos son adquiridos, o en el improbable caso
          de que queramos o entremos en quiebra, incluiríamos datos entre los
          activos transferidos a las partes que nos adquieran. Usted reconoce
          que tales transferencias pueden ocurrir y que cualquier parte que nos
          adquiera puede continuar usando su información personal de acuerdo con
          esta política.
        </p>
        <p className='bold'>9. Límites de nuestra política</p>
        <p>
          Nuestro sitio web puede tener enlaces a sitios externos que no son
          operados por nosotros. Tenga en cuenta que no tenemos control sobre el
          contenido y las políticas de esos sitios, y no podemos aceptar
          responsabilidad u obligación por sus respectivas prácticas de
          privacidad.
        </p>
        <p className='bold'>10. Cambios a esta política</p>
        <p>
          A nuestra discreción, podemos cambiar nuestra política de privacidad
          para reflejar las prácticas aceptables actuales. Tomaremos medidas
          razonables para informar a los usuarios sobre los cambios a través de
          nuestro sitio web. Su uso continuado de este sitio después de
          cualquier cambio a esta política se considerará como aceptación de
          nuestras prácticas en torno a la privacidad y la información personal.
        </p>
        <p>
          Si realizamos un cambio significativo en esta política de privacidad,
          por ejemplo, cambiando una base legal sobre la cual procesamos su
          información personal, le pediremos que vuelva a dar su consentimiento
          a la política de privacidad enmendada.
        </p>
        <p>Esta política es efectiva a partir del 16 de agosto de 2020.</p>
        <p>
          Invitamos y damos la bienvenida a
          <a onClick={() => window.open("https://about.sharegratitude.com/contact/", "_blank")}>contacto us</a>
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicyES;
