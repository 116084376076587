import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom"

import { useTranslation } from "react-i18next";
import Layout from "../../../Layout/Layout";

const GiveLocal = () => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <div className="text-wrapper">
        <h3>{t("Give local")}</h3>
        <br />
        <h5>{t("give local heading 01")}</h5>
        <br />
        <p>{t("give local content 01.1")}</p>
        <p>{t("give local content 01.2")}</p>
        <p>{t("give local content 01.3")}</p>
        <br />
        <h5>{t("give local heading 02")}</h5>
        <br />
        <p>{t("give local content 02.1")}</p>
        <p>{t("give local content 02.2")}</p>
        <br />
        <NavLink exact to="/contact">
          <Button className="btn btn-primary w-50">{t("Contact")}</Button>
        </NavLink>
        <br />
        <br />
       
      </div>
    </Layout>
  );
};

export default GiveLocal;
