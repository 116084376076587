import axios from 'axios';
import dotenv from 'dotenv';
import io from 'socket.io-client';

dotenv.config();
let socket;

const BASE_URL = process.env.REACT_APP_API_URL;
const devURL = process.env.REACT_APP_DEV_URL;
export const fetchRecipientsService = (request) => {
	const REGISTER_API_ENDPOINT = `${BASE_URL}/recipient/getRecipients`;
	const parameters = {
		method: 'GET',
	};

	return fetch(REGISTER_API_ENDPOINT, parameters)
		.then((response) => {
			return response.json();
		})
		.then((json) => {
			return json;
		});
};

// export const submitGratitudeMsg = async (request) => {
//     const REGISTER_API_ENDPOINT = `${BASE_URL}/message/save`;
//     var res = {};
//     try {
//         res = await axios.post(
//             REGISTER_API_ENDPOINT,
//             { ...request.payload },
//             {
//                 // headers: {
//                 //     Accept: "application/json",
//                 //     xsrfCookieName: "XSRF-TOKEN",
//                 //     xsrfHeaderName: "X-XSRF-TOKEN",
//                 // },
//             }
//         );

//     } catch (error) {
//         console.error('Error ---------->', error.response);
//         res = error.response;
//     }

//     return res;
// };

export const trackMyMessageService = async (request) => {
	// const id = request.payload.msgID;
	// delete request.payload.msgID;
	 const REGISTER_API_ENDPOINT = `${BASE_URL}/browser/registerUser`;
	//const REGISTER_API_ENDPOINT = 'http://localhost:5000/browser/registerUser';

	var res = await axios
		.post(
			REGISTER_API_ENDPOINT,
			{ ...request.payload },
			{
				// headers: {
				//     Accept: "application/json",
				//     xsrfCookieName: "XSRF-TOKEN",
				//     xsrfHeaderName: "X-XSRF-TOKEN",
				// },
			}
		)
		.then(function (response) {
			return response;
		})
		.catch(function (error) {
			return error;
		});

	return res;
};

// Socket //

export const submitGratitudeMsg = async (request) => {
	//console.log('Submit msg ===>', request.payload.payload);
	var socket = request.connect;
	request.payload.payload.browserHash = localStorage.getItem('browserHash').toString();
	return new Promise((resolve) => {
		socket.emit('store message', request.payload.payload, function (error, response) {
			let res = null;
			if (error) {
				const { errorData, message } = error;
				socket.disconnect();
				socket.off();
				res = error;
			} else {
				socket.disconnect();
				socket.off();
				res = response;
			}
			resolve(res);
		});
	});
};

// register volunteer
export const RegisterVolunteerService = async (request) => {
	// console.log(request.payload)
	// const id = request.payload.msgID;
	// delete request.payload.msgID;
	 const REGISTER_API_ENDPOINT = `${BASE_URL}/browser/registervolunteer`;
	//const REGISTER_API_ENDPOINT = 'http://localhost:5000/browser/registervolunteer';

	var res = await axios
		.post(
			REGISTER_API_ENDPOINT,
			{ ...request.payload },
			{
				// headers: {
				//     Accept: "application/json",
				//     xsrfCookieName: "XSRF-TOKEN",
				//     xsrfHeaderName: "X-XSRF-TOKEN",
				// },
			}
		)
		.then(function (response) {
			//console.log('response', response);
			return response;
		})
		.catch(function (error) {
			//console.log('error', error);
			return error;
		});

	return res;
};

