import * as types from './index';

export const fetchRecipientsAction = () => {
    return {
        type: types.FETCH_RECIPIENTS
    }
};

export const submitGratitudeMsgAction = (payload) => {
    return {
        type: types.SUBMIT_GRATITUDE_MSG,
        payload
    }
}

export const resetGratitudeMsgAction = () => {
    return {
        type: types.SUBMIT_GRATITUDE_MSG_RESET
    }
}

// Message my track
export const userRegistrationAction = (payload) => {
    return {
        type: types.USER_REGISTRATION,
        payload
    }
}

// valunteer registration
export const VolunteerRegistrationAction = (payload) => {
    return {
        type: types.VOLUNTEER_REGISTRATION,
        payload
    }
}

export const resetVolunteerRegistrationAction = () => {
    return {
        type: types.VOLUNTEER_REGISTRATION_RESET
    }
}

export const resetUserRegistrationAction = () => {
    return {
        type: types.USER_REGISTRATION_RESET
    }
}