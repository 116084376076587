import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


import "./donation.scss";

import send from "../../Resources/Images/send.svg";

const ModalShareEmail = ({
  open = false,
  donationMsg = {},
  modelHeader = "-",
  subHeader = "-",
  modelContent = "-",
  button1 = "Yes",
  button2 = "No",
  trigerFunction = () => { },
  closeModel = () => { },
  submitForm = () => { },
  errors = {},
  emailShareSuccess = "",
  openM
}) => {
  const { t, i18n } = useTranslation();
  const [state, setData] = useState({ fromName: "", toName: "", toEmail: "", subject: t("Message of Gratitude") })

  const handleClose = () => {
    closeModel();
  };

  useEffect(() => {
    if (emailShareSuccess) {
      setData({ fromName: "", toName: "", toEmail: "", subject: t("Stories of Gratitude") })
    }
  }, [emailShareSuccess])


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="email-share"
    >
      <div className="mo-header">
        {" "}
        <img src={send} className='send-mo'></img>
        {/* {modelHeader} {" "} */}
        <span onClick={handleClose} color="secondary" autoFocus>
          {t('back')}
        </span>
      </div>
      <DialogContent>
        {/* <h5>{subHeader}</h5> */}
        <DialogContentText id="alert-dialog-description" >
          <>
            {emailShareSuccess != "" ?
              emailShareSuccess ?
                <div className="alert alert-success">{t('email_share_success')}</div>
                : <div className="alert alert-danger">{t('email_share_fail')}</div>
              : null
            }
            <div className="form-group">
              <span className="error">{errors.fromName}</span>
              <input type="text" className="form-control" id="fromName" name="fromName" placeholder={t("FromName")}
                value={state.fromName}
                onChange={(event) => {
                  setData({
                    ...state,
                    fromName: event.target.value
                  })
                }
                }
              />
            </div>
            <div className="form-group">
              {/* <label for="exampleInputEmail1">{t("ToName")}</label> */}
              <span className="error">{errors.toName}</span>
              <input type="email" className="form-control" id="toName" name="toName" placeholder={t("ToName")}
                value={state.toName}
                onChange={(event) => {
                  setData({
                    ...state,
                    toName: event.target.value
                  })
                }
                }
              />
            </div>
            <div className="form-group">
              {/* <label for="exampleInputEmail1">{t("ToEmail")}</label> */}
              <span className="error">{errors.toEmail}</span>
              <input type="email" className="form-control" id="toEmail" name="toEmail" placeholder={t("ToEmail")}
                value={state.toEmail}
                onChange={(event) => {
                  setData({
                    ...state,
                    toEmail: event.target.value
                  })
                }
                }
              />
            </div>

            <div className="form-group">
              {/* <label for="exampleInputEmail1">{t("Subject")}</label> */}
              <span className="error"></span>
              <input type="text" className="form-control" id="subject" name="subject" readOnly placeholder={t("Subject")}
                value={t(state.subject)}
                onChange={(event) => {
                  setData({
                    ...state,
                    subject: event.target.value
                  })
                }
                }
              />

            </div>

            <button
              type="button"
              onClick={() => submitForm(state)}
              className="btn btn-primary btn-block"
              disabled={(state.fromName && state.toName && state.toEmail) ? false : true}
            >{t("Share your message")}
            </button>

          </>
        </DialogContentText>

        <a>
        </a>

        {/* <a onClick={handleClose} className="sec">
          {t('Not right now, thank you')}
        </a> */}

        {/* <small>
          {t('Tap the above ‘Give a little’ button to donate, or to learn more about how this charity partner is making a difference.')}
        </small> */}
      </DialogContent>
    </Dialog>
  );
};

export default ModalShareEmail;
