import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const createCustomerService = async (request) => {
    const CREATE_CUSTOMER = `${BASE_URL}/payment/create-customer`;
    
    const res = await axios.post(
        CREATE_CUSTOMER,
        { ...request.payload },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    return res;
};

export const paymentCheckoutService = async (request) => {
    const PAYMENT_CHECKOUT = `${BASE_URL}/payment/create-checkout-session`;
    
    const res = await axios.post(
        PAYMENT_CHECKOUT,
        { ...request.payload },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    return res;
}

export const fetchOrdersService = async (request) => {
    const FETCH_ORDERS = `${BASE_URL}/payment/gift-orders`;
    
    const res = await axios.post(
        FETCH_ORDERS,
        {...request.payload},
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
    return res;
}