import React from "react";
import { useTranslation } from "react-i18next";
import logoEn from "../../../../Resources/Images/logo-en-f.svg";
import logoEs from "../../../../Resources/Images/logo-es-f.svg";
import logoZh from "../../../../Resources/Images/logo-zh-f.svg";
import logoB from "../../../../Resources/Images/blank.jpg";
import { getLanguage } from '../../../../Utils/Storage';

const SplashScreen = () => {
  const { t } = useTranslation();

  const language = getLanguage();

  /**
   * change logo according to the user's country and it's language, default is English
   */
  let logoImg = logoB;

  if(language==="en"){
    logoImg = logoEn;

  }else if(language==="es"){
    logoImg = logoEs;

  }else if(language==="zh"){
    logoImg = logoZh;
  }

  return (
    <div className={`splash`}>
      <div className="splash-group">
        <img className="d-block img-fluid" src={logoImg} alt="logo-gratitude" />
        <p>{t("loading_msg")}</p>
      </div>
    </div>
  );
};

export default SplashScreen;
