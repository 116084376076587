import io from "socket.io-client";
const BASE_URL_SOCKET = process.env.REACT_APP_SOCKET_END_POINT;

/**
 * Establish socket connection
 *
 * @param {*} request payload
 * @returns response
 */
export const connectSocket = async (request) => {

    const endpoint = `${BASE_URL_SOCKET}/gratitude`;
    //Very simply connect to the socket
    const socket = io(endpoint);

    return socket;
};

/**
 * Establish socket connection
 *
 * @param {*} request payload
 * @returns response
 */
export const connectSocketCommunity = async (request) => {

    const endpoint = `${BASE_URL_SOCKET}/community`;
    //Very simply connect to the socket
    const socket = io(endpoint);

    return socket;
};