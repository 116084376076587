import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { queryGooglePlaces } from "../../../../Utils/GoogleAPI";

import RightImg from "../../../../Resources/Images/right.svg";
import marker from "../../../../Resources/Images/marker.svg";
import SearchLocation from "./SearchLocation";
import TagRecipient from "./TagRecipient";
import MsgImpact from "./MsgImpact";
import ThankYouMsg from "./ThankYouMsg";
import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
  getGeoCodeByAddress,
} from "../../../../Utils/GoogleAPI";
import {
  fetchRecipientsAction,
  submitGratitudeMsgAction,
  resetGratitudeMsgAction,
} from "../../../../Actions/gratitudeAction";
import {
  changeHeaderAction,
  getUserInfoAction,
  checkUserPrecenseAction,
} from "../../../../Actions/generalAction";
import { resetDittoMsgContent } from "../../../../Actions/communityAction";

import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import { validateEmail } from "../../../../Utils/Util";

import {
  UploadFileToS3,
  CheckImageModeration,
} from "../../../../Services/uploadFileService";

var deletedRecTagsAr = [];
var placeTags = [];

const thumbsContainer = {};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const InputGratutude = (props) => {
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState([]);

  // File upload
  // const onDrop = useCallback(acceptedFiles => {
  //   acceptedFiles.forEach((file) => {
  //     console.log("File-->", file.path);
  //     const reader = new FileReader()

  //     reader.onabort = () => console.log('file reading was aborted')
  //     reader.onerror = () => console.log('file reading has failed')
  //     reader.onload = () => {
  //       // Do whatever you want with the file contents
  //       const binaryStr = reader.result
  //       console.log(binaryStr)
  //       console.log("File-->", reader.result);
  //     }
  //     reader.readAsArrayBuffer(file)
  //   })
  // }, [])
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: false,
    maxFiles: 1,
    maxSize: 10000000000,
    accept: "image/*, video/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // console.log("Files -->", files);
  }, [files]);

  var history = useHistory();
  // Length of gratitude msg
  const gratitudeMsgLength = 1000;

  // validation
  const [validDisplayName, setValidDisplayName] = useState(true);
  const [validRecipientTag, setValidRecipientTag] = useState(true);
  const [validRecipientName, setValidRecipientName] = useState(true);

  // Recipient email
  const [recipientName, setRecipientName] = useState(
    props.ditto.recipientName ? props.ditto.recipientName : ""
  );
  const [recipientEmail, setRecipientEmail] = useState(
    props.ditto.recipientEmail ? props.ditto.recipientEmail[0] : ""
  );
  const [isValidrecipientEmail, setIsValidrecipientEmail] = useState(true);

  // Message length
  const [msgLength, setMsgLength] = useState(
    props.ditto.message
      ? gratitudeMsgLength - props.ditto.message.length
      : gratitudeMsgLength
  );

  //Enable submit button
  const [enableSubmitButton, setEnableSubmitButton] = useState(true);

  // Component enble/disable
  const [tagRecepient, setTagRecepient] = useState(false);
  const [tagRecipientLocation, setTagRecipientLocation] = useState(false);
  const [tagSenderLocation, setTagSenderLocation] = useState(false);

  // Share msg
  const [shareMsg, setShareMsg] = useState(false);
  const [enableThankYouMsg, setEnableThankYouMsg] = useState(false);

  //Submit msg ID
  const [submitMsgID, setSubmitMsgID] = useState("");

  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // Pre-loader
  const [enableLoader, setEnableLoader] = useState(false);

  // Set form values
  const [gratitudeMsg, setGratitudeMsg] = useState(
    props.ditto.message ? props.ditto.message : ""
  );
  const [recepientLocation, setRecepientLocation] = useState({
    latitude: 0,
    longitude: 0,
    description: props.ditto.locations
      ? props.ditto.locations[0].name.value
        ? props.ditto.locations[0].name.value
        : props.ditto.locations[0].name
      : "World",
    location: [
      props.ditto.locations
        ? {
            name: props.ditto.locations[0].name.value
              ? props.ditto.locations[0].name.value
              : props.ditto.locations[0].name,
            type: "city",
            placeId: props.ditto.locations[0].placeId,
          }
        : {
            name: "world",
            type: "city",
            placeId: 0,
          },
      props.ditto.locations
        ? {
            name: props.ditto.locations[1].name.value
              ? props.ditto.locations[1].name.value
              : props.ditto.locations[1].name,
            type: "country",
            placeId: props.ditto.locations[1].placeId,
          }
        : {
            name: "world",
            type: "country",
            placeId: 0,
          },
    ],
    continent: "Default",
  });
  const [senderLocation, setSenderLocation] = useState("");
  const [impactOfMsg, setImpactOfMsg] = useState(true);
  const [tags, setTags] = useState(
    props.ditto.taggedRecipient ? props.ditto.taggedRecipient : []
  );
  const [suggestions, setSuggestions] = useState([]);
  const [suggestRecipients, setSuggestRecipients] = useState([
    { id: "", text: "" },
  ]);
  const [senderName, setSenderName] = useState(
    props.userPrecense ? props.userPrecense.user.userName : ""
  );
  const [textInput, setTextInput] = useState(false);

  useEffect(() => {
    if (!props.userPrecense) {
      props.checkUserPrecenseAction();
    }
  }, []);

  useEffect(() => {
    if (props.userPrecense) {
      setSenderName(props.userPrecense ? props.userPrecense.user.userName : "");
      // Set browser hash
      if (props.userPrecense.browserHash) {
        localStorage.setItem("browserHash", props.userPrecense.browserHash);
      }
    }
  }, [props.userPrecense]);

  useEffect(() => {
    // Set sender location
    if (!senderLocation) {
      // Geo location get by geo code
      const setLatLong = async (geoLocation) => {
        var lat = geoLocation.coords.latitude;
        var long = geoLocation.coords.longitude;
        var response = await getGeoLocationByLatLong(lat, long);
        const arLength = response.results.length;
        const geoLoc = response.results[arLength - 4].formatted_address;

        var addressArLength =
          response.results[arLength - 1].address_components.length;
        const cityData = await getGeoCodeByAddress(
          response.results[arLength - 4].address_components[0].long_name
        );
        const countryData = await getGeoCodeByAddress(
          response.results[arLength - 2].address_components[0].long_name
        );

        setSenderLocation({
          latitude: lat,
          longitude: long,
          description: geoLoc,
          placeId: cityData[0].place_id,
          location: [
            {
              name: {
                offset: "auto",
                value:
                  response.results[arLength - 4].address_components[0]
                    .long_name,
              },
              type: "city",
              placeId: cityData[0].place_id,
            },
            {
              name: {
                offset: "auto",
                value:
                  response.results[arLength - 2].address_components[0]
                    .long_name,
              },
              type: "country",
              placeId: countryData[0].place_id,
            },
          ],
        });
      };

      // Geo location get by ip
      const setByIPaddress = async (error) => {
        var geoData = await getGeocodeByIPaddress();
        var geoCodeAr = geoData.loc.split(",");
        var lat = geoCodeAr[0];
        var long = geoCodeAr[1];
        var response = await getGeoLocationByLatLong(lat, long);

        if (response !== false) {
          const arLength = response.results.length;
          const geoLocation = response.results[arLength - 4].formatted_address;

          const cityData = await getGeoCodeByAddress(
            response.results[arLength - 4].address_components[0].long_name
          );
          const countryData = await getGeoCodeByAddress(
            response.results[arLength - 2].address_components[0].long_name
          );
          setSenderLocation({
            latitude: lat,
            longitude: long,
            description: geoLocation,
            placeId: cityData[0].place_id,
            location: [
              {
                name: {
                  offset: "auto",
                  value:
                    response.results[arLength - 4].address_components[0]
                      .long_name,
                },
                type: "city",
                placeId: cityData[0].place_id,
              },
              {
                name: {
                  offset: "auto",
                  value:
                    response.results[arLength - 2].address_components[0]
                      .long_name,
                },
                type: "country",
                placeId: countryData[0].place_id,
              },
            ],
          });
        }
      };
      var loc = navigator.geolocation.getCurrentPosition(
        setLatLong,
        setByIPaddress
      );
    }
  }, []);

  const backToMainComponent = () => {
    setTagRecipientLocation(false);
    setTagRecepient(false);
    setTagSenderLocation(false);
    props.changeHeaderAction({ type: "main" });
  };

  // Set message and remaing characters
  const setMsg = (msg) => {
    var msgLength = msg.length;
    var remainingChars = gratitudeMsgLength - msgLength;
    setGratitudeMsg(msg);
    setMsgLength(remainingChars);
  };

  // Delete recipient
  const deleteRecipient = (i) => {
    //('test -->', i);
    if (i >= 0) deletedRecTagsAr.push(tags[i].id);
    setTags(tags.filter((tags, index) => index !== i));
  };

  // Tag recipient
  const addRecipient = (tag) => {
    // Check tag is exsist
    var exsistTag = [];
    exsistTag = tags.filter(function (value, index, arr) {
      if (value.text === tag.text) return value;
    });

    // if tag is exsist return
    if (exsistTag.length === 1) return;

    if (tag.id === tag.text) tag.type = "custom";
    else tag.type = "non-custom";
    //('tag recipient', tags);
    setTags([...tags, tag]);
  };

  useEffect(() => {
    if (tags.length) setValidRecipientTag(true);
  }, [tags]);

  useEffect(() => {
    props.fetchRecipientsAction();
    // props.getUserInfoAction();
    props.changeHeaderAction({ type: "main" });
  }, []);

  useEffect(() => {
    if (props.recipients.success)
      setSuggestions(props.recipients.recipientList);
  }, [props.recipients]);

  // useEffect(() => {
  //   if (props.userInfo) {
  //     if (props.userInfo.data) {
  //       var nameOfSender = props.userInfo.data.user ? props.userInfo.data.user.displayName : '';
  //       setSenderName(nameOfSender);
  //     }
  //   }
  // }, [props.userInfo]);

  // const changeInputHanleTagRecipient = (e) => {
  //   //('change', e);
  // }

  //Submit message
  const submitMsg = async () => {
    setEnableLoader(true);
    let isDitto = props.ditto.length ? true : false;

    var formData = {
      message: gratitudeMsg,
      taggedRecipient: tags,
      recipientsLocation: recepientLocation.description,
      senderLoactions: senderLocation.location,
      author: senderName,
      authorLocation: senderLocation.description,
      locations: recepientLocation.location,
      continent: recepientLocation.continent,
      recipientName: recipientName,
      recipientEmail: recipientEmail ? [recipientEmail] : [],
      isImpactEnable: shareMsg,
      isDitto: isDitto,
      // recipientName: recipientName
      // selectedLanguage: props.lang
    };

    // Media file upload to S3 bucket
    if (files.length) {
      var checkModeration = "";
      let fileTypeAr = files[0].type.split("/");

      var uploadedFile = await UploadFileToS3(files[0], fileTypeAr[0]);
      if (uploadedFile.status !== 204) {
        setSeverity("error");
        setErrorMsg("Media file uploading process failed..!");
        setErrorNotifification(!errorNotifification);
        setEnableSubmitButton(true);
        setEnableLoader(false);
        return;
      }

      if (fileTypeAr[0] == "image")
        checkModeration = await CheckImageModeration(uploadedFile);
      // else checkModeration = await CheckVideoModeration(uploadedFile);

      //If image or video has inappropriate content throw error
      if (checkModeration.result == false) {
        setSeverity("error");
        setErrorMsg(checkModeration.msg);
        setErrorNotifification(!errorNotifification);
        setEnableSubmitButton(true);
        setEnableLoader(false);
        return;
      } else {
        formData.mediaName = uploadedFile.key;
        formData.mediaType = fileTypeAr[0];
        if (checkModeration.thumbnails3Key)
          formData.thumbnail = checkModeration.thumbnails3Key;
      }
    }

    // if message is ditto assign parent msg id
    if (isDitto) {
      formData.omId = props.ditto._id;
      formData.charity = props.ditto.charity;
      formData.messageIcon = props.ditto.messageIcon;
      formData.language = props.ditto.language;
    }

    setEnableSubmitButton(false);
    props.submitGratitudeMsgAction(formData);
  };

  //Submit message response
  useEffect(() => {
    if (props.submitGratitudeMsg) {
      if (props.submitGratitudeMsg.success) {
        // set submit msg id
        setSubmitMsgID(props.submitGratitudeMsg.message._id);
        // Check enable impact of msg
        if (props.userPrecense.isRegistered || !impactOfMsg) {
          setEnableThankYouMsg(true);
          props.resetGratitudeMsgAction();
        } else {
          setShareMsg(true);
        }
        setEnableLoader(false); // Disable loader

        // if (impactOfMsg) {
        //   setShareMsg(true);
        // } else {
        //   history.push("/");
        // }
        // props.resetGratitudeMsgAction();
      } else if (props.submitGratitudeMsg.error) {
        setSeverity("error");
        setErrorMsg(props.submitGratitudeMsg.error.message);
        setErrorNotifification(!errorNotifification);
        setEnableSubmitButton(true);
        setEnableLoader(false); // Disable loader
        props.resetGratitudeMsgAction();
      }

      /* If message is ditto reset sontent*/
      if (props.ditto.length) props.resetDittoMsgContent();
    }
  }, [props.submitGratitudeMsg]);

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  // Track my msg
  // const trackMyMsg = (formData) => {
  //   formData.messageId = submitMsgID;
  //   props.trackMyMsgAction(formData);
  // };

  // add recepients tags
  const tagRecipients = (event) => {
    var recipientAr = [];
    var customTags = [];

    tags.map((tag) => {
      if (!tag.preDefined) customTags.push(tag);
    });

    var msgInLowerCase = gratitudeMsg.toLowerCase();
    var wordAr = msgInLowerCase.split(/[ ,/-\\.]+/);
    // var wordAr = [];
    // msgWordAr.map((word) => {
    //   wordAr.push(word.trim());
    // });

    suggestions.map((suggestion) => {
      if (
        wordAr.includes(suggestion.text.toLowerCase()) &&
        !deletedRecTagsAr.includes(suggestion.id)
      ) {
        suggestion.type = "non-custom";
        suggestion.preDefined = true;
        recipientAr.push(suggestion);
      }

      // var rec = RegExp("(?<=[\\W])" + suggestion.text.toLowerCase() + "(?=[\\W])|^" + suggestion.text.toLowerCase(), "gi");
      // if (gratitudeMsg.toLowerCase().match(rec) && !tags.includes(suggestion) && !deletedRecTagsAr.includes(suggestion.id)) {
      //   suggestion.type = "non-custom";
      //   suggestion.preDefined = true;
      //   recipientAr.push(suggestion);
      //   setTags([...tags, suggestion]);
      // }
    });
    setTags([...customTags, ...recipientAr]);

    // if remove text
    var ar1 = tags.filter(function (value, index, arr) {
      if (
        !gratitudeMsg.toLowerCase().includes(value.text.toLowerCase()) &&
        value.preDefined
      )
        return value;
    });
    ar1.map((val) => {
      var index = tags.indexOf(val);
      tags.splice(index, 1);
      setTags(tags);
    });
  };

  // useEffect(() => {
  //   if (props.trackMyMsg.data) {
  //     if (props.trackMyMsg.data.success) {
  //       history.push("/");
  //     }
  //     props.resetTrackMyMsgAction();
  //   } else if (props.trackMyMsg.name === "Error") {
  //     setSeverity("error");
  //     setErrorMsg("Please check again and re-submit.");
  //     setErrorNotifification(!errorNotifification);
  //     props.resetTrackMyMsgAction();
  //   }
  // }, [props.trackMyMsg]);

  const handleSuggestions = (textInputValue, possibleSuggestionsArray) => {
    // var res = await queryGooglePlaces(textInputValue);
    //('input -->', textInputValue);
    textInputValue ? setTextInput(textInputValue) : setTextInput("");

    // possibleSuggestionsArray = [];

    var promise = new Promise((resolve, reject) => {
      var res = queryGooglePlaces(textInputValue);
      resolve(res);
    });

    promise.then(
      (result) => {
        placeTags = result;
        setSuggestRecipients([...props.recipients.recipientList, ...placeTags]);
      },
      function (error) {
        placeTags = [];
      }
    );

    // possibleSuggestionsArray = [...possibleSuggestionsArray, ...placeTags];
    var lowerCaseQuery = textInputValue.toLowerCase();
    return possibleSuggestionsArray.filter(function (suggestion) {
      // //('suggestion', suggestion);
      return suggestion.text.toLowerCase().includes(lowerCaseQuery);
    });
  };

  const recipientEmailValidation = (email) => {
    setRecipientEmail(email);

    if (email === "") {
      setIsValidrecipientEmail(true);
      return;
    }

    if (email) {
      setIsValidrecipientEmail(validateEmail(email));
      return;
    }
  };

  const validateDisplayName = (name) => {
    var isValidName = name ? true : false;
    setValidDisplayName(isValidName);
  };

  const validateRecipientName = (name) => {
    var isValidName = name ? true : false;
    setValidRecipientName(isValidName);
  };

  const validateFields = () => {
    var isValidName = senderName ? true : false;
    var isValidRecipientName = recipientName ? true : false;

    setValidDisplayName(isValidName);
    setValidRecipientName(isValidRecipientName);
    setValidRecipientTag(tags.length > 0 ? true : false);
  };

  const uploadImage = async (picture) => {
    var res = await UploadFileToS3(picture[0]);
  };

  const removeFile = (file) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles(acceptedFiles);
  };

  return (
    <div className="input-grat">
      {!tagRecipientLocation &&
        !tagRecepient &&
        !tagSenderLocation &&
        !shareMsg &&
        !enableThankYouMsg && (
          <div className="col-md-12">
            <h4 className="tit">{t("your_message")}</h4>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12  input-img">
                      <input
                        disabled={props.ditto.recipientName ? true : false}
                        value={recipientName}
                        onChange={(e) => {
                          setRecipientName(e.target.value);
                          validateRecipientName(e.target.value);
                        }}
                        type="text"
                        className="form-control form-control-sm pl-3 text-left"
                        maxLength={30}
                        placeholder={t("Recipient name")}
                      />
                      {/* {!validRecipientName ? (
                        <span className="inp-error">
                          {t("Recipient name is required")}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 ">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12  input-img">
                      <input
                        disabled={
                          props.ditto.recipientEmail
                            ? props.ditto.recipientEmail.length
                              ? true
                              : false
                            : false
                        }
                        value={
                          props.ditto.recipientEmail
                            ? props.ditto.recipientEmail.length
                              ? `${recipientEmail.substring(
                                  0,
                                  2
                                )}******${recipientEmail.substring(
                                  recipientEmail.indexOf("@")
                                )}`
                              : recipientEmail
                            : recipientEmail
                        }
                        onChange={(e) =>
                          recipientEmailValidation(e.target.value)
                        }
                        type="text"
                        className="form-control form-control-sm pl-3 text-left"
                        placeholder={t("Recipient email address (optional)")}
                      />
                      {!isValidrecipientEmail ? (
                        <span className="inp-error">{t("Invalid Format")}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list px-0">
                  <textarea
                    disabled={props.ditto.message ? true : false}
                    value={gratitudeMsg}
                    onChange={(e) => setMsg(e.target.value)}
                    onKeyPress={(e) => tagRecipients(e)}
                    onKeyUp={(e) => tagRecipients(e)}
                    name=""
                    maxLength={gratitudeMsgLength}
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control form-control-sm lf"
                    placeholder={t("write_your_message")}
                  ></textarea>
                  {gratitudeMsg ? (
                    <label className="charleft">
                      {msgLength} {t("chars_left")}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="input-list-img">
                  <p>
                    {t("select_a_image_or_video")}
                    <small>
                     &nbsp;{t("max_duration")} : 60 {t("seconds")}
                    </small>
                  </p>
                  <div {...getRootProps({ className: "dropzone disabled" })}>
                    <input {...getInputProps()} />
                  </div>
                  {/* <aside>
                    {acceptedFiles.map(file => (
                      <li key={file.name}>
                        {file.name} - {file.size} bytes
                      </li>
                    ))}

                  </aside> */}
                  <aside className="imgas" style={thumbsContainer}>
                    {files.map((file) => (
                      <div style={thumbsContainer}>
                        {file.type.split("/")[0] === "image" ? (
                          <div>
                            <div className="thumb" key={file.name}>
                              <div className="thumb-inner" style={thumbInner}>
                                <img src={file.preview} style={img} />
                              </div>
                            </div>
                            <button
                              className="closeb"
                              onClick={() => removeFile(file)}
                            >
                              x
                            </button>
                          </div>
                        ) : (
                          <div>
                            <video height="110" controls src={file.preview} />
                            <button
                              className="closeb"
                              onClick={() => removeFile(file)}
                            >
                              x
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </aside>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 ">
                <div className="input-list input-border">
                  <div
                    className="row"
                    onClick={
                      !props.ditto.locations
                        ? () => {
                            setTagRecipientLocation(true);
                            props.changeHeaderAction({ type: "msg" });
                          }
                        : ""
                    }
                  >
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                      <label htmlFor="" className="pl-2">{t("recepients_location")}</label>
                    </div>
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6  input-img">
                      <span role="textbox" className="tag-location">
                        <img src={marker} alt="" />
                        {recepientLocation.description}
                      </span>
                      <a>
                        <img src={RightImg} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12  d-none">
                <div className="input-list">
                  <div
                    className="row"
                    onClick={
                      !props.ditto.taggedRecipient
                        ? () => {
                            setTagRecepient(true);
                            props.changeHeaderAction({ type: "msg" });
                          }
                        : ""
                    }
                  >
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                      <label htmlFor="">{t("tag_recipients")}</label>
                    </div>
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6  input-img">
                      <span className="tag-location">
                        {tags.length !== 0
                          ? tags.length === 1
                            ? tags[0].text
                            : tags.length + " " + t("Recipients")
                          : ""}
                      </span>
                      <a>
                        <img src={RightImg} alt="" />
                      </a>

                      {!validRecipientTag ? (
                        <span className="inp-error">
                          {t("Required at leaset one tag")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12  pb-0">
                <div className="input-list pb-0">
                  <h4 className="tit mt-4">{t("about_you")}</h4>
                  <div className="row">
                    <div className=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="input-img">
                        <input
                          disabled={
                            props.userPrecense
                              ? props.userPrecense.isRegistered
                                ? true
                                : false
                              : false
                          }
                          value={senderName}
                          // onChange={(e) => setSenderName(e.target.value)}
                          onChange={(e) => {
                            setSenderName(e.target.value);
                            validateDisplayName(e.target.value);
                          }}
                          type="text"
                          className="form-control form-control-sm  text-left"
                          placeholder={t("your_display_name")}
                        />
                        {!validDisplayName ? (
                          <span className="inp-error">
                            {t("Display name is required")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 ">
                      <div className="input-list input-border mt-0">
                        <div
                          className="row"
                          onClick={() => {
                            setTagSenderLocation(true);
                            props.changeHeaderAction({ type: "msg" });
                          }}
                        >
                          <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 label-group">
                            <label htmlFor="">{t("your_location")}</label>
                          </div>
                          <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6  input-img">
                            <span className="tag-location d-block">
                              <img src={marker} alt="" />
                              {senderLocation.description}
                            </span>
                            <a>
                              <img src={RightImg} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="input-list mt-3 mb-3">
                  <div className="row">
                    <div className="col-10 col-xs-9 col-sm-9 col-md-9 col-lg-10">
                      <label htmlFor="">
                        {t("show_me_the_impact_of_my_message")}
                      </label>
                    </div>
                    <div className="col-2 col-xs-3 col-sm-3 col-md-3 col-lg-2  input-img">
                      <label className="switch float-right">
                        <input
                          checked={impactOfMsg ? true : false}
                          onChange={() => setImpactOfMsg(!impactOfMsg)}
                          type="checkbox"
                        />{" "}
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12">
                <a
                  onClick={
                    gratitudeMsg &&
                    recepientLocation &&
                    senderLocation &&
                    // tags.length !== 0 &&
                    senderName &&
                    enableSubmitButton &&
                    isValidrecipientEmail
                      ? () => submitMsg()
                      : () => validateFields()
                  }
                  href="#"
                  className={`float-right track active btn btn-secondary ${
                    gratitudeMsg &&
                    recepientLocation &&
                    senderLocation &&
                    // tags.length !== 0 &&
                    senderName &&
                    recipientName &&
                    enableSubmitButton &&
                    isValidrecipientEmail
                      ? "active"
                      : ""
                  }`}
                >
                  {t("share_your_gratitude")}
                </a>
              </div>
            </div>
            {enableLoader ? <CircularProgress disableShrink /> : ""}
          </div>
        )}

      {/* Tag recipients component*/}
      {tagRecepient && (
        <TagRecipient
          backToMainComponent={backToMainComponent}
          tags={tags}
          suggestions={suggestRecipients}
          addRecipient={addRecipient}
          deleteRecipient={deleteRecipient}
          // handleInputchange={changeInputHanleTagRecipient}
          handleSuggestions={handleSuggestions}
          textInput={textInput}
          senderLocation={senderLocation}
        />
      )}

      {/* Tag recipient location component*/}
      {tagRecipientLocation && (
        <SearchLocation
          backToMainComponent={backToMainComponent}
          setLocation={(location) => setRecepientLocation(location)}
        />
      )}

      {/* Tag sender location component*/}
      {tagSenderLocation && (
        <SearchLocation
          backToMainComponent={backToMainComponent}
          setLocation={(location) => setSenderLocation(location)}
        />
      )}

      {shareMsg && <MsgImpact />}
      {
          enableThankYouMsg &&
          <ThankYouMsg
            msg={gratitudeMsg}
            recipientName={recipientName}
          />
      }

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={3000}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRecipientsAction: () => dispatch(fetchRecipientsAction()),
    submitGratitudeMsgAction: (data) =>
      dispatch(submitGratitudeMsgAction(data)),
    resetGratitudeMsgAction: () => dispatch(resetGratitudeMsgAction()),
    // trackMyMsgAction: (data) => dispatch(trackMyMsgAction(data)),
    // resetTrackMyMsgAction: () => dispatch(resetTrackMyMsgAction()),
    changeHeaderAction: (data) => dispatch(changeHeaderAction(data)),
    getUserInfoAction: () => dispatch(getUserInfoAction()),
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
    resetDittoMsgContent: () => dispatch(resetDittoMsgContent()),
  };
};

const mapStateToProps = (response) => ({
  recipients: response.gratitude.recipients,
  submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  trackMyMsg: response.gratitude.trackMyMsg,
  lang: response.general.lang,
  userInfo: response.general.userInfo,
  userPrecense: response.general.userPrecense.data,
  ditto: response.community.ditto,
});
export default connect(mapStateToProps, mapDispatchToProps)(InputGratutude);
