import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import World from './Pages/World';

const worldRoutes = () => {
    return (
        <Fragment>
            <Route path="/world" exact component={World} />
        </Fragment>
    )
};

export default worldRoutes;

