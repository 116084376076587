import * as types from '../Actions';

const initailState = {
    recipients: [],
    submitGratitudeMsg: [],
    userRegistration: [],
    VolunteerRegistration:[]
};

export default function (state = initailState, action) {
    let response = action.response;

    switch (action.type) {
        // Fetch recipients
        case types.FETCH_RECIPIENTS_SUCCESS:
            return { ...state, recipients: response };
        case types.FETCH_RECIPIENTS_FAIL:
            return { ...state, recipients: response };

        // Submit gratitude msg
        case types.SUBMIT_GRATITUDE_MSG_SUCCESS:
            return { ...state, submitGratitudeMsg: response };
        case types.SUBMIT_GRATITUDE_MSG_FAIL:
            return { ...state, submitGratitudeMsg: response };
        case types.SUBMIT_GRATITUDE_MSG_RESET:
            return { ...state, submitGratitudeMsg: [] };

        // User registration
        case types.USER_REGISTRATION_SUCCESS:
            return { ...state, userRegistration: response };
        case types.USER_REGISTRATION_FAIL:
            return { ...state, userRegistration: response };
        case types.USER_REGISTRATION_RESET:
            return { ...state, userRegistration: [] };

        // Volunteer registration
        case types.VOLUNTEER_REGISTRATION_SUCCESS:
            return { ...state, VolunteerRegistration: response };
        case types.VOLUNTEER_REGISTRATION_FAIL:
            return { ...state, VolunteerRegistration: response };
        case types.VOLUNTEER_REGISTRATION_RESET:
            return { ...state, VolunteerRegistration: [] };
        default:
            return state;
    }
}