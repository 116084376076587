import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import marker from "../../../../Resources/Images/marker.svg";
import { validateEmail } from "../../../../Utils/Util";
import Layout from "../../../Layout/Layout";
import don from "../../../../Resources/Images/don.png";
import RightImg from "../../../../Resources/Images/right.svg";
import TransitionsModal from "../../../Common/TransitionsModal";
import SearchLocation from "../../Menu/Pages/SearchLocation";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import DonationSucessModal from "../../../Common/DonationSucessModal";
import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
} from "../../../../Utils/GoogleAPI";
import {
  donationRegistrationAction,
  resetUserDonationAction,
} from "../../../../Actions/communityAction";
import "./gratitude-list.scss";
import { useHistory } from "react-router-dom";

const Donation = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const [formDisable, setFormDisable] = useState(false);
  const [userName, setUserName] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [searchLocation, setSearchLocation] = useState(false);
  const [location, setLocation] = useState({});
  const [openUseOfTerms, setOpenUseOfTerms] = useState(false);
  const [donationAmount, setDonationAmount] = useState("");
  const [inputDonation, setinputDonationAmount] = useState("");
  const [type, setType] = useState("Beyond Blue Australia");
  const [reason, setReason] = useState("mental health");
  const [donationPopup, setDonationPopup] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  // validation
  const [validName, setValidName] = useState(true);
  const [validMobileNumber, setValidMobileNumber] = useState(true);
  const [isvalidEmail, setIsvalidEmail] = useState(true);
  const [validDonation, setValidDonation] = useState(true);
  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");
  var history = useHistory();
  const validateFields = () => {
    setIsvalidEmail(validateEmail(userEmail));
    setValidName(userName ? true : false);
    setValidMobileNumber(mobileNumber ? true : false);
  };
  const emailValidation = (email) => {
    setUserEmail(email);
    setValidEmail(validateEmail(email));
    setIsvalidEmail(validateEmail(email));
  };

  const mobileValidation = (mobile) => {
    var isValidMobile = mobile ? true : false;
    setValidMobileNumber(isValidMobile);
  };
  const validateNameField = (name) => {
    var isValidName = name ? true : false;
    setValidName(isValidName);
  };

  const validateDonationValue = (value) => {
    setValidDonation(false);
  };
  const reset = () => {
    setUserName("");
    setUserEmail("");
    setCompanyName("");
    setSearchLocation(false);
    setOpenUseOfTerms(false);
    setValidEmail(false);
    setDonationAmount("");
    setinputDonationAmount("");
    setType("Beyond Blue Australia");
    setReason(
      "everyone in Australia achieve their best possible mental health, whatever their age and wherever they live"
    );
    setAgreeWithTerms(false);
    setLocation({});
  };
  useEffect(() => {
    if (props.donationRegistration) {
      if (props.donationRegistration.data) {
        setDonationPopup(true);
        props.resetUserDonation();
        // reset();
      } else if (props.donationRegistration.name === "Error") {
        setSeverity("error");
        setErrorMsg("Please check again and re-submit.");
        setErrorNotifification(!errorNotifification);

        props.resetUserDonation();
        reset();
      }
    }
  }, [props.donationRegistration]);

  const setDefaultLocation = () => {
    if (!location.description) {
      // Geo location get by geo code
      const setLatLong = async (geoLocation) => {
        var lat = geoLocation.coords.latitude;
        var long = geoLocation.coords.longitude;
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLoc = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLoc,
          location: [
            {
              name: "world",
              type: "city",
              placeId: 0,
            },
            {
              name: "world",
              type: "country",
              placeId: 0,
            },
          ],
        });
      };

      // Geo location get by ip
      const setByIPaddress = async (error) => {
        var geoData = await getGeocodeByIPaddress();
        var geoCodeAr = geoData.loc.split(",");
        var lat = geoCodeAr[0];
        var long = geoCodeAr[1];
        var response = await getGeoLocationByLatLong(lat, long);

        const arLength = response.results.length;
        const geoLocation = response.results[arLength - 3].formatted_address;

        setLocation({
          latitude: lat,
          longitude: long,
          description: geoLocation,
        });
      };
      var loc = navigator.geolocation.getCurrentPosition(
        setLatLong,
        setByIPaddress
      );
    }
  };
  const onChange = (e) => {
    setinputDonationAmount(e.target.value);
    setDonationAmount(e.target.value);
  };

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  const closeModel = () => {
    history.push("/gratitude");
  };

  const donationRegistration = () => {
    const formData = {
      userName: userName,
      primaryEmail: userEmail,
      reason: reason,
      companyName: companyName,
      userLocation: location.description,
      browserHash: localStorage.getItem("browserHash").toString(),
      selectedLanguage: props.lang,
      donationType: type,
      donationAmount: `$${donationAmount}`,
      mobileNumber: mobileNumber,
    };
    props.donationRegistrationAction(formData);
  };
  return (
    <Layout>
      <div className="col-md-12 pr-0 pb-5">
        {!searchLocation ? (
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
              <div className="input-list bt-0 pt-0">
                <div className="row">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 ">
                    <h3 className="tit mb-1"> {t("your_donation")}</h3>
                    <div className="">
                      {t(
                        "Welcome to ShareGratitude Charity Collection. Please select a charity"
                      )}
                    </div>

                    <div className="mt-3 mb-4">
                      <div className="radio-btn-container">
                        <a
                          href="https://www.teamblackdog.org.au/fundraisers/sharegratitude"
                          target="_blank"
                          className="radio-btn"
                        >
                          <div>
                            <p style={{ color: "white" }}>
                              {" "}
                              Blackdog Institute
                            </p>
                            Donate
                          </div>
                        </a>
                        <a
                          href="https://www.charitywater.org/sharegratitude"
                          target="_blank"
                          className="radio-btn"
                        >
                          <div>
                            <p style={{ color: "white" }}> Charity Water</p>
                            Donate
                          </div>
                        </a>
                        <a
                          href="https://fundraise.hollows.org/fundraisers/sharegratitude"
                          target="_blank"
                          className="radio-btn"
                        >
                          <div>
                            <p style={{ color: "white" }}>
                              {" "}
                              Fred Hollows Foundation
                            </p>
                            Donate
                          </div>
                        </a>
                      </div>
                    </div>

                    <input
                      disabled={formDisable}
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        validateNameField(e.target.value);
                      }}
                      type="text"
                      className="form-control form-control-sm pl-0 d-none"
                      placeholder={t("full_name")}
                    />
                    {!validName ? (
                      <span className="inp-error">
                        {t("Full name is required")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 ">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                      <input
                        disabled={formDisable}
                        value={mobileNumber}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                          mobileValidation(e.target.value);
                        }}
                        type="text"
                        className="form-control form-control-sm pl-0"
                        placeholder={
                          t("your_mobile_number") + " (+1-212-456-7890)"
                        }
                      />
                      {!validMobileNumber ? (
                        <span className="inp-error">
                          {t("Mobile number is required")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                      <input
                        disabled={formDisable}
                        value={userEmail}
                        onChange={(e) => emailValidation(e.target.value)}
                        type="text"
                        className="form-control form-control-sm pl-0"
                        placeholder={t("email")}
                      />
                      {!isvalidEmail ? (
                        <span className="inp-error">
                          {t("Required valid email address")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 input-">
                      <input
                        disabled={formDisable}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        type="text"
                        className="form-control form-control-sm pl-0"
                        placeholder={t("Company name (optional)")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div
                    className="row"
                    onClick={() =>
                      !formDisable ? setSearchLocation(true) : ""
                    }
                  >
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 label-group">
                      <label htmlFor="">{t("location")}</label>
                    </div>
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 input-img">
                      <span className="tag-location d-block">
                        <img src={marker} alt="" />
                        {location.description}
                      </span>
                      <a>
                        <img src={RightImg} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div className="row">
                    <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 label-group">
                      <label className="">Your donation</label>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 pl-0 input-img">
                      <div className="btn-container d-value">
                        <button
                          className={
                            donationAmount == 50
                              ? "btn btn-primary selc"
                              : "btn btn-primary"
                          }
                          name="subject"
                          type="button"
                          value="50"
                          onClick={(e) => {
                            setDonationAmount(e.target.value);
                            //   validateDonationValue(e.target.value);
                          }}
                        >
                          50$
                        </button>
                        <button
                          name="subject"
                          className={
                            donationAmount == 100
                              ? "btn btn-primary selc"
                              : "btn btn-primary "
                          }
                          type="button"
                          value="100"
                          onClick={(e) => {
                            setDonationAmount(e.target.value);
                            //  validateDonationValue(e.target.value);
                          }}
                        >
                          100$
                        </button>
                        <button
                          name="subject"
                          className={
                            donationAmount == 150
                              ? "btn btn-primary selc"
                              : "btn btn-primary"
                          }
                          type="button"
                          value="150"
                          onClick={(e) => {
                            setDonationAmount(e.target.value);
                            //    validateDonationValue(e.target.value);
                          }}
                        >
                          150$
                        </button>
                        <button
                          name="subject"
                          className="btn btn-primary clean"
                          type="button"
                          value="fav_HTML"
                          onClick={(e) => {
                            setDonationAmount(e.target.value);
                            //   validateDonationValue(e.target.value);
                          }}
                        >
                          <input
                            disabled={formDisable}
                            value={inputDonation}
                            onChange={onChange}
                            style={{ width: "100px" }}
                            // type="text"
                            // className="form-control form-control-sm pl-3"
                            placeholder={t("$Other")}
                          />
                        </button>

                        {/* {!validDonation ? (
                          <span className="inp-error">
                            {t("Donation is required")}
                          </span>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0">
                <div className="input-list">
                  <div className="row">
                    <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 pl-0 link">
                      <Link>
                        <label
                          onClick={() => setOpenUseOfTerms(!openUseOfTerms)}
                          htmlFor=""
                        >
                          {t("agree_to_terms_of_service")}
                        </label>
                      </Link>
                    </div>
                    <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-0 input-">
                      <label className="switch float-right">
                        <input
                          disabled={formDisable}
                          checked={agreeWithTerms ? true : false}
                          onChange={() => setAgreeWithTerms(!agreeWithTerms)}
                          type="checkbox"
                        />{" "}
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                {" "}
                <a
                  onClick={
                    userName && validEmail && agreeWithTerms && mobileNumber
                      ? () => donationRegistration()
                      : () => validateFields()
                  }
                  className={`float-right track text-uppercase ${
                    userName && validEmail && agreeWithTerms && mobileNumber
                      ? "active"
                      : "inactive"
                  }`}
                >
                  {t("Donate")}
                </a>
              </div>
            </div>
            <div className="d-text">
              <p>
                ShareGratitude has a dedicated fundraising page directly with
                each of our supported charities.
              </p>
              <p>
                100% of your generosity will go towards your chosen cause. Share
                Gratitude does not charge any fees or receive commissions for
                fundraising activities. Your chosen charity will process your
                donation and provide a tax receipt.
              </p>
              <p>
                If you have any questions, feel free to contact me at
                <a href="mailto:don@sharegratitude.com">
                  {" "}
                  don@sharegratitude.com
                </a>
              </p>
              Thank you for your generosity and for being part of this beautiful
              and grateful community.
              <img src={don} alt="" />
              <p className="mb-0 font-weight-bold">
                Don Amal Francis & Dr Jani Warusavitarne
              </p>
              <p>Co-Founders</p>
            </div>
            <div className="d-text">
              <p className="text-uppercase titsg font-bold">
                <span>SG</span> Charity Collection
              </p>
              <p>
                {" "}
                With a sea of charity initiatives globally, it isn't easy to
                navigate and find that worthy of support. SG Charities are a
                collection of inspirational and impactful local initiatives from
                around the world. They are relevant, highly impactful, and often
                unknown initiatives.
              </p>
              <p>
                The charities are carefully selected to ensure they align with
                these principles and dedicated to the communities they seek to
                help. We are constantly researching and updating this list to
                provide you with a simple and easy space to donate, stay updated
                and support the causes you are passionate about.
              </p>
              <p>
                <strong> Disclaimer:</strong>
               100% of your generosity will go towards your chosen
                cause. ShareGratitude does not charge any fees or receive
                commissions for fundraising activities. Your chosen charity will
                process your donation and provide a tax receipt to you directly.
                Like you, we believe 100% of your generosity should go towards
                your chosen cause.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {searchLocation && (
          <SearchLocation
            backToMainComponent={() => setSearchLocation(false)}
            setLocation={(location) => setLocation(location)}
          />
        )}
        {donationPopup ? (
          <DonationSucessModal
            open={donationPopup}
            modelHeader={"Thank you"}
            donationAmount={donationAmount}
            type={type}
            reason={reason}
          />
        ) : (
          ""
        )}
        <TransitionsModal
          open={openUseOfTerms}
          button2={t("close")}
          closeModel={() => setOpenUseOfTerms(!openUseOfTerms)}
        />

        <CustomizedSnackbars
          closePopup={closeErrorNotifification}
          popupOpen={errorNotifification}
          notifyMsg={errorMsg}
          severity={severity}
          autoHideDuration={3000}
        />
      </div>
    </Layout>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    donationRegistrationAction: (data) =>
      dispatch(donationRegistrationAction(data)),
    resetUserDonation: () => dispatch(resetUserDonationAction()),
  };
};

const mapStateToProps = (response) => ({
  lang: response.general.lang,
  donationRegistration: response.community.donationRegistration,
  // userRegistration: response.gratitude.userRegistration,
  // submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  // userPrecense: response.general.userPrecense.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(Donation);
// export default Donation;
