import React, { useState, useRef } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

import { getGeoCodeByAddress } from "../../../../Utils/GoogleAPI";
import GroupImg from "../../../../Resources/Images/Group.svg";
import { useSelector } from "react-redux";

const LocationFilter = ({
  backToMainComponent,
  addLocations,
  removeLocations,
  locations,
  header,
}) => {
  const { t, i18n } = useTranslation();

  const googleInput = useRef();

  const [searchValue, setSearchValue] = useState("");

  const communitySocket = useSelector(
    (state) => state.community.communitySocket
  );

  const onChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const leaveRoomHandler = (socket, room) => {
    socket.emit("leave-room", { room: room }, (error, response) => {
      if (error) {
      } else {
        // console.log(response);
      }
    });
  };

  return (
    <>
      {header && (
        <div className="header-wrapper">
          <img src={GroupImg}></img>
      <h3>{t("Filters")} - {t("location")}</h3>
      <span onClick={backToMainComponent}>{t("back")}</span>
        </div>
      )}
      <div className="filter-menu">
        <div className="input-list">
          <div className="tagger">
            <GooglePlacesAutocomplete
              renderInput={(props) => (
                <div className="serach-panel">
                  {" "}
                  <input
                    id={props.id}
                    onKeyDown={props.onKeyDown}
                    value={searchValue}
                    onChange={(e) => {
                      props.onChange(e);
                      onChangeHandler(e);
                    }}
                    onFocus={() => {
                      setSearchValue("");
                    }}
                    type="text"
                    className="form-control js-search-place"
                    placeholder={t("search_location")}
                    ref={googleInput}
                  />
                  <div className="tagger-wrapper ">
                    {locations.map((loc) => (
                      <div
                        key={loc.placeId}
                        className="tag-options d-inline-flex"
                      >
                        <div className="d-flex align-items-center">
                          {loc.mainText}
                          <a
                            className="removeClass ml-1 d-block"
                            onClick={() => {
                              removeLocations(loc.placeId);
                              leaveRoomHandler(communitySocket, loc.placeId);
                            }}
                          >
                            x
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              renderSuggestions={(_, suggestions, onSelectSuggestion) => (
                <div className="">
                  <div className="search-results">
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        className="col-md-12 mt-3"
                        onClick={(event) =>
                          onSelectSuggestion(suggestion, event)
                        }
                      >
                        <div className="row">
                          <div className="col-10 col-sm-10 col-md-10 col-lg-10 pl-0">
                            <p className="main-result">
                              <i className="fas fa-map-marker-alt"></i>
                              {suggestion.structured_formatting.main_text
                                ? suggestion.structured_formatting.main_text
                                : ""}
                              <span className="sub-text">
                                {suggestion.structured_formatting.secondary_text
                                  ? suggestion.structured_formatting
                                      .secondary_text
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div className="col-2 col-sm-2 col-md-2 col-lg-2 mt-1 p-0">
                            {" "}
                            <i className="fas fa-plus-circle"></i>
                          </div>
                        </div>
                      </div>
                    ))}
                     <img src='https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png?hl=fr'/>
                  </div>
                </div>
              )}
              onSelect={async (suggestions) => {
                googleInput.current.focus();
                leaveRoomHandler(communitySocket, "World");
                const cityGeocode = await getGeoCodeByAddress(
                  suggestions.structured_formatting.main_text
                );
                addLocations({
                  placeId: cityGeocode[0].place_id,
                  mainText: suggestions.structured_formatting.main_text,
                });
              }}
              autocompletionRequest={{
                types: ["(regions)"],
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationFilter;
