import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core"
import { connect } from "react-redux";
import "./gratitude-list.scss";
import { useTranslation } from "react-i18next";
import VideoThumbnail from 'react-video-thumbnail';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ShowMoreText from 'react-show-more-text';

import Cleaner from "../../../../Resources/Images/Cleaner.svg";
import Marker from "../../../../Resources/Images/marker.svg";

import PromoteGreenImg from "../../../../Resources/Images/promote-green.svg";
import PromoteImg from "../../../../Resources/Images/promote.svg";
import SendImg from "../../../../Resources/Images/send.svg";
import TranslateImg from "../../../../Resources/Images/translate.svg";
import FalgImg from "../../../../Resources/Images/flag.svg";
import ComImg from "../../../../Resources/Images/comment.svg";
import FalgRedImg from "../../../../Resources/Images/flag-red.svg";
import DittoImg from "../../../../Resources/Images/ditto.svg";
import dot from "../../../../Resources/Images/dots.svg";
import link from "../../../../Resources/Images/link.svg";

// Recipient icons
import GiveImg from "../../../../Resources/Images/give.svg";
import DefaultImg from "../../../../Resources/Images/Default.svg";
import FrontLinersImg from "../../../../Resources/Images/NursesDoctors.svg";
import DisasterReliefImg from "../../../../Resources/Images/DiasterRelief.svg";
import MentalHealthImg from "../../../../Resources/Images/MentalHealth.svg";
import DomesticViolenceImg from "../../../../Resources/Images/DomesticViolence.svg";
import SuperMarketImg from "../../../../Resources/Images/SuperMarket.svg";
import PublicServiceImg from "../../../../Resources/Images/PublicService.svg";
import FirstResponseImg from "../../../../Resources/Images/FirstResponse.svg";
import FamilyFriendsImg from "../../../../Resources/Images/FamilyFriends.svg";
import DeliveryImg from "../../../../Resources/Images/Delivery.svg";
import ChemistsImg from "../../../../Resources/Images/Chemists.svg";
import fbImage from "../../../../Resources/Images/sg.jpg";
import { translateMessagesSaga } from "../../../../Sagas/communitySaga";
import { getLanguage } from '../../../../Utils/Storage';
import { Helmet } from "react-helmet";
import ReadMoreReact from "read-more-react";

const GratitudeList = ({
  dataList = {},
  makePromo = () => { },
  reportMsg = () => { },
  shareMsg = () => { },
  replyMsg = () => { },
  translateMsg = () => { },
  viewTranslateIcon = true,
  donation = () => { },
  openDittoComponent = () => { },
  dittoMsg = () => { },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [receipientIcon, setReceipientIcon] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [copySuccess, setcopySuccess] = useState(false);
  const [copyMessage , setCopyMessage]= useState('');
  const [togglecopyMessage , setTogglecopyMessage]= useState(false);
  const  [bb , setbb]= useState('');
  let enableRepostMsg = false;
  if (dataList.ditto.dittoedUsers) {
    enableRepostMsg = localStorage.getItem("browserHash") !== dataList.browserHash &&
      dataList.ditto.dittoedUsers.findIndex((dittoUser) => dittoUser.browserHash === localStorage.getItem("browserHash")) < 0 ?
      true : false;
  }

  const language = getLanguage();

  useEffect(() => {
    switch (dataList.messageIcon) {
      case "Nurses_Doctors":
        setReceipientIcon(FrontLinersImg);
        break;
      case "Disaster_Relief":
        setReceipientIcon(DisasterReliefImg);
        break;
      case "Mental_Health":
        setReceipientIcon(MentalHealthImg);
        break;
      case "Domestic_Violence":
        setReceipientIcon(DomesticViolenceImg);
        break;
      case "Supermarkets":
        setReceipientIcon(SuperMarketImg);
        break;
      case "Public_Service":
        setReceipientIcon(PublicServiceImg);
        break;
      case "First_Response":
        setReceipientIcon(FirstResponseImg);
        break;
      case "Family_Friends":
        setReceipientIcon(FamilyFriendsImg);
        break;
      case "Delivery":
        setReceipientIcon(DeliveryImg);
        break;
      case "Chemists":
        setReceipientIcon(ChemistsImg);
        break;
      default:
        setReceipientIcon(DefaultImg);
    }
     const dd =  replaceURLs(dataList.message);
  }, [dataList]);

  let gratitudeLang = (dataList.language) ? dataList.language.split("-") : ["en"];
  if (gratitudeLang[0] == language) {
    viewTranslateIcon = false;
  } else {
    viewTranslateIcon = true;
  }

 

  useEffect(() => {
    if (isPopoverOpen) {
      const timer = setTimeout(() => {
        setIsPopoverOpen(false)
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isPopoverOpen]);

 
  useEffect(() => {
    if (copyMessage) {
      const timer = setTimeout(() => {
        setCopyMessage('')
        setcopySuccess(false)
        setTogglecopyMessage(false)
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copyMessage]);
 const  copyCodeToClipboard = () => {
    setCopyMessage('Copied!')
    setToggle(!toggle)
    setcopySuccess(true)
    setTogglecopyMessage(true)
  
  }

  let msg = dataList.message.replace(/^\s+|\s+$/g, '').trim();

  const replaceURLs= (message) =>{
  if(!message) return;
 
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = 'http://' + hyperlink;
    }
  //  <a href={user.linkedninHandle}></a>
    return (`<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`)
  });
}
  return (
    <div className="massege-item d-flex">
      <div className="icon">
        <img src={receipientIcon} alt="" />
      </div>

      <div className="application">
        <Helmet>
          <title>{t("Message of Gratitude")}</title>
          <meta name="description" content={dataList.message} />
          <meta property="og:title" content={dataList.author} />
          <meta property="og:description" content={dataList.message} />
          <meta property="og:url" content={`${process.env.REACT_APP_LIVE_URL}/msg/${dataList._id}`} />
          <meta property="og:image" content={fbImage} />
        </Helmet>
      </div>

      <div className="w-100 descripton">
        <div className="name-loc pl-0">
          {dataList.recipientName ?
            <p className="name mr-2">
              {t("Dear ")}{dataList.recipientName},
            </p> : (<p className="name mr-2">{t("Dear ")}</p>)}

          <div className="location ml-0">
            <img src={Marker} alt="" />
            <span>{dataList.recipientsLocation}</span>
          </div>
        </div>

        <div className='dd-toggle' onClick={() => setToggle(!toggle)}>
          <img src={dot} />
        </div>
        <CopyToClipboard text={`${process.env.REACT_APP_LIVE_URL}/msg/${dataList._id}`}
          onCopy={() => {  copyCodeToClipboard() }}>
          <div className={`dd ${toggle ? "toggle" : ""}`}>
            <span value="copy text"><img src={link} />{t("copy_link")}</span>           
          </div>
        </CopyToClipboard>     
          {
             copySuccess ?
             <CopyToClipboard 
                 >
             <div className={`dd ${togglecopyMessage ? "toggle" : ""}`}>
               <span style={{color:"#008080"}} value="copy text"><img src={link} />{t("copyMessage")}</span>
              
             </div>
           
           </CopyToClipboard> : null
          }
        {/* <div className={`dd ${toggle ? "toggle" : ""}`}>
          <span ref={copyLinkBtn} value="copy text" onClick={(e) => { copyToClipboard(`${process.env.REACT_APP_LIVE_URL}/msg/${dataList._id}`); handleClick(e); setIsPopoverOpen(true); setToggle(!toggle) }} ><img src={link} />{t("copy_link")}</span>
        </div> */}

        <div className='msg-tag-loc'>
          <div className="tag-group">
            {dataList.taggedRecipient.map((tag, index) => (<span key={index}>#{tag.text}</span>))}
            {/* <div className="location"><img src={Marker} alt="" />{dataList.recipientsLocation}</div> */}
          </div>


        </div>
        
       <div className='descripton dscp'> 
         
        <p dangerouslySetInnerHTML={{
             __html: `
        ${replaceURLs(msg)}
        `}}/>
       </div>
        

        {/* {dataList.recipientName ?
            <p className="rec-name">
             {dataList.recipientName},
            </p> : ''} */}

        {/* <p className="descripton">{dataList.message}</p> */}
        <div className='msg-img'>
          {dataList.mediaName && dataList.mediaType == 'image' ? <img src={`${process.env.REACT_APP_CLOUD_FRONT_URL}${dataList.mediaName}`}/> : ""}
          {dataList.mediaName && dataList.mediaType == 'video' ?
           (<div
           dangerouslySetInnerHTML={{
             __html: `
             <video
               loop
               muted
               autoplay
               playsinline
               preload="metadata"
               controls
             >
             <source src=${process.env.REACT_APP_CLOUD_FRONT_URL}${dataList.mediaName} />

             </video>`
           }}
         />
       ): ""}
        </div>
        {/* {dataList.links && dataList.links.length > 0  ? 
          dataList.links.map(data=> {
           return (
             <div>
              <a href={data} target="_blank"  alt="">{data}</a>
              <br />
            </div>
           )
          })
       :""} */}
      
        <div className='msg-footer'>
          <ul className="clearfix mb-1">
            {/* <li>
              <a>
                <Tooltip title={t("Give")} enterDelay={500} arrow>
                  <img onClick={() => donation(dataList)} src={GiveImg} alt="" />
                </Tooltip>
              </a>
            </li> */}
            {/* <li>
              <a onClick={() => replyMsg(dataList)}>
                <Tooltip title={t("Reply")} enterDelay={500} arrow>
                  <img src={ComImg} alt="" />
                </Tooltip>
              </a>
              {dataList.replyCount ? <span className='rep-count' onClick={() => replyMsg(dataList)}>{dataList.replyCount}</span> : ''}
            </li> */}

            <li>
              {" "}
              <a onClick={() => shareMsg(dataList)}>
                <Tooltip title={t("Share")} enterDelay={500} arrow>
                  <img src={SendImg} alt="" />
                </Tooltip>
              </a>
            </li>
            <li>
              {" "}
              <a
                onClick={() =>
                  reportMsg(
                    dataList,
                    dataList.indecentReport.reportedHashes.includes(
                      props.userPrecense.data.browserHash
                    )
                      ? true
                      : false
                  )
                }
              >
                <Tooltip title={t("Report")} enterDelay={500} arrow>
                  <img
                    src={
                      props.userPrecense.data ?
                        dataList.indecentReport.reportedHashes.includes(
                          props.userPrecense.data.browserHash
                        )
                          ? FalgRedImg
                          : FalgImg
                        : FalgImg
                    }
                    alt=""
                    className="flag"
                  />
                </Tooltip>
              </a>
            </li>
            <li>
              <a onClick={() => makePromo(dataList)}>
                <Tooltip title={t("Like")} enterDelay={500} arrow>
                  <img
                    src={
                      props.userPrecense.data ?
                        dataList.support.supportedHashes.includes(
                          props.userPrecense.data.browserHash
                        )
                          ? PromoteGreenImg
                          : PromoteImg
                        : PromoteImg
                    }
                    alt=""
                  />
                </Tooltip>
                {dataList.support.count > 0 ? (
                  <span className="rep-count ml-1">{dataList.support.count}</span>
                ) : (
                    ""
                  )}
              </a>
            </li>


            {/* < li >
              <a onClick={() =>
                enableRepostMsg ?
                  dittoMsg(dataList) : ""}>

                {
                  enableRepostMsg ?
                    <Tooltip title={t("Repost")} enterDelay={500} arrow>
                      <img
                        src={DittoImg}
                        alt=""
                      />
                    </Tooltip>
                    :
                    dataList.ditto.dittoCount ?
                      <img
                        src={DittoImg}
                        alt=""
                      />
                      : ""
                }
              </a>
              {dataList.ditto.dittoCount ?
                <a onClick={() => openDittoComponent(dataList.ditto.dittoedUsers)}>
                  <Tooltip title={t("Reposted By")} enterDelay={500} arrow>
                    <span className="rep-count ml-1" >{dataList.ditto.dittoCount}</span>
                  </Tooltip>
                </a>
                : ""
              }
            </li> */}


            {viewTranslateIcon && (
              <li className="translate">
                {" "}
                <a onClick={() => translateMsg(dataList)}>
                  <Tooltip title={t("Translate")} enterDelay={500} arrow>
                    <img src={TranslateImg} alt="" />
                  </Tooltip>
                </a>
              </li>
            )}
          </ul>
          <div className="name-loc pl-0">
            <p className="name mb-0">{dataList.author}</p>
            <span className="location">
              <img src={Marker} alt="" />
              <span>{dataList.authorLocation}</span>
            </span>
          </div>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (response) => ({
  userPrecense: response.general.userPrecense,
});
export default connect(mapStateToProps)(GratitudeList);
