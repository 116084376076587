import React from 'react';
import Helmet from 'react-helmet'

import Layout from '../../../Layout/Layout';
import InputGratutude from './InputGratitude';
// import InputGratutude from './MsgImpact';

const injectGA = () => {
    if (typeof window == 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'DC-10340840');

    gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-10340840/2020-000/2020-0+standard'
    });

}


const Gratitude = () => {
    return (
        <Layout>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=DC-10340840"></script>
                <script>
                    {injectGA()}
                </script>
            </Helmet>
            <InputGratutude />
        </Layout >
    );
}

export default Gratitude;
