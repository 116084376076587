import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import CommunityPage from "../../Community/Pages/Community";
import TourGuidePage from "../../TourGiude/Pages/TourGuide";
import SplashScreen from "../../SplashScreen/Pages/SplashScreen";
import { checkUserPrecenseAction, setUserVisitBeforeAction, setTourGuideIsViewAction } from "../../../../Actions/generalAction";
import TourGuideDialog from "../../TourGiude/Pages/TourGuideDialog";

import { getGeocodeByIPaddress } from "../../../../Utils/GoogleAPI";
import { setLangAction } from "../../../../Actions/generalAction";
import { setLanguage, getLanguage, removeLanguage } from '../../../../Utils/Storage';

const HomePage = (props) => {
  var idleTime = 0;
  const [IsUserVisitedBefore, setIsUserVisitedBefore] = useState(true);
  const [IsLoading, setIsLoading] = useState(true);
  const [openTourGuide, setOpenTourGuide] = useState(false);
  const { t, i18n } = useTranslation();
  const selectedLanguage = getLanguage();


  let defaultLang = selectedLanguage;
  if (!selectedLanguage) {
    defaultLang = 'en';
  }

  useEffect(() => {
    setLang();
  }, []);

  const setLang = async () => {
    var geoCode = await getGeocodeByIPaddress();
    var lang = "";
    var country = geoCode.country;
    if (country === "CN") lang = "zh";
    else if (country === "ES") lang = "es";
    else if (country === "FR") lang = "fr";
    else if (country === "DE") lang = "de";
    else if (country === "IT") lang = "it";
    else if (country === "JP") lang = "ja";
    else if (country === "IR") lang = "fa";
    else if (country === "PT") lang = "pt";
    else lang = "en";

    if (!selectedLanguage) {
      setLanguage(lang);
      props.setLangAction(lang);
      i18n.changeLanguage(lang);

    } else {
      props.setLangAction(selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  };

  useEffect(() => {
    props.checkUserPrecenseAction();
  }, []);

  useEffect(() => {
    if (props.userPrecense.data) {
      if (props.userPrecense.data.isUserVisitedBefore) {
        setTimeout(function(){
          setOpenTourGuide(false);
          setIsLoading(false);
          setIsUserVisitedBefore(true);
        },0);
      } else {
        localStorage.setItem(
          "browserHash",
          props.userPrecense.data.browserHash
        );
        props.userPrecense.toolTipsRequiered = props.userPrecense.data.isUserVisitedBefore ? false : true;
        props.userPrecense.data.isUserVisitedBefore = true;

        setTimeout(function(){
          setOpenTourGuide(true);
          setIsLoading(false);
          setIsUserVisitedBefore(false);
          setUserVisitBeforeAction(props.userPrecense);
        },0);
        
      }
    }
  }, [props.userPrecense]);

  useEffect(() => {
    // if open tour guide start calculate idele time
    //console.log('Open tour guide ==>', openTourGuide);
    setInterval(() => {
      timerIncrement();
    }, 1000); // 1 seconds

    //console.log('idle time =>', idleTime);
    // would reset the timer 
    window.onload = window.ontouchstart = window.onclick = window.onkeypress = () => {
      idleTime = 0;
    }
  }, [openTourGuide]);

  const timerIncrement = () => {
    if (openTourGuide && idleTime < 11) {
      idleTime = idleTime + 1;
      if (idleTime === 10) { // 10 minutes
        closeTourGuide();
      }
    }
  }

  const closeTourGuide = () => {
    //console.log('Call function');
    setIsUserVisitedBefore(true);
    setOpenTourGuide(false);
    setIsLoading(false);
    props.setTourGuideIsViewAction();
  }

  return (
    <React.Fragment>
      {/* <CommunityPage /> */}
      {IsLoading ? (
        <div>
          <SplashScreen />
        </div>
      ) : <CommunityPage />}

      {/* <TourGuideDialog
        open={openTourGuide}
        userPrecense={() => closeTourGuide()}
      /> */}

    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserPrecenseAction: () => dispatch(checkUserPrecenseAction()),
    setUserVisitBeforeAction: (data) => dispatch(setUserVisitBeforeAction(data)),
    setTourGuideIsViewAction: () => dispatch(setTourGuideIsViewAction()),
    setLangAction: (data) => dispatch(setLangAction(data)),
  };
};

const mapStateToProps = (response) => ({
  userPrecense: response.general.userPrecense,
  lang: response.community.messages,
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
